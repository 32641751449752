import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { first, map, debounceTime, distinctUntilChanged } from "rxjs/operators";

import { isEqual } from "lodash";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { IPersistedFilter, IPersistedFilterList } from "@lib/filter/types";
import { ISelection } from "@lib/persistence/types";

import {
  IFeldRequest,
  IFeldResponse,
  IFeldResponseGroup,
  IFeldQueryFilterShapes
} from '@fa-kt-evaluation/felder/types';

@Injectable({
  providedIn: 'root'
})
export class FeldService {

  protected route = 'felder/feld';
  protected dependencies = [
    'felder/written-feld-kategorie',
    'felder/removed-feld-kategorie',
    'felder/written-feld-unterkategorie',
    'felder/removed-feld-unterkategorie',

    'felder/bewertet',
    'felder/feld-entfernt',
    'felder/feld-gespeichert',
    'felder/feld-option-entfernt',
    'felder/feld-option-gespeichert',
    'felder/feld-optionen-geordnet',
    'formulare/formular-entfernt',
    'formulare/formular-gespeichert',
    'formulare/formular-kopiert'
  ];

  readonly $result = new BehaviorSubject<IFeldResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IFeldRequest|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IFeldQueryFilterShapes|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private device: DeviceConfigService,
  ) {
/* << changes    */
/*    changes >> */
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IFeldRequest): IFeldRequest {
    if (payload) {
      this.$payload.next(payload as IFeldRequest);
    } else {
      payload = this.$payload.getValue() as IFeldRequest;
    }
    return payload;
  }

  public async request(payload: IFeldRequest, force = false): Promise<IFeldResponse> {
    const state = this.$result.getValue();
    if (!force && !this.device.alwaysRefresh && state && isEqual(payload, this.payload())) { return state; }
    payload = this.payload(payload);
    // if (!payload) { return this.$result.getValue() as IFeldResponse; }
    const query = this.http.serialize(payload);
    const { response, shapes } = await this.http.get<IFeldResponseGroup>(this.route + query);
    this.$result.next(response);
    this.$shapes.next(shapes);
    return response;
  }


  getFeldFeldKategorie(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['feld.feldKategorie']?.find(v => v.value === value)
  }

  get feldFeldKategorie(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['feld.feldKategorie'] as ISelection[] : []));
  }

  getFeldFeldUnterkategorie(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['feld.feldUnterkategorie']?.find(v => v.value === value)
  }

  get feldFeldUnterkategorie(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['feld.feldUnterkategorie'] as ISelection[] : []));
  }


}
