import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostProjekteHochzaehlenCommandServiceModule } from '../';
import { PostProjekteHochzaehlenCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostProjekteHochzaehlenCommandServiceModule
  ],
  declarations: [PostProjekteHochzaehlenCommandComponent],
  exports: [PostProjekteHochzaehlenCommandComponent],

})
export class PostProjekteHochzaehlenCommandComponentModule { }
