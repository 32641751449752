import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { DashboardServiceModule } from '../';
import { SavedDashboardFiltersComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    DashboardServiceModule
  ],
  declarations: [SavedDashboardFiltersComponent],
  exports: [SavedDashboardFiltersComponent],

})
export class SavedDashboardFiltersComponentModule { }
