import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IPostGutachtenRequest, IPostGutachtenResponse, IPostGutachtenCommandShapes } from '@fa-kt-evaluation/gutachten/types';

@Injectable({
  providedIn: 'root'
})
export class PostGutachtenCommandService {

  readonly route = 'gutachten/gutachten';
  protected dependencies = [
      'gutachten/written-gutachten-status',
      'gutachten/removed-gutachten-status',
      'objekte/written-objekt-art',
      'objekte/removed-objekt-art',
      'objekte/written-objekt-nutzung',
      'objekte/removed-objekt-nutzung',
      'gutachten/gutachten-gespeichert'
  ];

  readonly $shapes = new BehaviorSubject<IPostGutachtenCommandShapes|undefined>(undefined);

  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IPostGutachtenCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IPostGutachtenCommandShapes>(this.route + '/post/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IPostGutachtenRequest): Promise<IPostGutachtenResponse> {
    return await this.http.post<IPostGutachtenResponse>(this.route, payload) as unknown as IPostGutachtenResponse;
  }

  getGutachtenBewertungsStatus(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['gutachten.bewertungsStatus']?.find(v => v.value === value)
  }

  get gutachtenBewertungsStatus(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['gutachten.bewertungsStatus'] as ISelection[] : []));
  }

  getGutachtenObjekt(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['gutachten.objekt']?.find(v => v.value === value)
  }

  get gutachtenObjekt(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['gutachten.objekt'] as ISelection[] : []));
  }

  getGutachtenObjektArt(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['gutachten.objektArt']?.find(v => v.value === value)
  }

  get gutachtenObjektArt(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['gutachten.objektArt'] as ISelection[] : []));
  }

  getGutachtenObjektNutzung(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['gutachten.objektNutzung']?.find(v => v.value === value)
  }

  get gutachtenObjektNutzung(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['gutachten.objektNutzung'] as ISelection[] : []));
  }

}
