import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostReaktivierenCommandService } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [PostReaktivierenCommandService],
  exports: []
})
export class PostReaktivierenCommandServiceModule { }
