import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { DeleteAbteilungCommandServiceModule } from '../';
import { DeleteAbteilungCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteAbteilungCommandServiceModule
  ],
  declarations: [DeleteAbteilungCommandComponent],
  exports: [DeleteAbteilungCommandComponent],

})
export class DeleteAbteilungCommandComponentModule { }
