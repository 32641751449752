import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostProjektCommandServiceModule } from '../';
import { PostProjektCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostProjektCommandServiceModule
  ],
  declarations: [PostProjektCommandComponent],
  exports: [PostProjektCommandComponent],

})
export class PostProjektCommandComponentModule { }
