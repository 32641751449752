import { NgModule } from '@angular/core';
import {
  FeldFilterComponentModule,
  FeldDetailComponentModule,
  FeldSorterComponentModule,
  SavedFeldFiltersComponentModule
} from './';

@NgModule({
  imports: [
    FeldFilterComponentModule,
    FeldDetailComponentModule,
    FeldSorterComponentModule,
    SavedFeldFiltersComponentModule
  ],
  exports: [
    FeldFilterComponentModule,
    FeldDetailComponentModule,
    FeldSorterComponentModule,
    SavedFeldFiltersComponentModule
  ],
})
export class FeldQueryModule { }
