import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostSortiereAbschnitteCommandServiceModule } from '../';
import { PostSortiereAbschnitteCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostSortiereAbschnitteCommandServiceModule
  ],
  declarations: [PostSortiereAbschnitteCommandComponent],
  exports: [PostSortiereAbschnitteCommandComponent],

})
export class PostSortiereAbschnitteCommandComponentModule { }
