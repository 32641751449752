import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { KundenServiceModule } from '../';
import { KundenFilterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    KundenServiceModule
  ],
  declarations: [KundenFilterComponent],
  exports: [KundenFilterComponent],

})
export class KundenFilterComponentModule { }
