import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { ProfilServiceModule } from '../';
import { SavedProfilFiltersComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ProfilServiceModule
  ],
  declarations: [SavedProfilFiltersComponent],
  exports: [SavedProfilFiltersComponent],

})
export class SavedProfilFiltersComponentModule { }
