import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { IchServiceModule } from '../';
import { IchDetailComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    IchServiceModule
  ],
  declarations: [IchDetailComponent],
  exports: [IchDetailComponent],

})
export class IchDetailComponentModule { }
