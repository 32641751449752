import { NgModule } from '@angular/core';

import {
  PostZeileCommandServiceModule,
  PostZeileCommandComponentModule
} from './';

@NgModule({
  imports: [
    PostZeileCommandServiceModule,
    PostZeileCommandComponentModule
  ],
  exports: [
    PostZeileCommandServiceModule,
    PostZeileCommandComponentModule
  ]
})
export class PostZeileCommandModule { }
