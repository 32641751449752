import { NgModule } from '@angular/core';
import { 
  PostMitarbeiterCommandModule,
  PostSyncAzureActiveDirectoryCommandModule
} from './';

@NgModule({
  declarations: [],
  imports: [
    PostMitarbeiterCommandModule,
    PostSyncAzureActiveDirectoryCommandModule
  ],
  exports: [
    PostMitarbeiterCommandModule,
    PostSyncAzureActiveDirectoryCommandModule
  ]
})
export class PersonenCommandsModule { }
