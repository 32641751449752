import { Inject, Injectable, Injector } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree, UrlSegment } from "@angular/router";

import { BehaviorSubject, Observable } from "rxjs";

import { APP_CONFIG, IAppConfig } from "@lib/common/frontend";
import { AuthService } from "../../services";
import { addDays } from "date-fns";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  get auth(): AuthService {
    return this.injector.get(AuthService);
  }

  constructor(
    @Inject(APP_CONFIG) public environment: IAppConfig,
    private injector: Injector,
    private router: Router) {};

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.checkAndLoadRoutes();
    if ((this.isAuthenticated() && this.auth.access(state.url))) { //  || this.environment.no401
      return true;
    }
    this.fail().then();
    return false
  }

  private isAuthenticated(): boolean {
    if (this.auth.$id.getValue()) {
      return true;
    }
    const navigation = this.router.getCurrentNavigation();
    if (navigation && !navigation.previousNavigation && this.auth.$accounts.getValue().length > 0) {
      return true;
    }
    return false;
  }

  private async fail() {
    await this.router.navigate(['401']);
    return false;
  }

  private checkAndLoadRoutes() {
    const routes = this.auth.$routes.getValue();
    if (routes.length === 0) {
      const storage = localStorage.getItem(this.auth.AUTHORIZED_ROUTES_KEY);
      const { authorizedRoutes, expires } = (storage ? JSON.parse(storage) : { authorizedRoutes: "[]", expires: addDays(new Date(), -1) });
      if (new Date() > expires) {
        this.auth.$routes = new BehaviorSubject(authorizedRoutes ? JSON.parse(authorizedRoutes) : []);
      }
    }
  }
}
