import { NgModule } from '@angular/core';

import { LIB_CommonModule } from "@lib/common/frontend";

import { IchService } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [
    IchService,
  ],
  exports: []
})
export class IchServiceModule { }
