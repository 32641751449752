import { NgModule } from '@angular/core';

import {
  DeleteZeitCommandServiceModule,
  DeleteZeitCommandComponentModule
} from './';

@NgModule({
  imports: [
    DeleteZeitCommandServiceModule,
    DeleteZeitCommandComponentModule
  ],
  exports: [
    DeleteZeitCommandServiceModule,
    DeleteZeitCommandComponentModule
  ]
})
export class DeleteZeitCommandModule { }
