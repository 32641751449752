export const environment = {
  production: true,
  mode: process.env.NODE_ENV,
  stage: 'staging',
/* << environment    */
  admin: false,
  no401: false,
  serverURL: 'https://fa-kt-valuation-dev.azurewebsites.net/api/v1/',
  appInsights: {
    instrumentationKey: '841b053f-1aa5-4bb0-a0bd-315c4269492c',
  },
  auth: {
    principal: '2941f114-af5b-4d34-bdc3-c91eb69ccc96',
    clientId: '7e7b2845-7d68-45b9-a999-ee04fb16507f',
    authority: 'https://login.microsoftonline.com/f8fa683f-82b6-4aaa-8cdc-852cacd82240'
  },
  shared: 'QeThWmZq4t7w!z%C*F-JaNdRfUjXn2r5u8x/A?D(G+KbPeShVkYp3s6v9y$B&E)H'
/*    environment >> */
};
