import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from "@lib/auth/frontend";

@Component({
  selector: 'codeboard-welcome',
  templateUrl: './welcome.page.html',
  styleUrls: ['./welcome.page.css']
})
export class WelcomePage implements OnDestroy, OnInit {
  constructor(public auth: AuthService) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }
}
