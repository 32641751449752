import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { GeoCommandsModule } from './commands'
import {
  GeoQuerysModule,
} from './querys'
import {
  BereichsServiceModule, BereichsService
} from './settings';

const routes: Routes = [
  {
    path: 'geo',
    loadChildren: () => import('./pages').then(m => m.GeoPagesModule)
  },
  {
    path: 'geo',
    children: [
      {
        path: 'settings',
        loadChildren: () => import('./settings').then(m => m.GeoSettingsModule),
      }
    ]
  },
];

@NgModule({
  imports: [
    GeoCommandsModule,
    GeoQuerysModule,
    BereichsServiceModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    GeoCommandsModule,
    GeoQuerysModule,
    BereichsServiceModule
  ]
})
export class GeoModule {
  static forRoot(): ModuleWithProviders< GeoModule> {
    return {
      ngModule: GeoModule,
      providers: [
        BereichsService
      ]
    };
  }
}
