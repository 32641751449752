import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IPostRechnungRequest, IPostRechnungResponse, IPostRechnungCommandShapes } from '@fa-kt-evaluation/finanzen/types';

@Injectable({
  providedIn: 'root'
})
export class PostRechnungCommandService {

  readonly route = 'finanzen/rechnung';
  protected dependencies = [
      'finanzen/rechnung-erstellt'
  ];

  readonly $shapes = new BehaviorSubject<IPostRechnungCommandShapes|undefined>(undefined);

  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IPostRechnungCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IPostRechnungCommandShapes>(this.route + '/post/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IPostRechnungRequest): Promise<IPostRechnungResponse> {
    return await this.http.post<IPostRechnungResponse>(this.route, payload) as unknown as IPostRechnungResponse;
  }

  getRechnungBesichtigungen(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['rechnung.besichtigungen']?.find(v => v.value === value)
  }

  get rechnungBesichtigungen(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['rechnung.besichtigungen'] as ISelection[] : []));
  }

  getRechnungExtraVereinbarungs(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['rechnung.extraVereinbarungs']?.find(v => v.value === value)
  }

  get rechnungExtraVereinbarungs(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['rechnung.extraVereinbarungs'] as ISelection[] : []));
  }

  getRechnungHonorarVereinbarung(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['rechnung.honorarVereinbarung']?.find(v => v.value === value)
  }

  get rechnungHonorarVereinbarung(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['rechnung.honorarVereinbarung'] as ISelection[] : []));
  }

}
