import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { first, map, debounceTime, distinctUntilChanged } from "rxjs/operators";

import { isEqual } from "lodash";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { FilterPipe, SearchPipe, SorterPipe } from "@lib/filter/frontend";
import { IPersistedFilter, IPersistedFilterList } from "@lib/filter/types";
import { ISelection } from "@lib/persistence/types";

import {
  IUmkreissucheRequest,
  IUmkreissucheResponse,
  IUmkreissucheResponseGroup,
  IUmkreissucheQuerySorter,
  IUmkreissucheQueryFilter,
  IUmkreissucheQueryFilterShapes
} from '@fa-kt-evaluation/gutachten/types';

@Injectable({
  providedIn: 'root'
})
export class UmkreissucheService {

  protected route = 'gutachten/umkreissuche';
  protected dependencies = [
    'geo/written-bereich',
    'geo/removed-bereich',
    'gutachten/written-bewertungs-anlass',
    'gutachten/removed-bewertungs-anlass',
    'gutachten/written-gutachten-status',
    'gutachten/removed-gutachten-status',
    'objekte/written-objekt-art',
    'objekte/removed-objekt-art',
    'objekte/written-objekt-nutzung',
    'objekte/removed-objekt-nutzung',
    'personen/written-standort',
    'personen/removed-standort',
    'projekte/written-projekt-art',
    'projekte/removed-projekt-art',
    'projekte/written-projekt-status',
    'projekte/removed-projekt-status',

    'besichtigungen/besichtigung-entfernt',
    'besichtigungen/besichtigung-geordnet',
    'besichtigungen/besichtigung-gespeichert',
    'dokumente/datei-gespeichert',
    'dokumente/dokument-hinterlegt',
    'einstellungen/kalkulation-hochgeladen',
    'felder/bewertet',
    'finanzen/rechnung-erstellt',
    'finanzen/zahlungseingang-entfernt',
    'finanzen/zahlungseingang-gespeichert',
    'formulare/durchgang-gestartet',
    'gutachten/gutachten-entfernt',
    'gutachten/gutachten-gespeichert',
    'kommunikation/erinnerung-abgemeldet',
    'kommunikation/erinnerung-entfernt',
    'kommunikation/erinnerung-gemerkt',
    'kommunikation/vermerk-entfernt',
    'kommunikation/vermerkt',
    'objekte/objekt-entfernt',
    'objekte/objekt-gespeichert',
    'projekte/projekt-archiviert',
    'projekte/projekt-entfernt',
    'projekte/projekt-gespeichert',
    'projekte/projekt-kopiert',
    'projekte/projekt-reaktiviert',
    'zeiten/zeit-entfernt',
    'zeiten/zeit-gespeichert'
  ];

  readonly $result = new BehaviorSubject<IUmkreissucheResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IUmkreissucheRequest|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IUmkreissucheQueryFilterShapes|undefined>(undefined);
  readonly $response = new BehaviorSubject<IUmkreissucheResponse|undefined>(undefined);
  readonly $filter = new BehaviorSubject<IUmkreissucheQueryFilter>({});
  readonly $sorter = new BehaviorSubject<IUmkreissucheQuerySorter>({});
  readonly $search = new BehaviorSubject<string>('');
  readonly $presets = new BehaviorSubject<IPersistedFilter<IUmkreissucheResponse>[]>([]);
  readonly $preset = new BehaviorSubject<IPersistedFilter<IUmkreissucheResponse>|undefined>(undefined);
  private readonly $filtered = new BehaviorSubject<IUmkreissucheResponse|undefined>(undefined);
  private readonly $searched = new BehaviorSubject<IUmkreissucheResponse|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private device: DeviceConfigService,
    private search: SearchPipe,
    private filter: FilterPipe,
    private sorter: SorterPipe,
  ) {
/* << changes    */
/*    changes >> */
    combineLatest([
      this.$response,
      this.$filter,
    ]).subscribe(([response, filter]) => {
      if (response && response.umkreissuche) {
        const umkreissuche = this.filter.transform(response.umkreissuche, filter);
        this.$filtered.next( { ...response, umkreissuche });
      } else {
        this.$filtered.next(response);
      }
    });
    combineLatest([
      this.$filtered,
      this.$search.pipe(debounceTime(device.debounceTime), distinctUntilChanged()),
      this.$shapes.pipe(distinctUntilChanged((a, b) => isEqual(a, b)))
    ]).subscribe(([filtered, search, shapes]) => {
      if (filtered && filtered.umkreissuche) {
        const umkreissuche = this.search.transform(filtered.umkreissuche, search, '_search', shapes);
        this.$searched.next( { ...filtered, umkreissuche });
      } else {
        this.$searched.next(filtered);
      }
    });
    combineLatest([
      this.$searched,
      this.$sorter.pipe(distinctUntilChanged((a, b) => isEqual(a, b))),
      this.$shapes.pipe(distinctUntilChanged((a, b) => isEqual(a, b)))
    ]).pipe(debounceTime(10)).subscribe(([sorted, sorter, shapes]) => {
      if (sorted && sorted.umkreissuche) {
        const umkreissuche = this.sorter.transform(sorted.umkreissuche, sorter, shapes);
        this.$result.next( { ...sorted, umkreissuche });
      } else {
        this.$result.next(sorted);
      }
    });
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IUmkreissucheRequest): IUmkreissucheRequest {
    if (payload) {
      this.$payload.next(payload as IUmkreissucheRequest);
    } else {
      payload = this.$payload.getValue() as IUmkreissucheRequest;
    }
    return payload;
  }

  public async request(payload: IUmkreissucheRequest, force = false): Promise<IUmkreissucheResponse> {
    const state = this.$response.getValue();
    if (!force && !this.device.alwaysRefresh && state && isEqual(payload, this.payload())) { return state; }
    payload = this.payload(payload);
    if (!payload) { return this.$response.getValue() as IUmkreissucheResponse; }
    const query = this.http.serialize(payload);
    const { response, shapes, filter } = await this.http.get<IUmkreissucheResponseGroup>(this.route + query);
    this.$response.next(response);
    this.$shapes.next(shapes);
    this.$presets.next(filter && filter.filters ? filter.filters : []);
    return response;
  }

  public async writeFilter(filter: IPersistedFilter<IUmkreissucheResponse>): Promise<IPersistedFilter<IUmkreissucheResponse>|undefined> {
    filter = { ...filter, term: this.$search.getValue(), sorter: this.$sorter.getValue(), filter: this.$filter.getValue() };
    const result = await this.http.post(this.route + '/filters', filter) as IPersistedFilterList<IUmkreissucheResponse>;
    filter = result.filters.find(f => f.name === filter.name) as IPersistedFilter<IUmkreissucheResponse>;
    this.$presets.next(result.filters ? result.filters : []);
    this.setFilter(filter);
    return filter;
  }

  public async deleteFilter(id: string): Promise<void> {
    const result = await this.http.delete(this.route + '/filters?id=' + id) as IPersistedFilterList<IUmkreissucheResponse>;
    this.$presets.next(result.filters ? result.filters : []);
    this.setFilter();
  }

  public setFilter(filter?: IPersistedFilter<IUmkreissucheResponse>): void {
    this.$search.next(filter && filter.term ? filter.term : '');
    this.$filter.next(filter && filter.filter ? filter.filter : {});
    this.$sorter.next(filter && filter.sorter ? filter.sorter : {});
    this.$preset.next(filter ? filter : undefined);
  }


  getProjektAbteilung(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.abteilung']?.find(v => v.value === value)
  }

  get projektAbteilung(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.abteilung'] as ISelection[] : []));
  }

  getObjektBereiche(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['objekt.bereiche']?.find(v => v.value === value)
  }

  get objektBereiche(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['objekt.bereiche'] as ISelection[] : []));
  }

  getGutachtenBewertungsAnlass(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['gutachten.bewertungsAnlass']?.find(v => v.value === value)
  }

  get gutachtenBewertungsAnlass(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['gutachten.bewertungsAnlass'] as ISelection[] : []));
  }

  getProjektFond(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.fond']?.find(v => v.value === value)
  }

  get projektFond(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.fond'] as ISelection[] : []));
  }

  getGutachtenBewertungsStatus(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['gutachten.bewertungsStatus']?.find(v => v.value === value)
  }

  get gutachtenBewertungsStatus(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['gutachten.bewertungsStatus'] as ISelection[] : []));
  }

  getProjektKunde(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.kunde']?.find(v => v.value === value)
  }

  get projektKunde(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.kunde'] as ISelection[] : []));
  }

  getProjektBank(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.bank']?.find(v => v.value === value)
  }

  get projektBank(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.bank'] as ISelection[] : []));
  }

  getProjektBesichtigungen(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.besichtigungen']?.find(v => v.value === value)
  }

  get projektBesichtigungen(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.besichtigungen'] as ISelection[] : []));
  }

  getProjektGutachten(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.gutachten']?.find(v => v.value === value)
  }

  get projektGutachten(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.gutachten'] as ISelection[] : []));
  }

  getProjektPruefung(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.pruefung']?.find(v => v.value === value)
  }

  get projektPruefung(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.pruefung'] as ISelection[] : []));
  }

  getGutachtenObjekt(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['gutachten.objekt']?.find(v => v.value === value)
  }

  get gutachtenObjekt(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['gutachten.objekt'] as ISelection[] : []));
  }

  getGutachtenObjektArt(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['gutachten.objektArt']?.find(v => v.value === value)
  }

  get gutachtenObjektArt(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['gutachten.objektArt'] as ISelection[] : []));
  }

  getGutachtenObjektNutzung(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['gutachten.objektNutzung']?.find(v => v.value === value)
  }

  get gutachtenObjektNutzung(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['gutachten.objektNutzung'] as ISelection[] : []));
  }

  getProjektProjektArt(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.projektArt']?.find(v => v.value === value)
  }

  get projektProjektArt(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.projektArt'] as ISelection[] : []));
  }

  getProjektProjektStatus(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.projektStatus']?.find(v => v.value === value)
  }

  get projektProjektStatus(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.projektStatus'] as ISelection[] : []));
  }

  getProjektStandort(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.standort']?.find(v => v.value === value)
  }

  get projektStandort(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.standort'] as ISelection[] : []));
  }


}
