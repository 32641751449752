<nz-page-header [nzTitle]="PageTitle" [nzSubtitle]="PageSubitle">
  <ng-template #PageTitle>
    💻️ Das Gerät
  </ng-template>
  <ng-template #PageSubitle>
  </ng-template>
  <nz-breadcrumb nz-page-header-breadcrumb>
    <codeboard-breadcrumbs [pages]="['admin']"></codeboard-breadcrumbs>
  </nz-breadcrumb>
  <nz-page-header-extra>
  </nz-page-header-extra>
  <nz-page-header-content>
  </nz-page-header-content>
</nz-page-header>
<nz-content>
  <nz-row>
    <nz-col nzSpan='24' style="padding: 32px;">
      <codeboard-offline-cache></codeboard-offline-cache>
    </nz-col>
    <nz-col nzSpan='24' style="padding: 32px;">
      <codeboard-device-config></codeboard-device-config>
    </nz-col>
    <nz-col nzSpan="24" style="padding: 32px">
      <h3 nz-title>Dienste</h3>
      <codeboard-health></codeboard-health>
    </nz-col>
    <nz-col nzSpan='24' style="padding: 32px">
      <span style="float: right; margin-bottom: 16px" nz-typography nzType="secondary"><code>{{version}}-{{ stage === mode ? stage : stage + ' - ' + mode}} ({{build}})</code></span>
    </nz-col>
  </nz-row>
</nz-content>
