import { Component, OnInit, OnDestroy, Inject, Input, Output, EventEmitter, Injector, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { NzMessageService } from "ng-zorro-antd/message";

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { AuthService } from "@lib/auth/frontend";
import {
  ActionService,
  cleanObject, toInitials,
  CustomValidators, CustomFormatter,
  ISitemap, ISitemapCommand, SITEMAP,
} from "@lib/common/frontend";

import { IPostZahlungseingangRequest, IPostZahlungseingangResponse } from "@fa-kt-evaluation/finanzen/types";
import { PostZahlungseingangCommandService } from '@fa-kt-evaluation/finanzen/frontend'

/* << importing    */
import { IKunde } from '@fa-kt-evaluation/kunden/types';
/*    importing >> */

@Component({
  selector: 'fa-kt-evaluation-post-zahlungseingang',
  templateUrl: './post-zahlungseingang.component.html',
  styleUrls: ['./post-zahlungseingang.component.css']
})
export class PostZahlungseingangCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) { this.$loading.next(this.$loading.getValue() || loading); }
  @Input() set value(value: IPostZahlungseingangRequest) { this.patch(value); }
  @Output() valueChanged: EventEmitter<IPostZahlungseingangRequest> = new EventEmitter<IPostZahlungseingangRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostZahlungseingangResponse> = new EventEmitter<IPostZahlungseingangResponse>();

/* << fields    */
  @Input() projekt: string = "";
/*    fields >> */

  get modalRef() {
    return this.injector.get(NzModalRef)
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public postZahlungseingang: PostZahlungseingangCommandService,
/* << more    */
/*    more >> */
  ) {
    this.command = sitemap.FINANZEN.Commands.POST_ZAHLUNGSEINGANG;
    this.form = this.fb.group({
      zahlungseingang: this.fb.group({
        id: [null, []],
        projekt: [null, [Validators.required]],
        kunde: [null, []],
        rechnung: [null, [Validators.required]],
        summe: [null, [Validators.required]],
        datum: [null, [Validators.required]],
      })
    });
/* << constructor    */
/*    constructor >> */
    this.patch();
  }

  ngOnInit() {
    this.postZahlungseingang.prepare().catch(e => console.error(e));
    this.subscriptions.push(
      this.finished.subscribe((result) => { if (this.modalRef) { this.modalRef.destroy(result); }}),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() })
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostZahlungseingangRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),
      this.zahlungseingangId?.valueChanges.subscribe(async (id) => {
/* << on-zahlungseingang-id-change    */
/*    on-zahlungseingang-id-change >> */
      }) as Subscription,
      this.zahlungseingangProjekt?.valueChanges.subscribe(async (projekt) => {
/* << on-zahlungseingang-projekt-change    */
/*    on-zahlungseingang-projekt-change >> */
      }) as Subscription,
      this.zahlungseingangKunde?.valueChanges.subscribe(async (kunde) => {
/* << on-zahlungseingang-kunde-change    */
/*    on-zahlungseingang-kunde-change >> */
      }) as Subscription,
      this.zahlungseingangRechnung?.valueChanges.subscribe(async (rechnung) => {
/* << on-zahlungseingang-rechnung-change    */
        const selection = this.postZahlungseingang.getZahlungseingangRechnung(rechnung);
        if (selection && this.zahlungseingangSumme) {
          this.zahlungseingangSumme.patchValue(selection?.summe);
        }
/*    on-zahlungseingang-rechnung-change >> */
      }) as Subscription,
      this.zahlungseingangSumme?.valueChanges.subscribe(async (summe) => {
/* << on-zahlungseingang-summe-change    */
/*    on-zahlungseingang-summe-change >> */
      }) as Subscription,
      this.zahlungseingangDatum?.valueChanges.subscribe(async (datum) => {
/* << on-zahlungseingang-datum-change    */
/*    on-zahlungseingang-datum-change >> */
      }) as Subscription,
/* << subscibe    */
/*    subscibe >> */
    );
/* << init    */
/*    init >> */
  }

  ngOnDestroy() {
/* << end    */
/*    end >> */
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IPostZahlungseingangRequest) {
    if (value) {
      value = cleanObject(value);
      if (value.zahlungseingang) {
        value.zahlungseingang = cleanObject(value.zahlungseingang);
      }
      this.form.patchValue(value);
/* << custom    */
/*    custom >> */
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload.zahlungseingang) {
        payload.zahlungseingang = cleanObject(payload.zahlungseingang);
      }
      const response: IPostZahlungseingangResponse = await this.postZahlungseingang.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of (error.errors ? error.errors : [])) {
        for (const children of (validation.children ? validation.children : [])) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

/* << methods    */
/*    methods >> */

  get zahlungseingangId() {
    return this.form.controls.zahlungseingang.get('id');
  };
  get zahlungseingangProjekt() {
    return this.form.controls.zahlungseingang.get('projekt');
  };
  get zahlungseingangKunde() {
    return this.form.controls.zahlungseingang.get('kunde');
  };
  get zahlungseingangRechnung() {
    return this.form.controls.zahlungseingang.get('rechnung');
  };
  get zahlungseingangSumme() {
    return this.form.controls.zahlungseingang.get('summe');
  };
  get zahlungseingangDatum() {
    return this.form.controls.zahlungseingang.get('datum');
  };


}
