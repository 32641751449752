import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { ProjekteServiceModule } from '../';
import { ProjekteSorterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ProjekteServiceModule
  ],
  declarations: [ProjekteSorterComponent],
  exports: [ProjekteSorterComponent],

})
export class ProjekteSorterComponentModule { }
