import { NgModule } from '@angular/core';
import {
  KundeFilterComponentModule,
  KundeDetailComponentModule,
  KundeSorterComponentModule,
  SavedKundeFiltersComponentModule
} from './';

@NgModule({
  imports: [
    KundeFilterComponentModule,
    KundeDetailComponentModule,
    KundeSorterComponentModule,
    SavedKundeFiltersComponentModule
  ],
  exports: [
    KundeFilterComponentModule,
    KundeDetailComponentModule,
    KundeSorterComponentModule,
    SavedKundeFiltersComponentModule
  ],
})
export class KundeQueryModule { }
