import { NgModule } from '@angular/core';
import {
  ZeitenFilterComponentModule,
  ZeitenListComponentModule,
  ZeitenSorterComponentModule,
  SavedZeitenFiltersComponentModule
} from './';

@NgModule({
  imports: [
    ZeitenFilterComponentModule,
    ZeitenListComponentModule,
    ZeitenSorterComponentModule,
    SavedZeitenFiltersComponentModule
  ],
  exports: [
    ZeitenFilterComponentModule,
    ZeitenListComponentModule,
    ZeitenSorterComponentModule,
    SavedZeitenFiltersComponentModule
  ],
})
export class ZeitenQueryModule { }
