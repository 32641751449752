import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IPostReferenzenHochladenRequest, IPostReferenzenHochladenResponse, IPostReferenzenHochladenCommandShapes } from '@fa-kt-evaluation/felder/types';

@Injectable({
  providedIn: 'root'
})
export class PostReferenzenHochladenCommandService {

  readonly route = 'felder/referenzen-hochladen';
  protected dependencies = [
      'felder/referenzen-hochgeladen'
  ];

  readonly $shapes = new BehaviorSubject<IPostReferenzenHochladenCommandShapes|undefined>(undefined);
  
  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IPostReferenzenHochladenCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IPostReferenzenHochladenCommandShapes>(this.route + '/post/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IPostReferenzenHochladenRequest): Promise<IPostReferenzenHochladenResponse> {
    return await this.http.upload<IPostReferenzenHochladenResponse>(this.route, payload) as unknown as IPostReferenzenHochladenResponse;
  }
  
}
