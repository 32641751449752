import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IPostAbteilungRequest, IPostAbteilungResponse, IPostAbteilungCommandShapes } from '@fa-kt-evaluation/kunden/types';

@Injectable({
  providedIn: 'root'
})
export class PostAbteilungCommandService {

  readonly route = 'kunden/abteilung';
  protected dependencies = [
      'kunden/abteilung-gespeichert'
  ];

  readonly $shapes = new BehaviorSubject<IPostAbteilungCommandShapes|undefined>(undefined);
  
  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IPostAbteilungCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IPostAbteilungCommandShapes>(this.route + '/post/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IPostAbteilungRequest): Promise<IPostAbteilungResponse> {
    return await this.http.post<IPostAbteilungResponse>(this.route, payload) as unknown as IPostAbteilungResponse;
  }
  
}
