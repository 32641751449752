import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { FormulareServiceModule } from '../';
import { SavedFormulareFiltersComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    FormulareServiceModule
  ],
  declarations: [SavedFormulareFiltersComponent],
  exports: [SavedFormulareFiltersComponent],

})
export class SavedFormulareFiltersComponentModule { }
