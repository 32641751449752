import { NgModule } from '@angular/core';

import {
  PostBesichtigungCommandServiceModule,
  PostBesichtigungCommandComponentModule
} from './';

@NgModule({
  imports: [
    PostBesichtigungCommandServiceModule,
    PostBesichtigungCommandComponentModule
  ],
  exports: [
    PostBesichtigungCommandServiceModule,
    PostBesichtigungCommandComponentModule
  ]
})
export class PostBesichtigungCommandModule { }
