import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostAbteilungCommandService } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [PostAbteilungCommandService],
  exports: []
})
export class PostAbteilungCommandServiceModule { }
