import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { ReisekostenabrechnungServiceModule } from '../';
import { ReisekostenabrechnungFilterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ReisekostenabrechnungServiceModule
  ],
  declarations: [ReisekostenabrechnungFilterComponent],
  exports: [ReisekostenabrechnungFilterComponent],

})
export class ReisekostenabrechnungFilterComponentModule { }
