<!---
  <div formGroupName="mitarbeiter">
    <input class="cdk-visually-hidden" formControlName="id" id="id"/>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="email">{{ command.Inputs.EMAIL.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="EmailErrorTip">
        <ng-template #EmailErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.EMAIL.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="email" id="email" [placeholder]="command.Inputs.EMAIL.placeholder" cdkFocusInitial/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="anzeigename">{{ command.Inputs.ANZEIGENAME.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AnzeigenameErrorTip">
        <ng-template #AnzeigenameErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ANZEIGENAME.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="anzeigename" id="anzeigename" [placeholder]="command.Inputs.ANZEIGENAME.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="bild">{{ command.Inputs.BILD.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BildErrorTip">
        <ng-template #BildErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BILD.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="bild" id="bild" [placeholder]="command.Inputs.BILD.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <codeboard-address-input formControlName="addresse" id="addresse" required="false" ></codeboard-address-input>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="vorname">{{ command.Inputs.VORNAME.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="VornameErrorTip">
        <ng-template #VornameErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.VORNAME.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="vorname" id="vorname" [placeholder]="command.Inputs.VORNAME.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="nachname">{{ command.Inputs.NACHNAME.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NachnameErrorTip">
        <ng-template #NachnameErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NACHNAME.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="nachname" id="nachname" [placeholder]="command.Inputs.NACHNAME.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="telefon">{{ command.Inputs.TELEFON.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="TelefonErrorTip">
        <ng-template #TelefonErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.TELEFON.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="telefon" id="telefon" [placeholder]="command.Inputs.TELEFON.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="standort">{{ command.Inputs.STANDORT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="StandortErrorTip">
        <ng-template #StandortErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STANDORT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="standort" id="standort" [shapes]="postMitarbeiter.mitarbeiterStandort | async" [placeholder]="command.Inputs.STANDORT.placeholder" required=true ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="rollen">{{ command.Inputs.ROLLEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="RollenErrorTip">
        <ng-template #RollenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ROLLEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value mode="multiple" formControlName="rollen" id="rollen" [shapes]="postMitarbeiter.mitarbeiterRollen | async" [placeholder]="command.Inputs.ROLLEN.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="stundenlohn">{{ command.Inputs.STUNDENLOHN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="StundenlohnErrorTip">
        <ng-template #StundenlohnErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STUNDENLOHN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="stundenlohn" id="stundenlohn" [nzPlaceHolder]="command.Inputs.STUNDENLOHN.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="inaktiv">{{ command.Inputs.INAKTIV.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="InaktivErrorTip">
        <ng-template #InaktivErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.INAKTIV.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <label nz-checkbox formControlName="inaktiv" id="inaktiv"></label>
      </nz-form-control>
    </nz-form-item>
  <nz-form-item style="width: 100%;">
    <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="isAdmin">Administrator</nz-form-label>
    <nz-form-control [nzSm]="12" [nzXs]="24" >
      <label nz-checkbox formControlName="isAdmin" id="isAdmin"></label>
    </nz-form-control>
  </nz-form-item>
  </div>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
<!--- << custom    -->
    <div formGroupName="mitarbeiter">
      <input class="cdk-visually-hidden" formControlName="id" id="id"/>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="bild">{{ command.Inputs.BILD.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24">
          <codeboard-image-upload formControlName="bild" id="bild" ></codeboard-image-upload>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="email">{{ command.Inputs.EMAIL.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="command.Inputs.EMAIL.hint">
          <input nz-input formControlName="email" id="email" [placeholder]="command.Inputs.EMAIL.placeholder"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="anzeigename">{{ command.Inputs.ANZEIGENAME.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="command.Inputs.ANZEIGENAME.hint">
          <input nz-input formControlName="anzeigename" id="anzeigename" [placeholder]="command.Inputs.ANZEIGENAME.placeholder" cdkFocusInitial/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="vorname">Namen</nz-form-label>
        <nz-form-control [nzSm]="6" [nzXs]="24" >
          <input nz-input formControlName="vorname" id="vorname" [placeholder]="command.Inputs.VORNAME.placeholder"/>
        </nz-form-control>
        <nz-form-control [nzSm]="6" [nzXs]="24" >
          <input nz-input formControlName="nachname" id="nachname" [placeholder]="command.Inputs.NACHNAME.placeholder"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="telefon">{{ command.Inputs.TELEFON.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" >
          <input nz-input formControlName="telefon" id="telefon" [placeholder]="command.Inputs.TELEFON.placeholder"/>
        </nz-form-control>
      </nz-form-item>
      <codeboard-address-input formControlName="addresse" id="addresse" placeholder="Heimatadresse"></codeboard-address-input>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="standort">{{ command.Inputs.STANDORT.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="StandortErrorTip">
          <ng-template #StandortErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STANDORT.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <codeboard-select-value formControlName="standort" id="standort" [shapes]="postMitarbeiter.mitarbeiterStandort | async" [placeholder]="command.Inputs.STANDORT.placeholder" required=true ></codeboard-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="rollen">{{ command.Inputs.ROLLEN.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="command.Inputs.ROLLEN.hint">
          <codeboard-select-value mode="multiple" formControlName="rollen" id="rollen" [shapes]="(postMitarbeiter.$shapes | async)?.['mitarbeiter.rollen']" [placeholder]="command.Inputs.ROLLEN.placeholder" ></codeboard-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="tourenplanung">Tourenplanung</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" >
          <label nz-checkbox formControlName="tourenplanung" id="tourenplanung"></label>
        </nz-form-control>
      </nz-form-item>
      <ng-container [ngSwitch]="auth.$isAdmin | async">
        <nz-form-item style="width: 100%;" *ngSwitchCase="true">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="isAdmin">Administrator</nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" >
            <label nz-checkbox formControlName="isAdmin" id="isAdmin"></label>
          </nz-form-control>
        </nz-form-item>
        <ng-container *ngSwitchCase="false">
          <label class="cdk-visually-hidden" nz-checkbox formControlName="isAdmin" id="isAdmin-hidden"></label>
        </ng-container>
      </ng-container>
      <ng-container [ngSwitch]="auth.$isFinanzen | async">
        <nz-form-item style="width: 100%;" *ngSwitchCase="true">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="stundenlohn">{{ command.Inputs.STUNDENLOHN.title}}</nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="StundenlohnErrorTip">
            <ng-template #StundenlohnErrorTip let-control>
              <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STUNDENLOHN.hint }}</ng-container>
              <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
            </ng-template>
            <input nz-input type="number" style="width: 100px" formControlName="stundenlohn" id="stundenlohn" [placeholder]="command.Inputs.STUNDENLOHN.placeholder"/> €
          </nz-form-control>
        </nz-form-item>
        <input class="cdk-visually-hidden" *ngSwitchCase="false" formControlName="stundenlohn" id="stundenlohn-hidden"/>
      </ng-container>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="inaktiv">{{ command.Inputs.INAKTIV.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="InaktivErrorTip">
          <ng-template #InaktivErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.INAKTIV.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <label nz-checkbox formControlName="inaktiv" id="inaktiv"></label>
        </nz-form-control>
      </nz-form-item>
    </div>
<!---    custom >> -->
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button"
          type="submit"
          [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)"
          nz-tooltip [nzTooltipTitle]="SubmitTooltip">
<!--- << confirm    -->
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Speichern
<!---    confirm >> -->
          <ng-template #SubmitTooltip>
<!--- << hint    -->
            Mitarbeiter in der App und dem <i nz-icon nzType="windows" nzTheme="outline"></i> Azure Active Directory speichern
<!---    hint >> -->
          </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
