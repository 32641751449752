import { NgModule } from '@angular/core';

import {
  PostHochladenCommandServiceModule,
  PostHochladenCommandComponentModule
} from './';

@NgModule({
  imports: [
    PostHochladenCommandServiceModule,
    PostHochladenCommandComponentModule
  ],
  exports: [
    PostHochladenCommandServiceModule,
    PostHochladenCommandComponentModule
  ]
})
export class PostHochladenCommandModule { }
