import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostHochladenCommandServiceModule } from '../';
import { PostHochladenCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostHochladenCommandServiceModule
  ],
  declarations: [PostHochladenCommandComponent],
  exports: [PostHochladenCommandComponent],

})
export class PostHochladenCommandComponentModule { }
