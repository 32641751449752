import { NgModule } from '@angular/core';
import { 
  BerichtQueryModule,
  BerichteQueryModule,
  DurchsuchenQueryModule
} from './';

@NgModule({
  declarations: [],
  imports: [
    BerichtQueryModule,
    BerichteQueryModule,
    DurchsuchenQueryModule
  ],
  exports: [
    BerichtQueryModule,
    BerichteQueryModule,
    DurchsuchenQueryModule
  ]
})
export class MarktQuerysModule { }
