import { Pipe, PipeTransform } from "@angular/core";

import get from 'lodash/get';

import {
  Filter,
  IDateValue,
  IFilter,
  IFilterExpression,
  IGeoRangeValue,
  INumberValue,
  IValue
} from "@lib/filter/types";
import { PipeLoadingService } from "../services";


@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  constructor(private loading: PipeLoadingService) {
  }

  transform(items?: Array<any>, filter?: IFilter<any>, cut = false): any {
    if (!items || items.length === 0) { return [] };
    if (!filter) { return items };
    this.loading.loading();
    Object.entries(filter).forEach(([key, filter]) => {
      if (cut) {
        const keys = key.split('.');
        keys.shift();
        key = keys.join('.');
      }
      items = (items ? items : []).filter(item => item && key && this.resolve(item, key, filter, filter && typeof filter.value === "boolean"));
    })
    this.loading.loaded();
    return items;
  }

  getValues(filter: Filter) {
    const value: IValue = filter.value;
    let min: any;
    let max: any;
    if (value) {
      min = (value as IDateValue).min || typeof (value as IDateValue).min === "number" ? (value as IDateValue).min : null;
      max = (value as IDateValue).max || typeof (value as IDateValue).max === "number" ? (value as IDateValue).max : null;
      // lat = (value as IGeoRangeValue).lat ? (value as IGeoRangeValue).lat : null;
      // lon = (value as IGeoRangeValue).lon ? (value as IGeoRangeValue).lon : null;
      // distance = (value as IGeoRangeValue).distance ? (value as IGeoRangeValue).distance : null;
    }
    return { value, min, max }
  }

  private resolve(item: any, key: string, filter: Filter, isBooleanFilter = false): boolean {
    if (!key || !item || !filter) { return false; }
    let check = get(item, key);
    if (check === undefined) {
      const paths = key.split('.');
      const items = get(item, paths.shift() as string);
      key = paths.join('.');
      if (!items || !items.map) { return filter.operator === 'nicht' || filter.operator === 'not in' };
      const results = items.map((item: any) => this.resolve(item, key, filter, isBooleanFilter));
      return results.some((r: boolean) => r);
    }
    let { value, min, max } = this.getValues(filter);
    if (Array.isArray(check) && check.length === 0) {
      (check as any) = [null];
    }
    switch (filter.operator) {
      case "in":
        if (Array.isArray(check)) {
          return check.some(v => v >= min && v <= max);
        } else {
          if (check === null || check === undefined) { check = 0; }
          if (min && (min as Date).toISOString && max && (max as Date).toISOString) {
            (min as Date).setHours(0);
            (min as Date).setMinutes(0);
            (min as Date).setSeconds(0);
            (max as Date).setHours(0);
            (max as Date).setMinutes(0);
            (max as Date).setSeconds(0);
            return (new Date(check) >= (min as Date) && (new Date(check)) <= (max as Date));
          }
          return ((check as number) >= (min as number) && (check as number) <= (max as number));
        }
        break;
      case "not in":
        if (Array.isArray(check)) {
          return !check.some(v => v >= min && v <= max);
        } else {
          if (check === null || check === undefined) { check = 0; }
          return !((check as number) >= (min as number) && (check as number) <= (max as number));
        }
        break;
      case ">":
        if (Array.isArray(check)) {
          return check.some(v => Number(v) > Number(value));
        } else {
          if (check === null || check === undefined) { check = 0; }
          return ((check as number) > (value as number));
        }
        break;
      case ">=":
        if (Array.isArray(check)) {
          return check.some(v => Number(v) >= Number(value));
        } else {
          if (check === null || check === undefined) { check = 0; }
          return ((check as number) >= (value as number));
        }
        break;
      case "=":
        if (Array.isArray(check)) {
          return check.some(v => v === value);
        } else {
          if (check === null || check === undefined) { check = 0; }
          return ((check as number) === (value as number));
        }
        break;
      case "<":
        if (Array.isArray(check)) {
          return check.some(v => Number(v) < Number(value));
        } else {
          if (check === null || check === undefined) { check = 0; }
          return ((check as number) < (value as number));
        }
        break;
      case "<=":
        if (Array.isArray(check)) {
          return check.some(v => Number(v) <= Number(value));
        } else {
          if (check === null || check === undefined) { check = 0; }
          return ((check as number) <= (value as number));
        }
        break;
      case "hat":
        if (Array.isArray(check)) {
          if (Array.isArray(value)) {
            return value.some(v => check.includes(v));
          } else {
            return check.includes(value)
          }
        } else if (Array.isArray(value)) {
          return value.includes(check)
        } else {
          return check === value;
        }
      case "alle":
      case "ist":
        if (Array.isArray(check)) {
          if (Array.isArray(value)) {
            return value.every(v => check.includes(v));
          } else {
            return check.includes(value)
          }
        } else if (Array.isArray(value)) {
          return value.includes(check)
        } else {
          return isBooleanFilter ? check && value as boolean || (!check && !value as boolean) : check === value;
        }
      case "nicht":
        if (Array.isArray(check)) {
          if (Array.isArray(value)) {
            return !value.some(v => check.includes(v));
          } else {
            return !check.includes(value)
          }
        } else if (Array.isArray(value)) {
          return !value.includes(check)
        } else {
          return check !== value;
        }
        break;
      default:
        return false;
    }
  }
}
