import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { FelderServiceModule } from '../';
import { FelderFilterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    FelderServiceModule
  ],
  declarations: [FelderFilterComponent],
  exports: [FelderFilterComponent],

})
export class FelderFilterComponentModule { }
