import { NgModule } from '@angular/core';
import { 
  OffeneGutachtenQueryModule,
  TourQueryModule,
  TourenQueryModule
} from './';

@NgModule({
  declarations: [],
  imports: [
    OffeneGutachtenQueryModule,
    TourQueryModule,
    TourenQueryModule
  ],
  exports: [
    OffeneGutachtenQueryModule,
    TourQueryModule,
    TourenQueryModule
  ]
})
export class BesichtigungenQuerysModule { }
