import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { FelderCommandsModule } from './commands'
import {
  FelderQuerysModule,
  FeldService,
  FelderService
} from './querys'
import {
  FeldKategoriesServiceModule, FeldKategoriesService,
  FeldUnterkategoriesServiceModule, FeldUnterkategoriesService
} from './settings';

const routes: Routes = [
  {
    path: 'felder',
    loadChildren: () => import('./pages').then(m => m.FelderPagesModule)
  },
  {
    path: 'felder',
    children: [
      {
        path: 'settings',
        loadChildren: () => import('./settings').then(m => m.FelderSettingsModule),
      }
    ]
  },
];

@NgModule({
  imports: [
    FelderCommandsModule,
    FelderQuerysModule,
    FeldKategoriesServiceModule,
    FeldUnterkategoriesServiceModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    FelderCommandsModule,
    FelderQuerysModule,
    FeldKategoriesServiceModule,
    FeldUnterkategoriesServiceModule
  ]
})
export class FelderModule {
  static forRoot(): ModuleWithProviders< FelderModule> {
    return {
      ngModule: FelderModule,
      providers: [
        FeldService,
        FelderService,
        FeldKategoriesService,
        FeldUnterkategoriesService
      ]
    };
  }
}
