import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { BerichteServiceModule } from '../';
import { BerichteFilterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    BerichteServiceModule
  ],
  declarations: [BerichteFilterComponent],
  exports: [BerichteFilterComponent],

})
export class BerichteFilterComponentModule { }
