import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IPostZeitRequest, IPostZeitResponse, IPostZeitCommandShapes } from '@fa-kt-evaluation/zeiten/types';

@Injectable({
  providedIn: 'root'
})
export class PostZeitCommandService {

  readonly route = 'zeiten/zeit';
  protected dependencies = [
      'zeiten/written-zeit-art',
      'zeiten/removed-zeit-art',
      'zeiten/written-zeit-unterart',
      'zeiten/removed-zeit-unterart',
      'zeiten/zeit-gespeichert'
  ];

  readonly $shapes = new BehaviorSubject<IPostZeitCommandShapes|undefined>(undefined);
  
  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IPostZeitCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IPostZeitCommandShapes>(this.route + '/post/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IPostZeitRequest): Promise<IPostZeitResponse> {
    return await this.http.post<IPostZeitResponse>(this.route, payload) as unknown as IPostZeitResponse;
  }
  
  getZeitKunde(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['zeit.kunde']?.find(v => v.value === value)
  }

  get zeitKunde(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['zeit.kunde'] as ISelection[] : []));
  }

  getZeitMitarbeiter(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['zeit.mitarbeiter']?.find(v => v.value === value)
  }

  get zeitMitarbeiter(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['zeit.mitarbeiter'] as ISelection[] : []));
  }

  getZeitProjekt(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['zeit.projekt']?.find(v => v.value === value)
  }

  get zeitProjekt(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['zeit.projekt'] as ISelection[] : []));
  }

  getZeitZeitArt(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['zeit.zeitArt']?.find(v => v.value === value)
  }

  get zeitZeitArt(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['zeit.zeitArt'] as ISelection[] : []));
  }

  getZeitZeitUnterart(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['zeit.zeitUnterart']?.find(v => v.value === value)
  }

  get zeitZeitUnterart(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['zeit.zeitUnterart'] as ISelection[] : []));
  }

}
