import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { UmkreissucheServiceModule } from '../';
import { UmkreissucheSorterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    UmkreissucheServiceModule
  ],
  declarations: [UmkreissucheSorterComponent],
  exports: [UmkreissucheSorterComponent],

})
export class UmkreissucheSorterComponentModule { }
