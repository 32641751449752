import { AbstractControl, FormControl, ValidationErrors } from "@angular/forms";

export class CustomValidators {
  static isNotSpecial(control: FormControl): void|{ [key: string]: boolean } {
    const nameRegexp: RegExp = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    if (control.value && nameRegexp.test(control.value)) {
      return { invalidName: true };
    }
  }
  static isUnique(isUnique: (value: any) => boolean) {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (isUnique(value)) {
        return null;
      }
      return { unique: '"' + value + '" existiert bereits' }
    }
  }
  static requiredFileType(types: string[]) {
    return function (control: FormControl) {
      const file = control.value;
      if (file) {
        const extension = file.name.split('.')[1].toLowerCase();
        if (!types.map(type => type.toLowerCase()).includes(extension.toLowerCase())) {
          return {
            requiredFileType: true
          };
        }
        return null;
      }
      return null;
    };
  }
}
