import { NgModule } from '@angular/core';

import { LIB_CommonModule } from "@lib/common/frontend";
import { FilterPipe, SearchPipe, SorterPipe } from "@lib/filter/frontend";

import { KundenService } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [
    KundenService,
    SearchPipe,
    FilterPipe,
    SorterPipe
  ],
  exports: []
})
export class KundenServiceModule { }
