import { Component, OnInit, OnDestroy, Inject, Input, Output, EventEmitter, Injector, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { NzMessageService } from "ng-zorro-antd/message";

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { AuthService } from "@lib/auth/frontend";
import {
  ActionService,
  cleanObject, toInitials,
  CustomValidators, CustomFormatter, 
  ISitemap, ISitemapCommand, SITEMAP,
} from "@lib/common/frontend";

import { IPostObjektRequest, IPostObjektResponse } from "@fa-kt-evaluation/objekte/types";
import { PostObjektCommandService } from '@fa-kt-evaluation/objekte/frontend'

/* << importing    */
import { IAddress } from "@lib/geo/types";
/*    importing >> */

@Component({
  selector: 'fa-kt-evaluation-post-objekt',
  templateUrl: './post-objekt.component.html',
  styleUrls: ['./post-objekt.component.css']
})
export class PostObjektCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) { this.$loading.next(this.$loading.getValue() || loading); }
  @Input() set value(value: IPostObjektRequest) { this.patch(value); }
  @Output() valueChanged: EventEmitter<IPostObjektRequest> = new EventEmitter<IPostObjektRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostObjektResponse> = new EventEmitter<IPostObjektResponse>();

/* << fields    */
  $addresse = new BehaviorSubject<IAddress|undefined>(undefined);
/*    fields >> */

  get modalRef() {
    return this.injector.get(NzModalRef)
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public postObjekt: PostObjektCommandService,
/* << more    */
/*    more >> */
  ) {
    this.command = sitemap.OBJEKTE.Commands.POST_OBJEKT;
    this.form = this.fb.group({
      objekt: this.fb.group({
        id: [null, []],
        name: [null, [Validators.required]],
        addresse: [],
        bereiche: [[], []],
        anspracheOutlookKontakts: [[], []],
        klingelschild: [null, []],
        kommentar: [null, []],
      })
    });
/* << constructor    */
    this.objektName?.disable();
/*    constructor >> */
    this.patch();
  }

  ngOnInit() {
    this.postObjekt.prepare().catch(e => console.error(e));
    this.subscriptions.push(
      this.finished.subscribe((result) => { if (this.modalRef) { this.modalRef.destroy(result); }}),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() })
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostObjektRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),
      this.objektId?.valueChanges.subscribe(async (id) => {
/* << on-objekt-id-change    */
/*    on-objekt-id-change >> */
      }) as Subscription,
      this.objektName?.valueChanges.subscribe(async (name) => {
/* << on-objekt-name-change    */
/*    on-objekt-name-change >> */
      }) as Subscription,
      this.objektAddresse?.valueChanges.subscribe(async (addresse) => {
/* << on-objekt-addresse-change    */
        this.$addresse.next(addresse)
        const name = this.objektName;
        name?.patchValue(`${addresse.strasse ? addresse.strasse + ', ' : ''}${ addresse.plz ? addresse.plz + ' ' : ''}${ addresse.land ? addresse.land : '' }`);
/*    on-objekt-addresse-change >> */
      }) as Subscription,
      this.objektBereiche?.valueChanges.subscribe(async (bereiche) => {
/* << on-objekt-bereiche-change    */
/*    on-objekt-bereiche-change >> */
      }) as Subscription,
      this.objektAnspracheOutlookKontakts?.valueChanges.subscribe(async (anspracheOutlookKontakts) => {
/* << on-objekt-ansprache-outlook-kontakts-change    */
/*    on-objekt-ansprache-outlook-kontakts-change >> */
      }) as Subscription,
      this.objektKlingelschild?.valueChanges.subscribe(async (klingelschild) => {
/* << on-objekt-klingelschild-change    */
/*    on-objekt-klingelschild-change >> */
      }) as Subscription,
      this.objektKommentar?.valueChanges.subscribe(async (kommentar) => {
/* << on-objekt-kommentar-change    */
/*    on-objekt-kommentar-change >> */
      }) as Subscription,
/* << subscibe    */
/*    subscibe >> */
    );
/* << init    */
/*    init >> */
  }

  ngOnDestroy() {
/* << end    */
/*    end >> */
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IPostObjektRequest) {
    if (value) {
      value = cleanObject(value);
      if (value.objekt) {
        value.objekt = cleanObject(value.objekt);
      }
      this.form.patchValue(value);
/* << custom    */
/*    custom >> */
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload.objekt) {
        payload.objekt = cleanObject(payload.objekt);
      }
      const response: IPostObjektResponse = await this.postObjekt.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of (error.errors ? error.errors : [])) {
        for (const children of (validation.children ? validation.children : [])) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

/* << methods    */
/*    methods >> */

  get objektId() {
    return this.form.controls.objekt.get('id');
  };
  get objektName() {
    return this.form.controls.objekt.get('name');
  };
  get objektAddresse() {
    return this.form.controls.objekt.get('addresse');
  };
  get objektBereiche() {
    return this.form.controls.objekt.get('bereiche');
  };
  get objektAnspracheOutlookKontakts() {
    return this.form.controls.objekt.get('anspracheOutlookKontakts');
  };
  get objektKlingelschild() {
    return this.form.controls.objekt.get('klingelschild');
  };
  get objektKommentar() {
    return this.form.controls.objekt.get('kommentar');
  };


}
