import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostTourCommandService } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [PostTourCommandService],
  exports: []
})
export class PostTourCommandServiceModule { }
