import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IPostFeldRequest, IPostFeldResponse, IPostFeldCommandShapes } from '@fa-kt-evaluation/felder/types';

@Injectable({
  providedIn: 'root'
})
export class PostFeldCommandService {

  readonly route = 'felder/feld';
  protected dependencies = [
      'felder/written-feld-kategorie',
      'felder/removed-feld-kategorie',
      'felder/written-feld-unterkategorie',
      'felder/removed-feld-unterkategorie',
      'felder/feld-gespeichert'
  ];

  readonly $shapes = new BehaviorSubject<IPostFeldCommandShapes|undefined>(undefined);
  
  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IPostFeldCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IPostFeldCommandShapes>(this.route + '/post/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IPostFeldRequest): Promise<IPostFeldResponse> {
    return await this.http.post<IPostFeldResponse>(this.route, payload) as unknown as IPostFeldResponse;
  }
  
  getFeldFeldKategorie(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['feld.feldKategorie']?.find(v => v.value === value)
  }

  get feldFeldKategorie(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['feld.feldKategorie'] as ISelection[] : []));
  }

  getFeldFeldUnterkategorie(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['feld.feldUnterkategorie']?.find(v => v.value === value)
  }

  get feldFeldUnterkategorie(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['feld.feldUnterkategorie'] as ISelection[] : []));
  }

}
