import { Component, OnInit, OnDestroy, Inject, Input, Output, EventEmitter, Injector, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { NzMessageService } from "ng-zorro-antd/message";

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { AuthService } from "@lib/auth/frontend";
import {
  ActionService,
  cleanObject, toInitials,
  CustomValidators, CustomFormatter,
  ISitemap, ISitemapCommand, SITEMAP,
} from "@lib/common/frontend";

import { IPostFeldRequest, IPostFeldResponse } from "@fa-kt-evaluation/felder/types";
import { PostFeldCommandService } from '@fa-kt-evaluation/felder/frontend'

/* << importing    */
import { IFeldOption } from "@fa-kt-evaluation/felder/types";
/*    importing >> */

@Component({
  selector: 'fa-kt-evaluation-post-feld',
  templateUrl: './post-feld.component.html',
  styleUrls: ['./post-feld.component.css']
})
export class PostFeldCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) { this.$loading.next(this.$loading.getValue() || loading); }
  @Input() set value(value: IPostFeldRequest) { this.patch(value); }
  @Output() valueChanged: EventEmitter<IPostFeldRequest> = new EventEmitter<IPostFeldRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostFeldResponse> = new EventEmitter<IPostFeldResponse>();

/* << fields    */
  @Input() optionen: IFeldOption[] = [];
/*    fields >> */

  get modalRef() {
    return this.injector.get(NzModalRef)
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public postFeld: PostFeldCommandService,
/* << more    */
/*    more >> */
  ) {
    this.command = sitemap.FELDER.Commands.POST_FELD;
    this.form = this.fb.group({
      feld: this.fb.group({
        id: [null, []],
        schluessel: [null, [Validators.required, CustomValidators.isNotSpecial]],
        name: [null, []],
        kommentar: [null, []],
        feldKategorie: [null, [Validators.required]],
        feldUnterkategorie: [null, [Validators.required]],
        art: [null, [Validators.required]],
        format: [null, []],
        voreinstellung: [null, []],
      })
    });
/* << constructor    */
/*    constructor >> */
    this.patch();
  }

  ngOnInit() {
    this.postFeld.prepare().catch(e => console.error(e));
    this.subscriptions.push(
      this.finished.subscribe((result) => { if (this.modalRef) { this.modalRef.destroy(result); }}),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() })
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostFeldRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),
      this.feldId?.valueChanges.subscribe(async (id) => {
/* << on-feld-id-change    */
/*    on-feld-id-change >> */
      }) as Subscription,
      this.feldSchluessel?.valueChanges.subscribe(async (schluessel) => {
/* << on-feld-schluessel-change    */
/*    on-feld-schluessel-change >> */
      }) as Subscription,
      this.feldName?.valueChanges.subscribe(async (name) => {
/* << on-feld-name-change    */
/*    on-feld-name-change >> */
      }) as Subscription,
      this.feldKommentar?.valueChanges.subscribe(async (kommentar) => {
/* << on-feld-kommentar-change    */
/*    on-feld-kommentar-change >> */
      }) as Subscription,
      this.feldFeldKategorie?.valueChanges.subscribe(async (feldKategorie) => {
/* << on-feld-feld-kategorie-change    */
/*    on-feld-feld-kategorie-change >> */
      }) as Subscription,
      this.feldFeldUnterkategorie?.valueChanges.subscribe(async (feldUnterkategorie) => {
/* << on-feld-feld-unterkategorie-change    */
/*    on-feld-feld-unterkategorie-change >> */
      }) as Subscription,
      this.feldArt?.valueChanges.subscribe(async (art) => {
/* << on-feld-art-change    */
/*    on-feld-art-change >> */
      }) as Subscription,
      this.feldFormat?.valueChanges.subscribe(async (format) => {
/* << on-feld-format-change    */
/*    on-feld-format-change >> */
      }) as Subscription,
      this.feldVoreinstellung?.valueChanges.subscribe(async (voreinstellung) => {
/* << on-feld-voreinstellung-change    */
/*    on-feld-voreinstellung-change >> */
      }) as Subscription,
/* << subscibe    */
      combineLatest([
        this.feldFeldKategorie?.valueChanges.pipe(distinctUntilChanged()),
        this.feldFeldUnterkategorie?.valueChanges.pipe(distinctUntilChanged()),
        this.feldName?.valueChanges.pipe(debounceTime(400), distinctUntilChanged()),
      ]).subscribe(() => {
        if (!this.feldFeldKategorie || !this.feldFeldUnterkategorie || !this.feldName) { return; }
        const shapes = this.postFeld.$shapes.getValue();
        const feldKategories = shapes?.['feld.feldKategorie'];
        const feldKategorie = feldKategories?.find(fk => fk.value === this.feldFeldKategorie?.value);
        const kategorie = feldKategorie?.label?.split(' |')[0];
        const feldUnterkategories = shapes?.['feld.feldUnterkategorie'];
        const feldUnterkategorie = feldUnterkategories?.find(fk => fk.value === this.feldFeldUnterkategorie?.value);
        const unterkategorie = feldUnterkategorie?.label?.split(' |')[0];
        const name = this.feldName.value;
        if (kategorie && unterkategorie && name) {
          this.feldSchluessel?.patchValue(kategorie + '_' + unterkategorie + '_' + toInitials(name));
        }
      }) as Subscription,
/*    subscibe >> */
    );
/* << init    */
/*    init >> */
  }

  ngOnDestroy() {
/* << end    */
/*    end >> */
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IPostFeldRequest) {
    if (value) {
      value = cleanObject(value);
      if (value.feld) {
        value.feld = cleanObject(value.feld);
      }
      this.form.patchValue(value);
/* << custom    */
/*    custom >> */
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload.feld) {
        payload.feld = cleanObject(payload.feld);
      }
      const response: IPostFeldResponse = await this.postFeld.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of (error.errors ? error.errors : [])) {
        for (const children of (validation.children ? validation.children : [])) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

/* << methods    */
  hasValue(option: string) {
    let state = this.feldVoreinstellung?.value;
    if (Array.isArray(state)) {
      return state.includes(option);
    } else {
      return state === option;
    }
  }

  toggleValues(option: string) {
    let state = this.feldVoreinstellung?.value;
    state = Array.isArray(state) ? state : [state];
    state = state.includes(option) ? state.filter((s: string) => s !== option) : [ ...state, option];
    state = state.filter((option: string) => option);
    this.feldVoreinstellung?.patchValue(state)
  }
/*    methods >> */

  get feldId() {
    return this.form.controls.feld.get('id');
  };
  get feldSchluessel() {
    return this.form.controls.feld.get('schluessel');
  };
  get feldName() {
    return this.form.controls.feld.get('name');
  };
  get feldKommentar() {
    return this.form.controls.feld.get('kommentar');
  };
  get feldFeldKategorie() {
    return this.form.controls.feld.get('feldKategorie');
  };
  get feldFeldUnterkategorie() {
    return this.form.controls.feld.get('feldUnterkategorie');
  };
  get feldArt() {
    return this.form.controls.feld.get('art');
  };
  get feldFormat() {
    return this.form.controls.feld.get('format');
  };
  get feldVoreinstellung() {
    return this.form.controls.feld.get('voreinstellung');
  };


}
