import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { DokumenteCommandsModule } from './commands'
import {
  DokumenteQuerysModule,
} from './querys'
import {
  DateiArtsServiceModule, DateiArtsService,
  ErforderlichesDokumentsServiceModule, ErforderlichesDokumentsService
} from './settings';

const routes: Routes = [
  {
    path: 'dokumente',
    loadChildren: () => import('./pages').then(m => m.DokumentePagesModule)
  },
  {
    path: 'dokumente',
    children: [
      {
        path: 'settings',
        loadChildren: () => import('./settings').then(m => m.DokumenteSettingsModule),
      }
    ]
  },
];

@NgModule({
  imports: [
    DokumenteCommandsModule,
    DokumenteQuerysModule,
    DateiArtsServiceModule,
    ErforderlichesDokumentsServiceModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    DokumenteCommandsModule,
    DokumenteQuerysModule,
    DateiArtsServiceModule,
    ErforderlichesDokumentsServiceModule
  ]
})
export class DokumenteModule {
  static forRoot(): ModuleWithProviders< DokumenteModule> {
    return {
      ngModule: DokumenteModule,
      providers: [
        DateiArtsService,
        ErforderlichesDokumentsService
      ]
    };
  }
}
