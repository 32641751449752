import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { VorlagenServiceModule } from '../';
import { VorlagenListComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    VorlagenServiceModule
  ],
  declarations: [VorlagenListComponent],
  exports: [VorlagenListComponent],

})
export class VorlagenListComponentModule { }
