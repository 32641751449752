import { ErrorHandler, ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ApplicationinsightsAngularpluginErrorService } from "@microsoft/applicationinsights-angularplugin-js";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ScrollingModule } from "@angular/cdk/scrolling";

import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzBreadCrumbModule } from "ng-zorro-antd/breadcrumb";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzNotificationModule, NzNotificationServiceModule } from "ng-zorro-antd/notification";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzInputNumberModule } from "ng-zorro-antd/input-number";
import { NzCardModule } from "ng-zorro-antd/card";
import { NzMentionModule } from "ng-zorro-antd/mention";
import { NzContextMenuServiceModule, NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzListModule } from "ng-zorro-antd/list";
import { NzMessageModule, NzMessageServiceModule } from "ng-zorro-antd/message";
import { NzSwitchModule } from "ng-zorro-antd/switch";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzCarouselModule } from "ng-zorro-antd/carousel";
import { NzPaginationModule } from "ng-zorro-antd/pagination";
import { NzTimelineModule } from "ng-zorro-antd/timeline";
import { NzResizableModule } from "ng-zorro-antd/resizable";
import { NzProgressModule } from "ng-zorro-antd/progress";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzAlertModule } from "ng-zorro-antd/alert";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { NzSliderModule } from "ng-zorro-antd/slider";
import { NzAutocompleteModule } from "ng-zorro-antd/auto-complete";
import { NzTreeModule } from "ng-zorro-antd/tree";
import { NzStatisticModule } from "ng-zorro-antd/statistic";
import { NzTreeSelectModule } from "ng-zorro-antd/tree-select";
import { NzStepsModule } from "ng-zorro-antd/steps";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";
import { NzCommentModule } from "ng-zorro-antd/comment";
import { NzSkeletonModule } from "ng-zorro-antd/skeleton";
import { NzBadgeModule } from "ng-zorro-antd/badge";
import { NzTagModule } from "ng-zorro-antd/tag";
import { NzSpaceModule } from "ng-zorro-antd/space";
import { NzDescriptionsModule } from "ng-zorro-antd/descriptions";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzTypographyModule } from "ng-zorro-antd/typography";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzEmptyModule } from "ng-zorro-antd/empty";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { NzAffixModule } from "ng-zorro-antd/affix";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzPipesModule } from "ng-zorro-antd/pipes";
import { NzUploadModule } from "ng-zorro-antd/upload";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzTimePickerModule } from "ng-zorro-antd/time-picker";
import { NzAnchorModule } from "ng-zorro-antd/anchor";
import { de_DE, NZ_DATE_LOCALE, NZ_I18N } from "ng-zorro-antd/i18n";
import { DisconnectOutline } from '@ant-design/icons-angular/icons';
import { de } from 'date-fns/locale';

import { LIB_GeoModule } from "@lib/geo/frontend";
import { LIB_AuthModule } from "@lib/auth/frontend";
import { LIB_HttpsModule } from "@lib/https/frontend";
import { LIB_FilterModule } from "@lib/filter/frontend";
import { LIB_ContactsModule } from "@lib/contacts/frontend";

import {
  ActionService,
  BroadcastService,
  DeviceConfigService,
  ResolverLoadingService,
  SecretService,
  HealthService
} from "./services";
import {
  HealthComponent,
  LoadingSpinnerComponent,
  ColorIconComponent,
  BreadcrumbComponent,
  DeviceConfigComponent,
  FileUploadComponent,
  ActionSelectorComponent,
  NavigationComponent,
  IconPickerComponent,
  ColorPickerComponent,
  TextEditorComponent,
  JsonViewerComponent,
  icons
} from "./components";
import { ActionSearchFilterPipe } from "./pipes";
import { DevicePage, NotFoundPage, UnauthorizedPage, WelcomePage } from "./pages";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { MetaComponent } from './components/meta/meta.component';

const routes: Routes = [
  { path: 'willkommen',   component: WelcomePage },
  { path: '404',   component: NotFoundPage },
  { path: '401',   component: UnauthorizedPage },
  { path: 'device',   component: DevicePage }
]


@NgModule({
  imports: [
    LIB_AuthModule,
    LIB_HttpsModule,
    LIB_FilterModule,
    LIB_GeoModule,
    LIB_ContactsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    DragDropModule,
    ScrollingModule,
    CKEditorModule,
    NzIconModule.forRoot([DisconnectOutline, ...icons]),
    NzMessageModule,
    NzMessageServiceModule,
    NzNotificationModule,
    NzNotificationServiceModule,
    NzLayoutModule,
    NzTypographyModule,
    NzModalModule,
    NzCardModule,
    NzSpaceModule,
    NzEmptyModule,
    NzSkeletonModule,
    NzPaginationModule,
    NzDividerModule,
    NzProgressModule,
    NzAffixModule,
    NzAlertModule,
    NzAnchorModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzPopconfirmModule,
    NzPopoverModule,
    NzGridModule,
    NzCollapseModule,
    NzBadgeModule,
    NzContextMenuServiceModule,
    NzAvatarModule,
    NzFormModule,
    NzButtonModule,
    NzTableModule,
    NzSelectModule,
    NzInputModule,
    NzAutocompleteModule,
    NzToolTipModule,
    NzTreeModule,
    NzTreeSelectModule,
    NzCheckboxModule,
    NzDescriptionsModule,
    NzSwitchModule,
    NzSpinModule,
    NzDropDownModule,
    NzDrawerModule,
    NzListModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzMenuModule,
    NzRadioModule,
    NzBreadCrumbModule,
    NzSliderModule,
    NzStatisticModule,
    NzTabsModule,
    NzTagModule,
    NzPipesModule,
    NzPageHeaderModule,
    NzStepsModule,
    NzSpaceModule,
    NzTimelineModule,
    NzCommentModule,
    NzCarouselModule,
    NzMentionModule,
    NzInputNumberModule,
    NzResizableModule,
    NzUploadModule,
    // NzCodeEditorComponent,
  ],
  declarations: [
    WelcomePage,
    NotFoundPage,
    UnauthorizedPage,
    DevicePage,
    IconPickerComponent,
    ColorPickerComponent,
    ColorIconComponent,
    LoadingSpinnerComponent,
    NavigationComponent,
    BreadcrumbComponent,
    ActionSelectorComponent,
    TextEditorComponent,
    FileUploadComponent,
    JsonViewerComponent,
    HealthComponent,
    DeviceConfigComponent,
    ActionSearchFilterPipe,
    MetaComponent,
  ],
  exports: [
    LIB_AuthModule,
    LIB_HttpsModule,
    LIB_FilterModule,
    LIB_GeoModule,
    LIB_ContactsModule,
    WelcomePage,
    NotFoundPage,
    UnauthorizedPage,
    DevicePage,
    IconPickerComponent,
    ColorPickerComponent,
    ColorIconComponent,
    LoadingSpinnerComponent,
    NavigationComponent,
    BreadcrumbComponent,
    ActionSelectorComponent,
    HealthComponent,
    DeviceConfigComponent,
    TextEditorComponent,
    FileUploadComponent,
    MetaComponent,
    JsonViewerComponent,
    ActionSearchFilterPipe,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DragDropModule,
    ScrollingModule,
    CKEditorModule,
    NzMessageModule,
    NzMessageServiceModule,
    NzNotificationModule,
    NzNotificationServiceModule,
    NzLayoutModule,
    NzTypographyModule,
    NzModalModule,
    NzCardModule,
    NzSpaceModule,
    NzEmptyModule,
    NzIconModule,
    NzSkeletonModule,
    NzPaginationModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzDividerModule,
    NzProgressModule,
    NzAffixModule,
    NzAlertModule,
    NzAnchorModule,
    NzPopconfirmModule,
    NzPopoverModule,
    NzGridModule,
    NzCollapseModule,
    NzBadgeModule,
    NzContextMenuServiceModule,
    NzAvatarModule,
    NzDatePickerModule,
    NzFormModule,
    NzButtonModule,
    NzTableModule,
    NzSelectModule,
    NzInputModule,
    NzAutocompleteModule,
    NzToolTipModule,
    NzTreeModule,
    NzTreeSelectModule,
    NzCheckboxModule,
    NzDescriptionsModule,
    NzSwitchModule,
    NzSpinModule,
    NzDropDownModule,
    NzDrawerModule,
    NzListModule,
    NzMenuModule,
    NzRadioModule,
    NzBreadCrumbModule,
    NzSliderModule,
    NzStatisticModule,
    NzTabsModule,
    NzTagModule,
    NzPipesModule,
    NzPageHeaderModule,
    NzStepsModule,
    NzSpaceModule,
    NzTimelineModule,
    NzCommentModule,
    NzCarouselModule,
    NzMentionModule,
    NzInputNumberModule,
    NzResizableModule,
    NzUploadModule,
    // NzCodeEditorComponent,
  ]
})
export class LIB_CommonModule {

  static forRoot(): ModuleWithProviders<LIB_CommonModule> {
    return {
      ngModule: LIB_CommonModule,
      providers: [
        { provide: NZ_I18N, useValue: de_DE },
        { provide: NZ_DATE_LOCALE, useValue: de },
        ActionService,
        BroadcastService,
        SecretService,
        HealthService,
        DeviceConfigService,
        ResolverLoadingService,
        { provide: ErrorHandler, useClass: ApplicationinsightsAngularpluginErrorService }
      ]
    };
  }
}
