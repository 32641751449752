import { NgModule } from '@angular/core';

import {
  PostSortiereAbschnitteCommandServiceModule,
  PostSortiereAbschnitteCommandComponentModule
} from './';

@NgModule({
  imports: [
    PostSortiereAbschnitteCommandServiceModule,
    PostSortiereAbschnitteCommandComponentModule
  ],
  exports: [
    PostSortiereAbschnitteCommandServiceModule,
    PostSortiereAbschnitteCommandComponentModule
  ]
})
export class PostSortiereAbschnitteCommandModule { }
