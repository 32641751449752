import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostZeitCommandServiceModule } from '../';
import { PostZeitCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostZeitCommandServiceModule
  ],
  declarations: [PostZeitCommandComponent],
  exports: [PostZeitCommandComponent],

})
export class PostZeitCommandComponentModule { }
