import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { ArchivServiceModule } from '../';
import { ArchivListComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ArchivServiceModule
  ],
  declarations: [ArchivListComponent],
  exports: [ArchivListComponent],

})
export class ArchivListComponentModule { }
