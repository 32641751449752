import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ResolverLoadingService {

  private _$resolving: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  readonly $resolving: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() {
    this._$resolving.pipe(map(resolvings => resolvings > 0)).subscribe(resolving => this.$resolving.next(resolving));
  }

  resolving(): void {
    this._$resolving.next(this._$resolving.getValue() + 1);
  }

  resolved(): void {
    this._$resolving.next(this._$resolving.getValue() - 1);
  }
}
