import { NgModule } from '@angular/core';

import {
  PostBerichtCommandServiceModule,
  PostBerichtCommandComponentModule
} from './';

@NgModule({
  imports: [
    PostBerichtCommandServiceModule,
    PostBerichtCommandComponentModule
  ],
  exports: [
    PostBerichtCommandServiceModule,
    PostBerichtCommandComponentModule
  ]
})
export class PostBerichtCommandModule { }
