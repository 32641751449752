import { Component, OnInit, OnDestroy, Inject, Input, Output, EventEmitter, Injector, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router, Params, Data } from "@angular/router";

import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { NzMessageService } from "ng-zorro-antd/message";

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map } from "rxjs/operators";

import { ActionService, ISitemap, ISitemapPage, ResolverLoadingService, SITEMAP } from "@lib/common/frontend";
import { AuthService } from "@lib/auth/frontend";
import { FileService } from '@lib/files/frontend';

import { IGutachten } from "@fa-kt-evaluation/gutachten/types";
import { IKunde } from "@fa-kt-evaluation/kunden/types";
import { IMitarbeiter } from "@fa-kt-evaluation/personen/types";
import { IProjekt } from "@fa-kt-evaluation/projekte/types";
import { IZeit } from "@fa-kt-evaluation/zeiten/types";
import { IZeitArt } from "@fa-kt-evaluation/zeiten/types";
import { IZeitUnterart } from "@fa-kt-evaluation/zeiten/types";
import { ZeitService } from "@fa-kt-evaluation/zeiten/frontend";
import { IZeitRequest, IZeitResponse } from "@fa-kt-evaluation/zeiten/types";
import { PostZeitCommandService } from "@fa-kt-evaluation/zeiten/frontend";
import { IPostZeitRequest, IPostZeitResponse } from "@fa-kt-evaluation/zeiten/types";
import { DeleteZeitCommandService } from "@fa-kt-evaluation/zeiten/frontend";
import { IDeleteZeitRequest, IDeleteZeitResponse } from "@fa-kt-evaluation/zeiten/types";
/* << imports    */
import { ProjektService } from "@fa-kt-evaluation/projekte/frontend";
import { GutachtenService } from "@fa-kt-evaluation/gutachten/frontend";
import { KundeService } from "@fa-kt-evaluation/kunden/frontend";

/*    imports >> */
@Component({
  selector: 'fa-kt-evaluation-zeit',
  templateUrl: './zeit.component.html',
  styleUrls: ['./zeit.component.css']
})
export class ZeitComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  public readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly $postZeit: BehaviorSubject<IPostZeitRequest|null> = new BehaviorSubject<IPostZeitRequest|null>(null)
  public readonly $deleteZeit: BehaviorSubject<IDeleteZeitRequest|null> = new BehaviorSubject<IDeleteZeitRequest|null>(null)


/* << fields    */
  @Input() set id(id: string) { this.openZeit(id)};
  @Output() idChanged = new EventEmitter<string>();
  @Input() set seconds(seconds: number) {
    if (seconds) {
      const minuten = Math.round(seconds / 60)
      const stunden = Math.floor(minuten / 60)
      this.patch({ minuten: minuten % 60, stunden });
      this.openZeit({});
    }
  };
  $patch = new BehaviorSubject<IZeit|undefined>(undefined)

  get projekt() {
    return this.injector.get(ProjektService);
  }
  get gutachten() {
    return this.injector.get(GutachtenService);
  }
  get kunde() {
    return this.injector.get(KundeService);
  }
/*    fields >> */

  get modalRef() {
    return this.injector.get(NzModalRef)
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    public route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    private loading: ResolverLoadingService,
    public auth: AuthService,
    public files: FileService,
    public zeit: ZeitService,
    public postZeit: PostZeitCommandService, 
    public deleteZeit: DeleteZeitCommandService 
/* << injection    */
/*    injection >> */
  ) {
/* << constructor    */
/*    constructor >> */
  }

  async ngOnInit() {
/* << init    */
    await this.postZeit.prepare();
/*    init >> */
    this.subscriptions.push(...[
      this.route.queryParams.subscribe(async (params: Params) => {
/* << url    */
/*    url >> */
      }),
/* << subscriptions    */
      this.kunde.$result.subscribe(kunde => {
        if (kunde && kunde.id !== 'new') {
          this.patch({ kunde: kunde.id})
        }
      }),
      this.projekt.$result.subscribe(projekt => {
        if (projekt && projekt.id !== 'new') {
          this.patch({ projekt: projekt.id})
        }
      }),
      this.gutachten.$result.subscribe(gutachten => {
        if (gutachten && gutachten.id !== 'new') {
          this.patch({ gutachten: [gutachten.id]})
        }
      }),
/*    subscriptions >> */
    ]);
  }

  ngOnDestroy() {
    this.subscriptions.forEach($ => $.unsubscribe());
/* << destroy    */
/*    destroy >> */
  }

  public async  setZeit(id: string) {
/* << set-zeit    */
/*    set-zeit >> */
  }

  async finishedPostZeit(response?: IPostZeitResponse) {
 /* << after post-zeit    */
    await this.openZeit(null);
 /*    after post-zeit >> */     
  }
  
  async finishedDeleteZeit(response?: IDeleteZeitResponse) {
 /* << after delete-zeit    */
    await this.openZeit(null);
 /*    after delete-zeit >> */     
  }
  

/* << methods    */
  private patch(params: Partial<IZeit>) {
    const post = this.$postZeit.getValue();
    const patch = this.$patch.getValue();
    const zeit = {
      ...post?.zeit,
      ...patch,
      ...params
    } as unknown as IZeit;
    this.$patch.next(zeit);
  }

  public async openZeit(zeit: IZeit|null|any) {
    if (!zeit) {
      this.$postZeit.next(null);
      this.$deleteZeit.next(null);
      this.idChanged.next(null);
      return;
    }
    if (zeit && typeof zeit === 'string' && zeit.length > 3) {
      const result = await this.zeit.request({ zeit }).catch();
      zeit = result.zeit;
    }
    if (zeit && (!zeit.id || zeit.id === 'new')) {
      zeit = {
        ...zeit,
        ...this.$patch.getValue()
      }
      zeit.mitarbeiter = this.auth.$id.getValue();
      zeit.datum = new Date();
    }
    console.log({ zeit });
    this.$postZeit.next(null);
    this.$postZeit.next({ zeit });
    if (zeit && zeit.id) {
      this.$deleteZeit.next({ id: zeit.id });
    } else {
      this.$deleteZeit.next(null);
    }
  }
/*    methods >> */


  public async submitPostZeit(request?: IPostZeitRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$postZeit.getValue();
    if (payload) {
      try {
        const response = await this.postZeit.request(payload);
        await this.finishedPostZeit(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }
  
  public async submitDeleteZeit(request?: IDeleteZeitRequest) {
    this.$loading.next(true);
    const payload = request ? request : this.$deleteZeit.getValue();
    if (payload) {
      try {
        const response = await this.deleteZeit.request(payload);
        await this.finishedDeleteZeit(response);
      } catch (error: any) {
        this.message.error('Oops, etwas ist schief gelaufen' + (error && error.message ? ': ' + error.message : ''));
        console.error(error);
      }
    }
    this.$loading.next(false);
  }
  
}