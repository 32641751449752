import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { ZeitenServiceModule } from '../';
import { SavedZeitenFiltersComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ZeitenServiceModule
  ],
  declarations: [SavedZeitenFiltersComponent],
  exports: [SavedZeitenFiltersComponent],

})
export class SavedZeitenFiltersComponentModule { }
