import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostVorlageCommandServiceModule } from '../';
import { PostVorlageCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostVorlageCommandServiceModule
  ],
  declarations: [PostVorlageCommandComponent],
  exports: [PostVorlageCommandComponent],

})
export class PostVorlageCommandComponentModule { }
