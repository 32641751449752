import { NgModule } from '@angular/core';

import {
  PostSyncAzureActiveDirectoryCommandServiceModule,
  PostSyncAzureActiveDirectoryCommandComponentModule
} from './';

@NgModule({
  imports: [
    PostSyncAzureActiveDirectoryCommandServiceModule,
    PostSyncAzureActiveDirectoryCommandComponentModule
  ],
  exports: [
    PostSyncAzureActiveDirectoryCommandServiceModule,
    PostSyncAzureActiveDirectoryCommandComponentModule
  ]
})
export class PostSyncAzureActiveDirectoryCommandModule { }
