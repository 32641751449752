import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { TourServiceModule } from '../';
import { TourDetailComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    TourServiceModule
  ],
  declarations: [TourDetailComponent],
  exports: [TourDetailComponent],

})
export class TourDetailComponentModule { }
