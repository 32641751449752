import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { DurchgangServiceModule } from '../';
import { DurchgangFilterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    DurchgangServiceModule
  ],
  declarations: [DurchgangFilterComponent],
  exports: [DurchgangFilterComponent],

})
export class DurchgangFilterComponentModule { }
