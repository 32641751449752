import { NgModule } from '@angular/core';
import { 
  FeldQueryModule,
  FelderQueryModule
} from './';

@NgModule({
  declarations: [],
  imports: [
    FeldQueryModule,
    FelderQueryModule
  ],
  exports: [
    FeldQueryModule,
    FelderQueryModule
  ]
})
export class FelderQuerysModule { }
