import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IPostFormularRequest, IPostFormularResponse, IPostFormularCommandShapes } from '@fa-kt-evaluation/formulare/types';

@Injectable({
  providedIn: 'root'
})
export class PostFormularCommandService {

  readonly route = 'formulare/formular';
  protected dependencies = [
      'gutachten/written-bewertungs-anlass',
      'gutachten/removed-bewertungs-anlass',
      'kunden/written-kunden-art',
      'kunden/removed-kunden-art',
      'objekte/written-objekt-art',
      'objekte/removed-objekt-art',
      'formulare/formular-gespeichert'
  ];

  readonly $shapes = new BehaviorSubject<IPostFormularCommandShapes|undefined>(undefined);
  
  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IPostFormularCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IPostFormularCommandShapes>(this.route + '/post/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IPostFormularRequest): Promise<IPostFormularResponse> {
    return await this.http.post<IPostFormularResponse>(this.route, payload) as unknown as IPostFormularResponse;
  }
  
  getFormularBewertungsAnlaesse(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['formular.bewertungsAnlaesse']?.find(v => v.value === value)
  }

  get formularBewertungsAnlaesse(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['formular.bewertungsAnlaesse'] as ISelection[] : []));
  }

  getFormularOhneFelder(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['formular.ohneFelder']?.find(v => v.value === value)
  }

  get formularOhneFelder(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['formular.ohneFelder'] as ISelection[] : []));
  }

  getFormularKunden(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['formular.kunden']?.find(v => v.value === value)
  }

  get formularKunden(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['formular.kunden'] as ISelection[] : []));
  }

  getFormularKundenArten(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['formular.kundenArten']?.find(v => v.value === value)
  }

  get formularKundenArten(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['formular.kundenArten'] as ISelection[] : []));
  }

  getFormularObjektArten(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['formular.objektArten']?.find(v => v.value === value)
  }

  get formularObjektArten(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['formular.objektArten'] as ISelection[] : []));
  }

}
