import { Injectable } from '@angular/core';
import { AbstractControl } from "@angular/forms";

import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, first, map } from "rxjs/operators";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { FilterPipe, SearchPipe, SorterPipe } from "@lib/filter/frontend";
import { ISelection } from "@lib/persistence/types";

import {
  IZeitArtsRequest,
  IZeitArtsResponse,
  IZeitArtsQuerySorter,
  IZeitArtsQueryFilter,
  IZeitArtsQueryFilterShapes,
  IZeitArtsResponseGroup,
} from '@fa-kt-evaluation/zeiten/types';

@Injectable({
  providedIn: 'root'
})
export class ZeitArtsService {

  protected route = 'zeiten/settings/zeit-arts';
  protected dependencies = [
    'zeiten/written-zeit-art',
    'zeiten/removed-zeit-art',
  ];

  readonly $result = new BehaviorSubject<IZeitArtsResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IZeitArtsRequest|undefined>(undefined);
  readonly $response = new BehaviorSubject<IZeitArtsResponse|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IZeitArtsQueryFilterShapes|undefined>(undefined);
  readonly $filter = new BehaviorSubject<IZeitArtsQueryFilter>({});
  readonly $sorter = new BehaviorSubject<IZeitArtsQuerySorter>({});
  readonly $search = new BehaviorSubject<string>('');
  private readonly $filtered = new BehaviorSubject<IZeitArtsResponse|undefined>(undefined);
  private readonly $searched = new BehaviorSubject<IZeitArtsResponse|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private search: SearchPipe,
    private filter: FilterPipe,
    private sorter: SorterPipe,
    private device: DeviceConfigService
  ) {
    combineLatest([
      this.$response,
      this.$filter,
    ]).subscribe(([response, filter]) => {
      if (response && response.zeitArts) {
        const zeitArts = this.filter.transform(response.zeitArts, filter, true);
        this.$filtered.next( { ...response, zeitArts });
      } else {
        this.$filtered.next(response);
      }
    });
    combineLatest([
      this.$filtered,
      this.$search.pipe(debounceTime(device.debounceTime), distinctUntilChanged()),
      this.$shapes
    ]).subscribe(([filtered, search, shapes]) => {
      if (filtered && filtered.zeitArts) {
        const zeitArts = this.search.transform(filtered.zeitArts, search, '_search', shapes);
        this.$searched.next( { ...filtered, zeitArts });
      } else {
        this.$searched.next(filtered);
      }
    });
    combineLatest([
      this.$searched,
      this.$sorter,
      this.$shapes
    ]).pipe(debounceTime(10)).subscribe(([searched, sorter, shapes]) => {
      if (searched && searched.zeitArts) {
        const zeitArts = this.sorter.transform(searched.zeitArts, sorter, shapes);
        this.$result.next( { ...searched, zeitArts });
      } else {
        this.$result.next(searched);
      }
    });
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IZeitArtsRequest): IZeitArtsRequest {
    if (payload) {
      this.$payload.next(payload as IZeitArtsRequest);
    } else {
      payload = this.$payload.getValue() as IZeitArtsRequest;
    }
    return payload;
  }

  public async request(payload?: IZeitArtsRequest, force = false): Promise<IZeitArtsResponse> {
    payload = this.payload(payload);
    const state = this.$response.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const { response, shapes } = await this.http.get<IZeitArtsResponseGroup>(this.route + this.http.serialize(payload));
    this.$shapes.next(shapes);
    this.$response.next(response);
    return response;
  }
  
  public nameIsUnique(control: AbstractControl|null): (name: string) => boolean {
    return (name: string) => {
      const id = control ? control.value : '';
      let isUnique = true;
      if (name) {
        const response = this.$response.getValue();
        if (response && response.zeitArts) {
          isUnique = response.zeitArts.filter(za => za.id !== id && za.name === name).length === 0;
        } else {
          isUnique = true;
        }
      }
      return isUnique;
    }
  }



}
