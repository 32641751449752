import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse  } from '@angular/common/http';
import { BehaviorSubject, fromEvent, merge, of, TimeoutError } from "rxjs";
import { NzMessageService } from "ng-zorro-antd/message";
import { map } from "rxjs/operators";
import { prepareCommand, pushFiles } from "@lib/common/frontend";
import { BlobServiceClient } from "@azure/storage-blob";
import { FileService } from "../../../../../../files/frontend/src/lib/services/file.service";


@Injectable({
  providedIn: 'root'
})
export class HttpService {

  readonly $offline = new BehaviorSubject<boolean>(false);
  public readonly $Authorization: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private http: HttpClient, private messages: NzMessageService, private files: FileService) {
    merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    ).pipe(map(() => !navigator.onLine)).subscribe((offline) => {
      if (offline !== this.$offline.getValue()) {
        this.messages.create(offline ? 'warning' : 'success', offline ? 'Das Gerät ist offline'  : 'Das Gerät ist online');
      }
      this.$offline.next(offline);
    });
  }

  async get<I>(route: string, options: any = {}): Promise<I> {
    console.debug('GET', route);
    let result: any = await this.http.get<I>(route, options).toPromise();
    return result;
  }

  async post<I>(route: string, payload: any, options: any = {}): Promise<I> {
    payload = prepareCommand(payload);
    console.debug('POST', route, payload);
    const response = await this.http.post<I>(route, payload, options).toPromise() as unknown as I;
    return response;
  }

  async upload<I>(route: string, payload: any, options: any = {}): Promise<I> {
    const containerName = 'fa-kt-apps';
    const files = pushFiles(payload);
    payload = prepareCommand(payload, '', containerName);
    route = route + this.serialize(payload);
    console.debug('UPLOAD', route, files, payload);
    await this.files.upload(containerName, files);
    const response = await this.http.post<I>(route, payload, options).toPromise() as unknown as I;
    return response;
  }

  async patch<I>(route: string, payload: any, options: any = {}): Promise<I> {
    payload = prepareCommand(payload);
    console.debug('PATCH', route, payload);
    const response = await this.http.patch<I>(route, payload, options).toPromise() as unknown as I;
    return response;
  }

  async put<I>(route: string, payload: any, options: any = {}): Promise<I> {
    payload = prepareCommand(payload);
    console.debug('put', route, payload);
    const response = await this.http.put<I>(route, payload, options).toPromise() as unknown as I;
    return response;
  }

  async delete<I>(route: string, options: any = {}): Promise<I> {
    console.debug('DELETE', route);
    const response = await this.http.delete<I>(route, options).toPromise() as unknown as I;
    return response;
  }

  public isOfflineOrBadConnectionError(err: HttpErrorResponse): boolean {
    return (
      err instanceof TimeoutError ||
      err.error instanceof ErrorEvent ||
      this.$offline.getValue() ||
      !window.navigator.onLine
    );
  }

  public serialize(obj: any): string {
    if (!obj) { return ''; }
    return '?' + Object.entries(obj)
      .filter(([, value]) => value)
      .map(([key, value]) => `${key}=${encodeURIComponent(value && (value as File).name ? (value as File).name : value as string)}`)
      .join('&');
  }
}
