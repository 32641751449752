import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostFondCommandServiceModule } from '../';
import { PostFondCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostFondCommandServiceModule
  ],
  declarations: [PostFondCommandComponent],
  exports: [PostFondCommandComponent],

})
export class PostFondCommandComponentModule { }
