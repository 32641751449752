import { NgModule } from '@angular/core';

import {
  PostHonorarVereinbarungCommandServiceModule,
  PostHonorarVereinbarungCommandComponentModule
} from './';

@NgModule({
  imports: [
    PostHonorarVereinbarungCommandServiceModule,
    PostHonorarVereinbarungCommandComponentModule
  ],
  exports: [
    PostHonorarVereinbarungCommandServiceModule,
    PostHonorarVereinbarungCommandComponentModule
  ]
})
export class PostHonorarVereinbarungCommandModule { }
