import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostBesichtigungCommandServiceModule } from '../';
import { PostBesichtigungCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostBesichtigungCommandServiceModule
  ],
  declarations: [PostBesichtigungCommandComponent],
  exports: [PostBesichtigungCommandComponent],

})
export class PostBesichtigungCommandComponentModule { }
