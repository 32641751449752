import { Injectable } from "@angular/core";

import { BehaviorSubject } from "rxjs";

import { IGraphContact, IGraphContactFolder } from "@lib/contacts/types";
import { HttpService } from "@lib/https/frontend";
import { AuthService } from "@lib/auth/frontend";
import { SecretService } from "@lib/common/frontend";

@Injectable({
  providedIn: 'root'
})
export class OutlookContactsService {

  baseURL = 'https://graph.microsoft.com/v1.0/me/'

  $contactFolders: BehaviorSubject<IGraphContactFolder[]|undefined> = new BehaviorSubject<IGraphContactFolder[]|undefined>(undefined);
  $contacts: BehaviorSubject<IGraphContact[]|undefined> = new BehaviorSubject<IGraphContact[]|undefined>(undefined);
  private defaultFolder: string|null = null;

  constructor(private auth: AuthService, private http: HttpService, private secrets: SecretService) {
    this.secrets.$secrets.subscribe(secrets => {
      if (secrets && secrets.outlook) {
        this.defaultFolder = secrets.outlook.defaultFolder;
      }
    })
  }

  public async listContactFolders() {
    let result: IGraphContactFolder[] = await this.http.get(this.baseURL + 'contactFolders');
    if (result) {
      this.$contactFolders.next(result);
      return result;
    }
    return []
  }


  public async createContactFolder(contactFolder: IGraphContactFolder) {
    if (!contactFolder) { return }
    const result = await this.http.post(this.baseURL + 'contactFolders', contactFolder, { headers: { Prefer: 'IdType="ImmutableId"'} });
    await this.listContactFolders();
    return result as IGraphContactFolder;
  }

  public async updateContactFolder(contactFolder: IGraphContactFolder) {
    if (!contactFolder || !contactFolder.id) { return };
    const result = await this.http.patch(this.baseURL + 'contactFolders/' + contactFolder.id, contactFolder, { headers: { Prefer: 'IdType="ImmutableId"'} });
    await this.listContactFolders();
    return result as IGraphContactFolder;
  }

  public async removeContactFolder(id: string) {
    await this.http.delete(this.baseURL + 'contactFolders/' + id);
    await this.listContactFolders();
  }

  async ensureContactFolders(displayName: string, parentDisplayName?: string) {
    const folders = this.$contactFolders.getValue();
    if (!folders) { return; }
    let parent = folders.find(f => f.displayName === parentDisplayName);
    if (parentDisplayName && !parent) {
      parent = await this.createContactFolder({
        displayName: parentDisplayName
      });
    };
    let folder = folders.find(f => f.displayName === displayName);
    if (!folder) {
      folder = await this.createContactFolder({
        parentFolderId: parent ? parent.id as string : null,
        displayName
      });
    };
    return { folder, parent }
  }

  public async listContacts() {
    let result: IGraphContact[] = await this.http.get(this.baseURL + 'contacts');
    if (result) {
      const current = this.$contacts.getValue();
      const same: IGraphContact[] = result.map((contact: IGraphContact) => ({ contact, old: (current ? current : []).find(e => e.id === contact.id && e.changeKey === contact.changeKey)})).filter((entry: any) => entry.old).map((entry: any) => ({ ...entry.contact, photo: entry.old.photo}));
      let changed = result.filter((c: IGraphContact) => !same.some((e) => e.id === c.id));
      changed = await Promise.all(changed.map(async (contact: IGraphContact) => ({ ...contact, photo: await this.getPhoto(contact.id)})));
      this.$contacts.next(same.concat(changed));
      return result;
    }
    return [];
  }

  async getPhoto(contactID: string): Promise<string|undefined> {
    try {
      const data = await fetch(this.baseURL + 'contacts/' + contactID + '/photo/$value', { headers: { 'Content-Type': 'image/jpg', ...this.auth.headers } });
      const blob = await data.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data as string);
        }
      });
    } catch (e: any) {
      return await new Promise((resolve) => {
        resolve(undefined);
      });
    }
  }

  public async createContact(contact: IGraphContact) {
    if (!contact) { return };
    if (this.defaultFolder && !contact.parentFolderId) { contact.parentFolderId = this.defaultFolder; }
    const photo = contact.photo;
    delete contact.photo
    const result = await this.http.post<IGraphContact>(this.baseURL + 'contacts', contact, { headers: { Prefer: 'IdType="ImmutableId"'} });
    if (photo) {
      try {
        const image = await fetch(photo).then(res => res.blob());
        await this.http.patch(this.baseURL + `contacts/${result.id}/photo/$value`, image);
      } catch (e) {
        console.error(e)
      }
    }
    await this.listContacts();
    return result as IGraphContact;
  }

  public async updateContact(contact: IGraphContact) {
    if (!contact || !contact.id) { return }
    if (this.defaultFolder && !contact.parentFolderId) { contact.parentFolderId = this.defaultFolder; }
    const photo = contact.photo;
    delete contact.photo
    const result = await this.http.patch<IGraphContact>(this.baseURL + 'contacts/' + contact.id, contact, { headers: { Prefer: 'IdType="ImmutableId"'} });
    if (photo) {
      try {
        const image = await fetch(photo).then(res => res.blob());
        await this.http.patch(this.baseURL + `contacts/${result.id}/photo/$value`, image);
      } catch (e) {
        console.error(e)
      }
    }
    await this.listContacts();
    return result as IGraphContact;
  }

  public async removeContact(id: string) {
    await this.http.delete(this.baseURL + 'contacts/' + id);
    await this.listContacts();
  }

}
