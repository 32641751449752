import { NgModule } from '@angular/core';
import { 
  PostDurchgangCommandModule,
  DeleteFormularCommandModule,
  PostFormularCommandModule,
  PostFormularKopierenCommandModule
} from './';

@NgModule({
  declarations: [],
  imports: [
    PostDurchgangCommandModule,
    DeleteFormularCommandModule,
    PostFormularCommandModule,
    PostFormularKopierenCommandModule
  ],
  exports: [
    PostDurchgangCommandModule,
    DeleteFormularCommandModule,
    PostFormularCommandModule,
    PostFormularKopierenCommandModule
  ]
})
export class FormulareCommandsModule { }
