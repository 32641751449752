import { Injectable } from '@angular/core';
import { AbstractControl } from "@angular/forms";

import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, first, map } from "rxjs/operators";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { FilterPipe, SearchPipe, SorterPipe } from "@lib/filter/frontend";
import { ISelection } from "@lib/persistence/types";

import {
  IFeldKategoriesRequest,
  IFeldKategoriesResponse,
  IFeldKategoriesQuerySorter,
  IFeldKategoriesQueryFilter,
  IFeldKategoriesQueryFilterShapes,
  IFeldKategoriesResponseGroup,
} from '@fa-kt-evaluation/felder/types';

@Injectable({
  providedIn: 'root'
})
export class FeldKategoriesService {

  protected route = 'felder/settings/feld-kategories';
  protected dependencies = [
    'felder/written-feld-kategorie',
    'felder/removed-feld-kategorie',
  ];

  readonly $result = new BehaviorSubject<IFeldKategoriesResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IFeldKategoriesRequest|undefined>(undefined);
  readonly $response = new BehaviorSubject<IFeldKategoriesResponse|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IFeldKategoriesQueryFilterShapes|undefined>(undefined);
  readonly $filter = new BehaviorSubject<IFeldKategoriesQueryFilter>({});
  readonly $sorter = new BehaviorSubject<IFeldKategoriesQuerySorter>({});
  readonly $search = new BehaviorSubject<string>('');
  private readonly $filtered = new BehaviorSubject<IFeldKategoriesResponse|undefined>(undefined);
  private readonly $searched = new BehaviorSubject<IFeldKategoriesResponse|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private search: SearchPipe,
    private filter: FilterPipe,
    private sorter: SorterPipe,
    private device: DeviceConfigService
  ) {
    combineLatest([
      this.$response,
      this.$filter,
    ]).subscribe(([response, filter]) => {
      if (response && response.feldKategories) {
        const feldKategories = this.filter.transform(response.feldKategories, filter, true);
        this.$filtered.next( { ...response, feldKategories });
      } else {
        this.$filtered.next(response);
      }
    });
    combineLatest([
      this.$filtered,
      this.$search.pipe(debounceTime(device.debounceTime), distinctUntilChanged()),
      this.$shapes
    ]).subscribe(([filtered, search, shapes]) => {
      if (filtered && filtered.feldKategories) {
        const feldKategories = this.search.transform(filtered.feldKategories, search, '_search', shapes);
        this.$searched.next( { ...filtered, feldKategories });
      } else {
        this.$searched.next(filtered);
      }
    });
    combineLatest([
      this.$searched,
      this.$sorter,
      this.$shapes
    ]).pipe(debounceTime(10)).subscribe(([searched, sorter, shapes]) => {
      if (searched && searched.feldKategories) {
        const feldKategories = this.sorter.transform(searched.feldKategories, sorter, shapes);
        this.$result.next( { ...searched, feldKategories });
      } else {
        this.$result.next(searched);
      }
    });
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IFeldKategoriesRequest): IFeldKategoriesRequest {
    if (payload) {
      this.$payload.next(payload as IFeldKategoriesRequest);
    } else {
      payload = this.$payload.getValue() as IFeldKategoriesRequest;
    }
    return payload;
  }

  public async request(payload?: IFeldKategoriesRequest, force = false): Promise<IFeldKategoriesResponse> {
    payload = this.payload(payload);
    const state = this.$response.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const { response, shapes } = await this.http.get<IFeldKategoriesResponseGroup>(this.route + this.http.serialize(payload));
    this.$shapes.next(shapes);
    this.$response.next(response);
    return response;
  }
  
  public initialenIsUnique(control: AbstractControl|null): (initialen: string) => boolean {
    return (initialen: string) => {
      const id = control ? control.value : '';
      let isUnique = true;
      if (initialen) {
        const response = this.$response.getValue();
        if (response && response.feldKategories) {
          isUnique = response.feldKategories.filter(fk => fk.id !== id && fk.initialen === initialen).length === 0;
        } else {
          isUnique = true;
        }
      }
      return isUnique;
    }
  }



}
