import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostRechnungRunterzaehlenCommandServiceModule } from '../';
import { PostRechnungRunterzaehlenCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostRechnungRunterzaehlenCommandServiceModule
  ],
  declarations: [PostRechnungRunterzaehlenCommandComponent],
  exports: [PostRechnungRunterzaehlenCommandComponent],

})
export class PostRechnungRunterzaehlenCommandComponentModule { }
