import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { NzSelectModeType } from "ng-zorro-antd/select";
import { NzSelectSizeType } from "ng-zorro-antd/select/select.component";
import { BehaviorSubject, combineLatest, Subscription } from "rxjs";

import { ISelection } from "@lib/persistence/types";
import { toInitials } from "@lib/common/frontend";

@Component({
  selector: 'codeboard-select-value',
  templateUrl: './select-value.component.html',
  styleUrls: ['./select-value.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: SelectValueComponent
    }
  ]
})
export class SelectValueComponent implements OnInit, OnDestroy, ControlValueAccessor  {
  subscriptions: Subscription[] = [];

  onChange = (value: string|string[]|null) => {};
  onTouched = () => {};
  touched = false;

  @Input() disabled = false;
  @Input() mode: NzSelectModeType = 'default';

  @Input() set shapes(shapes: ISelection[]) {
    this.$shapes.next(shapes ? shapes : []);
  }
  $shapes = new BehaviorSubject<ISelection[]>([]);
  $filtered = new BehaviorSubject<ISelection[]>([]);

  @Input() set filter(filter: string|string[]) {
    this.$filter.next(filter);
  }
  $filter = new BehaviorSubject<string|string[]|undefined>(undefined);

  _value: string|string[]|undefined;
  @Input() set value(value: string|string[]|null) {
    this._value = value ? value : undefined;
    this.onChange(value);
  }
  @Output()
  valueChange: EventEmitter<string|string[]> = new EventEmitter()

  @Input() required: boolean = false;
  @Input() placeholder: string = '';
  @Input() width: string = '';
  @Input() size: NzSelectSizeType = 'default';
  $avatar = new BehaviorSubject<boolean>(false)

  creating: string|null = null;
  @Input() creatable = false;
  @Output() created = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit(): void {
    this.subscriptions.push(...[
      combineLatest([
        this.$shapes,
        this.$filter
      ]).subscribe(([shapes, filter]) => {
        const toArray = (value: string|string[]) => Array.isArray(value) ? value : [value];
        if (filter && !filter.includes('*')) {
          filter = toArray(filter);
          shapes = shapes.filter(value => !value.filter || toArray(value.filter).some(item => filter?.includes(item)));
        }
        this.$filtered.next(shapes)
      }),
      this.$filtered.subscribe(filtered => {
        this.$avatar.next(filtered.some(s => s.avatar))
      }),
    ]);
  }

  ngOnDestroy(): void {
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  public setValue(value: string|string[]|null): void {
    this.value = value;
    this.valueChange.emit(this._value);
    this.onChange(value);
    this.markAsTouched();
  }


  writeValue(value: string|string[]|null) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  search(term: string) {
    if (!this.creatable || !term) {
      this.creating = null;
      return;
    }
    if (!this.$filtered.getValue().find(s => s.label.includes(term) || term.includes(s.label))) {
      this.creating = term.length > 3 ? term : null;
    }
  }

  create() {
    if (this.creatable && this.creating && this.creating.length > 3) {
      this.created.emit(this.creating as string);
    }
  }

  getMitarbeiterText(mitarbeiter: string) {
    return toInitials(mitarbeiter.split('(')[0]);
  }
}
