import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { RechnungsVorlagenServiceModule } from '../';
import { RechnungsVorlagenFilterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    RechnungsVorlagenServiceModule
  ],
  declarations: [RechnungsVorlagenFilterComponent],
  exports: [RechnungsVorlagenFilterComponent],

})
export class RechnungsVorlagenFilterComponentModule { }
