import { Component } from '@angular/core';
import { MitarbeiterService } from "@fa-kt-evaluation/personen/frontend";

/* << imports    */
 
/*    imports >> */
@Component({
  selector: 'fa-kt-evaluation-saved-mitarbeiter-filters',
  templateUrl: './saved-mitarbeiter-filters.component.html',
  styleUrls: ['./saved-mitarbeiter-filters.component.css']
})
export class SavedMitarbeiterFiltersComponent {

  constructor(
    public mitarbeiter: MitarbeiterService
/* << injection    */
/*    injection >> */
  ) {
  }
/* << fields   */
 
/*   fields >> */
/* << methods   */
 
/*   methods >> */
}