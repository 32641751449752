import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostVermerkCommandServiceModule } from '../';
import { PostVermerkCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostVermerkCommandServiceModule
  ],
  declarations: [PostVermerkCommandComponent],
  exports: [PostVermerkCommandComponent],

})
export class PostVermerkCommandComponentModule { }
