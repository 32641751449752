import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { first, map, debounceTime, distinctUntilChanged } from "rxjs/operators";

import { isEqual } from "lodash";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { FilterPipe, SearchPipe, SorterPipe } from "@lib/filter/frontend";
import { IPersistedFilter, IPersistedFilterList } from "@lib/filter/types";
import { ISelection } from "@lib/persistence/types";

import {
  ITourenRequest,
  ITourenResponse,
  ITourenResponseGroup,
  ITourenQuerySorter,
  ITourenQueryFilter,
  ITourenQueryFilterShapes
} from '@fa-kt-evaluation/besichtigungen/types';

@Injectable({
  providedIn: 'root'
})
export class TourenService {

  protected route = 'besichtigungen/touren';
  protected dependencies = [
    'besichtigungen/written-besichtigungs-status',
    'besichtigungen/removed-besichtigungs-status',
    'besichtigungen/written-tour-status',
    'besichtigungen/removed-tour-status',
    'finanzen/written-reisekostenabrechnung-status',
    'finanzen/removed-reisekostenabrechnung-status',

    'besichtigungen/besichtigung-entfernt',
    'besichtigungen/besichtigung-geordnet',
    'besichtigungen/besichtigung-gespeichert',
    'besichtigungen/tour-entfernt',
    'besichtigungen/tour-gespeichert',
    'besichtigungen/tour-gestartet',
    'finanzen/rechnung-erstellt',
    'finanzen/reisekostenabrechnung-entfernt',
    'finanzen/reisekostenabrechnung-gespeichert',
    'formulare/durchgang-gestartet'
  ];

  readonly $result = new BehaviorSubject<ITourenResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<ITourenRequest|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<ITourenQueryFilterShapes|undefined>(undefined);
  readonly $response = new BehaviorSubject<ITourenResponse|undefined>(undefined);
  readonly $filter = new BehaviorSubject<ITourenQueryFilter>({});
  readonly $sorter = new BehaviorSubject<ITourenQuerySorter>({});
  readonly $search = new BehaviorSubject<string>('');
  readonly $presets = new BehaviorSubject<IPersistedFilter<ITourenResponse>[]>([]);
  readonly $preset = new BehaviorSubject<IPersistedFilter<ITourenResponse>|undefined>(undefined);
  private readonly $filtered = new BehaviorSubject<ITourenResponse|undefined>(undefined);
  private readonly $searched = new BehaviorSubject<ITourenResponse|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private device: DeviceConfigService,
    private search: SearchPipe,
    private filter: FilterPipe,
    private sorter: SorterPipe,
  ) {
/* << changes    */
/*    changes >> */
    combineLatest([
      this.$response,
      this.$filter,
    ]).subscribe(([response, filter]) => {
      if (response && response.touren) {
        const touren = this.filter.transform(response.touren, filter);
        this.$filtered.next( { ...response, touren });
      } else {
        this.$filtered.next(response);
      }
    });
    combineLatest([
      this.$filtered,
      this.$search.pipe(debounceTime(device.debounceTime), distinctUntilChanged()),
      this.$shapes.pipe(distinctUntilChanged((a, b) => isEqual(a, b)))
    ]).subscribe(([filtered, search, shapes]) => {
      if (filtered && filtered.touren) {
        const touren = this.search.transform(filtered.touren, search, '_search', shapes);
        this.$searched.next( { ...filtered, touren });
      } else {
        this.$searched.next(filtered);
      }
    });
    combineLatest([
      this.$searched,
      this.$sorter.pipe(distinctUntilChanged((a, b) => isEqual(a, b))),
      this.$shapes.pipe(distinctUntilChanged((a, b) => isEqual(a, b)))
    ]).pipe(debounceTime(10)).subscribe(([sorted, sorter, shapes]) => {
      if (sorted && sorted.touren) {
        const touren = this.sorter.transform(sorted.touren, sorter, shapes);
        this.$result.next( { ...sorted, touren });
      } else {
        this.$result.next(sorted);
      }
    });
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: ITourenRequest): ITourenRequest {
    if (payload) {
      this.$payload.next(payload as ITourenRequest);
    } else {
      payload = this.$payload.getValue() as ITourenRequest;
    }
    return payload;
  }

  public async request(payload: ITourenRequest, force = false): Promise<ITourenResponse> {
    const state = this.$response.getValue();
    if (!force && !this.device.alwaysRefresh && state && isEqual(payload, this.payload())) { return state; }
    payload = this.payload(payload);
    if (!payload) { return this.$response.getValue() as ITourenResponse; }
    const query = this.http.serialize(payload);
    const { response, shapes, filter } = await this.http.get<ITourenResponseGroup>(this.route + query);
    this.$response.next(response);
    this.$shapes.next(shapes);
    this.$presets.next(filter && filter.filters ? filter.filters : []);
    return response;
  }

  public async writeFilter(filter: IPersistedFilter<ITourenResponse>): Promise<IPersistedFilter<ITourenResponse>|undefined> {
    filter = { ...filter, term: this.$search.getValue(), sorter: this.$sorter.getValue(), filter: this.$filter.getValue() };
    const result = await this.http.post(this.route + '/filters', filter) as IPersistedFilterList<ITourenResponse>;
    filter = result.filters.find(f => f.name === filter.name) as IPersistedFilter<ITourenResponse>;
    this.$presets.next(result.filters ? result.filters : []);
    this.setFilter(filter);
    return filter;
  }

  public async deleteFilter(id: string): Promise<void> {
    const result = await this.http.delete(this.route + '/filters?id=' + id) as IPersistedFilterList<ITourenResponse>;
    this.$presets.next(result.filters ? result.filters : []);
    this.setFilter();
  }

  public setFilter(filter?: IPersistedFilter<ITourenResponse>): void {
    this.$search.next(filter && filter.term ? filter.term : '');
    this.$filter.next(filter && filter.filter ? filter.filter : {});
    this.$sorter.next(filter && filter.sorter ? filter.sorter : {});
    this.$preset.next(filter ? filter : undefined);
  }


  getBesichtigungenBesichtigungsStatus(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['besichtigungen.besichtigungsStatus']?.find(v => v.value === value)
  }

  get besichtigungenBesichtigungsStatus(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['besichtigungen.besichtigungsStatus'] as ISelection[] : []));
  }

  getTourMitarbeiter(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['tour.mitarbeiter']?.find(v => v.value === value)
  }

  get tourMitarbeiter(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['tour.mitarbeiter'] as ISelection[] : []));
  }

  getBesichtigungenMitarbeiter(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['besichtigungen.mitarbeiter']?.find(v => v.value === value)
  }

  get besichtigungenMitarbeiter(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['besichtigungen.mitarbeiter'] as ISelection[] : []));
  }

  getReisekostenerstattungMitarbeiter(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['reisekostenerstattung.mitarbeiter']?.find(v => v.value === value)
  }

  get reisekostenerstattungMitarbeiter(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['reisekostenerstattung.mitarbeiter'] as ISelection[] : []));
  }

  getReisekostenerstattungReisekostenabrechnungStatus(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['reisekostenerstattung.reisekostenabrechnungStatus']?.find(v => v.value === value)
  }

  get reisekostenerstattungReisekostenabrechnungStatus(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['reisekostenerstattung.reisekostenabrechnungStatus'] as ISelection[] : []));
  }

  getReisekostenerstattungTouren(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['reisekostenerstattung.touren']?.find(v => v.value === value)
  }

  get reisekostenerstattungTouren(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['reisekostenerstattung.touren'] as ISelection[] : []));
  }

  getTourTourStatus(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['tour.tourStatus']?.find(v => v.value === value)
  }

  get tourTourStatus(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['tour.tourStatus'] as ISelection[] : []));
  }


}
