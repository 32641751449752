import { NgModule } from '@angular/core';

import { LIB_CommonModule } from "@lib/common/frontend";
import { FilterPipe, SearchPipe, SorterPipe } from "@lib/filter/frontend";

import { VorlagenService } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [
    VorlagenService,
    SearchPipe,
    FilterPipe,
    SorterPipe
  ],
  exports: []
})
export class VorlagenServiceModule { }
