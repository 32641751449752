import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostAbmeldenCommandService } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [PostAbmeldenCommandService],
  exports: []
})
export class PostAbmeldenCommandServiceModule { }
