import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { BerichtServiceModule } from '../';
import { BerichtFilterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    BerichtServiceModule
  ],
  declarations: [BerichtFilterComponent],
  exports: [BerichtFilterComponent],

})
export class BerichtFilterComponentModule { }
