import { Component } from '@angular/core';
import { FelderService } from "@fa-kt-evaluation/felder/frontend";

/* << imports    */
 
/*    imports >> */
@Component({
  selector: 'fa-kt-evaluation-saved-felder-filters',
  templateUrl: './saved-felder-filters.component.html',
  styleUrls: ['./saved-felder-filters.component.css']
})
export class SavedFelderFiltersComponent {

  constructor(
    public felder: FelderService
/* << injection    */
/*    injection >> */
  ) {
  }
}