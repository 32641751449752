import { NgModule } from '@angular/core';

import { LIB_CommonModule } from "@lib/common/frontend";
import { FilterPipe, SearchPipe, SorterPipe } from "@lib/filter/frontend";

import { BerichteService } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [
    BerichteService,
    SearchPipe,
    FilterPipe,
    SorterPipe
  ],
  exports: []
})
export class BerichteServiceModule { }
