import { NgModule } from '@angular/core';
import {
  IchFilterComponentModule,
  IchDetailComponentModule,
  IchSorterComponentModule,
  SavedIchFiltersComponentModule
} from './';

@NgModule({
  imports: [
    IchFilterComponentModule,
    IchDetailComponentModule,
    IchSorterComponentModule,
    SavedIchFiltersComponentModule
  ],
  exports: [
    IchFilterComponentModule,
    IchDetailComponentModule,
    IchSorterComponentModule,
    SavedIchFiltersComponentModule
  ],
})
export class IchQueryModule { }
