import { Component, Input, OnInit } from '@angular/core';
import { DependenciesService } from '@lib/auth/frontend';
import { IMitarbeiter } from "@fa-kt-evaluation/personen/types";

@Component({
  selector: 'codeboard-meta',
  templateUrl: './meta.component.html',
  styleUrls: ['./meta.component.css']
})
export class MetaComponent implements OnInit {

  @Input() value: any;
  private users: IMitarbeiter[] = [];

  constructor(
    public dependencies: DependenciesService
  ) { }

  async ngOnInit(): Promise<void> {
    this.dependencies.users.subscribe(users => this.users = users);
  }

  getUser(id: string): IMitarbeiter|undefined {
    return this.users.find(u => u.id === id);
  }

}
