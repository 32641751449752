import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { DurchgangServiceModule } from '../';
import { SavedDurchgangFiltersComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    DurchgangServiceModule
  ],
  declarations: [SavedDurchgangFiltersComponent],
  exports: [SavedDurchgangFiltersComponent],

})
export class SavedDurchgangFiltersComponentModule { }
