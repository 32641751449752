import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { ProjekteCommandsModule } from './commands'
import {
  ProjekteQuerysModule,
  ArchivService,
  DashboardService,
  ProjektService,
  ProjekteService
} from './querys'
import {
  ProjektArtsServiceModule, ProjektArtsService,
  ProjektStatiServiceModule, ProjektStatiService
} from './settings';

const routes: Routes = [
  {
    path: 'projekte',
    loadChildren: () => import('./pages').then(m => m.ProjektePagesModule)
  },
  {
    path: 'projekte',
    children: [
      {
        path: 'settings',
        loadChildren: () => import('./settings').then(m => m.ProjekteSettingsModule),
      }
    ]
  },
];

@NgModule({
  imports: [
    ProjekteCommandsModule,
    ProjekteQuerysModule,
    ProjektArtsServiceModule,
    ProjektStatiServiceModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    ProjekteCommandsModule,
    ProjekteQuerysModule,
    ProjektArtsServiceModule,
    ProjektStatiServiceModule
  ]
})
export class ProjekteModule {
  static forRoot(): ModuleWithProviders< ProjekteModule> {
    return {
      ngModule: ProjekteModule,
      providers: [
        ArchivService,
        DashboardService,
        ProjektService,
        ProjekteService,
        ProjektArtsService,
        ProjektStatiService
      ]
    };
  }
}
