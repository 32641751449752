import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { DashboardServiceModule } from '../';
import { DashboardSorterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    DashboardServiceModule
  ],
  declarations: [DashboardSorterComponent],
  exports: [DashboardSorterComponent],

})
export class DashboardSorterComponentModule { }
