import { NgModule } from '@angular/core';

import {
  PostReaktivierenCommandServiceModule,
  PostReaktivierenCommandComponentModule
} from './';

@NgModule({
  imports: [
    PostReaktivierenCommandServiceModule,
    PostReaktivierenCommandComponentModule
  ],
  exports: [
    PostReaktivierenCommandServiceModule,
    PostReaktivierenCommandComponentModule
  ]
})
export class PostReaktivierenCommandModule { }
