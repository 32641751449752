import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { NzGridModule } from "ng-zorro-antd/grid";
import { NzAutocompleteModule } from "ng-zorro-antd/auto-complete";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { NzTypographyModule } from "ng-zorro-antd/typography";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzIconModule } from "ng-zorro-antd/icon";

import { LIB_HttpsModule } from "@lib/https/frontend";

import { AddressInputComponent, AtlasMapComponent } from "./components";
import { AddressPipe } from "./pipes";
import { AtlasService } from "./services";
import { NzButtonModule } from "ng-zorro-antd/button";

@NgModule({
  imports: [
    CommonModule,
    LIB_HttpsModule,
    NzSpinModule,
    ReactiveFormsModule,
    NzGridModule,
    NzAutocompleteModule,
    FormsModule,
    NzFormModule,
    NzInputModule,
    NzCollapseModule,
    NzTypographyModule,
    NzIconModule,
    NzButtonModule
  ],
  declarations: [
    AddressInputComponent,
    AtlasMapComponent,
    AddressPipe
  ],
  exports: [
    AddressInputComponent,
    AtlasMapComponent,
    AddressPipe
  ]
})
export class LIB_GeoModule {
  static forRoot(): ModuleWithProviders<LIB_GeoModule> {
    return {
      ngModule: LIB_GeoModule,
      providers: [
        AtlasService
      ]
    }
  }
}
