import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { GutachtenServiceModule } from '../';
import { GutachtenListComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    GutachtenServiceModule
  ],
  declarations: [GutachtenListComponent],
  exports: [GutachtenListComponent],

})
export class GutachtenListComponentModule { }
