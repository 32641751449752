import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { IchServiceModule } from '../';
import { IchSorterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    IchServiceModule
  ],
  declarations: [IchSorterComponent],
  exports: [IchSorterComponent],

})
export class IchSorterComponentModule { }
