import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { ObjektServiceModule } from '../';
import { SavedObjektFiltersComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ObjektServiceModule
  ],
  declarations: [SavedObjektFiltersComponent],
  exports: [SavedObjektFiltersComponent],

})
export class SavedObjektFiltersComponentModule { }
