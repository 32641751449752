import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IPostKundeRequest, IPostKundeResponse, IPostKundeCommandShapes } from '@fa-kt-evaluation/kunden/types';

@Injectable({
  providedIn: 'root'
})
export class PostKundeCommandService {

  readonly route = 'kunden/kunde';
  protected dependencies = [
      'kunden/written-kunden-art',
      'kunden/removed-kunden-art',
      'kunden/written-kunden-gruppe',
      'kunden/removed-kunden-gruppe',
      'kunden/kunde-gespeichert'
  ];

  readonly $shapes = new BehaviorSubject<IPostKundeCommandShapes|undefined>(undefined);
  
  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IPostKundeCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IPostKundeCommandShapes>(this.route + '/post/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IPostKundeRequest): Promise<IPostKundeResponse> {
    return await this.http.post<IPostKundeResponse>(this.route, payload) as unknown as IPostKundeResponse;
  }
  
  getKundeKundenArt(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['kunde.kundenArt']?.find(v => v.value === value)
  }

  get kundeKundenArt(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['kunde.kundenArt'] as ISelection[] : []));
  }

  getKundeKundenGruppe(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['kunde.kundenGruppe']?.find(v => v.value === value)
  }

  get kundeKundenGruppe(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['kunde.kundenGruppe'] as ISelection[] : []));
  }

}
