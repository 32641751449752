import { Injectable } from '@angular/core';
import { AbstractControl } from "@angular/forms";

import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, first, map } from "rxjs/operators";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { FilterPipe, SearchPipe, SorterPipe } from "@lib/filter/frontend";
import { ISelection } from "@lib/persistence/types";

import {
  IObjektArtsRequest,
  IObjektArtsResponse,
  IObjektArtsQuerySorter,
  IObjektArtsQueryFilter,
  IObjektArtsQueryFilterShapes,
  IObjektArtsResponseGroup,
} from '@fa-kt-evaluation/objekte/types';

@Injectable({
  providedIn: 'root'
})
export class ObjektArtsService {

  protected route = 'objekte/settings/objekt-arts';
  protected dependencies = [
    'objekte/written-objekt-art',
    'objekte/removed-objekt-art',
    'objekte/written-objekt-nutzung',
    'objekte/removed-objekt-nutzung',
  ];

  readonly $result = new BehaviorSubject<IObjektArtsResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IObjektArtsRequest|undefined>(undefined);
  readonly $response = new BehaviorSubject<IObjektArtsResponse|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IObjektArtsQueryFilterShapes|undefined>(undefined);
  readonly $filter = new BehaviorSubject<IObjektArtsQueryFilter>({});
  readonly $sorter = new BehaviorSubject<IObjektArtsQuerySorter>({});
  readonly $search = new BehaviorSubject<string>('');
  private readonly $filtered = new BehaviorSubject<IObjektArtsResponse|undefined>(undefined);
  private readonly $searched = new BehaviorSubject<IObjektArtsResponse|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private search: SearchPipe,
    private filter: FilterPipe,
    private sorter: SorterPipe,
    private device: DeviceConfigService
  ) {
    combineLatest([
      this.$response,
      this.$filter,
    ]).subscribe(([response, filter]) => {
      if (response && response.objektArts) {
        const objektArts = this.filter.transform(response.objektArts, filter, true);
        this.$filtered.next( { ...response, objektArts });
      } else {
        this.$filtered.next(response);
      }
    });
    combineLatest([
      this.$filtered,
      this.$search.pipe(debounceTime(device.debounceTime), distinctUntilChanged()),
      this.$shapes
    ]).subscribe(([filtered, search, shapes]) => {
      if (filtered && filtered.objektArts) {
        const objektArts = this.search.transform(filtered.objektArts, search, '_search', shapes);
        this.$searched.next( { ...filtered, objektArts });
      } else {
        this.$searched.next(filtered);
      }
    });
    combineLatest([
      this.$searched,
      this.$sorter,
      this.$shapes
    ]).pipe(debounceTime(10)).subscribe(([searched, sorter, shapes]) => {
      if (searched && searched.objektArts) {
        const objektArts = this.sorter.transform(searched.objektArts, sorter, shapes);
        this.$result.next( { ...searched, objektArts });
      } else {
        this.$result.next(searched);
      }
    });
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IObjektArtsRequest): IObjektArtsRequest {
    if (payload) {
      this.$payload.next(payload as IObjektArtsRequest);
    } else {
      payload = this.$payload.getValue() as IObjektArtsRequest;
    }
    return payload;
  }

  public async request(payload?: IObjektArtsRequest, force = false): Promise<IObjektArtsResponse> {
    payload = this.payload(payload);
    const state = this.$response.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const { response, shapes } = await this.http.get<IObjektArtsResponseGroup>(this.route + this.http.serialize(payload));
    this.$shapes.next(shapes);
    this.$response.next(response);
    return response;
  }
  
  public initialenIsUnique(control: AbstractControl|null): (initialen: string) => boolean {
    return (initialen: string) => {
      const id = control ? control.value : '';
      let isUnique = true;
      if (initialen) {
        const response = this.$response.getValue();
        if (response && response.objektArts) {
          isUnique = response.objektArts.filter(oa => oa.id !== id && oa.initialen === initialen).length === 0;
        } else {
          isUnique = true;
        }
      }
      return isUnique;
    }
  }


  async getObjektArtsObjektNutzung(value: string): Promise<ISelection|undefined> {
    return new Promise(resolve => {
      this.objektArtsObjektNutzung.pipe(first()).subscribe(objektArtsObjektNutzung => resolve(objektArtsObjektNutzung.find(a => a.value === value)));
    });
  }

  get objektArtsObjektNutzung(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['objektArts.objektNutzung'] as ISelection[] : []));
  }


}
