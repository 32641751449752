import { Injectable } from '@angular/core';
import { AbstractControl } from "@angular/forms";

import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, first, map } from "rxjs/operators";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { FilterPipe, SearchPipe, SorterPipe } from "@lib/filter/frontend";
import { ISelection } from "@lib/persistence/types";

import {
  IBereichsRequest,
  IBereichsResponse,
  IBereichsQuerySorter,
  IBereichsQueryFilter,
  IBereichsQueryFilterShapes,
  IBereichsResponseGroup,
} from '@fa-kt-evaluation/geo/types';

@Injectable({
  providedIn: 'root'
})
export class BereichsService {

  protected route = 'geo/settings/bereichs';
  protected dependencies = [
    'geo/written-bereich',
    'geo/removed-bereich',
  ];

  readonly $result = new BehaviorSubject<IBereichsResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IBereichsRequest|undefined>(undefined);
  readonly $response = new BehaviorSubject<IBereichsResponse|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IBereichsQueryFilterShapes|undefined>(undefined);
  readonly $filter = new BehaviorSubject<IBereichsQueryFilter>({});
  readonly $sorter = new BehaviorSubject<IBereichsQuerySorter>({});
  readonly $search = new BehaviorSubject<string>('');
  private readonly $filtered = new BehaviorSubject<IBereichsResponse|undefined>(undefined);
  private readonly $searched = new BehaviorSubject<IBereichsResponse|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private search: SearchPipe,
    private filter: FilterPipe,
    private sorter: SorterPipe,
    private device: DeviceConfigService
  ) {
    combineLatest([
      this.$response,
      this.$filter,
    ]).subscribe(([response, filter]) => {
      if (response && response.bereichs) {
        const bereichs = this.filter.transform(response.bereichs, filter, true);
        this.$filtered.next( { ...response, bereichs });
      } else {
        this.$filtered.next(response);
      }
    });
    combineLatest([
      this.$filtered,
      this.$search.pipe(debounceTime(device.debounceTime), distinctUntilChanged()),
      this.$shapes
    ]).subscribe(([filtered, search, shapes]) => {
      if (filtered && filtered.bereichs) {
        const bereichs = this.search.transform(filtered.bereichs, search, '_search', shapes);
        this.$searched.next( { ...filtered, bereichs });
      } else {
        this.$searched.next(filtered);
      }
    });
    combineLatest([
      this.$searched,
      this.$sorter,
      this.$shapes
    ]).pipe(debounceTime(10)).subscribe(([searched, sorter, shapes]) => {
      if (searched && searched.bereichs) {
        const bereichs = this.sorter.transform(searched.bereichs, sorter, shapes);
        this.$result.next( { ...searched, bereichs });
      } else {
        this.$result.next(searched);
      }
    });
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IBereichsRequest): IBereichsRequest {
    if (payload) {
      this.$payload.next(payload as IBereichsRequest);
    } else {
      payload = this.$payload.getValue() as IBereichsRequest;
    }
    return payload;
  }

  public async request(payload?: IBereichsRequest, force = false): Promise<IBereichsResponse> {
    payload = this.payload(payload);
    const state = this.$response.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const { response, shapes } = await this.http.get<IBereichsResponseGroup>(this.route + this.http.serialize(payload));
    this.$shapes.next(shapes);
    this.$response.next(response);
    return response;
  }
  


}
