import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { DeleteBesichtigungCommandServiceModule } from '../';
import { DeleteBesichtigungCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteBesichtigungCommandServiceModule
  ],
  declarations: [DeleteBesichtigungCommandComponent],
  exports: [DeleteBesichtigungCommandComponent],

})
export class DeleteBesichtigungCommandComponentModule { }
