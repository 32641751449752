import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { FormularServiceModule } from '../';
import { FormularFilterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    FormularServiceModule
  ],
  declarations: [FormularFilterComponent],
  exports: [FormularFilterComponent],

})
export class FormularFilterComponentModule { }
