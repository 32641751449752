import { NgModule } from '@angular/core';
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { ServiceWorkerModule } from '@angular/service-worker';

import { registerLocaleData } from '@angular/common';
import de from '@angular/common/locales/de';
registerLocaleData(de);

import {LOCALE_ID} from '@angular/core';


const userLang = navigator.language || (navigator as any).userLanguage;
const localeLang = userLang.split('_')[0];


import { LIB_CommonModule } from '@lib/common/frontend';
import { LIB_AuthModule } from '@lib/auth/frontend';
import { LIB_ContactsModule } from '@lib/contacts/frontend';
import { LIB_GeoModule } from "@lib/geo/frontend";
import { LIB_HttpsModule } from "@lib/https/frontend";

import { APP_TITLE, APP_CONFIG, SITEMAP, APP_VERSION, APP_BUILD } from '@lib/common/frontend';
import { AppTitle, AppConfig, Sitemap, AppVersion, AppBuild } from './app.config';
import { AppComponent } from './app.component';

import { AuswertungenModule } from "@fa-kt-evaluation/auswertungen/frontend";
import { BesichtigungenModule } from "@fa-kt-evaluation/besichtigungen/frontend";
import { DokumenteModule } from "@fa-kt-evaluation/dokumente/frontend";
import { EinstellungenModule } from "@fa-kt-evaluation/einstellungen/frontend";
import { FelderModule } from "@fa-kt-evaluation/felder/frontend";
import { FinanzenModule } from "@fa-kt-evaluation/finanzen/frontend";
import { FormulareModule } from "@fa-kt-evaluation/formulare/frontend";
import { GeoModule } from "@fa-kt-evaluation/geo/frontend";
import { GutachtenModule } from "@fa-kt-evaluation/gutachten/frontend";
import { KommunikationModule } from "@fa-kt-evaluation/kommunikation/frontend";
import { KundenModule } from "@fa-kt-evaluation/kunden/frontend";
import { MarktModule } from "@fa-kt-evaluation/markt/frontend";
import { ObjekteModule } from "@fa-kt-evaluation/objekte/frontend";
import { PersonenModule } from "@fa-kt-evaluation/personen/frontend";
import { ProjekteModule } from "@fa-kt-evaluation/projekte/frontend";
import { TexteModule } from "@fa-kt-evaluation/texte/frontend";
import { ZeitenModule } from "@fa-kt-evaluation/zeiten/frontend";

const routes: Routes = [
  { path: '**', redirectTo: 'willkommen', pathMatch: 'full' }
];

/* << importing    */
import { ZeitComponentModule } from '@fa-kt-evaluation/zeiten/frontend';
import { LIB_FilesModule } from "@lib/files/frontend";
/*    importing >> */

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LIB_CommonModule.forRoot(),
    LIB_AuthModule.forRoot(),
    LIB_HttpsModule.forRoot(),
    LIB_GeoModule.forRoot(),
    LIB_ContactsModule.forRoot(),
    AuswertungenModule.forRoot(),
    BesichtigungenModule.forRoot(),
    DokumenteModule.forRoot(),
    EinstellungenModule.forRoot(),
    FelderModule.forRoot(),
    FinanzenModule.forRoot(),
    FormulareModule.forRoot(),
    GeoModule.forRoot(),
    GutachtenModule.forRoot(),
    KommunikationModule.forRoot(),
    KundenModule.forRoot(),
    MarktModule.forRoot(),
    ObjekteModule.forRoot(),
    PersonenModule.forRoot(),
    ProjekteModule.forRoot(),
    TexteModule.forRoot(),
    ZeitenModule.forRoot(),
    RouterModule.forRoot(routes, { useHash: false, preloadingStrategy: PreloadAllModules }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: AppConfig.production, registrationStrategy: 'registerWhenStable:30000'})
/* << custom    */
    ,ZeitComponentModule
/*    custom >> */
  ],
  providers: [
    { provide: LOCALE_ID, useValue: localeLang },
    { provide: SITEMAP, useValue: Sitemap },
    { provide: APP_TITLE, useValue: AppTitle },
    { provide: APP_CONFIG, useValue: AppConfig },
    { provide: APP_VERSION, useValue: AppVersion },
    { provide: APP_BUILD, useValue: AppBuild }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
