import { NgModule } from '@angular/core';
import {
  ObjektFilterComponentModule,
  ObjektDetailComponentModule,
  ObjektSorterComponentModule,
  SavedObjektFiltersComponentModule
} from './';

@NgModule({
  imports: [
    ObjektFilterComponentModule,
    ObjektDetailComponentModule,
    ObjektSorterComponentModule,
    SavedObjektFiltersComponentModule
  ],
  exports: [
    ObjektFilterComponentModule,
    ObjektDetailComponentModule,
    ObjektSorterComponentModule,
    SavedObjektFiltersComponentModule
  ],
})
export class ObjektQueryModule { }
