import { NgModule } from '@angular/core';
import { 
  PostZeitCommandModule,
  DeleteZeitCommandModule
} from './';

@NgModule({
  declarations: [],
  imports: [
    PostZeitCommandModule,
    DeleteZeitCommandModule
  ],
  exports: [
    PostZeitCommandModule,
    DeleteZeitCommandModule
  ]
})
export class ZeitenCommandsModule { }
