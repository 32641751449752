<!---
  <div formGroupName="formular">
    <input class="cdk-visually-hidden" formControlName="id" id="id"/>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">{{ command.Inputs.NAME.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NameErrorTip">
        <ng-template #NameErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NAME.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="name" id="name" [placeholder]="command.Inputs.NAME.placeholder" cdkFocusInitial/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="initialen">{{ command.Inputs.INITIALEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="InitialenErrorTip">
        <ng-template #InitialenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.INITIALEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="initialen" id="initialen" [placeholder]="command.Inputs.INITIALEN.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="objektArten">{{ command.Inputs.OBJEKT_ARTEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ObjektArtenErrorTip">
        <ng-template #ObjektArtenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.OBJEKT_ARTEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value mode="multiple" formControlName="objektArten" id="objektArten" [shapes]="postFormular.formularObjektArten | async" [placeholder]="command.Inputs.OBJEKT_ARTEN.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="bewertungsAnlaesse">{{ command.Inputs.BEWERTUNGS_ANLAESSE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BewertungsAnlaesseErrorTip">
        <ng-template #BewertungsAnlaesseErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BEWERTUNGS_ANLAESSE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value mode="multiple" formControlName="bewertungsAnlaesse" id="bewertungsAnlaesse" [shapes]="postFormular.formularBewertungsAnlaesse | async" [placeholder]="command.Inputs.BEWERTUNGS_ANLAESSE.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kundenArten">{{ command.Inputs.KUNDEN_ARTEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KundenArtenErrorTip">
        <ng-template #KundenArtenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KUNDEN_ARTEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value mode="multiple" formControlName="kundenArten" id="kundenArten" [shapes]="postFormular.formularKundenArten | async" [placeholder]="command.Inputs.KUNDEN_ARTEN.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kunden">{{ command.Inputs.KUNDEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KundenErrorTip">
        <ng-template #KundenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KUNDEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value mode="multiple" formControlName="kunden" id="kunden" [shapes]="postFormular.formularKunden | async" [placeholder]="command.Inputs.KUNDEN.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="ohneFelder">{{ command.Inputs.OHNE_FELDER.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="OhneFelderErrorTip">
        <ng-template #OhneFelderErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.OHNE_FELDER.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value mode="multiple" formControlName="ohneFelder" id="ohneFelder" [shapes]="postFormular.formularOhneFelder | async" [placeholder]="command.Inputs.OHNE_FELDER.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kommentar">{{ command.Inputs.KOMMENTAR.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KommentarErrorTip">
        <ng-template #KommentarErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KOMMENTAR.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <textarea nz-input formControlName="kommentar" id="kommentar" [placeholder]="command.Inputs.KOMMENTAR.placeholder"></textarea>
      </nz-form-control>
    </nz-form-item>
  </div>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
<!--- << custom    -->
    <div formGroupName="formular">
      <input class="cdk-visually-hidden" formControlName="id" id="id"/>
      <input class="cdk-visually-hidden" formControlName="objektArten" id="objektArten"/>
      <input class="cdk-visually-hidden" formControlName="bewertungsAnlaesse" id="bewertungsAnlaesse"/>
      <input class="cdk-visually-hidden" formControlName="kundenArten" id="kundenArten"/>
      <input class="cdk-visually-hidden" formControlName="kunden" id="kunden"/>

      <nz-divider nzText="Allgemein" nzOrientation="left"></nz-divider>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">{{ command.Inputs.NAME.title}}</nz-form-label>
        <nz-form-control [nzSm]="8" [nzXs]="24" [nzErrorTip]="NameErrorTip">
          <ng-template #NameErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NAME.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input formControlName="name" id="name" [placeholder]="command.Inputs.NAME.placeholder" cdkFocusInitial/>
        </nz-form-control>
        <nz-form-control nzOffset="1" [nzSm]="3" [nzXs]="24" [nzErrorTip]="InitialenErrorTip">
          <ng-template #InitialenErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.INITIALEN.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input formControlName="initialen" id="initialen" [placeholder]="command.Inputs.INITIALEN.placeholder"/>
        </nz-form-control>
      </nz-form-item>
      <nz-divider nzText="Felder" nzOrientation="left"></nz-divider>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="ohneFelder">Felder</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="OhneFelderErrorTip">
          <ng-template #OhneFelderErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.OHNE_FELDER.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <codeboard-select-value mode="multiple" formControlName="felder" id="felder" [shapes]="postFormular.formularOhneFelder | async" [placeholder]="'Felder'" ></codeboard-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-divider nzText="Sonstiges" nzOrientation="left"></nz-divider>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kommentar">{{ command.Inputs.KOMMENTAR.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KommentarErrorTip">
          <ng-template #KommentarErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KOMMENTAR.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <textarea nz-input formControlName="kommentar" id="kommentar" [placeholder]="command.Inputs.KOMMENTAR.placeholder"></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>
<!---    custom >> -->
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button"
          type="submit"
          [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)"
          nz-tooltip [nzTooltipTitle]="SubmitTooltip">
<!--- << confirm    -->
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Speichern
<!---    confirm >> -->
          <ng-template #SubmitTooltip>
<!--- << hint    -->
            Formular Speichern
<!---    hint >> -->
          </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
