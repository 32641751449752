import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { DeleteBerichtCommandServiceModule } from '../';
import { DeleteBerichtCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteBerichtCommandServiceModule
  ],
  declarations: [DeleteBerichtCommandComponent],
  exports: [DeleteBerichtCommandComponent],

})
export class DeleteBerichtCommandComponentModule { }
