import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IPostErinnerungRequest, IPostErinnerungResponse, IPostErinnerungCommandShapes } from '@fa-kt-evaluation/kommunikation/types';

@Injectable({
  providedIn: 'root'
})
export class PostErinnerungCommandService {

  readonly route = 'kommunikation/erinnerung';
  protected dependencies = [
      'kommunikation/erinnerung-entfernt',
      'kommunikation/erinnerung-gemerkt'
  ];

  readonly $shapes = new BehaviorSubject<IPostErinnerungCommandShapes|undefined>(undefined);
  
  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IPostErinnerungCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IPostErinnerungCommandShapes>(this.route + '/post/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IPostErinnerungRequest): Promise<IPostErinnerungResponse> {
    return await this.http.post<IPostErinnerungResponse>(this.route, payload) as unknown as IPostErinnerungResponse;
  }
  
  getErinnerungMitarbeiter(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['erinnerung.mitarbeiter']?.find(v => v.value === value)
  }

  get erinnerungMitarbeiter(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['erinnerung.mitarbeiter'] as ISelection[] : []));
  }

  getErinnerungVerfasser(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['erinnerung.verfasser']?.find(v => v.value === value)
  }

  get erinnerungVerfasser(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['erinnerung.verfasser'] as ISelection[] : []));
  }

}
