import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { DeleteBerichtsVorlageCommandServiceModule } from '../';
import { DeleteBerichtsVorlageCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteBerichtsVorlageCommandServiceModule
  ],
  declarations: [DeleteBerichtsVorlageCommandComponent],
  exports: [DeleteBerichtsVorlageCommandComponent],

})
export class DeleteBerichtsVorlageCommandComponentModule { }
