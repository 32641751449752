import { Injectable } from '@angular/core';

import { IAzureMapsSuggestion } from "@lib/geo/types";
import { SecretService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

@Injectable({
  providedIn: 'root'
})
export class AtlasService {

  private key: string|null = null;

  public readonly bundeslandKennzahlLength = 2;
  public readonly regierungsbezirkKennzahlLength = 1;
  public readonly kreisKennzahlLength = 2;
  public readonly verbandKennzahlLength = 4;
  public readonly gemeindeKennzahlLength = 3;
  public readonly keyPattern = /^[0-9]{1,4}$/;
  public readonly plzPattern = /^[0-9]{4,6}$/;

  constructor(private http: HttpService, private secrets: SecretService) {
    this.secrets.$secrets.subscribe(secrets => {
      if (secrets && secrets.maps) {
        this.key = secrets.maps.key;
      }
    })
  }

  async suggestByTerm(query: string, types = ["Address Range", "Point Address"]): Promise<IAzureMapsSuggestion[]> {
    if (!this.key || !query) {
      return [];
    }
    const countrySet = 'AT,BE,BG,DK,DE,EE,FI,FR,GR,IE,IT,HR,LV,LT,LU,MT,NL,PL,PT,RO,SE,SK,SI,ES,CZ,HU,CY,CH,' +
      'MC,IS,LI,NO,' +
      'AL,ME,MK,RS,TR,' +
      'AD,BY,BA,MD,MC,RU,SM,UA,VA,UK' +
      'USA,JPN,CHN,AUS,BRA,SGP'
    const language = 'de-DE';
    let suggestions: IAzureMapsSuggestion[] = [];
    try {
      // , { headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}}
      const result: any = await this.http.get(`https://atlas.microsoft.com/search/address/json?subscription-key=${this.key}&api-version=1.0&query=${query}&typeahead=true&countrySet=${countrySet}&language=${language}&extendedPostalCodesFor=PAD`)
      if (result && result.results) {
        suggestions = result.results.filter((entry: any) => types.length === 0 || types.includes(entry.type))
          .map((entry: any) => ({ ...entry, address: { ...entry.address, streetAddress: entry.address.streetName + ' ' + entry.address.streetNumber }}));
      }
    } catch (e) {
      console.error(e);
    }
    return suggestions;
  }

  async suggestByCoordinates(longitude: number, latitude: number): Promise<IAzureMapsSuggestion[]> {
    if (!this.key || !latitude && !longitude) {
      return [];
    }
    const language = 'de-DE';
    let suggestions: IAzureMapsSuggestion[] = [];
    try {
      const result: any = await this.http.get(`https://atlas.microsoft.com/search/address/reverse/json?subscription-key=${this.key}&api-version=1.0&query=${latitude},${longitude}&language=${language}`)
      if (result && result.addresses) {
        suggestions = result.addresses.filter((entry: any) => entry.address.streetName && entry.address.streetNumber)
          .map((entry: any) => ({ position: { lon: entry.position.split(',')[1], lat: entry.position.split(',')[0] }, address: { ...entry.address, streetAddress: entry.address.streetName + ' ' + entry.address.streetNumber }}));
      }
    } catch (e) {
      console.error(e);
    }
    return suggestions;
  }

  async suggestRegionalSchluessel(suchbegriff: string) {
    const result: any = await this.http.get(`https://ags-ars.api.vsm.nrw/orte?suchbegriff=` + encodeURIComponent(suchbegriff), { headers: { Accept: 'application/json' }});
    if (result && result.daten) {
      const schluessel = result.daten.filter((e: any) => e.regionalschluessel);
      if (schluessel && schluessel.length > 0) {
        const regionalSchluessel = schluessel[0].regionalschluessel;
        const bundeslandKennzahl = regionalSchluessel.substr(0, this.bundeslandKennzahlLength);
        const regierungsbezirkKennzahl = regionalSchluessel.substr(this.bundeslandKennzahlLength, this.regierungsbezirkKennzahlLength);
        const kreisKennzahl = regionalSchluessel.substr(this.bundeslandKennzahlLength + this.regierungsbezirkKennzahlLength, this.kreisKennzahlLength);
        const verbandKennzahl = regionalSchluessel.substr(this.bundeslandKennzahlLength + this.regierungsbezirkKennzahlLength + this.kreisKennzahlLength, this.verbandKennzahlLength);
        const gemeindeKennzahl = regionalSchluessel.substr(this.bundeslandKennzahlLength + this.regierungsbezirkKennzahlLength + this.kreisKennzahlLength + this.verbandKennzahlLength);
        return {
          regionalSchluessel,
          bundeslandKennzahl,
          regierungsbezirkKennzahl,
          kreisKennzahl,
          verbandKennzahl,
          gemeindeKennzahl
        }
      }
    };
    return null;
  }
}
