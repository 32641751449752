import { Component } from '@angular/core';
import { ReisekostenabrechnungenService } from "@fa-kt-evaluation/finanzen/frontend";

/* << imports    */
 
/*    imports >> */
@Component({
  selector: 'fa-kt-evaluation-saved-reisekostenabrechnungen-filters',
  templateUrl: './saved-reisekostenabrechnungen-filters.component.html',
  styleUrls: ['./saved-reisekostenabrechnungen-filters.component.css']
})
export class SavedReisekostenabrechnungenFiltersComponent {

  constructor(
    public reisekostenabrechnungen: ReisekostenabrechnungenService
/* << injection    */
/*    injection >> */
  ) {
  }
}