<!---
  <div formGroupName="eintrag">
    <input class="cdk-visually-hidden" formControlName="id" id="id"/>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="gutachten">{{ command.Inputs.GUTACHTEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="GutachtenErrorTip">
        <ng-template #GutachtenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.GUTACHTEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="gutachten" id="gutachten" [shapes]="postEintrag.eintragGutachten | async" [placeholder]="command.Inputs.GUTACHTEN.placeholder" cdkFocusInitial required=true ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="feld">{{ command.Inputs.FELD.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FeldErrorTip">
        <ng-template #FeldErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FELD.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="feld" id="feld" [shapes]="postEintrag.eintragFeld | async" [placeholder]="command.Inputs.FELD.placeholder" required=true ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="wert">{{ command.Inputs.WERT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="WertErrorTip">
        <ng-template #WertErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.WERT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="wert" id="wert" [shapes]="postEintrag.eintragWert | async" [placeholder]="command.Inputs.WERT.placeholder" required=true ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="wertExtra">{{ command.Inputs.WERT_EXTRA.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="WertExtraErrorTip">
        <ng-template #WertExtraErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.WERT_EXTRA.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="wertExtra" id="wertExtra" [placeholder]="command.Inputs.WERT_EXTRA.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="akkusativ">{{ command.Inputs.AKKUSATIV.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AkkusativErrorTip">
        <ng-template #AkkusativErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.AKKUSATIV.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="akkusativ" id="akkusativ" [placeholder]="command.Inputs.AKKUSATIV.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="nominativ">{{ command.Inputs.NOMINATIV.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NominativErrorTip">
        <ng-template #NominativErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NOMINATIV.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="nominativ" id="nominativ" [placeholder]="command.Inputs.NOMINATIV.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="dativ">{{ command.Inputs.DATIV.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DativErrorTip">
        <ng-template #DativErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.DATIV.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="dativ" id="dativ" [placeholder]="command.Inputs.DATIV.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="genitiv">{{ command.Inputs.GENITIV.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="GenitivErrorTip">
        <ng-template #GenitivErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.GENITIV.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="genitiv" id="genitiv" [placeholder]="command.Inputs.GENITIV.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="achtung">{{ command.Inputs.ACHTUNG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AchtungErrorTip">
        <ng-template #AchtungErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ACHTUNG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <label nz-checkbox formControlName="achtung" id="achtung"></label>
      </nz-form-control>
    </nz-form-item>
  </div>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
<!--- << custom    -->
    <div formGroupName="eintrag">
      <input class="cdk-visually-hidden" formControlName="id" id="id"/>
      <input class="cdk-visually-hidden" formControlName="gutachten" id="gutachten"/>
      <input class="cdk-visually-hidden" formControlName="feld" id="feld"/>

      <ng-container [ngSwitch]="mode">
        <ng-container *ngSwitchCase="'text'">
          <nz-form-item style="width: 100%;">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="wert">{{ command.Inputs.WERT.title}}</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="WertErrorTip">
              <ng-template #WertErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.WERT.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <codeboard-multi-value formControlName="wert" id="wert" [placeholder]="command.Inputs.WERT.placeholder"></codeboard-multi-value>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item style="width: 100%;">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="akkusativ">{{ command.Inputs.AKKUSATIV.title}}</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AkkusativErrorTip">
              <ng-template #AkkusativErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.AKKUSATIV.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <input nz-input formControlName="akkusativ" id="akkusativ" [placeholder]="command.Inputs.AKKUSATIV.placeholder"/>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item style="width: 100%;">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="nominativ">{{ command.Inputs.NOMINATIV.title}}</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NominativErrorTip">
              <ng-template #NominativErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NOMINATIV.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <input nz-input formControlName="nominativ" id="nominativ" [placeholder]="command.Inputs.NOMINATIV.placeholder"/>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item style="width: 100%;">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="dativ">{{ command.Inputs.DATIV.title}}</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DativErrorTip">
              <ng-template #DativErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.DATIV.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <input nz-input formControlName="dativ" id="dativ" [placeholder]="command.Inputs.DATIV.placeholder"/>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item style="width: 100%;">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="genitiv">{{ command.Inputs.GENITIV.title}}</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="GenitivErrorTip">
              <ng-template #GenitivErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.GENITIV.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <input nz-input formControlName="genitiv" id="genitiv" [placeholder]="command.Inputs.GENITIV.placeholder"/>
            </nz-form-control>
          </nz-form-item>
        </ng-container>
        <ng-container *ngSwitchCase="'option'">
          Antwort: <strong>{{ eintragWert?.value }}</strong><br>
          <br>
          <p *ngFor="let option of optionen">
            <button nz-button nzType="default" nzBlock type="button" nzShape="round" (click)="eintragWert.patchValue(option.option)">{{ option.option }}</button>
          </p>
          <input class="cdk-visually-hidden" formControlName="wert" id="0-hidden"/>
          <input class="cdk-visually-hidden" formControlName="akkusativ" id="1-hidden"/>
          <input class="cdk-visually-hidden" formControlName="nominativ" id="2-hidden"/>
          <input class="cdk-visually-hidden" formControlName="dativ" id="3-hidden"/>
          <input class="cdk-visually-hidden" formControlName="genitiv" id="4-hidden"/>
        </ng-container>
        <ng-container *ngSwitchCase="'optionPlus'">
          <p *ngFor="let option of optionen">
            <button nz-button nzType="primary" type="button" nzShape="round" (click)="eintragWert.patchValue(option.option)">{{ option.option }}</button>
          </p>
          <nz-form-item style="width: 100%;">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="wert">{{ command.Inputs.WERT.title}}</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="WertErrorTip">
              <ng-template #WertErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.WERT.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <codeboard-multi-value [mode]="mode" formControlName="wert" id="plus-wert" [placeholder]="command.Inputs.WERT.placeholder"></codeboard-multi-value>
            </nz-form-control>
          </nz-form-item>
          <input class="cdk-visually-hidden" formControlName="akkusativ" id="5-hidden"/>
          <input class="cdk-visually-hidden" formControlName="nominativ" id="6-hidden"/>
          <input class="cdk-visually-hidden" formControlName="dativ" id="7-hidden"/>
          <input class="cdk-visually-hidden" formControlName="genitiv" id="8-hidden"/>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <nz-form-item style="width: 100%;">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="wert">{{ command.Inputs.WERT.title}}</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="WertErrorTip">
              <ng-template #WertErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.WERT.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <codeboard-multi-value [mode]="mode" formControlName="wert" id="default-wert" [placeholder]="command.Inputs.WERT.placeholder"></codeboard-multi-value>
            </nz-form-control>
          </nz-form-item>
          <input class="cdk-visually-hidden" formControlName="akkusativ" id="9-hidden"/>
          <input class="cdk-visually-hidden" formControlName="nominativ" id="21-hidden"/>
          <input class="cdk-visually-hidden" formControlName="dativ" id="13-hidden"/>
          <input class="cdk-visually-hidden" formControlName="genitiv" id="14-hidden"/>
        </ng-container>
      </ng-container>

    </div>
<!---    custom >> -->
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button"
          type="submit"
          [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)"
          nz-tooltip [nzTooltipTitle]="SubmitTooltip">
<!--- << confirm    -->
          <i nz-icon nzType="check-circle" nzTheme="outline"></i>
          Bewertung Speichern
<!---    confirm >> -->
          <ng-template #SubmitTooltip>
<!--- << hint    -->
            Eintrag speichern
<!---    hint >> -->
          </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
