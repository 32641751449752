import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { TourenServiceModule } from '../';
import { TourenListComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    TourenServiceModule
  ],
  declarations: [TourenListComponent],
  exports: [TourenListComponent],

})
export class TourenListComponentModule { }
