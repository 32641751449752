import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { KundeServiceModule } from '../';
import { SavedKundeFiltersComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    KundeServiceModule
  ],
  declarations: [SavedKundeFiltersComponent],
  exports: [SavedKundeFiltersComponent],

})
export class SavedKundeFiltersComponentModule { }
