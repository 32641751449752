import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IDeleteKundeRequest, IDeleteKundeResponse, IDeleteKundeCommandShapes } from '@fa-kt-evaluation/kunden/types';

@Injectable({
  providedIn: 'root'
})
export class DeleteKundeCommandService {

  readonly route = 'kunden/kunde';
  protected dependencies = [
      'kunden/written-kunden-art',
      'kunden/removed-kunden-art',
      'kunden/written-kunden-gruppe',
      'kunden/removed-kunden-gruppe',
      'kunden/kunde-entfernt'
  ];

  readonly $shapes = new BehaviorSubject<IDeleteKundeCommandShapes|undefined>(undefined);
  
  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IDeleteKundeCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IDeleteKundeCommandShapes>(this.route + '/delete/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IDeleteKundeRequest): Promise<IDeleteKundeResponse> {
    return await this.http.delete<IDeleteKundeResponse>(this.route + this.http.serialize(payload)) as unknown as IDeleteKundeResponse;
  }
  
}
