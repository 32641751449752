import { Injectable } from '@angular/core';
import { AbstractControl } from "@angular/forms";

import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, first, map } from "rxjs/operators";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { FilterPipe, SearchPipe, SorterPipe } from "@lib/filter/frontend";
import { ISelection } from "@lib/persistence/types";

import {
  IGutachtenStatiRequest,
  IGutachtenStatiResponse,
  IGutachtenStatiQuerySorter,
  IGutachtenStatiQueryFilter,
  IGutachtenStatiQueryFilterShapes,
  IGutachtenStatiResponseGroup,
} from '@fa-kt-evaluation/gutachten/types';

@Injectable({
  providedIn: 'root'
})
export class GutachtenStatiService {

  protected route = 'gutachten/settings/gutachten-stati';
  protected dependencies = [
    'gutachten/written-gutachten-status',
    'gutachten/removed-gutachten-status',
  ];

  readonly $result = new BehaviorSubject<IGutachtenStatiResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IGutachtenStatiRequest|undefined>(undefined);
  readonly $response = new BehaviorSubject<IGutachtenStatiResponse|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IGutachtenStatiQueryFilterShapes|undefined>(undefined);
  readonly $filter = new BehaviorSubject<IGutachtenStatiQueryFilter>({});
  readonly $sorter = new BehaviorSubject<IGutachtenStatiQuerySorter>({});
  readonly $search = new BehaviorSubject<string>('');
  private readonly $filtered = new BehaviorSubject<IGutachtenStatiResponse|undefined>(undefined);
  private readonly $searched = new BehaviorSubject<IGutachtenStatiResponse|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private search: SearchPipe,
    private filter: FilterPipe,
    private sorter: SorterPipe,
    private device: DeviceConfigService
  ) {
    combineLatest([
      this.$response,
      this.$filter,
    ]).subscribe(([response, filter]) => {
      if (response && response.gutachtenStati) {
        const gutachtenStati = this.filter.transform(response.gutachtenStati, filter, true);
        this.$filtered.next( { ...response, gutachtenStati });
      } else {
        this.$filtered.next(response);
      }
    });
    combineLatest([
      this.$filtered,
      this.$search.pipe(debounceTime(device.debounceTime), distinctUntilChanged()),
      this.$shapes
    ]).subscribe(([filtered, search, shapes]) => {
      if (filtered && filtered.gutachtenStati) {
        const gutachtenStati = this.search.transform(filtered.gutachtenStati, search, '_search', shapes);
        this.$searched.next( { ...filtered, gutachtenStati });
      } else {
        this.$searched.next(filtered);
      }
    });
    combineLatest([
      this.$searched,
      this.$sorter,
      this.$shapes
    ]).pipe(debounceTime(10)).subscribe(([searched, sorter, shapes]) => {
      if (searched && searched.gutachtenStati) {
        const gutachtenStati = this.sorter.transform(searched.gutachtenStati, sorter, shapes);
        this.$result.next( { ...searched, gutachtenStati });
      } else {
        this.$result.next(searched);
      }
    });
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IGutachtenStatiRequest): IGutachtenStatiRequest {
    if (payload) {
      this.$payload.next(payload as IGutachtenStatiRequest);
    } else {
      payload = this.$payload.getValue() as IGutachtenStatiRequest;
    }
    return payload;
  }

  public async request(payload?: IGutachtenStatiRequest, force = false): Promise<IGutachtenStatiResponse> {
    payload = this.payload(payload);
    const state = this.$response.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const { response, shapes } = await this.http.get<IGutachtenStatiResponseGroup>(this.route + this.http.serialize(payload));
    this.$shapes.next(shapes);
    this.$response.next(response);
    return response;
  }
  


}
