import { Component, OnInit, OnDestroy, Inject, Input, Output, EventEmitter, Injector, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { NzMessageService } from "ng-zorro-antd/message";

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { AuthService } from "@lib/auth/frontend";
import {
  ActionService,
  cleanObject, toInitials,
  CustomValidators, CustomFormatter,
  ISitemap, ISitemapCommand, SITEMAP,
} from "@lib/common/frontend";

import { IPostMitarbeiterRequest, IPostMitarbeiterResponse } from "@fa-kt-evaluation/personen/types";
import { PostMitarbeiterCommandService } from '@fa-kt-evaluation/personen/frontend'

/* << importing    */
/*    importing >> */

@Component({
  selector: 'fa-kt-evaluation-post-mitarbeiter',
  templateUrl: './post-mitarbeiter.component.html',
  styleUrls: ['./post-mitarbeiter.component.css']
})
export class PostMitarbeiterCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) { this.$loading.next(this.$loading.getValue() || loading); }
  @Input() set value(value: IPostMitarbeiterRequest) { this.patch(value); }
  @Output() valueChanged: EventEmitter<IPostMitarbeiterRequest> = new EventEmitter<IPostMitarbeiterRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostMitarbeiterResponse> = new EventEmitter<IPostMitarbeiterResponse>();

/* << fields    */
/*    fields >> */

  get modalRef() {
    return this.injector.get(NzModalRef)
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public postMitarbeiter: PostMitarbeiterCommandService,
/* << more    */
/*    more >> */
  ) {
    this.command = sitemap.PERSONEN.Commands.POST_MITARBEITER;
    this.form = this.fb.group({
      mitarbeiter: this.fb.group({
        id: [null, []],
        email: [null, [Validators.required]],
        anzeigename: [null, [Validators.required]],
        bild: [null, []],
        addresse: [],
        vorname: [null, []],
        nachname: [null, []],
        telefon: [null, []],
        standort: [null, [Validators.required]],
        rollen: [[], [Validators.required]],
        stundenlohn: [null, []],
        inaktiv: [null, []],
        tourenplanung: [null, []],
        isAdmin: [false, []],
      })
    });
/* << constructor    */
/*    constructor >> */
    this.patch();
  }

  ngOnInit() {
    this.postMitarbeiter.prepare().catch(e => console.error(e));
    this.subscriptions.push(
      this.finished.subscribe((result) => { if (this.modalRef) { this.modalRef.destroy(result); }}),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() })
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostMitarbeiterRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),
      this.mitarbeiterId?.valueChanges.subscribe(async (id) => {
/* << on-mitarbeiter-id-change    */
/*    on-mitarbeiter-id-change >> */
      }) as Subscription,
      this.mitarbeiterEmail?.valueChanges.subscribe(async (email) => {
/* << on-mitarbeiter-email-change    */
/*    on-mitarbeiter-email-change >> */
      }) as Subscription,
      this.mitarbeiterAnzeigename?.valueChanges.subscribe(async (anzeigename) => {
/* << on-mitarbeiter-anzeigename-change    */
/*    on-mitarbeiter-anzeigename-change >> */
      }) as Subscription,
      this.mitarbeiterBild?.valueChanges.subscribe(async (bild) => {
/* << on-mitarbeiter-bild-change    */
/*    on-mitarbeiter-bild-change >> */
      }) as Subscription,
      this.mitarbeiterAddresse?.valueChanges.subscribe(async (addresse) => {
/* << on-mitarbeiter-addresse-change    */
/*    on-mitarbeiter-addresse-change >> */
      }) as Subscription,
      this.mitarbeiterVorname?.valueChanges.subscribe(async (vorname) => {
/* << on-mitarbeiter-vorname-change    */
/*    on-mitarbeiter-vorname-change >> */
      }) as Subscription,
      this.mitarbeiterNachname?.valueChanges.subscribe(async (nachname) => {
/* << on-mitarbeiter-nachname-change    */
/*    on-mitarbeiter-nachname-change >> */
      }) as Subscription,
      this.mitarbeiterTelefon?.valueChanges.subscribe(async (telefon) => {
/* << on-mitarbeiter-telefon-change    */
/*    on-mitarbeiter-telefon-change >> */
      }) as Subscription,
      this.mitarbeiterStandort?.valueChanges.subscribe(async (standort) => {
/* << on-mitarbeiter-standort-change    */
/*    on-mitarbeiter-standort-change >> */
      }) as Subscription,
      this.mitarbeiterRollen?.valueChanges.subscribe(async (rollen) => {
/* << on-mitarbeiter-rollen-change    */
/*    on-mitarbeiter-rollen-change >> */
      }) as Subscription,
      this.mitarbeiterStundenlohn?.valueChanges.subscribe(async (stundenlohn) => {
/* << on-mitarbeiter-stundenlohn-change    */
/*    on-mitarbeiter-stundenlohn-change >> */
      }) as Subscription,
      this.mitarbeiterInaktiv?.valueChanges.subscribe(async (inaktiv) => {
/* << on-mitarbeiter-inaktiv-change    */
/*    on-mitarbeiter-inaktiv-change >> */
      }) as Subscription,
/* << subscibe    */
      this.auth.$isAdmin.subscribe(isAdmin => {
        if (isAdmin) {
          this.form.controls.mitarbeiter.get('rollen')?.enable();
          this.form.controls.mitarbeiter.get('isAdmin')?.enable();
        } else {
          this.form.controls.mitarbeiter.get('rollen')?.disable();
          this.form.controls.mitarbeiter.get('isAdmin')?.disable();
        }
      }),
/*    subscibe >> */
    );
/* << init    */
    this.form.controls.mitarbeiter.get('email')?.disable();
/*    init >> */
  }

  ngOnDestroy() {
/* << end    */
/*    end >> */
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IPostMitarbeiterRequest) {
    if (value) {
      value = cleanObject(value);
      if (value.mitarbeiter) {
        value.mitarbeiter = cleanObject(value.mitarbeiter);
      }
      this.form.patchValue(value);
/* << custom    */
/*    custom >> */
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload.mitarbeiter) {
        payload.mitarbeiter = cleanObject(payload.mitarbeiter);
      }
      payload.mitarbeiter.groups = payload.mitarbeiter.rollen;
      const response: IPostMitarbeiterResponse = await this.postMitarbeiter.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of (error.errors ? error.errors : [])) {
        for (const children of (validation.children ? validation.children : [])) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

/* << methods    */
/*    methods >> */

  get mitarbeiterId() {
    return this.form.controls.mitarbeiter.get('id');
  };
  get mitarbeiterEmail() {
    return this.form.controls.mitarbeiter.get('email');
  };
  get mitarbeiterAnzeigename() {
    return this.form.controls.mitarbeiter.get('anzeigename');
  };
  get mitarbeiterBild() {
    return this.form.controls.mitarbeiter.get('bild');
  };
  get mitarbeiterAddresse() {
    return this.form.controls.mitarbeiter.get('addresse');
  };
  get mitarbeiterVorname() {
    return this.form.controls.mitarbeiter.get('vorname');
  };
  get mitarbeiterNachname() {
    return this.form.controls.mitarbeiter.get('nachname');
  };
  get mitarbeiterTelefon() {
    return this.form.controls.mitarbeiter.get('telefon');
  };
  get mitarbeiterStandort() {
    return this.form.controls.mitarbeiter.get('standort');
  };
  get mitarbeiterRollen() {
    return this.form.controls.mitarbeiter.get('rollen');
  };
  get mitarbeiterStundenlohn() {
    return this.form.controls.mitarbeiter.get('stundenlohn');
  };
  get mitarbeiterInaktiv() {
    return this.form.controls.mitarbeiter.get('inaktiv');
  };


}
