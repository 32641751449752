import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostKalkulationHochladenCommandServiceModule } from '../';
import { PostKalkulationHochladenCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostKalkulationHochladenCommandServiceModule
  ],
  declarations: [PostKalkulationHochladenCommandComponent],
  exports: [PostKalkulationHochladenCommandComponent],

})
export class PostKalkulationHochladenCommandComponentModule { }
