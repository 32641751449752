import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { BehaviorSubject, combineLatest, Subscription } from "rxjs";

import '@ckeditor/ckeditor5-build-classic/build/translations/de';
import { ChangeEvent, CKEditor5, CKEditorComponent } from "@ckeditor/ckeditor5-angular";
import * as ClassicEditorWithPlaceholder from './placeholder/ckeditor';

import { IFeld } from "@fa-kt-evaluation/felder/types";
import { propertiesToArray, toConstant, toInitials } from "../../functions";
import { compileWithNunjucks } from "@lib/templates/types";

import { get, uniqBy } from "lodash";
import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { JsonViewerComponent } from "../json-viewer";
import { Feld } from "@fa-kt-evaluation/texte/frontend";

@Component({
  selector: 'codeboard-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: TextEditorComponent
    }
  ]
})
export class TextEditorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  private readonly subscriptions: Subscription[] = [];

  onChange = (value: string) => {};
  onTouched = () => {};
  touched = false;
  @Input() disabled = false;
  @Input() set object(obj: any) {
    this.$object.next(obj);
  }
  $object = new BehaviorSubject<any>({});
  @Input() set felder(felder: Feld[]) {
    this.$felder.next(felder);
  }
  $felder = new BehaviorSubject<Feld[]>([]);
  @Input() set placeholder(value: string) {
    this.config.placeholder = value;
  }

  @Input() set text(text: string) {
    if (!text) { text = ''; }
    this.$text.next(text)
  }
  @Output() textChange = new EventEmitter<string>();
  @Output() results = new EventEmitter<string>();

  public Editor = ClassicEditorWithPlaceholder;
  public config = {
    language: 'de',
    placeholder: '',
    toolbar: [],
  };
  @ViewChild( 'editor' ) editorComponent: CKEditorComponent|undefined;

  $text = new BehaviorSubject<string>('');
  $rendered = new BehaviorSubject<string>('');
  errors: any[] = [];

  $placeholders = new BehaviorSubject<IFeld[]>([]);
  option = "";
  showPlaceholderSelection = false;

  $ifs = new BehaviorSubject<IFeld[]>([]);
  condition = "";
  showConditionSelection = false;

  search = "";

  showObject: any;

  constructor(
    public modal: NzModalService,
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(...[
      combineLatest([
        this.$felder,
        this.$object
      ]).subscribe(([felder, obj]) => {
        function getFeldArt(value: any): 'datum'|'haken'|'zahl'|'text'|'option'|'optionPlus'|'mehrfachauswahlPlus' {
          const typeOf = typeof value;
          switch (typeOf) {
            case "number":
              return 'zahl';
            case "boolean":
              return 'haken';
          }
          if (value instanceof Date && !isNaN(value as any)) {
            return 'datum';
          }
          return 'text';
        }
        felder = felder.concat(propertiesToArray(obj)
          .filter((feld: string) => !feld?.includes('._') && feld?.[feld?.length - 1] !== '.')
          .map(name => ({
              id: "",
              name: name,
              schluessel: this.toSchluessel(name),
              art: getFeldArt(get(obj, name)),
              feldKategorie: "",
              feldUnterkategorie: "",
              feldOptionen: [],
            })));
        let placeholders: Feld[] = felder;
        let ifs: Feld[] = [];
        for (const feld of placeholders) {
          ifs.push({
            ...feld,
            name: feld.name + " wenn vorhanden"
          });
          switch (feld.art) {
            case "zahl":
              ifs.push({
                ...feld,
                schluessel: feld.schluessel + " === 1",
                name: feld.name + " wenn Einzahl"
              })
              break;
            case "option":
            case "optionPlus":
            case "mehrfachauswahlPlus":
              feld.feldOptionen.forEach((option) => {
                ifs.push({
                  ...feld,
                  schluessel: feld.schluessel + " === '" + option.option + "'",
                  name: feld.name + " ist '" + option.option + "'"
                })
              })
              break;
          }
        }
        this.$placeholders.next(uniqBy(placeholders, "schluessel"));
        this.$ifs.next(uniqBy(ifs, "schluessel"));
      }),
      this.$text.subscribe(text => {
        this.onChange(text);
        this.textChange.emit(text);
      }),
      combineLatest([
        this.$text,
        this.$object
      ]).subscribe(([text, obj]) => {
        try {
          this.errors = [];
          console.debug(text, obj);
          const rendered = compileWithNunjucks(text, obj);
          this.$rendered.next(rendered);
        } catch (error: any) {
          this.errors.push(error);
          console.error(error);
        }
      }),
      this.$rendered.subscribe(this.results.emit),
    ])
  }

  toSchluessel(name: string): string {
    const arr = name.split(" ");
    if (!arr || arr.length === 0) { return ''; }
    if (arr.length === 1) { return (arr[0]); }
    const last: string = arr.pop() as string;
    return arr.map(name => toConstant(toInitials(name))).join('_') + '_' + toConstant(last);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach($ => $.unsubscribe());
  }

  get editor() {
    return this.editorComponent?.editorInstance;
  }

  public change( { editor }: ChangeEvent ) {
    this.$text.next(editor.getData());
  }


  writeValue(value: string) {
    this.text = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  onReady(editor: CKEditor5.Editor) {
    const toolbarContainer = document.querySelector( '.document-editor__toolbar' );
    toolbarContainer?.appendChild( editor.ui.view.toolbar.element );
  }


  createPlaceholder() {
    this.showPlaceholderSelection = !this.showPlaceholderSelection;
    this.showConditionSelection = false;
  }

  insertPlaceholder(value: string) {
    if (!value) { return; }
    this.editor?.execute( 'placeholder', { value } );
    this.option = "";
    this.showPlaceholderSelection = false;
  }

  createCondition() {
    this.showConditionSelection = !this.showConditionSelection;
    this.showPlaceholderSelection = false;
  }

  insertCondition(value: string) {
    if (!value) { return; }
    this.editor?.execute( 'if', { value });
    this.nextPosition();
    this.editor?.execute( 'else', { value });
    this.nextPosition();
    this.editor?.execute( 'end', { value });
    this.condition = "";
    this.showConditionSelection = false;
  }

  private nextPosition() {
    this.editor?.model.change((writer: any) => {
      const pos = this.editor?.model.document.selection.getFirstPosition()
      pos.path[1] = pos.path[1]+1;
      writer.setSelection(pos);
    } );
  }
}
