import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { first, map, debounceTime, distinctUntilChanged } from "rxjs/operators";

import { isEqual } from "lodash";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { FilterPipe, SearchPipe, SorterPipe } from "@lib/filter/frontend";
import { IPersistedFilter, IPersistedFilterList } from "@lib/filter/types";
import { ISelection } from "@lib/persistence/types";

import {
  IGutachtenRequest,
  IGutachtenResponse,
  IGutachtenResponseGroup,
  IGutachtenQuerySorter,
  IGutachtenQueryFilter,
  IGutachtenQueryFilterShapes
} from '@fa-kt-evaluation/gutachten/types';

@Injectable({
  providedIn: 'root'
})
export class GutachtenService {

  protected route = 'gutachten/gutachten';
  protected dependencies = [
    'besichtigungen/written-besichtigungs-status',
    'besichtigungen/removed-besichtigungs-status',
    'dokumente/written-erforderliches-dokument',
    'dokumente/removed-erforderliches-dokument',
    'geo/written-bereich',
    'geo/removed-bereich',
    'gutachten/written-bewertungs-anlass',
    'gutachten/removed-bewertungs-anlass',
    'gutachten/written-gutachten-status',
    'gutachten/removed-gutachten-status',
    'markt/written-herausgeber',
    'markt/removed-herausgeber',
    'objekte/written-objekt-art',
    'objekte/removed-objekt-art',
    'objekte/written-objekt-nutzung',
    'objekte/removed-objekt-nutzung',
    'personen/written-standort',
    'personen/removed-standort',
    'projekte/written-projekt-art',
    'projekte/removed-projekt-art',
    'projekte/written-projekt-status',
    'projekte/removed-projekt-status',

    'besichtigungen/besichtigung-entfernt',
    'besichtigungen/besichtigung-geordnet',
    'besichtigungen/besichtigung-gespeichert',
    'dokumente/datei-gespeichert',
    'dokumente/dokument-hinterlegt',
    'einstellungen/kalkulation-hochgeladen',
    'felder/bewertet',
    'formulare/durchgang-gestartet',
    'gutachten/gutachten-entfernt',
    'gutachten/gutachten-gespeichert'
  ];

  readonly $result = new BehaviorSubject<IGutachtenResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IGutachtenRequest|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IGutachtenQueryFilterShapes|undefined>(undefined);
  readonly $response = new BehaviorSubject<IGutachtenResponse|undefined>(undefined);
  readonly $filter = new BehaviorSubject<IGutachtenQueryFilter>({});
  readonly $sorter = new BehaviorSubject<IGutachtenQuerySorter>({});
  readonly $search = new BehaviorSubject<string>('');
  readonly $presets = new BehaviorSubject<IPersistedFilter<IGutachtenResponse>[]>([]);
  readonly $preset = new BehaviorSubject<IPersistedFilter<IGutachtenResponse>|undefined>(undefined);
  private readonly $filtered = new BehaviorSubject<IGutachtenResponse|undefined>(undefined);
  private readonly $searched = new BehaviorSubject<IGutachtenResponse|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private device: DeviceConfigService,
    private search: SearchPipe,
    private filter: FilterPipe,
    private sorter: SorterPipe,
  ) {
/* << changes    */
/*    changes >> */
    combineLatest([
      this.$response,
      this.$filter,
    ]).subscribe(([response, filter]) => {
      if (response && response.gutachten) {
        const gutachten = this.filter.transform(response.gutachten, filter);
        this.$filtered.next( { ...response, gutachten });
      } else {
        this.$filtered.next(response);
      }
    });
    combineLatest([
      this.$filtered,
      this.$search.pipe(debounceTime(device.debounceTime), distinctUntilChanged()),
      this.$shapes.pipe(distinctUntilChanged((a, b) => isEqual(a, b)))
    ]).subscribe(([filtered, search, shapes]) => {
      if (filtered && filtered.gutachten) {
        const gutachten = this.search.transform(filtered.gutachten, search, '_search', shapes);
        this.$searched.next( { ...filtered, gutachten });
      } else {
        this.$searched.next(filtered);
      }
    });
    combineLatest([
      this.$searched,
      this.$sorter.pipe(distinctUntilChanged((a, b) => isEqual(a, b))),
      this.$shapes.pipe(distinctUntilChanged((a, b) => isEqual(a, b)))
    ]).pipe(debounceTime(10)).subscribe(([sorted, sorter, shapes]) => {
      if (sorted && sorted.gutachten) {
        const gutachten = this.sorter.transform(sorted.gutachten, sorter, shapes);
        this.$result.next( { ...sorted, gutachten });
      } else {
        this.$result.next(sorted);
      }
    });
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IGutachtenRequest): IGutachtenRequest {
    if (payload) {
      this.$payload.next(payload as IGutachtenRequest);
    } else {
      payload = this.$payload.getValue() as IGutachtenRequest;
    }
    return payload;
  }

  public async request(payload: IGutachtenRequest, force = false): Promise<IGutachtenResponse> {
    console.log('request');
    const state = this.$response.getValue();
    if (!force && !this.device.alwaysRefresh && state && isEqual(payload, this.payload())) { return state; }
    payload = this.payload(payload);
    if (!payload) { return this.$response.getValue() as IGutachtenResponse; }
    const query = this.http.serialize(payload);
    const { response, shapes, filter } = await this.http.get<IGutachtenResponseGroup>(this.route + query);
    this.$response.next(response);
    this.$shapes.next(shapes);
    this.$presets.next(filter && filter.filters ? filter.filters : []);
    return response;
  }

  public async writeFilter(filter: IPersistedFilter<IGutachtenResponse>): Promise<IPersistedFilter<IGutachtenResponse>|undefined> {
    filter = { ...filter, term: this.$search.getValue(), sorter: this.$sorter.getValue(), filter: this.$filter.getValue() };
    const result = await this.http.post(this.route + '/filters', filter) as IPersistedFilterList<IGutachtenResponse>;
    filter = result.filters.find(f => f.name === filter.name) as IPersistedFilter<IGutachtenResponse>;
    this.$presets.next(result.filters ? result.filters : []);
    this.setFilter(filter);
    return filter;
  }

  public async deleteFilter(id: string): Promise<void> {
    const result = await this.http.delete(this.route + '/filters?id=' + id) as IPersistedFilterList<IGutachtenResponse>;
    this.$presets.next(result.filters ? result.filters : []);
    this.setFilter();
  }

  public setFilter(filter?: IPersistedFilter<IGutachtenResponse>): void {
    this.$search.next(filter && filter.term ? filter.term : '');
    this.$filter.next(filter && filter.filter ? filter.filter : {});
    this.$sorter.next(filter && filter.sorter ? filter.sorter : {});
    this.$preset.next(filter ? filter : undefined);
  }


  getObjektBereiche(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['objekt.bereiche']?.find(v => v.value === value)
  }

  get objektBereiche(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['objekt.bereiche'] as ISelection[] : []));
  }

  getBesichtigungBesichtigungsStatus(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['besichtigung.besichtigungsStatus']?.find(v => v.value === value)
  }

  get besichtigungBesichtigungsStatus(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['besichtigung.besichtigungsStatus'] as ISelection[] : []));
  }

  getGutachtenBewertungsAnlass(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['gutachten.bewertungsAnlass']?.find(v => v.value === value)
  }

  get gutachtenBewertungsAnlass(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['gutachten.bewertungsAnlass'] as ISelection[] : []));
  }

  getEintraegeFeld(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['eintraege.feld']?.find(v => v.value === value)
  }

  get eintraegeFeld(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['eintraege.feld'] as ISelection[] : []));
  }

  getEintraegeGutachten(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['eintraege.gutachten']?.find(v => v.value === value)
  }

  get eintraegeGutachten(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['eintraege.gutachten'] as ISelection[] : []));
  }

  getGutachtenBewertungsStatus(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['gutachten.bewertungsStatus']?.find(v => v.value === value)
  }

  get gutachtenBewertungsStatus(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['gutachten.bewertungsStatus'] as ISelection[] : []));
  }

  getBerichteHerausgeber(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['berichte.herausgeber']?.find(v => v.value === value)
  }

  get berichteHerausgeber(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['berichte.herausgeber'] as ISelection[] : []));
  }

  getBesichtigungMitarbeiter(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['besichtigung.mitarbeiter']?.find(v => v.value === value)
  }

  get besichtigungMitarbeiter(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['besichtigung.mitarbeiter'] as ISelection[] : []));
  }

  getGutachtenObjekt(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['gutachten.objekt']?.find(v => v.value === value)
  }

  get gutachtenObjekt(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['gutachten.objekt'] as ISelection[] : []));
  }

  getGutachtenObjektArt(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['gutachten.objektArt']?.find(v => v.value === value)
  }

  get gutachtenObjektArt(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['gutachten.objektArt'] as ISelection[] : []));
  }

  getGutachtenObjektNutzung(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['gutachten.objektNutzung']?.find(v => v.value === value)
  }

  get gutachtenObjektNutzung(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['gutachten.objektNutzung'] as ISelection[] : []));
  }


}
