import { NgModule } from '@angular/core';

import {
  PostStartTourCommandServiceModule,
  PostStartTourCommandComponentModule
} from './';

@NgModule({
  imports: [
    PostStartTourCommandServiceModule,
    PostStartTourCommandComponentModule
  ],
  exports: [
    PostStartTourCommandServiceModule,
    PostStartTourCommandComponentModule
  ]
})
export class PostStartTourCommandModule { }
