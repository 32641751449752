import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { BerichtServiceModule } from '../';
import { BerichtDetailComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    BerichtServiceModule
  ],
  declarations: [BerichtDetailComponent],
  exports: [BerichtDetailComponent],

})
export class BerichtDetailComponentModule { }
