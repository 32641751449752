import { NgModule } from '@angular/core';

import {
  PostVermerkCommandServiceModule,
  PostVermerkCommandComponentModule
} from './';

@NgModule({
  imports: [
    PostVermerkCommandServiceModule,
    PostVermerkCommandComponentModule
  ],
  exports: [
    PostVermerkCommandServiceModule,
    PostVermerkCommandComponentModule
  ]
})
export class PostVermerkCommandModule { }
