import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { first, map, debounceTime, distinctUntilChanged } from "rxjs/operators";

import { isEqual } from "lodash";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { IPersistedFilter, IPersistedFilterList } from "@lib/filter/types";
import { ISelection } from "@lib/persistence/types";

import {
  IDashboardRequest,
  IDashboardResponse,
  IDashboardResponseGroup,
  IDashboardQueryFilterShapes
} from '@fa-kt-evaluation/projekte/types';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  protected route = 'projekte/dashboard';
  protected dependencies = [
    'besichtigungen/written-tour-status',
    'besichtigungen/removed-tour-status',
    'personen/written-rolle',
    'personen/removed-rolle',
    'personen/written-standort',
    'personen/removed-standort',

    'besichtigungen/besichtigung-entfernt',
    'besichtigungen/besichtigung-geordnet',
    'besichtigungen/besichtigung-gespeichert',
    'besichtigungen/tour-entfernt',
    'besichtigungen/tour-gespeichert',
    'besichtigungen/tour-gestartet',
    'finanzen/reisekostenabrechnung-entfernt',
    'finanzen/reisekostenabrechnung-gespeichert',
    'gutachten/gutachten-entfernt',
    'gutachten/gutachten-gespeichert',
    'kommunikation/erinnerung-abgemeldet',
    'kommunikation/erinnerung-entfernt',
    'kommunikation/erinnerung-gemerkt',
    'kommunikation/vermerk-entfernt',
    'kommunikation/vermerkt',
    'personen/mitarbeiter-aktualisiert',
    'projekte/projekt-archiviert',
    'projekte/projekt-entfernt',
    'projekte/projekt-gespeichert',
    'projekte/projekt-kopiert',
    'projekte/projekt-reaktiviert',
    'zeiten/zeit-entfernt',
    'zeiten/zeit-gespeichert'
  ];

  readonly $result = new BehaviorSubject<IDashboardResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IDashboardRequest|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IDashboardQueryFilterShapes|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private device: DeviceConfigService,
  ) {
/* << changes    */
/*    changes >> */
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IDashboardRequest): IDashboardRequest {
    if (payload) {
      this.$payload.next(payload as IDashboardRequest);
    } else {
      payload = this.$payload.getValue() as IDashboardRequest;
    }
    return payload;
  }

  public async request(payload: IDashboardRequest, force = false): Promise<IDashboardResponse> {
    const state = this.$result.getValue();
    if (!force && !this.device.alwaysRefresh && state && isEqual(payload, this.payload())) { return state; }
    payload = this.payload(payload);
    // if (!payload) { return this.$result.getValue() as IDashboardResponse; }
    const query = this.http.serialize(payload);
    const { response, shapes } = await this.http.get<IDashboardResponseGroup>(this.route + query);
    this.$result.next(response);
    this.$shapes.next(shapes);
    return response;
  }


  getErinnerungenMitarbeiter(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['erinnerungen.mitarbeiter']?.find(v => v.value === value)
  }

  get erinnerungenMitarbeiter(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['erinnerungen.mitarbeiter'] as ISelection[] : []));
  }

  getErinnerungenVerfasser(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['erinnerungen.verfasser']?.find(v => v.value === value)
  }

  get erinnerungenVerfasser(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['erinnerungen.verfasser'] as ISelection[] : []));
  }

  getTourMitarbeiter(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['tour.mitarbeiter']?.find(v => v.value === value)
  }

  get tourMitarbeiter(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['tour.mitarbeiter'] as ISelection[] : []));
  }

  getTourTourStatus(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['tour.tourStatus']?.find(v => v.value === value)
  }

  get tourTourStatus(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['tour.tourStatus'] as ISelection[] : []));
  }


}
