import { Injectable } from "@angular/core";

import { NzMessageService } from "ng-zorro-antd/message";
import { NzModalService } from "ng-zorro-antd/modal";
import { BehaviorSubject } from "rxjs";

import { HTTPS_METHOD, SyncableTask } from "@lib/https/types";
import { HttpService } from "../http";

@Injectable({
  providedIn: "root"
})
export class OfflineCacheService {

  private readonly STORAGE_KEY = "syncTasks";
  readonly $syncableTasks: BehaviorSubject<SyncableTask[]> = new BehaviorSubject<SyncableTask[]>([]);
  readonly $syncing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private messages: NzMessageService,
    private modal: NzModalService,
    public http: HttpService
  ) {
    this.readExistingSyncableTask();
  }

  private readExistingSyncableTask(): SyncableTask[] {
    const result = localStorage.getItem(this.STORAGE_KEY);
    const syncableTasks: SyncableTask[] = result ? JSON.parse(result) as SyncableTask[] : [];
    this.$syncableTasks.next(syncableTasks);
    return syncableTasks;
  }

  public addSyncableTask(syncableTask: SyncableTask|undefined) {
    if (syncableTask) {
      let syncableTasks = this.$syncableTasks.getValue();
      syncableTasks = syncableTasks.filter(st => !this.matches(st, syncableTask)).concat([syncableTask]);
      this.messages.error('Keine Internetverbindung. Anfrage wurde im Speicher hinterlegt und kann synchronisiert werden');
      this.$syncableTasks.next(syncableTasks);
      localStorage.setItem(this.STORAGE_KEY, JSON.stringify(syncableTasks));
    }
  }

  private removeSyncableTask(syncableTask: SyncableTask) {
    let syncableTasks = this.$syncableTasks.getValue();
    syncableTasks = syncableTasks.filter(st => !this.matches(st, syncableTask));
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(syncableTasks));
    this.$syncableTasks.next(syncableTasks);
  }

  private resetSyncableTasks() {
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify([]));
    this.$syncableTasks.next([]);
    this.messages.success('Der Speicher wurde gelöscht');
  }

  async sync(): Promise<void> {
    this.$syncing.next(true);
    const syncableTasks = this.readExistingSyncableTask();
    let count = syncableTasks.length;
    if (count === 0) {
      this.$syncing.next(false);
      return;
    }
    this.messages.info('Speicher wird synchronisiert...');
    let failed = 0;
    console.debug("syncableTasks", syncableTasks);
    const sync = await Promise.all(syncableTasks.map(async (task: SyncableTask) => await this.syncTask(task, false, () => failed++)));
    while (sync.length) {
      await Promise.all(sync.splice(0, 1).map((sync) => sync()));
    }
    this.$syncing.next(false);
    const success = count - failed;
    this.messages.success(`${success} Aufgabe${success === 1 ? '' : 'n'} wurde${success === 1 ? '' : 'n'} synchronisiert. ${failed > 0 ? (failed + ' Aufgabe' + (failed === 1 ? '' : 'n') + ' ' + (failed === 1 ? 'muss' : 'müssen') + ' wiederholt werden.') : ''}`)
  }

  private matches(syncableTask1: SyncableTask, syncableTask2: SyncableTask): boolean {
    return syncableTask1.method === syncableTask2.method
      && syncableTask1.url === syncableTask2.url
      && JSON.stringify(syncableTask1.body) === JSON.stringify(syncableTask2.body);
  }

  public clear() {
    const count = this.$syncableTasks.getValue().length;
    this.modal.confirm({
      nzTitle: `Wirklich löschen?`,
      nzContent: `<p>Es ${count === 1 ? 'wird' : 'werden' } ${count} noch nicht-synchronisierte Befehl${count === 1 ? '' : 'e' } aus dem Speicher gelöscht</p>`,
      nzOkText: 'Ja, restlos entfernen',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: () => this.resetSyncableTasks(),
      nzCancelText: 'Abbrechen'
    });
  }

  public async clearCache() {
    this.modal.confirm({
      nzTitle: `Wirklich löschen?`,
      nzContent: `<p>Der Speicher beinhaltet alle bereits geladene Anfragen <mark>der letzten Woche</mark>.</p>`,
      nzOkText: 'Ja, restlos entfernen',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzOnOk: async () => {
        if ('caches' in window) {
          const keyList = await caches.keys();
          await Promise.all(keyList.map((key) => caches.delete(key)));
        }
        this.messages.success('Der Speicher wurde gelöscht');
      },
      nzCancelText: 'Abbrechen'
    });
  }

  async syncTask(task: SyncableTask, execute = false, fail: (() => void) = () => { return; }) {
    let output;
    switch (task.method) {
      case HTTPS_METHOD.DELETE:
        output = async () => {
          try {
            await this.http.delete(task.url);
            this.removeSyncableTask(task);
          } catch (e) {
            console.error(e);
            this.messages.warning('failed: ' + HTTPS_METHOD[task.method] + ' ' + task.url);
            fail();
          }
        };
        break;
      case HTTPS_METHOD.PUT:
        output = async () => {
          try {
            await this.http.put(task.url, task.body);
            this.removeSyncableTask(task);
          } catch (e) {
            console.error(e);
            this.messages.warning('failed: ' + HTTPS_METHOD[task.method] + ' ' + task.url);
            fail();
          }
        };
        break;
      case HTTPS_METHOD.PATCH:
        output = async () => {
          try {
            await this.http.patch(task.url, task.body);
            this.removeSyncableTask(task);
          } catch (e) {
            console.error(e);
            this.messages.warning('failed: ' + HTTPS_METHOD[task.method] + ' ' + task.url);
            fail();
          }
        };
        break;
      case HTTPS_METHOD.POST:
        output = async () => {
          try {
            await this.http.post(task.url, task.body);
            this.removeSyncableTask(task);
          } catch (e) {
            console.error(e);
            this.messages.warning('failed: ' + HTTPS_METHOD[task.method] + ' ' + task.url);
            fail();
          }
        };
        break;
      default:
        output = async () => {
          this.removeSyncableTask(task);
        };
    }
    if (execute) {
      await output();
    }
    return output;
  }
}
