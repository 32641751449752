import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: 'codeboard-search-bar',
  template: `
    <nz-row>
      <nz-col nzSpan="24">
        <nz-input-group [nzPrefix]='prefixIconSearch' style="width: 100%" nzBorderless>
          <ng-template #prefixIconSearch>
            <i nz-icon nzType='search'></i>
          </ng-template>
          <input nz-input nzBorderless type='text' (focus)="focused.emit()"
                 [ngModel]="_search" (ngModelChange)="searchChange.emit($event)"
                 [placeholder]="placeholder"
                 cdkFocusInitial/>
        </nz-input-group>
      </nz-col>
    </nz-row>
  `,
  styles: [
  ]
})
export class SearchBarComponent implements OnInit {

  @Input() placeholder = 'Suche'; //  ⌨💨

  _search: string|null = '';
  @Input() set search(term: string|null) {
    this._search = term;
  }
  @Output() searchChange: EventEmitter<string|null> = new EventEmitter<string|null>();
  @Output() focused: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

}
