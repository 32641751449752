import mustache from 'mustache';
import nunjucks from 'nunjucks';

export function compile(content: string, context: any): string {
  return mustache.render(content, context);
}

export function compileWithNunjucks(content: string, context: any): string {
  return nunjucks.renderString(content, context);
}
