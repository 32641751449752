import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { ProjekteServiceModule } from '../';
import { SavedProjekteFiltersComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ProjekteServiceModule
  ],
  declarations: [SavedProjekteFiltersComponent],
  exports: [SavedProjekteFiltersComponent],

})
export class SavedProjekteFiltersComponentModule { }
