import { NgModule } from '@angular/core';

import {
  DeleteVorlageCommandServiceModule,
  DeleteVorlageCommandComponentModule
} from './';

@NgModule({
  imports: [
    DeleteVorlageCommandServiceModule,
    DeleteVorlageCommandComponentModule
  ],
  exports: [
    DeleteVorlageCommandServiceModule,
    DeleteVorlageCommandComponentModule
  ]
})
export class DeleteVorlageCommandModule { }
