import { NgModule } from '@angular/core';

import {
  DeleteObjektCommandServiceModule,
  DeleteObjektCommandComponentModule
} from './';

@NgModule({
  imports: [
    DeleteObjektCommandServiceModule,
    DeleteObjektCommandComponentModule
  ],
  exports: [
    DeleteObjektCommandServiceModule,
    DeleteObjektCommandComponentModule
  ]
})
export class DeleteObjektCommandModule { }
