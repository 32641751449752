import { Component, OnInit, OnDestroy, Inject, Input, Output, EventEmitter, Injector, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { NzMessageService } from "ng-zorro-antd/message";

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { AuthService } from "@lib/auth/frontend";
import {
  ActionService,
  cleanObject, toInitials,
  CustomValidators, CustomFormatter, 
  ISitemap, ISitemapCommand, SITEMAP,
} from "@lib/common/frontend";

import { IPostFondRequest, IPostFondResponse } from "@fa-kt-evaluation/kunden/types";
import { PostFondCommandService } from '@fa-kt-evaluation/kunden/frontend'

/* << importing    */
/*    importing >> */

@Component({
  selector: 'fa-kt-evaluation-post-fond',
  templateUrl: './post-fond.component.html',
  styleUrls: ['./post-fond.component.css']
})
export class PostFondCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) { this.$loading.next(this.$loading.getValue() || loading); }
  @Input() set value(value: IPostFondRequest) { this.patch(value); }
  @Output() valueChanged: EventEmitter<IPostFondRequest> = new EventEmitter<IPostFondRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostFondResponse> = new EventEmitter<IPostFondResponse>();

/* << fields    */
/*    fields >> */

  get modalRef() {
    return this.injector.get(NzModalRef)
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public postFond: PostFondCommandService,
/* << more    */
/*    more >> */
  ) {
    this.command = sitemap.KUNDEN.Commands.POST_FOND;
    this.form = this.fb.group({
      fond: this.fb.group({
        id: [null, []],
        kunde: [null, [Validators.required]],
        name: [null, [Validators.required]],
        aktiv: [null, []],
      })
    });
/* << constructor    */
/*    constructor >> */
    this.patch();
  }

  ngOnInit() {
    this.postFond.prepare().catch(e => console.error(e));
    this.subscriptions.push(
      this.finished.subscribe((result) => { if (this.modalRef) { this.modalRef.destroy(result); }}),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() })
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostFondRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),
      this.fondId?.valueChanges.subscribe(async (id) => {
/* << on-fond-id-change    */
/*    on-fond-id-change >> */
      }) as Subscription,
      this.fondKunde?.valueChanges.subscribe(async (kunde) => {
/* << on-fond-kunde-change    */
/*    on-fond-kunde-change >> */
      }) as Subscription,
      this.fondName?.valueChanges.subscribe(async (name) => {
/* << on-fond-name-change    */
/*    on-fond-name-change >> */
      }) as Subscription,
      this.fondAktiv?.valueChanges.subscribe(async (aktiv) => {
/* << on-fond-aktiv-change    */
/*    on-fond-aktiv-change >> */
      }) as Subscription,
/* << subscibe    */
/*    subscibe >> */
    );
/* << init    */
/*    init >> */
  }

  ngOnDestroy() {
/* << end    */
/*    end >> */
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IPostFondRequest) {
    if (value) {
      value = cleanObject(value);
      if (value.fond) {
        value.fond = cleanObject(value.fond);
      }
      this.form.patchValue(value);
/* << custom    */
/*    custom >> */
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload.fond) {
        payload.fond = cleanObject(payload.fond);
      }
      const response: IPostFondResponse = await this.postFond.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of (error.errors ? error.errors : [])) {
        for (const children of (validation.children ? validation.children : [])) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

/* << methods    */
/*    methods >> */

  get fondId() {
    return this.form.controls.fond.get('id');
  };
  get fondKunde() {
    return this.form.controls.fond.get('kunde');
  };
  get fondName() {
    return this.form.controls.fond.get('name');
  };
  get fondAktiv() {
    return this.form.controls.fond.get('aktiv');
  };


}
