import { ISelection } from "@lib/persistence/types";


export type IFilter<T> = {
  [path: string]: Filter;
};

export type Filter = (INumberFilter | IArrayFilter | IEnumFilter | IDateFilter | IGeoRangeFilter | IBooleanFilter | ISingleReferenceFilter<any> | IArrayReferenceFilter<any>);
export type IFilterConfig<T> = Array<(INumberConfig | IArrayConfig | IEnumConfig | IDateConfig | IGeoRangeConfig | IBooleanConfig | ISingleReferenceConfig<any> | IArrayReferenceConfig<any>)>;
export type IValue = INumberValue | IDateValue | IGeoRangeValue | IEnumValue | IArrayValue | ISingleReferenceValue | IBooleanValue;
export type IOperator =
  INumberOperator
  | IArrayOperator
  | IBooleanOperator
  | IDateOperator
  | IGeoRangeOperator
  | IEnumOperator
  | ISingleReferenceOperator
  | IArrayReferenceOperator;




export enum FilterType {
  Number,
  Boolean,
  Date,
  DateTime,
  Time,
  GeoRange,
  Enum,
  Array,
  SingleReference,
  ArrayReference,
}

export interface FilterConfig {
  type: FilterType;
  index: number;
  key: string;
  title: string;
  value: IValue | any | null;
  operators: IOperator[];
  operator: IOperator;
  selections?: ISelection[];
}

export interface IFilterExpression {
  value: IValue;
  operator: IOperator;
}

export type INumberValue = number | { min: number, max: number }
const NumberValue: INumberValue = 0;
export type INumberOperator = "in" | ">" | ">=" | "=" | "<" | "<=";
const NumberOperators: INumberOperator[] = ["in", ">", ">=", "=", "<", "<="];
const NumberOperator: INumberOperator = "=";
export interface INumberFilter extends IFilterExpression {
  value: INumberValue;
  operator: INumberOperator;
}
export interface INumberConfig extends FilterConfig {
  operators: INumberOperator[];
  operator: INumberOperator;
  value: INumberValue;
}
export function NumberFilterConfig(key: string, title: string): INumberConfig {
  return {
    key,
    title,
    type: FilterType.Number,
    operators: NumberOperators,
    operator: NumberOperator,
    value: NumberValue,
    index: 0
  };
}


export type IBooleanValue = boolean;
const BooleanValue: IBooleanValue = true;
const BooleanOperators: IBooleanOperator[] = ["ist"];
const BooleanOperator: IBooleanOperator = "ist";
export type IBooleanOperator = "ist";
export interface IBooleanFilter extends IFilterExpression {
  value: IBooleanValue;
  operator: IBooleanOperator;
}
export interface IBooleanConfig extends FilterConfig {
  operators: IBooleanOperator[];
  operator: IBooleanOperator;
  value: IBooleanValue;
}
export function BooleanFilterConfig(key: string, title: string): IBooleanConfig {
  return {
    key,
    title,
    type: FilterType.Boolean,
    operators: BooleanOperators,
    operator: BooleanOperator,
    value: BooleanValue,
    index: 0
  };
}

export type IDateValue = { min: Date, max: Date }
const DateValue: IDateValue = { min: new Date(), max: new Date() };
export type IDateOperator = "in";
const DateOperators: IDateOperator[] = ["in"];
const DateOperator: IDateOperator = "in";
export interface IDateFilter extends IFilterExpression {
  value: IDateValue;
  operator: IDateOperator;
}
export interface IDateConfig extends FilterConfig {
  operators: IDateOperator[];
  operator: IDateOperator;
  value: IDateValue;
}
export function DateFilterConfig(key: string, title: string): IDateConfig {
  return {
    key,
    title,
    type: FilterType.Date,
    operators: DateOperators,
    operator: DateOperator,
    value: DateValue,
    index: 0
  };
}
export interface IDateTimeFilter extends IFilterExpression {
  value: IDateValue;
  operator: IDateOperator;
}
export interface IDateTimeConfig extends FilterConfig {
  operators: IDateOperator[];
  operator: IDateOperator;
  value: IDateValue;
}
export function DateTimeFilterConfig(key: string, title: string): IDateTimeConfig {
  return {
    key,
    title,
    type: FilterType.Date,
    operators: DateOperators,
    operator: DateOperator,
    value: DateValue,
    index: 0
  };
}
export interface ITimeFilter extends IFilterExpression {
  value: IDateValue;
  operator: IDateOperator;
}
export interface ITimeConfig extends FilterConfig {
  operators: IDateOperator[];
  operator: IDateOperator;
  value: IDateValue;
}
export function TimeFilterConfig(key: string, title: string): ITimeConfig {
  return {
    key,
    title,
    type: FilterType.Time,
    operators: DateOperators,
    operator: DateOperator,
    value: DateValue,
    index: 0
  };
}


export type IGeoRangeValue = { lat: number, lon: number, distance: number };
const GeoRangeValue: IGeoRangeValue = { lat: null, lon: null, distance: 0 };
export type IGeoRangeOperator = "in" | "not in";
const GeoRangeOperator: IGeoRangeOperator[] = ["in", "not in"];
const GeoRangeExpression: IGeoRangeOperator = "in";
export interface IGeoRangeFilter extends IFilterExpression {
  value: IGeoRangeValue,
  operator: IGeoRangeOperator;
}
export interface IGeoRangeConfig extends FilterConfig {
  operators: IGeoRangeOperator[];
  operator: IGeoRangeOperator;
  value: IGeoRangeValue;
}
export function GeoRangeFilterConfig(key: string, title: string): IGeoRangeConfig {
  return {
    key,
    title,
    type: FilterType.GeoRange,
    operators: GeoRangeOperator,
    operator: GeoRangeExpression,
    value: GeoRangeValue,
    index: 0
  };
}

export type IEnumValue = string
const EnumValue: IEnumValue = "";
export type IEnumOperator = "ist" | "nicht";
const EnumOperators: IEnumOperator[] = ["ist", "nicht" ];
const EnumOperator: IEnumOperator = "ist";
export interface IEnumFilter extends IFilterExpression {
  value: IEnumValue;
  operator: IEnumOperator;
}
export interface IEnumConfig extends FilterConfig {
  operators: IEnumOperator[];
  operator: IEnumOperator;
  value: IEnumValue;
  selections?: ISelection[];
}
export function EnumFilterConfig(key: string, title: string): IEnumConfig {
  return {
    key,
    title,
    type: FilterType.Enum,
    operators: EnumOperators,
    operator: EnumOperator,
    value: EnumValue,
    selections: [],
    index: 0
  };
}


export type IArrayValue = string;
const ArrayValue: IArrayValue = '';
export type IArrayOperator = "hat" | "alle" | "nicht" | "nicht alle"
const ArrayOperators: IArrayOperator[] = ["hat", "alle", "nicht", "nicht alle"];
const ArrayOperator: IArrayOperator = "hat";
export interface IArrayFilter extends IFilterExpression {
  value: IArrayValue;
  operator: IArrayOperator;
}
export interface IArrayConfig extends FilterConfig {
  operators: IArrayOperator[];
  operator: IArrayOperator;
  value: IArrayValue;
}
export function ArrayFilterConfig(key: string, title: string): IArrayConfig {
  return {
    key,
    title,
    type: FilterType.Array,
    operators: ArrayOperators,
    operator: ArrayOperator,
    value: ArrayValue,
    index: 0
  };
}

export type ISingleReferenceValue = string | null;
export type ISingleReferenceOperator = "ist"|"nicht";
const SingleReferenceOperator: ISingleReferenceOperator[] = ["ist", "nicht"];
const SingleReferenceOperatorPreset: ISingleReferenceOperator = "ist";
export interface ISingleReferenceFilter<T> extends IFilterExpression {
  value: ISingleReferenceValue,
  operator: ISingleReferenceOperator;
}
export interface ISingleReferenceConfig<T> extends FilterConfig {
  operators: ISingleReferenceOperator[];
  operator: ISingleReferenceOperator;
  value: string | null;
  selections?: ISelection[];
  mapper: string;
}

export function SingleReferenceFilterConfig<T>(key: string, title: string): ISingleReferenceConfig<T> {
  return {
    key,
    title,
    mapper: null,
    type: FilterType.SingleReference,
    operators: SingleReferenceOperator,
    operator: SingleReferenceOperatorPreset,
    value: null,
    selections: [],
    index: 0
  };
}

export type IArrayReferenceValue = string | null;
export type IArrayReferenceOperator = "hat" | "alle" | "nicht"
const ArrayReferenceOperator: IArrayReferenceOperator[] = ["hat", "alle", "nicht"];
const ArrayReferenceOperatorPreset: IArrayReferenceOperator = "hat";
export interface IArrayReferenceFilter<T> extends IFilterExpression {
  value: IArrayReferenceValue,
  operator: IArrayReferenceOperator;
}
export interface IArrayReferenceConfig<T> extends FilterConfig {
  operators: IArrayReferenceOperator[];
  operator: IArrayReferenceOperator;
  value: string | null;
  selections?: ISelection[];
  mapper: string;
}

export function ArrayReferenceFilterConfig<T>(key: string, title: string): IArrayReferenceConfig<T> {
  return {
    key,
    title,
    mapper: null,
    type: FilterType.ArrayReference,
    operators: ArrayReferenceOperator,
    operator: ArrayReferenceOperatorPreset,
    value: null,
    selections: [],
    index: 0
  };
}

