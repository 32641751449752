import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostFeldOptionCommandServiceModule } from '../';
import { PostFeldOptionCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostFeldOptionCommandServiceModule
  ],
  declarations: [PostFeldOptionCommandComponent],
  exports: [PostFeldOptionCommandComponent],

})
export class PostFeldOptionCommandComponentModule { }
