<nz-select class="select-value"
  (nzOnSearch)="search($event)"
  (ngModelChange)="setValue($event)"
  (keyup.enter)="create()"
  [ngModel]="_value"
  [nzCustomTemplate]="View"
  [nzDisabled]="disabled"
  [nzAllowClear]="!required"
  [nzMaxTagCount]="3"
  [nzMaxTagPlaceholder]="MAX_OVERLOAD"
  [nzMode]="mode" [nzPlaceHolder]="placeholder"
  [nzSize]="size" [style.width]="width"
  nzShowSearch
  [nzNotFoundContent]="NotFound">
  <ng-template #View let-selected>
    <nz-avatar *ngIf="(selected.nzValue | type:($filtered | async):'avatar') || ($avatar | async)" [nzSrc]="selected.nzValue | type:($filtered | async):'avatar'" [nzSize]="20" nzIcon="user" style="margin-right: 6px"></nz-avatar>
    <i *ngIf="selected.nzValue | type:($filtered | async):'icon'" nz-icon [style.color]="selected.nzValue | type:($filtered | async):'color'" style="margin-right: 6px" [style.marginTop]="mode !== 'small' ? '4px' : ''" [nzType]="selected.nzValue | type:($filtered | async):'icon'" [style.color]="selected.nzValue | type:($filtered | async):'color'"></i>
    <label nz-typography [style.color]="selected.nzValue | type:($filtered | async):'color'" style="margin-right: 6px;"> {{ selected.nzLabel }} </label>
  </ng-template>
  <nz-option *ngFor="let option of ($filtered | async)" [nzLabel]="option.label" [nzValue]="option.value" nzCustomContent>
    <nz-avatar *ngIf="option.avatar || ($avatar | async)" [nzSrc]="option.avatar" [nzSize]="20" [nzText]="getMitarbeiterText(option.label)" style="margin-right: 6px"></nz-avatar>
    <i *ngIf="option.icon" nz-icon [nzType]="option.icon"  [style.color]="option.color"></i>
    <label nz-typography [style.color]="option.color ? option.color  : ''"> {{ option.label}} </label>
  </nz-option>
</nz-select>
<ng-template #MAX_OVERLOAD let-selectedList>& {{ selectedList.length }} mehr</ng-template>
<ng-template #NotFound>
  <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="NotFoundContent" [nzNotFoundFooter]="NotFoundFooter"></nz-empty>
  <ng-template #NotFoundContent>
    <span>
      Keine Daten.
      <a *ngIf="creatable && creating">Zum Erstellen ENTER drücken</a>
      <span *ngIf="creatable && creating">oder</span>
    </span>
  </ng-template>
  <ng-template #NotFoundFooter>
    <button *ngIf="creatable && creating" nz-button nzType="primary" (click)="create()">Hinzufügen</button>
  </ng-template>
</ng-template>
