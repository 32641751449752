import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostBerichtCommandServiceModule } from '../';
import { PostBerichtCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostBerichtCommandServiceModule
  ],
  declarations: [PostBerichtCommandComponent],
  exports: [PostBerichtCommandComponent],

})
export class PostBerichtCommandComponentModule { }
