import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { FelderServiceModule } from '../';
import { FelderSorterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    FelderServiceModule
  ],
  declarations: [FelderSorterComponent],
  exports: [FelderSorterComponent],

})
export class FelderSorterComponentModule { }
