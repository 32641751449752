import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { FormulareServiceModule } from '../';
import { FormulareFilterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    FormulareServiceModule
  ],
  declarations: [FormulareFilterComponent],
  exports: [FormulareFilterComponent],

})
export class FormulareFilterComponentModule { }
