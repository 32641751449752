import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostObjektCommandServiceModule } from '../';
import { PostObjektCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostObjektCommandServiceModule
  ],
  declarations: [PostObjektCommandComponent],
  exports: [PostObjektCommandComponent],

})
export class PostObjektCommandComponentModule { }
