import { NgModule } from '@angular/core';
import { 
  DurchgangQueryModule,
  FormularQueryModule,
  FormulareQueryModule
} from './';

@NgModule({
  declarations: [],
  imports: [
    DurchgangQueryModule,
    FormularQueryModule,
    FormulareQueryModule
  ],
  exports: [
    DurchgangQueryModule,
    FormularQueryModule,
    FormulareQueryModule
  ]
})
export class FormulareQuerysModule { }
