import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { distinctUntilChanged, filter } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {

  public readonly $events: BehaviorSubject<string> = new BehaviorSubject('start');

  constructor() {
  }

  public next(eventName: string): void {
    this.$events.next(eventName);
  }

  public on(eventNames: string[]): Function {
    return (callback: () => Promise<any>) => this.$events.pipe(
        filter(event => eventNames.indexOf(event) >= 0)
      ).subscribe(callback);
  }
}
