import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter, OnDestroy } from "@angular/core";


import * as atlas from "azure-maps-control";
import * as atlasDrawing from 'azure-maps-drawing-tools';

import { AuthenticationType, ControlPosition, ControlStyle } from "azure-maps-control";

import { BehaviorSubject, combineLatest, Subscription } from "rxjs";

import { SecretService } from "@lib/common/frontend";
import { IAddress, IAzureMapsCircle, IAzureMapsPosition, IAzureMapsRoute, IAzureMapsSuggestion } from "@lib/geo/types";
import { drawing } from "azure-maps-drawing-tools";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { AtlasService } from "../../services";

@Component({
  selector: 'codeboard-atlas-map',
  templateUrl: './atlas-map.component.html',
  styleUrls: ['./atlas-map.component.css']
})
export class AtlasMapComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  public readonly $waitingForSecrets = new BehaviorSubject<boolean>(true);
  private subscriptionKey: string|undefined = undefined;

  @Input() width: string = '0';
  @Input() height: string = '0';

  @ViewChild('AddressMap') mapRef: ElementRef<HTMLDivElement>|undefined
  map: atlas.Map|undefined;
  circleDataSource: atlas.source.DataSource|undefined;
  zoom: number|undefined = undefined;

  @Input() mode: 'address'|'addresses'|'draw'|'routes' = 'address';

  $coordinateArray = new BehaviorSubject<IAzureMapsPosition[]>([]);
  bounds?: atlas.data.BoundingBox;
  @Input() set coordinateArray(coordinateArray: 'clear'|IAzureMapsPosition[]) {
    if (coordinateArray === 'clear') {
      this.clear();
      return;
    }
    if (this.map) {
      this.setPoints(coordinateArray);
    }
    this.$coordinateArray.next(coordinateArray);
  }
  get coordinateArray(): IAzureMapsPosition[] {
    return this.$coordinateArray.getValue();
  }

  $circle = new BehaviorSubject<IAzureMapsCircle|undefined>(undefined);
  @Input() set circle(circle: 'clear'|IAzureMapsCircle) {
    if (circle === 'clear') {
      this.clear();
      return;
    }
    if (this.map) {
      this.setCircle(circle);
    }
    this.$circle.next(circle);
  }
  get circle(): IAzureMapsCircle {
    return this.$circle.getValue() as IAzureMapsCircle;
  }

  @Output() clicked: EventEmitter<any> = new EventEmitter<any>()



  $coordinates = new BehaviorSubject<IAzureMapsPosition|undefined>(undefined);
  @Input() set coordinates(coordinates: 'clear'|IAzureMapsPosition) {
    if (coordinates === 'clear') {
      this.clear();
      return;
    }
    coordinates = { draggable: false, ...coordinates as IAzureMapsPosition };
    if (this.map) {
      this.setPoint(coordinates);
    }
    this.$coordinates.next(coordinates);
  }
  get coordinates() {
    return this.$coordinates.getValue() as IAzureMapsPosition;
  }

  @Output() moved: EventEmitter<IAzureMapsPosition> = new EventEmitter<IAzureMapsPosition>()

  drawingManager: atlasDrawing.drawing.DrawingManager|undefined;
  drawing = false;
  $search: BehaviorSubject<string> = new BehaviorSubject<string>('');
  $suggestions: BehaviorSubject<IAzureMapsSuggestion[]> = new BehaviorSubject<IAzureMapsSuggestion[]>([]);
  @Output() drew: EventEmitter<atlas.Shape> = new EventEmitter<atlas.Shape>()

  _geometry!: any;
  @Input() set geometry(geometry: any) {
    if (!geometry) { return; }
    if (this.map) {
      this.setGeometry(geometry);
    }
    this._geometry = geometry;
  }
  get geometry() {
    return this._geometry;
  }


  _routes!: IAzureMapsRoute[];
  _routeIDs: string[] = [];
  @Input() set routes(routes: 'clear'|IAzureMapsRoute[]) {
    if (routes === 'clear') {
      this.clear();
      return;
    }
    if (this.map) {
      this.setRoutes(routes);
    }
    this._routes = routes;
  }
  get routes(): IAzureMapsRoute[] {
    return this._routes;
  }



  constructor(private secrets: SecretService, private atlas: AtlasService) {
    this.secrets.$secrets.subscribe(secrets => {
      if (secrets && secrets.maps) {
        this.subscriptionKey = secrets.maps.key;
        if (this.coordinates && this.coordinates !== 'clear') {
          this.createMap();
          if (this.geometry) {
            this.setGeometry(this.geometry);
          }
          if (this.coordinates) {
            this.setPoint(this.coordinates);
          }
          if (this.coordinateArray) {
            this.setPoints(this.coordinateArray);
          }
          if (this.circle) {
            this.setCircle(this.circle);
          }
          if (this.routes) {
            this.setRoutes(this.routes);
          }
        }
      }
    })
  }


  ngOnInit(): void {
    this.subscriptions.push(...[
      combineLatest([
        this.$coordinates,
        this.$coordinateArray,
        this.$waitingForSecrets
      ]).subscribe(([coordinate, coordinates, waitingForSecrets]) => {
        if (waitingForSecrets || this.bounds) { return; }
        if (coordinate) { coordinates.push(coordinate); }
        let boundsPositions: Array<{lng: number, lat:number}> = [];
        coordinates.filter(coordinate => coordinate && coordinate.lon && coordinate.lat).forEach(coordinate => {
          boundsPositions.push({ lng: coordinate.lon, lat: coordinate.lat });
        });
        this.bounds = atlas.data.BoundingBox.fromLatLngs(boundsPositions);
        this.map?.setCamera({ bounds: this.bounds, padding: {top: 20, bottom: 20, left: 20, right: 20}});
      }),
      this.$search.pipe(
        debounceTime(200),
        distinctUntilChanged()
      ).subscribe(async (term: string) => {
        const suggestions = await this.atlas.suggestByTerm(term, []);
        if (suggestions && suggestions.length > 0) {
          this.setSuggestion(suggestions[0]);
        }
        this.$suggestions.next(suggestions)
      })
    ])
  }

  ngAfterViewInit() {
    this.createMap()
  }

  ngOnDestroy(): void {
    this.bounds = undefined;
    this.map?.dispose();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private createMap() {
    if (!this.subscriptionKey) {
      console.error('No Subscription Key for Azure Maps');
      return;
    }
    if (this.mapRef) {
      const map = new atlas.Map(this.mapRef.nativeElement, {
        center: [0, 0],
        view: 'Auto',
        language: 'de-DE',
        showLogo: true,
        showFeedbackLink: false,
        showBuildingModels: true,
        authOptions: {
          authType: AuthenticationType.subscriptionKey,
          subscriptionKey: this.subscriptionKey
        }
      });
      map.events.add('click', (e) => {
        if (e.position) {
          const [lon, lat] = e.position;
        }
      });

      map.events.add('zoomend', ($event) => {
        if (this.map) {
          this.zoom = this.map.getCamera().zoom as number;
        }
      });

      map.events.add('moveend', ($event) => {
        // console.log($event);
      });

      map.events.add('ready',  () => {
        map.controls.add(new atlas.control.ZoomControl({
          style: ControlStyle.auto,
          zoomDelta: 1
        }), {
          position: ControlPosition.BottomLeft
        });
        map.controls.add(new atlas.control.CompassControl(), {
          position: ControlPosition.BottomLeft
        });
        map.setCamera({
          duration: 1000,
          type: 'fly'
        });
        switch (this.mode) {
          case "address":
          case "addresses":
          case "routes":
            map.controls.add(new atlas.control.StyleControl({
              mapStyles: ['road', 'grayscale_dark', 'night', 'road_shaded_relief', 'satellite', 'satellite_road_labels']
            }), {
              position: ControlPosition.TopRight
            });
            map.controls.add(new atlas.control.PitchControl(), {
              position: ControlPosition.BottomLeft
            });
            break;
          case "draw":
            this.drawingManager = new drawing.DrawingManager((map as any), {
              mode: "idle",
              interactionType: "freehand",
            } as any);
            (map as atlas.Map).events.add('drawingcomplete' as any, (evt) => {
              this.toggleDrawing();
            });
            break;
        }
        this.map = map;
        if (this.coordinates && this.coordinates !== 'clear') {
          setTimeout(() => this.setPoint(this.coordinates as IAzureMapsPosition), 200);
        }
        if (this.coordinateArray) {
          this.setPoints(this.coordinateArray);
        }
        if (this.circle) {
          this.setCircle(this.circle);
        }
        if (this.geometry) {
          this.setGeometry(this.geometry);
        }
        if (this.routes) {
          this.setRoutes(this.routes);
        }
        this.$waitingForSecrets.next(false);
      });
    }
  }

  clear() {
    if (!this.map) { return; }
    this.map.markers.clear();
  }

  setPoint(coordinate: IAzureMapsPosition) {
    if (!this.map) { return; }
    this.clear();
    let position = [0, 0]
    if (coordinate && coordinate.lon && coordinate.lat && !isNaN(coordinate.lon) && !isNaN(coordinate.lat)) {
      position = [coordinate.lon, coordinate.lat];
      const draggable = coordinate.draggable;
      let marker = new atlas.HtmlMarker({
        draggable,
        position
      });
      this.map.events.add('dragend', marker, async (e) => {
        const position = marker.getOptions().position;
        if (position) {
          const [lon, lat] = position;
          this.moved.emit( { lat, lon });
        }
      });
      this.map?.events.add('click', marker, (e) => {
        this.clicked.emit(coordinate.object);
      })
      this.map.markers.add(marker);
    }
  }

  setPoints(coordinates: IAzureMapsPosition[]) {
    if (!this.map) { return; }
    this.clear();

    const popup = new atlas.Popup({
      pixelOffset: [0, -18]
    });
    coordinates.forEach(coordinate => {
      if (coordinate && coordinate.lon && coordinate.lat) {
        let marker = new atlas.HtmlMarker({
          draggable: false,
          position: [coordinate.lon, coordinate.lat],
          color: coordinate.color
        });
        (marker as any).properties = {
          popup: coordinate.popup,
          ...coordinate.object
        };
        this.map?.markers.add(marker);
        if (coordinate.popup) {
          this.map?.events.add('mouseover', marker, (e) => {
            const marker = e.target;
            const props = (marker as any).properties;
            popup.setOptions({
              content: atlas.PopupTemplate.applyTemplate(props, props.popup),
              position: (marker as any).getOptions().position,
              pixelOffset: [0, -18],
              closeButton: true
            });
            popup.open(this.map);
          });
          this.map?.events.add('click' as any, () => {
            popup.close();
          });
        }
        this.map?.events.add('click', marker, (e) => {
          this.clicked.emit(coordinate.object);
        })

      }
    });

  }

  setSuggestion(suggestion: IAzureMapsSuggestion) {
    const center = [suggestion.position.lon, suggestion.position.lat];
    this.map?.setCamera({ zoom: 16, center });
  }

  toggleDrawing() {
    this.drawing = !this.drawing;
    if (this.drawingManager && this.drawing) {
      const source = (this.drawingManager as drawing.DrawingManager).getSource();
      source.clear();
      this.drawingManager.setOptions({ mode: "draw-polygon" } as any);
    } else if (this.drawingManager) {
      this.drawingManager.setOptions({ mode: 'idle'} as any);
      this.drawing = false;
      const source = (this.drawingManager as drawing.DrawingManager).getSource();
      const geometry = source["shapes"][0].data.geometry;
      this.drew.emit(JSON.parse(JSON.stringify(geometry)))
      source.clear();
      this.setGeometry(geometry);
    }
  }

  private setGeometry(geometry: any) {
    this.drawingManager?.edit(new atlas.Shape({
      'type': 'Feature',
      geometry
    }) as any);
  }

  private setRoutes(routes: IAzureMapsRoute[]) {
    if (!this.map) { return; }
    this._routeIDs.forEach(id => this.map?.layers.remove(id))
    this._routeIDs = [];
    for (const route of routes) {
      const dataSource = new atlas.source.DataSource();
      this.map.sources.add(dataSource);
      let coordinates: any[] = [];
      for (const leg of (route.legs ? route.legs : [])) {
        if (leg.points) {
          coordinates = coordinates.concat(leg.points.map((point) => {
            return [point.longitude, point.latitude];
          }));
        }
      }
      const feature = new atlas.data.Feature(new atlas.data.LineString(coordinates));
      dataSource.add(feature as any);
      const id = 'LineLayer' + route.summary?.lengthInMeters;
      const layer = new atlas.layer.LineLayer(dataSource, id, {
        strokeColor: route.color,
        strokeWidth: 3
      });
      this.map?.events.add('click', layer, (e) => {
        this.clicked.emit(route.object);
      })
      this.map.layers.add(layer);
      this._routeIDs.push(id);
    }
  }

  private setCircle(circle: IAzureMapsCircle) {
    if (!this.circleDataSource) {
      this.circleDataSource = new atlas.source.DataSource();
      this.map?.sources.add(this.circleDataSource);
    } else {
      this.circleDataSource.clear();
    }

    this.circleDataSource?.add(
      new atlas.data.Feature(new atlas.data.Point([circle.lon, circle.lat]),
      {
        subType: "Circle",
        radius: circle.radius
      })
    );

    this.map?.layers.add(new atlas.layer.PolygonLayer(this.circleDataSource as atlas.source.DataSource, null as any, {
      fillColor: circle.color ?? 'rgba(0, 200, 200, 0.5)'
    }));
  }
}
