import { Component, OnInit, OnDestroy, Inject, Input, Output, EventEmitter, Injector, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { NzMessageService } from "ng-zorro-antd/message";

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { AuthService } from "@lib/auth/frontend";
import {
  ActionService,
  cleanObject, toInitials,
  CustomValidators, CustomFormatter, 
  ISitemap, ISitemapCommand, SITEMAP,
} from "@lib/common/frontend";

import { IPostDateiHochladenRequest, IPostDateiHochladenResponse } from "@fa-kt-evaluation/dokumente/types";
import { PostDateiHochladenCommandService } from '@fa-kt-evaluation/dokumente/frontend'

/* << importing    */
/*    importing >> */

@Component({
  selector: 'fa-kt-evaluation-post-datei-hochladen',
  templateUrl: './post-datei-hochladen.component.html',
  styleUrls: ['./post-datei-hochladen.component.css']
})
export class PostDateiHochladenCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) { this.$loading.next(this.$loading.getValue() || loading); }
  @Input() set value(value: IPostDateiHochladenRequest) { this.patch(value); }
  @Output() valueChanged: EventEmitter<IPostDateiHochladenRequest> = new EventEmitter<IPostDateiHochladenRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostDateiHochladenResponse> = new EventEmitter<IPostDateiHochladenResponse>();

/* << fields    */
/*    fields >> */

  get modalRef() {
    return this.injector.get(NzModalRef)
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public postDateiHochladen: PostDateiHochladenCommandService,
/* << more    */
/*    more >> */
  ) {
    this.command = sitemap.DOKUMENTE.Commands.POST_DATEI_HOCHLADEN;
    this.form = this.fb.group({
      datei: this.fb.group({
        id: [null, []],
        projekt: [null, [Validators.required]],
        gutachten: [null, []],
        dateiArt: [null, [Validators.required]],
        datei: [null, [Validators.required]],
        aktuell: [null, [Validators.required]],
      })
    });
/* << constructor    */
/*    constructor >> */
    this.patch();
  }

  ngOnInit() {
    this.postDateiHochladen.prepare().catch(e => console.error(e));
    this.subscriptions.push(
      this.finished.subscribe((result) => { if (this.modalRef) { this.modalRef.destroy(result); }}),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() })
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostDateiHochladenRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),
      this.dateiId?.valueChanges.subscribe(async (id) => {
/* << on-datei-id-change    */
/*    on-datei-id-change >> */
      }) as Subscription,
      this.dateiProjekt?.valueChanges.subscribe(async (projekt) => {
/* << on-datei-projekt-change    */
/*    on-datei-projekt-change >> */
      }) as Subscription,
      this.dateiGutachten?.valueChanges.subscribe(async (gutachten) => {
/* << on-datei-gutachten-change    */
/*    on-datei-gutachten-change >> */
      }) as Subscription,
      this.dateiDateiArt?.valueChanges.subscribe(async (dateiArt) => {
/* << on-datei-datei-art-change    */
/*    on-datei-datei-art-change >> */
      }) as Subscription,
      this.dateiDatei?.valueChanges.subscribe(async (datei) => {
/* << on-datei-datei-change    */
/*    on-datei-datei-change >> */
      }) as Subscription,
      this.dateiAktuell?.valueChanges.subscribe(async (aktuell) => {
/* << on-datei-aktuell-change    */
/*    on-datei-aktuell-change >> */
      }) as Subscription,
/* << subscibe    */
/*    subscibe >> */
    );
/* << init    */
    // this.postDateiHochladen.dateiDateiArt.toPromise().then(is => this.dateiDateiArt?.patchValue(is[0]));
/*    init >> */
  }

  ngOnDestroy() {
/* << end    */
/*    end >> */
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IPostDateiHochladenRequest) {
    if (value) {
      value = cleanObject(value);
      if (value.datei) {
        value.datei = cleanObject(value.datei);
      }
      this.form.patchValue(value);
/* << custom    */
/*    custom >> */
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload.datei) {
        payload.datei = cleanObject(payload.datei);
      }
      const response: IPostDateiHochladenResponse = await this.postDateiHochladen.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of (error.errors ? error.errors : [])) {
        for (const children of (validation.children ? validation.children : [])) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

/* << methods    */
/*    methods >> */

  get dateiId() {
    return this.form.controls.datei.get('id');
  };
  get dateiProjekt() {
    return this.form.controls.datei.get('projekt');
  };
  get dateiGutachten() {
    return this.form.controls.datei.get('gutachten');
  };
  get dateiDateiArt() {
    return this.form.controls.datei.get('dateiArt');
  };
  get dateiDatei() {
    return this.form.controls.datei.get('datei');
  };
  get dateiAktuell() {
    return this.form.controls.datei.get('aktuell');
  };


}
