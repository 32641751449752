import { NgModule } from '@angular/core';

import {
  PostKundeCommandServiceModule,
  PostKundeCommandComponentModule
} from './';

@NgModule({
  imports: [
    PostKundeCommandServiceModule,
    PostKundeCommandComponentModule
  ],
  exports: [
    PostKundeCommandServiceModule,
    PostKundeCommandComponentModule
  ]
})
export class PostKundeCommandModule { }
