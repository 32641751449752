import { NgModule } from '@angular/core';
import {
  BerichtFilterComponentModule,
  BerichtDetailComponentModule,
  BerichtSorterComponentModule,
  SavedBerichtFiltersComponentModule
} from './';

@NgModule({
  imports: [
    BerichtFilterComponentModule,
    BerichtDetailComponentModule,
    BerichtSorterComponentModule,
    SavedBerichtFiltersComponentModule
  ],
  exports: [
    BerichtFilterComponentModule,
    BerichtDetailComponentModule,
    BerichtSorterComponentModule,
    SavedBerichtFiltersComponentModule
  ],
})
export class BerichtQueryModule { }
