<nz-upload nzType="drag" [nzMultiple]="multi" style="width: 100%;"
           [(nzFileList)]="fileList" (nzChange)="handleChange($event)" [nzBeforeUpload]="beforeUpload">
  <p class="ant-upload-drag-icon">
    <i nz-icon [nzType]="icon"></i>
  </p>
  <p class="ant-upload-text">
    {{ text }}
  </p>
  <p class="ant-upload-hint">
    {{ hint }}
  </p>
</nz-upload>
