import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from "lodash";

@Pipe({
  name: 'order'
})
export class OrderPipe implements PipeTransform {

  transform(value: any[], field?: string): unknown[] {
    if (field) {
      return orderBy(value, field);
    }
    return value.sort();
  }

}
