import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostAbmeldenCommandServiceModule } from '../';
import { PostAbmeldenCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostAbmeldenCommandServiceModule
  ],
  declarations: [PostAbmeldenCommandComponent],
  exports: [PostAbmeldenCommandComponent],

})
export class PostAbmeldenCommandComponentModule { }
