import { NgModule } from '@angular/core';
import {
  UmkreissucheFilterComponentModule,
  UmkreissucheListComponentModule,
  UmkreissucheSorterComponentModule,
  SavedUmkreissucheFiltersComponentModule
} from './';

@NgModule({
  imports: [
    UmkreissucheFilterComponentModule,
    UmkreissucheListComponentModule,
    UmkreissucheSorterComponentModule,
    SavedUmkreissucheFiltersComponentModule
  ],
  exports: [
    UmkreissucheFilterComponentModule,
    UmkreissucheListComponentModule,
    UmkreissucheSorterComponentModule,
    SavedUmkreissucheFiltersComponentModule
  ],
})
export class UmkreissucheQueryModule { }
