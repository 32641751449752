import { Component, OnInit, OnDestroy, Inject, Input, Output, EventEmitter, Injector, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { NzMessageService } from "ng-zorro-antd/message";

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { AuthService, DependenciesService } from "@lib/auth/frontend";
import {
  ActionService,
  cleanObject, toInitials,
  CustomValidators, CustomFormatter,
  ISitemap, ISitemapCommand, SITEMAP, join
} from "@lib/common/frontend";

import {
  IPostReisekostenabrechnungRequest,
  IPostReisekostenabrechnungResponse,
  IReisekostenabrechnung
} from "@fa-kt-evaluation/finanzen/types";
import { PostReisekostenabrechnungCommandService } from '@fa-kt-evaluation/finanzen/frontend'

/* << importing    */
import { FileService } from "@lib/files/frontend";
import { ITour, ITourResponse } from "@fa-kt-evaluation/besichtigungen/types";
import { TourenService } from "@fa-kt-evaluation/besichtigungen/frontend";
import { ISelection } from "@lib/persistence/types";
import { BesichtigungRepository } from "@fa-kt-evaluation/besichtigungen/backend";
import { format } from "date-fns";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import { IMitarbeiter } from "@fa-kt-evaluation/personen/types";
import { round } from "lodash";

/*    importing >> */

@Component({
  selector: 'fa-kt-evaluation-post-reisekostenabrechnung',
  templateUrl: './post-reisekostenabrechnung.component.html',
  styleUrls: ['./post-reisekostenabrechnung.component.css']
})
export class PostReisekostenabrechnungCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) { this.$loading.next(this.$loading.getValue() || loading); }
  @Input() set value(value: IPostReisekostenabrechnungRequest) { this.patch(value); }
  @Output() valueChanged: EventEmitter<IPostReisekostenabrechnungRequest> = new EventEmitter<IPostReisekostenabrechnungRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostReisekostenabrechnungResponse> = new EventEmitter<IPostReisekostenabrechnungResponse>();

/* << fields    */
  @Input() tourenResponse: ITourResponse[] = [];
  @Input() set km(km: number) {
    this.reisekostenabrechnungNormaleKm?.patchValue(km);
  }
  $touren = new BehaviorSubject<ISelection[]>([]);
/*    fields >> */

  get modalRef() {
    return this.injector.get(NzModalRef)
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public postReisekostenabrechnung: PostReisekostenabrechnungCommandService,
/* << more    */
    public files: FileService,
    public touren: TourenService,
    public dependencies: DependenciesService,
/*    more >> */
  ) {
    this.command = sitemap.FINANZEN.Commands.POST_REISEKOSTENABRECHNUNG;
    this.form = this.fb.group({
      reisekostenabrechnung: this.fb.group({
        id: [null, []],
        mitarbeiter: [null, [Validators.required]],
        touren: [[], []],
        reisekostenabrechnungStatus: [null, [Validators.required]],
        tagesgeldAcht: [null, []],
        tagesgeldVierundzwanzig: [null, []],
        anUndAbreisetag: [null, []],
        fruehstuecke: [null, []],
        mittagessen: [null, []],
        abendessen: [null, []],
        uebernachtungspauschale: [null, []],
        normaleKm: [null, [Validators.required]],
        mitnahmeentschaedigung: [null, []],
        sonstigePositionen: [null, []],
        sonstigeSumme: [null, []],
        summe: [null, []],
        antrag: [null, []],
      })
    });
/* << constructor    */
/*    constructor >> */
    this.patch();
  }

  ngOnInit() {
    this.postReisekostenabrechnung.prepare().catch(e => console.error(e));
    this.subscriptions.push(
      this.touren.$result.subscribe(result => {
        if (!result) { return; }
        this.$touren.next(result.touren.map(row => {
          const count = row.besichtigungen?.filter(b => !b.besichtigung.rueckfahrt).length;
          return ({
            label: `am ${format(new Date(row.tour.am), 'dd.MM.yyyy')} (${count} Besichtigung${count === 1 ? '' : 'en'}) ${row.tour.abgerechnet ? ' - abgerechnet' : ''}`,
            value: row.tour.id,
            filter: row.tour.mitarbeiter,
          });
        }));
      }),
      this.finished.subscribe((result) => { if (this.modalRef) { this.modalRef.destroy(result); }}),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() })
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostReisekostenabrechnungRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),
      this.reisekostenabrechnungId?.valueChanges.subscribe(async (id) => {
/* << on-reisekostenabrechnung-id-change    */
/*    on-reisekostenabrechnung-id-change >> */
      }) as Subscription,
      this.reisekostenabrechnungMitarbeiter?.valueChanges.subscribe(async (mitarbeiter) => {
/* << on-reisekostenabrechnung-mitarbeiter-change    */
/*    on-reisekostenabrechnung-mitarbeiter-change >> */
      }) as Subscription,
      this.reisekostenabrechnungTouren?.valueChanges.subscribe(async (touren) => {
/* << on-reisekostenabrechnung-touren-change    */
/*    on-reisekostenabrechnung-touren-change >> */
      }) as Subscription,
      this.reisekostenabrechnungReisekostenabrechnungStatus?.valueChanges.subscribe(async (reisekostenabrechnungStatus) => {
/* << on-reisekostenabrechnung-reisekostenabrechnung-status-change    */
/*    on-reisekostenabrechnung-reisekostenabrechnung-status-change >> */
      }) as Subscription,
      this.reisekostenabrechnungTagesgeldAcht?.valueChanges.subscribe(async (tagesgeldAcht) => {
/* << on-reisekostenabrechnung-tagesgeld-acht-change    */
/*    on-reisekostenabrechnung-tagesgeld-acht-change >> */
      }) as Subscription,
      this.reisekostenabrechnungTagesgeldVierundzwanzig?.valueChanges.subscribe(async (tagesgeldVierundzwanzig) => {
/* << on-reisekostenabrechnung-tagesgeld-vierundzwanzig-change    */
/*    on-reisekostenabrechnung-tagesgeld-vierundzwanzig-change >> */
      }) as Subscription,
      this.reisekostenabrechnungAnUndAbreisetag?.valueChanges.subscribe(async (anUndAbreisetag) => {
/* << on-reisekostenabrechnung-an-und-abreisetag-change    */
/*    on-reisekostenabrechnung-an-und-abreisetag-change >> */
      }) as Subscription,
      this.reisekostenabrechnungFruehstuecke?.valueChanges.subscribe(async (fruehstuecke) => {
/* << on-reisekostenabrechnung-fruehstuecke-change    */
/*    on-reisekostenabrechnung-fruehstuecke-change >> */
      }) as Subscription,
      this.reisekostenabrechnungMittagessen?.valueChanges.subscribe(async (mittagessen) => {
/* << on-reisekostenabrechnung-mittagessen-change    */
/*    on-reisekostenabrechnung-mittagessen-change >> */
      }) as Subscription,
      this.reisekostenabrechnungAbendessen?.valueChanges.subscribe(async (abendessen) => {
/* << on-reisekostenabrechnung-abendessen-change    */
/*    on-reisekostenabrechnung-abendessen-change >> */
      }) as Subscription,
      this.reisekostenabrechnungUebernachtungspauschale?.valueChanges.subscribe(async (uebernachtungspauschale) => {
/* << on-reisekostenabrechnung-uebernachtungspauschale-change    */
/*    on-reisekostenabrechnung-uebernachtungspauschale-change >> */
      }) as Subscription,
      this.reisekostenabrechnungNormaleKm?.valueChanges.subscribe(async (normaleKm) => {
/* << on-reisekostenabrechnung-normale-km-change    */
/*    on-reisekostenabrechnung-normale-km-change >> */
      }) as Subscription,
      this.reisekostenabrechnungMitnahmeentschaedigung?.valueChanges.subscribe(async (mitnahmeentschaedigung) => {
/* << on-reisekostenabrechnung-mitnahmeentschaedigung-change    */
/*    on-reisekostenabrechnung-mitnahmeentschaedigung-change >> */
      }) as Subscription,
      this.reisekostenabrechnungSonstigePositionen?.valueChanges.subscribe(async (sonstigePositionen) => {
/* << on-reisekostenabrechnung-sonstige-positionen-change    */
/*    on-reisekostenabrechnung-sonstige-positionen-change >> */
      }) as Subscription,
      this.reisekostenabrechnungSonstigeSumme?.valueChanges.subscribe(async (sonstigeSumme) => {
/* << on-reisekostenabrechnung-sonstige-summe-change    */
/*    on-reisekostenabrechnung-sonstige-summe-change >> */
      }) as Subscription,
      this.reisekostenabrechnungSumme?.valueChanges.subscribe(async (summe) => {
/* << on-reisekostenabrechnung-summe-change    */
/*    on-reisekostenabrechnung-summe-change >> */
      }) as Subscription,
      this.reisekostenabrechnungAntrag?.valueChanges.subscribe(async (antrag) => {
/* << on-reisekostenabrechnung-antrag-change    */
/*    on-reisekostenabrechnung-antrag-change >> */
      }) as Subscription,
/* << subscibe    */
/*    subscibe >> */
    );
/* << init    */
/*    init >> */
  }

  ngOnDestroy() {
/* << end    */
/*    end >> */
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IPostReisekostenabrechnungRequest) {
    if (value) {
      value = cleanObject(value);
      if (value.reisekostenabrechnung) {
        value.reisekostenabrechnung = cleanObject(value.reisekostenabrechnung);
      }
      this.form.patchValue(value);
/* << custom    */
/*    custom >> */
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload.reisekostenabrechnung) {
        payload.reisekostenabrechnung = cleanObject(payload.reisekostenabrechnung);
      }
      const response: IPostReisekostenabrechnungResponse = await this.postReisekostenabrechnung.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of (error.errors ? error.errors : [])) {
        for (const children of (validation.children ? validation.children : [])) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

/* << methods    */
/*    methods >> */

  get reisekostenabrechnungId() {
    return this.form.controls.reisekostenabrechnung.get('id');
  };
  get reisekostenabrechnungMitarbeiter() {
    return this.form.controls.reisekostenabrechnung.get('mitarbeiter');
  };
  get reisekostenabrechnungTouren() {
    return this.form.controls.reisekostenabrechnung.get('touren');
  };
  get reisekostenabrechnungReisekostenabrechnungStatus() {
    return this.form.controls.reisekostenabrechnung.get('reisekostenabrechnungStatus');
  };
  get reisekostenabrechnungTagesgeldAcht() {
    return this.form.controls.reisekostenabrechnung.get('tagesgeldAcht');
  };
  get reisekostenabrechnungTagesgeldVierundzwanzig() {
    return this.form.controls.reisekostenabrechnung.get('tagesgeldVierundzwanzig');
  };
  get reisekostenabrechnungAnUndAbreisetag() {
    return this.form.controls.reisekostenabrechnung.get('anUndAbreisetag');
  };
  get reisekostenabrechnungFruehstuecke() {
    return this.form.controls.reisekostenabrechnung.get('fruehstuecke');
  };
  get reisekostenabrechnungMittagessen() {
    return this.form.controls.reisekostenabrechnung.get('mittagessen');
  };
  get reisekostenabrechnungAbendessen() {
    return this.form.controls.reisekostenabrechnung.get('abendessen');
  };
  get reisekostenabrechnungUebernachtungspauschale() {
    return this.form.controls.reisekostenabrechnung.get('uebernachtungspauschale');
  };
  get reisekostenabrechnungNormaleKm() {
    return this.form.controls.reisekostenabrechnung.get('normaleKm');
  };
  get reisekostenabrechnungMitnahmeentschaedigung() {
    return this.form.controls.reisekostenabrechnung.get('mitnahmeentschaedigung');
  };
  get reisekostenabrechnungSonstigePositionen() {
    return this.form.controls.reisekostenabrechnung.get('sonstigePositionen');
  };
  get reisekostenabrechnungSonstigeSumme() {
    return this.form.controls.reisekostenabrechnung.get('sonstigeSumme');
  };
  get reisekostenabrechnungSumme() {
    return this.form.controls.reisekostenabrechnung.get('summe');
  };
  get reisekostenabrechnungAntrag() {
    return this.form.controls.reisekostenabrechnung.get('antrag');
  };


  async download() {
    this.$loading.next(true);
    const blob = await this.files.get("fa-kt-apps/Reisekostenabrechnung.docx");
    const reisekostenabrechnung: IReisekostenabrechnung = this.form.getRawValue().reisekostenabrechnung;
    const zip = new PizZip(await this.files.readFileAsync(blob as File));
    const doc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true,
      delimiters: { start: "{", end: "}" },
    });
    const tagesgeldAcht = (reisekostenabrechnung.tagesgeldAcht ?? 0);
    const tagesgeldAchtSumme = round(tagesgeldAcht * 8, 2);

    const tagesgeldVierundzwanzig = (reisekostenabrechnung.tagesgeldVierundzwanzig ?? 0);
    const tagesgeldVierundzwanzigSumme = round(tagesgeldVierundzwanzig * 24, 2);

    const anUndAbreisetag = (reisekostenabrechnung.anUndAbreisetag ?? 0);
    const anUndAbreisetagSumme = round(anUndAbreisetag * 14, 2);

    const fruehstuecke = (reisekostenabrechnung.fruehstuecke ?? 0);
    const fruehstueckeSumme = round(fruehstuecke * 5.6, 2);

    const mittagessen = (reisekostenabrechnung.mittagessen ?? 0);
    const mittagessenSumme = round(mittagessen * 11.2, 2);

    const abendessen = (reisekostenabrechnung.mittagessen ?? 0);
    const abendessenSumme = round(abendessen * 11.2, 2);

    const uebernachtungspauschale = (reisekostenabrechnung.uebernachtungspauschale ?? 0);
    const uebernachtungspauschaleSumme = round(uebernachtungspauschale * 20, 2);

    const normaleKm = (reisekostenabrechnung.normaleKm ?? 0);
    const normaleKmSumme = round(normaleKm * 0.3, 2);

    const mitnahmeentschaedigung = (reisekostenabrechnung.mitnahmeentschaedigung ?? 0);
    const mitnahmeentschaedigungSumme = round(mitnahmeentschaedigung * 0.02, 2);

    const sonstigeSumme = (reisekostenabrechnung.sonstigeSumme ?? 0);

    const abrechnung =
      (tagesgeldAchtSumme > 0 ? ('Tagesgeld (8 EUR * ' + tagesgeldAcht + '): EUR ' + tagesgeldAchtSumme + '\n') : '') +
      (tagesgeldVierundzwanzigSumme > 0 ? ('Tagesgeld (24 EUR * ' + tagesgeldVierundzwanzig + '): EUR ' + tagesgeldVierundzwanzigSumme + '\n') : '') +
      (anUndAbreisetagSumme > 0 ? ('An- & Abreisetage (14 EUR * ' + anUndAbreisetag + '): EUR ' + anUndAbreisetagSumme + '\n') : '') +
      (fruehstueckeSumme > 0 ? 'Frühstücke (5,60 EUR * ' + fruehstuecke + '): EUR ' + fruehstueckeSumme + '\n' : '') +
      (mittagessenSumme > 0 ? 'Mittagessen (11,20 EUR * ' + mittagessen + '): EUR ' + mittagessenSumme + '\n' : '') +
      (abendessenSumme > 0 ? 'Abendessen (11,20 EUR * ' + abendessen + '): EUR ' + abendessenSumme + '\n' : '') +
      (uebernachtungspauschaleSumme > 0 ? 'Übernachtungspauschale (20 EUR * ' + uebernachtungspauschale + '): EUR ' + uebernachtungspauschaleSumme + '\n' : '') +
      (normaleKmSumme > 0 ? 'Normale KM (0,30 EUR * ' + normaleKm + 'km): EUR ' + normaleKmSumme + '\n' : '') +
      (mitnahmeentschaedigungSumme > 0 ? 'Mitnahmeentschaedigung (0,02 EUR * ' + mitnahmeentschaedigung + 'km): EUR ' + mitnahmeentschaedigungSumme + '\n' : '') +
      (sonstigeSumme > 0 ? 'Sonstige Summe: EUR ' + sonstigeSumme + '\n' : '') +
      (reisekostenabrechnung.sonstigePositionen ? '\n' + reisekostenabrechnung.sonstigePositionen + '' : '');

    const betrag = round(
      tagesgeldAchtSumme +
      tagesgeldVierundzwanzigSumme +
      anUndAbreisetagSumme +
      fruehstueckeSumme +
      mittagessenSumme +
      abendessenSumme +
      uebernachtungspauschaleSumme +
      normaleKmSumme +
      mitnahmeentschaedigungSumme +
      sonstigeSumme
    , 2);

    let links = "";
    const touren = this.tourenResponse?.filter(row => row.besichtigungen).map(row => {
      let strecke = 0;
      let tour = row.besichtigungen?.map(row => {
        let besichtigung = new Date(row.besichtigung.von as Date).toLocaleTimeString() + ': ' + (row.besichtigung.ende?.land ?? '') + ' ' + (row.besichtigung.ende?.plz ?? '') + ', ' + (row.besichtigung.ende?.strasse ?? '') + ' ' + (row.besichtigung.ende?.extra ?? '') + ' (' + (row.besichtigung.fahrtstrecke as number / 1000).toFixed(2) + ' km)';
        strecke = strecke + (row.besichtigung.fahrtstrecke as number / 1000);
        links = links + row.besichtigung.route + '\n';
        return besichtigung;
      }).join('\n');
      const start = row.besichtigungen?.shift()?.besichtigung;
      tour = new Date(row.tour.am).toLocaleDateString() + ' Start: ' + (start?.start?.land ?? '') + ' ' + (start?.start?.plz ?? '') + ', ' + (start?.start?.strasse ?? '') + ' ' + (start?.start?.extra ?? '') + '(' + round(strecke, 2).toFixed(2) + 'km)\n' + tour + '\nhttps://apps.fa-kt-valuation.de/besichtigungen/tour?id=' + row.tour.id + '\n'; //
      return tour;
    }).join('\n\n')


    const mitarbeiter = this.dependencies.$users.getValue()?.mitarbeiter.find(m => m.mitarbeiter.id === reisekostenabrechnung.mitarbeiter)?.mitarbeiter;
    const objekt = {
      mitarbeiterAnzeigename: mitarbeiter?.anzeigename,
      mitarbeiterEmail: mitarbeiter?.email,
      mitarbeiterStrasse: (mitarbeiter?.addresse?.strasse ?? '') + ' ' + (mitarbeiter?.addresse?.extra ?? ''),
      mitarbeiterAnschrift: (mitarbeiter?.addresse?.plz ?? '') + ' ' + (mitarbeiter?.addresse?.gemeinde_stadt ?? ''),
      datum: new Date().toLocaleDateString(),
      id: reisekostenabrechnung.id,
      touren,
      abrechnung,
      betrag,
      links
    };
    doc.render(objekt);
    const out = doc.getZip().generate({
      type: "blob",
      mimeType:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
    this.files.downloadBlob(out, mitarbeiter?.email + '_' + reisekostenabrechnung.id + ".docx");
    this.$loading.next(false);
  }
}
