import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { KundenServiceModule } from '../';
import { SavedKundenFiltersComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    KundenServiceModule
  ],
  declarations: [SavedKundenFiltersComponent],
  exports: [SavedKundenFiltersComponent],

})
export class SavedKundenFiltersComponentModule { }
