import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'codeboard-not-found',
  templateUrl: './not-found.page.html',
  styleUrls: ['./not-found.page.css']
})
export class NotFoundPage implements OnDestroy, OnInit{

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }
}
