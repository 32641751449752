import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IPostObjektRequest, IPostObjektResponse, IPostObjektCommandShapes } from '@fa-kt-evaluation/objekte/types';

@Injectable({
  providedIn: 'root'
})
export class PostObjektCommandService {

  readonly route = 'objekte/objekt';
  protected dependencies = [
      'geo/written-bereich',
      'geo/removed-bereich',
      'objekte/objekt-gespeichert'
  ];

  readonly $shapes = new BehaviorSubject<IPostObjektCommandShapes|undefined>(undefined);
  
  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IPostObjektCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IPostObjektCommandShapes>(this.route + '/post/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IPostObjektRequest): Promise<IPostObjektResponse> {
    return await this.http.post<IPostObjektResponse>(this.route, payload) as unknown as IPostObjektResponse;
  }
  
  getObjektBereiche(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['objekt.bereiche']?.find(v => v.value === value)
  }

  get objektBereiche(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['objekt.bereiche'] as ISelection[] : []));
  }

}
