import { NgModule } from '@angular/core';
import {
  GutachtenFilterComponentModule,
  GutachtenListComponentModule,
  GutachtenSorterComponentModule,
  SavedGutachtenFiltersComponentModule
} from './';

@NgModule({
  imports: [
    GutachtenFilterComponentModule,
    GutachtenListComponentModule,
    GutachtenSorterComponentModule,
    SavedGutachtenFiltersComponentModule
  ],
  exports: [
    GutachtenFilterComponentModule,
    GutachtenListComponentModule,
    GutachtenSorterComponentModule,
    SavedGutachtenFiltersComponentModule
  ],
})
export class GutachtenQueryModule { }
