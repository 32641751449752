import { NgModule } from '@angular/core';

import {
  PostBerichtsVorlageCommandServiceModule,
  PostBerichtsVorlageCommandComponentModule
} from './';

@NgModule({
  imports: [
    PostBerichtsVorlageCommandServiceModule,
    PostBerichtsVorlageCommandComponentModule
  ],
  exports: [
    PostBerichtsVorlageCommandServiceModule,
    PostBerichtsVorlageCommandComponentModule
  ]
})
export class PostBerichtsVorlageCommandModule { }
