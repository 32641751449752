<nz-spin [nzSpinning]="offlineCache.$syncing | async">
  <nz-row>
    <nz-col [nzSpan]="24">
      <h3 nz-typography>Offline Speicher</h3>
    </nz-col>
    <nz-col nzFlex>
      <nz-statistic [nzValue]="((offlineCache.$syncableTasks | async)?.length | number)!" [nzTitle]="'Synchronisierbare Anfragen'" style="margin: 16px"></nz-statistic>
    </nz-col>
    <nz-col nzFlex>
      <nz-button-group style="margin: 16px">
        <button nz-button nzType="primary" [disabled]="(offlineCache.$syncableTasks | async)?.length === 0 || (offlineCache.http.$offline | async)" (click)="offlineCache.sync()">
          <i nz-icon nzType="cloud-sync" nzTheme="outline"></i>
          Synchronisieren
        </button>
        <button nz-button nzType="dashed" nzDanger [disabled]="(offlineCache.$syncableTasks | async)?.length === 0" (click)="offlineCache.clear()">
          <i nz-icon nzType="cloud" nzTheme="outline"></i>
          Befehle löschen
        </button>
        <button nz-button nzType="dashed" nzDanger (click)="offlineCache.clearCache()">
          <i nz-icon nzType="cloud-server" nzTheme="outline"></i>
          Speicher löschen
        </button>
      </nz-button-group>
    </nz-col>
    <nz-col nzSpan='24'>
      <br>
      <nz-collapse>
        <nz-collapse-panel
          *ngFor="let syncableTask of offlineCache.$syncableTasks | async"
          [nzHeader]="CollapsePanelHeader">
          <ng-template #CollapsePanelHeader>
            {{ HTTPS_METHOD[syncableTask.method] }} {{ syncableTask.url}}
          </ng-template>
          <code style="margin:0;" *ngIf="syncableTask.body">
            {{ syncableTask.body | json}}
          </code>
          <br>
          <button nz-button nzType="default" (click)="offlineCache.syncTask(syncableTask, true)">
            <i nz-icon nzType="cloud-sync" nzTheme="outline"></i>
            Synchronisieren
          </button>
        </nz-collapse-panel>
      </nz-collapse>
    </nz-col>
  </nz-row>
</nz-spin>
