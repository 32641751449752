import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostStartTourCommandServiceModule } from '../';
import { PostStartTourCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostStartTourCommandServiceModule
  ],
  declarations: [PostStartTourCommandComponent],
  exports: [PostStartTourCommandComponent],

})
export class PostStartTourCommandComponentModule { }
