import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostFeldOptionCommandService } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [PostFeldOptionCommandService],
  exports: []
})
export class PostFeldOptionCommandServiceModule { }
