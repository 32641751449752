import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { FormularServiceModule } from '../';
import { SavedFormularFiltersComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    FormularServiceModule
  ],
  declarations: [SavedFormularFiltersComponent],
  exports: [SavedFormularFiltersComponent],

})
export class SavedFormularFiltersComponentModule { }
