import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { ReisekostenabrechnungenServiceModule } from '../';
import { SavedReisekostenabrechnungenFiltersComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ReisekostenabrechnungenServiceModule
  ],
  declarations: [SavedReisekostenabrechnungenFiltersComponent],
  exports: [SavedReisekostenabrechnungenFiltersComponent],

})
export class SavedReisekostenabrechnungenFiltersComponentModule { }
