import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { ProfilServiceModule } from '../';
import { ProfilSorterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ProfilServiceModule
  ],
  declarations: [ProfilSorterComponent],
  exports: [ProfilSorterComponent],

})
export class ProfilSorterComponentModule { }
