import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { ArchivServiceModule } from '../';
import { ArchivFilterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ArchivServiceModule
  ],
  declarations: [ArchivFilterComponent],
  exports: [ArchivFilterComponent],

})
export class ArchivFilterComponentModule { }
