import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IPostRechnungRunterzaehlenRequest, IPostRechnungRunterzaehlenResponse, IPostRechnungRunterzaehlenCommandShapes } from '@fa-kt-evaluation/finanzen/types';

@Injectable({
  providedIn: 'root'
})
export class PostRechnungRunterzaehlenCommandService {

  readonly route = 'finanzen/rechnung-runterzaehlen';
  protected dependencies = [
      'finanzen/rechnungen-runtergezaehlt'
  ];

  readonly $shapes = new BehaviorSubject<IPostRechnungRunterzaehlenCommandShapes|undefined>(undefined);
  
  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IPostRechnungRunterzaehlenCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IPostRechnungRunterzaehlenCommandShapes>(this.route + '/post/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IPostRechnungRunterzaehlenRequest): Promise<IPostRechnungRunterzaehlenResponse> {
    return await this.http.post<IPostRechnungRunterzaehlenResponse>(this.route, payload) as unknown as IPostRechnungRunterzaehlenResponse;
  }
  
}
