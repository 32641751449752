<nz-spin [nzSpinning]="auth.$loading | async">
  <nz-avatar [nzSize]="32"
             nzIcon="user"
             [nzSrc]="auth.$avatar | async"
             [nzShape]="'square'"
             nz-popover
             nzPopoverTrigger="click"
             [nzPopoverTitle]="title"
             [nzPopoverContent]="content"
             [(nzPopoverVisible)]="showMenu"
             (nzPopoverVisibleChange)="showMenu = false"
  ></nz-avatar>
</nz-spin>
<ng-template #title>
  <ng-container [ngSwitch]="!!(auth.$id | async)">
    <ul nz-menu nzMode="vertical" *ngSwitchCase="true">
      <li nz-menu-item (click)="navigateToMe()">
        <i nz-icon nzType="user" nzTheme="outline"></i>
        Zu meinem Profil
      </li>
      <li nz-menu-item (click)="navigateToDevice()">
        <i nz-icon nzType="mobile" nzTheme="outline"></i>
        Zum Gerät
        <nz-badge
          nzStandalone
          [nzCount]="(offlineCache.$syncableTasks | async)?.length"
          [nzStyle]="{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset' }"
        >
        </nz-badge>
      </li>
      <li nz-menu-item *ngIf="$settings | async" (click)="navigateToSettings()">
        <i nz-icon nzType="setting" nzTheme="outline"></i>
        Zu den Einstellungen
      </li>
    </ul>
    <button *ngSwitchCase="false" nz-button nzType="primary" (click)="auth.login()">Anmelden</button>
  </ng-container>
</ng-template>
<ng-template #content>
  <ng-container [ngSwitch]="!!(auth.$id | async)">
    <span *ngSwitchCase="true" nz-typography nzType="secondary">
      <i *ngIf="auth.$isAdmin | async" nz-icon nzType="unlock" nzTheme="outline"></i>
      <i *ngIf="auth.$isFinanzen | async" nz-icon nzType="euro" nzTheme="outline"></i>
      Sie sind als "{{ auth.$displayName | async }}" angemeldet.
      <a nz-button nzType="link" (click)="auth.logout()"><span nz-typography nzType="secondary">
        <i><u>Abmelden</u></i></span>
      </a>
    </span>
    <span *ngSwitchCase="false" nz-typography nzType="danger">Sie sind nicht angemeldet</span>
  </ng-container>
</ng-template>
