import { NgModule } from '@angular/core';
import { 
  ZeitQueryModule,
  ZeitenQueryModule
} from './';

@NgModule({
  declarations: [],
  imports: [
    ZeitQueryModule,
    ZeitenQueryModule
  ],
  exports: [
    ZeitQueryModule,
    ZeitenQueryModule
  ]
})
export class ZeitenQuerysModule { }
