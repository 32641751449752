import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { APP_VERSION, APP_BUILD, SITEMAP, ISitemap, APP_CONFIG, IAppConfig } from "../../types";

@Component({
  selector: 'codeboard-admin-page',
  templateUrl: './device.page.html',
  styleUrls: ['./device.page.css']
})
export class DevicePage implements OnInit, OnDestroy {

  mode;
  stage;

  constructor(
    @Inject(APP_CONFIG) public environment: IAppConfig,
    @Inject(APP_VERSION) public version: String,
    @Inject(APP_BUILD) public build: String,
    @Inject(SITEMAP) public sitemap: ISitemap
  ) {
    this.mode = environment.mode;
    this.stage = environment.stage;
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

}
