import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { GutachtenCommandsModule } from './commands'
import {
  GutachtenQuerysModule,
  GutachtenService,
  UmkreissucheService
} from './querys'
import {
  BewertungsAnlasssServiceModule, BewertungsAnlasssService,
  GutachtenStatiServiceModule, GutachtenStatiService
} from './settings';

const routes: Routes = [
  {
    path: 'gutachten',
    loadChildren: () => import('./pages').then(m => m.GutachtenPagesModule)
  },
  {
    path: 'gutachten',
    children: [
      {
        path: 'settings',
        loadChildren: () => import('./settings').then(m => m.GutachtenSettingsModule),
      }
    ]
  },
];

@NgModule({
  imports: [
    GutachtenCommandsModule,
    GutachtenQuerysModule,
    BewertungsAnlasssServiceModule,
    GutachtenStatiServiceModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    GutachtenCommandsModule,
    GutachtenQuerysModule,
    BewertungsAnlasssServiceModule,
    GutachtenStatiServiceModule
  ]
})
export class GutachtenModule {
  static forRoot(): ModuleWithProviders< GutachtenModule> {
    return {
      ngModule: GutachtenModule,
      providers: [
        GutachtenService,
        UmkreissucheService,
        BewertungsAnlasssService,
        GutachtenStatiService
      ]
    };
  }
}
