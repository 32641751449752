import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { MarktCommandsModule } from './commands'
import {
  MarktQuerysModule,
  BerichtService,
  BerichteService,
  DurchsuchenService
} from './querys'
import {
  HerausgebersServiceModule, HerausgebersService,
  TemplatesServiceModule, TemplatesService
} from './settings';

const routes: Routes = [
  {
    path: 'markt',
    loadChildren: () => import('./pages').then(m => m.MarktPagesModule)
  },
  {
    path: 'markt',
    children: [
      {
        path: 'settings',
        loadChildren: () => import('./settings').then(m => m.MarktSettingsModule),
      }
    ]
  },
];

@NgModule({
  imports: [
    MarktCommandsModule,
    MarktQuerysModule,
    HerausgebersServiceModule,
    TemplatesServiceModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    MarktCommandsModule,
    MarktQuerysModule,
    HerausgebersServiceModule,
    TemplatesServiceModule
  ]
})
export class MarktModule {
  static forRoot(): ModuleWithProviders< MarktModule> {
    return {
      ngModule: MarktModule,
      providers: [
        BerichtService,
        BerichteService,
        DurchsuchenService,
        HerausgebersService,
        TemplatesService
      ]
    };
  }
}
