import { NgModule } from '@angular/core';

import {
  DeleteTourCommandServiceModule,
  DeleteTourCommandComponentModule
} from './';

@NgModule({
  imports: [
    DeleteTourCommandServiceModule,
    DeleteTourCommandComponentModule
  ],
  exports: [
    DeleteTourCommandServiceModule,
    DeleteTourCommandComponentModule
  ]
})
export class DeleteTourCommandModule { }
