import { NgModule } from '@angular/core';

import {
  PostReferenzenHochladenCommandServiceModule,
  PostReferenzenHochladenCommandComponentModule
} from './';

@NgModule({
  imports: [
    PostReferenzenHochladenCommandServiceModule,
    PostReferenzenHochladenCommandComponentModule
  ],
  exports: [
    PostReferenzenHochladenCommandServiceModule,
    PostReferenzenHochladenCommandComponentModule
  ]
})
export class PostReferenzenHochladenCommandModule { }
