import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PipeLoadingService {

  private _$loading: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  readonly $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() {
    this._$loading.pipe(map(resolvings => resolvings > 0)).subscribe(resolving => this.$loading.next(resolving));
  }

  loading(): void {
    this._$loading.next(this._$loading.getValue() + 1);
  }

  loaded(): void {
    this._$loading.next(this._$loading.getValue() - 1);
  }
}
