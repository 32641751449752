import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostFormularKopierenCommandServiceModule } from '../';
import { PostFormularKopierenCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostFormularKopierenCommandServiceModule
  ],
  declarations: [PostFormularKopierenCommandComponent],
  exports: [PostFormularKopierenCommandComponent],

})
export class PostFormularKopierenCommandComponentModule { }
