import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostReaktivierenCommandServiceModule } from '../';
import { PostReaktivierenCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostReaktivierenCommandServiceModule
  ],
  declarations: [PostReaktivierenCommandComponent],
  exports: [PostReaktivierenCommandComponent],

})
export class PostReaktivierenCommandComponentModule { }
