import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { DeleteObjektCommandServiceModule } from '../';
import { DeleteObjektCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteObjektCommandServiceModule
  ],
  declarations: [DeleteObjektCommandComponent],
  exports: [DeleteObjektCommandComponent],

})
export class DeleteObjektCommandComponentModule { }
