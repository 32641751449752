import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { RechnungsVorlagenServiceModule } from '../';
import { RechnungsVorlagenListComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    RechnungsVorlagenServiceModule
  ],
  declarations: [RechnungsVorlagenListComponent],
  exports: [RechnungsVorlagenListComponent],

})
export class RechnungsVorlagenListComponentModule { }
