import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { DeleteReisekostenabrechnungCommandServiceModule } from '../';
import { DeleteReisekostenabrechnungCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteReisekostenabrechnungCommandServiceModule
  ],
  declarations: [DeleteReisekostenabrechnungCommandComponent],
  exports: [DeleteReisekostenabrechnungCommandComponent],

})
export class DeleteReisekostenabrechnungCommandComponentModule { }
