import { NgModule } from '@angular/core';
import {
  DurchsuchenFilterComponentModule,
  DurchsuchenListComponentModule,
  DurchsuchenSorterComponentModule,
  SavedDurchsuchenFiltersComponentModule
} from './';

@NgModule({
  imports: [
    DurchsuchenFilterComponentModule,
    DurchsuchenListComponentModule,
    DurchsuchenSorterComponentModule,
    SavedDurchsuchenFiltersComponentModule
  ],
  exports: [
    DurchsuchenFilterComponentModule,
    DurchsuchenListComponentModule,
    DurchsuchenSorterComponentModule,
    SavedDurchsuchenFiltersComponentModule
  ],
})
export class DurchsuchenQueryModule { }
