import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IDeleteRechnungsVorlageRequest, IDeleteRechnungsVorlageResponse, IDeleteRechnungsVorlageCommandShapes } from '@fa-kt-evaluation/finanzen/types';

@Injectable({
  providedIn: 'root'
})
export class DeleteRechnungsVorlageCommandService {

  readonly route = 'finanzen/rechnungs-vorlage';
  protected dependencies = [
      'finanzen/rechnungs-vorlage-geloescht'
  ];

  readonly $shapes = new BehaviorSubject<IDeleteRechnungsVorlageCommandShapes|undefined>(undefined);
  
  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IDeleteRechnungsVorlageCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IDeleteRechnungsVorlageCommandShapes>(this.route + '/delete/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IDeleteRechnungsVorlageRequest): Promise<IDeleteRechnungsVorlageResponse> {
    return await this.http.delete<IDeleteRechnungsVorlageResponse>(this.route + this.http.serialize(payload)) as unknown as IDeleteRechnungsVorlageResponse;
  }
  
}
