import { NgModule } from '@angular/core';

import {
  PostDateiHochladenCommandServiceModule,
  PostDateiHochladenCommandComponentModule
} from './';

@NgModule({
  imports: [
    PostDateiHochladenCommandServiceModule,
    PostDateiHochladenCommandComponentModule
  ],
  exports: [
    PostDateiHochladenCommandServiceModule,
    PostDateiHochladenCommandComponentModule
  ]
})
export class PostDateiHochladenCommandModule { }
