import { Component } from '@angular/core';
import { RechnungsVorlagenService } from "@fa-kt-evaluation/finanzen/frontend";

/* << imports    */
 
/*    imports >> */
@Component({
  selector: 'fa-kt-evaluation-saved-rechnungs-vorlagen-filters',
  templateUrl: './saved-rechnungs-vorlagen-filters.component.html',
  styleUrls: ['./saved-rechnungs-vorlagen-filters.component.css']
})
export class SavedRechnungsVorlagenFiltersComponent {

  constructor(
    public rechnungsVorlagen: RechnungsVorlagenService
/* << injection    */
/*    injection >> */
  ) {
  }
}