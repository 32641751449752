import { NgModule } from '@angular/core';

import {
  PostExtraVereinbarungCommandServiceModule,
  PostExtraVereinbarungCommandComponentModule
} from './';

@NgModule({
  imports: [
    PostExtraVereinbarungCommandServiceModule,
    PostExtraVereinbarungCommandComponentModule
  ],
  exports: [
    PostExtraVereinbarungCommandServiceModule,
    PostExtraVereinbarungCommandComponentModule
  ]
})
export class PostExtraVereinbarungCommandModule { }
