export class CustomFormatter {
  static km = {
    formatter: (value: number): string => `${toLocale(value, 'km')}`,
    parser: (value: string): number => Number(value.replace(' km', ''))
  }
  static m = {
    formatter: (value: number): string => `${toLocale(value, 'm')}`,
    parser: (value: string): number => Number(value.replace(' m', ''))
  }
  static euro = {
    formatter: (value: number): string => `${toLocale(value, '€')}`,
    parser: (value: string): number => Number(value.replace(' €', ''))
  }
  static percent = {
    formatter: (value: number): string => `${toLocale(value, '%')}`,
    parser: (value: string): number => Number(value.replace(' %', ''))
  }
  static days = {
    formatter: (value: number): string => `${toLocale(value, 'T')}`,
    parser: (value: string): number => Number(value.replace(' T', ''))
  }
  static hours = {
    formatter: (value: number): string => `${toLocale(value, 'S')}`,
    parser: (value: string): number => Number(value.replace(' S', ''))
  }
  static minutes = {
    formatter: (value: number): string => `${toLocale(value, 'M')}`,
    parser: (value: string): number => Number(value.replace(' M', ''))
  }
}

function toLocale(value: any, sign: string) {
  if (!value) { return ''; }
  return Number((value).toFixed(2)).toLocaleString() + ' ' + sign;
}

function parseNumber(number: string): number {
  number = number
    .split('.').join('_')
    .split(',').join('.')
    .split('_').join(',')
  return parseFloat(number);
}
