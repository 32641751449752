import { NgModule } from '@angular/core';
import { 
  RechnungsVorlagenQueryModule,
  ReisekostenabrechnungQueryModule,
  ReisekostenabrechnungenQueryModule
} from './';

@NgModule({
  declarations: [],
  imports: [
    RechnungsVorlagenQueryModule,
    ReisekostenabrechnungQueryModule,
    ReisekostenabrechnungenQueryModule
  ],
  exports: [
    RechnungsVorlagenQueryModule,
    ReisekostenabrechnungQueryModule,
    ReisekostenabrechnungenQueryModule
  ]
})
export class FinanzenQuerysModule { }
