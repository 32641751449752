import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { GutachtenServiceModule } from '../';
import { GutachtenSorterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    GutachtenServiceModule
  ],
  declarations: [GutachtenSorterComponent],
  exports: [GutachtenSorterComponent],

})
export class GutachtenSorterComponentModule { }
