import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { BesichtigungenCommandsModule } from './commands'
import {
  BesichtigungenQuerysModule,
  OffeneGutachtenService,
  TourService,
  TourenService
} from './querys'
import {
  BesichtigungsStatiServiceModule, BesichtigungsStatiService,
  TourStatiServiceModule, TourStatiService
} from './settings';

const routes: Routes = [
  {
    path: 'besichtigungen',
    loadChildren: () => import('./pages').then(m => m.BesichtigungenPagesModule)
  },
  {
    path: 'besichtigungen',
    children: [
      {
        path: 'settings',
        loadChildren: () => import('./settings').then(m => m.BesichtigungenSettingsModule),
      }
    ]
  },
];

@NgModule({
  imports: [
    BesichtigungenCommandsModule,
    BesichtigungenQuerysModule,
    BesichtigungsStatiServiceModule,
    TourStatiServiceModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    BesichtigungenCommandsModule,
    BesichtigungenQuerysModule,
    BesichtigungsStatiServiceModule,
    TourStatiServiceModule
  ]
})
export class BesichtigungenModule {
  static forRoot(): ModuleWithProviders< BesichtigungenModule> {
    return {
      ngModule: BesichtigungenModule,
      providers: [
        OffeneGutachtenService,
        TourService,
        TourenService,
        BesichtigungsStatiService,
        TourStatiService
      ]
    };
  }
}
