import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor, FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators
} from "@angular/forms";

import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from "ng-zorro-antd/message";
import { BehaviorSubject, combineLatest, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { EmailAddress, IGraphContact } from "@lib/contacts/types";
import { ActionService, cleanObject, toInitials } from "@lib/common/frontend";
import { OutlookContactsService } from "../../services";

@Component({
  selector: 'codeboard-outlook-contacts',
  templateUrl: './outlook-contacts.component.html',
  styleUrls: ['./outlook-contacts.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: OutlookContactsComponent
    }
  ]
})
export class OutlookContactsComponent implements OnInit, OnDestroy, ControlValueAccessor  {
  subscriptions: Subscription[] = [];

  onChange = (contacts: string[]) => {};
  onTouched = () => {};
  touched = false;
  visible = false;
  @Input() disabled = false;
  @Input() required = false;

  @Input() set contacts(contacts: string[]) {
    this.$values.next(contacts ? contacts : []);
  }
  $values: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  $contacts: BehaviorSubject<IGraphContact[]> = new BehaviorSubject<IGraphContact[]>([]);

  $search: BehaviorSubject<string> = new BehaviorSubject<string>('');

  $book: BehaviorSubject<IGraphContact[]|undefined> = new BehaviorSubject<IGraphContact[]|undefined>(undefined);
  $contact: BehaviorSubject<IGraphContact|undefined> = new BehaviorSubject<IGraphContact|undefined>(undefined);
  form: FormGroup;

  /*
  @Input() set folder(folder: { folder: string, parent?: string }) {
    if (!folder || !folder.folder) { return; }
    this.subscriptions.push(...[
      this.outlook.$contactFolders.subscribe(folders => {
        if (!folders) { return; }
        this.outlook.ensureContactFolders(folder.folder, folder.parent).then((result) => {
          if (!result) { return; }
          const { parent, folder } = result;
          this.$parent.next(parent);
          this.$self.next(folder);
        });
      })
    ])
  };
  $self = new BehaviorSubject<IGraphContactFolder|undefined>(undefined);
  $parent = new BehaviorSubject<IGraphContactFolder|undefined>(undefined);
  */
  @Input() companyName: string|undefined = undefined;
  @Input() department: string|undefined = undefined;
  @Input() businessHomePage: string|undefined = undefined;

  @Output()
  contactsChange: EventEmitter<string[]> = new EventEmitter()
  @Input() placeholder = 'Suche nach Kontakten';

  constructor(
    public outlook: OutlookContactsService,
    private modal: NzModalService,
    private messages: NzMessageService,
    private actions: ActionService,
    private fb: FormBuilder) {
    this.form = fb.group({
      displayName: [null, [Validators.required]],
      givenName: [null, [Validators.required]],
      photo: [null, []],
      title: [null, []],
      initials: [null, []],
      birthday: [null, []],
      middleName: [null, []],
      surname: [null, []],
      nickName: [null, []],
      generation: [null, []],
      companyName: [null, []],
      department: [null, []],
      officeLocation: [null, []],
      profession: [null, []],
      jobTitle: [null, []],
      assistantName: [null, []],
      spouseName: [null, []],
      children:             this.fb.array([]),
      manager: [null, []],
      personalNotes: [null, []],
      mobilePhone: [null, []],
      businessHomePage: [null, []],
      imAddresses: this.fb.array([]),
      businessPhones: this.fb.array([]),
      emailAddresses: this.fb.array([]),
      homePhones:           this.fb.array([]),
      // Address;
    })
  }



  ngOnInit(): void {
    this.subscriptions.push(...[
      this.$values.subscribe(contacts => this.onChange(contacts)),
      combineLatest([this.$values, this.outlook.$contacts]).subscribe(([contacts, outlook]) => {
        if (contacts && outlook) {
          this.$contacts.next(contacts.map(c => outlook.find(o => o.id === c) as IGraphContact).filter(c => c && c.id));
          if (this.$book.getValue()) {
            this.$book.next((outlook as IGraphContact[]).map(c => ({ ...c, disabled: contacts.includes(c.id as string) })));
          }
        } else {
          this.$contacts.next([]);
        }
      }),
      this.form.controls.displayName.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged()
      ).subscribe(displayName => {
        if (!displayName) { return; }
        const contact = this.form.value;
        const split = displayName.split(' ');
        const [a, b, c, ...d] = split;
        if (!contact.nickname) { this.form.patchValue({ nickname: displayName })}
        if (!contact.initials) { this.form.patchValue({ initials: toInitials(displayName) })}
        switch (split.length) {
          case 1:
            if (!contact.givenName) { this.form.patchValue({ givenName: a })}
            break;
          case 2:
            if (!contact.givenName) { this.form.patchValue({ givenName: a })}
            if (!contact.surName) { this.form.patchValue({ surname: b })}
            break;
          case 3:
            if (!contact.givenName) { this.form.patchValue({ givenName: a })}
            if (!contact.middleName) { this.form.patchValue({ middleName: b })}
            if (!contact.surName) { this.form.patchValue({ surname: c })}
            break;
          default:
            if (!contact.title) { this.form.patchValue({ title: a })}
            if (!contact.givenName) { this.form.patchValue({ givenName: b })}
            if (!contact.middleName) { this.form.patchValue({ middleName: c })}
            if (!contact.surName) { this.form.patchValue({ surname: Array.isArray(d) ? d.join(' ') : d })}
        }
      })
    ])
    // if (!this.outlook.$contactFolders.getValue()) { this.outlook.listContactFolders().then(); }
    if (!this.outlook.$contacts.getValue()) { this.outlook.listContacts().then(); }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach($ => $.unsubscribe());
  }

  async openBook() {
    let outlook = this.outlook.$contacts.getValue();
    if (!outlook) {
      this.messages.warning('Kontaktbuch wurde noch nicht geladen');
      return
    }
    const value = this.$values.getValue();
    this.$book.next((outlook as IGraphContact[]).map(c => ({ ...c, disabled: value.includes(c.id as string) })));
  }
  closeBook() {
    this.$book.next(undefined);
  }

  removeContact(id: string) {
    this.$values.next(this.$values.getValue().filter(v => v !== id));

  }
  addContact(id: string) {
    this.$values.next(this.$values.getValue().filter(v => v !== id).concat([id]));
    if (this.$book.getValue()?.filter(b => !b.disabled).length === 0) {
      this.closeBook();
    }
  }

  startContact() {
    const contact: IGraphContact = {} as IGraphContact;
    if (this.companyName) { contact.companyName = this.companyName; }
    if (this.department) { contact.department = this.department; }
    if (this.businessHomePage) { contact.businessHomePage = this.businessHomePage; }
    this.openContact(contact)
  }
  openContact(contact: IGraphContact) {
    if (this.companyName && !contact.companyName) { contact.companyName = this.companyName; }
    if (this.department && !contact.department) { contact.department = this.department; }
    if (this.businessHomePage && !contact.businessHomePage) { contact.businessHomePage = this.businessHomePage; }
    delete contact.disabled;
    this.$contact.next(contact);
    this.form.patchValue(contact);
    if (contact.businessPhones) {
      contact.businessPhones.forEach(businessPhone => this.addBusinessPhone(businessPhone))
    }
    if (contact.homePhones) {
      contact.homePhones.forEach(homePhone => this.addHomePhone(homePhone));
    }
    if (contact.emailAddresses) {
      contact.emailAddresses.forEach(emailAddress => this.addEmailAddress(emailAddress));
    }
    if (contact.imAddresses) {
      contact.imAddresses.forEach(imAddress => this.addIMAddress(imAddress));
    }
    if (contact.children) {
      contact.children.forEach(child => this.addChild(child))
      contact.children.forEach(child => this.addChild(child))
    }
    this.form.markAsDirty();
    // this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
  }
  get _businessHomePage() {
    return this.form.get("businessHomePage")
  }
  get mobilePhone() {
    return this.form.get("mobilePhone")
  }
  get children(): FormArray {
    return this.form.get("children") as FormArray
  }
  addChild(child = '') {
    this.children.push(new FormControl(child, [Validators.required]));
  }
  removeChild(i: number) {
    this.children.removeAt(i);
  }
  get imAddresses(): FormArray {
    return this.form.get("imAddresses") as FormArray
  }
  imAddress(i: number) {
    return this.imAddresses.controls[i].value;
  }
  addIMAddress(imAddress = '') {
    this.imAddresses.push(new FormControl(imAddress, [Validators.required]));
  }
  removeIMAddress(i: number) {
    this.imAddresses.removeAt(i);
  }

  get homePhones(): FormArray {
    return this.form.get("homePhones") as FormArray
  }
  homePhone(i: number) {
    return this.homePhones.controls[i].value;
  }
  addHomePhone(homePhone = '') {
    this.homePhones.push(new FormControl(homePhone, [Validators.required, Validators.pattern('[- +()0-9]+')]));
  }
  removeHomePhone(i: number) {
    this.homePhones.removeAt(i);
  }

  get businessPhones(): FormArray {
    return this.form.get("businessPhones") as FormArray
  }
  businessPhone(i: number) {
    return this.businessPhones.controls[i].value;
  }
  addBusinessPhone(businessPhone = '') {
    this.businessPhones.push(new FormControl(businessPhone, [Validators.required, Validators.pattern('[- +()0-9]+')]));
  }
  removeBusinessPhone(i: number) {
    this.businessPhones.removeAt(i);
  }

  get emailAddresses(): FormArray {
    return this.form.get("emailAddresses") as FormArray
  }
  emailAddress(i: number): string {
    return this.emailAddresses.controls[i].get('address')?.value;
  }
  addEmailAddress(emailAddress: EmailAddress = { name: '', address: '' }) {
    this.emailAddresses.push(this.fb.group({
      name: [emailAddress.name, []],
      address: [emailAddress.address, [Validators.required]],
    }));
  }
  removeEmailAddress(i: number) {
    this.emailAddresses.removeAt(i);
  }

  cancelContact() {
    this.form.reset();
    this.homePhones.clear();
    this.businessPhones.clear();
    this.emailAddresses.clear();
    this.imAddresses.clear();
    this.children.clear();
    this.$contact.next(undefined);
  }
  async saveContact() {
    const contact = this.$contact.getValue();
    if (contact && contact.id) {
      await this.updateContact();
    } else {
      await this.createContact();
    }
  }
  async createContact() {
    const contact: IGraphContact = cleanObject(this.form.value);
    if (this.companyName && !contact.companyName) { contact.companyName = this.companyName; }
    if (this.department && !contact.department) { contact.department = this.department; }
    if (this.businessHomePage && !contact.businessHomePage) { contact.businessHomePage = this.businessHomePage; }
    contact.fileAs = contact.displayName;
    delete contact.disabled;
    const result = await this.outlook.createContact(contact);
    if (result && result.id) {
      await this.addContact(result.id);
    }
    this.cancelContact();
  }
  async updateContact() {
    const contact: IGraphContact = { ...this.$contact.getValue(), ...this.form.value };
    if (this.companyName && !contact.companyName) { contact.companyName = this.companyName; }
    if (this.department && !contact.department) { contact.department = this.department; }
    if (this.businessHomePage && !contact.businessHomePage) { contact.businessHomePage = this.businessHomePage; }
    contact.fileAs = contact.displayName;
    delete contact.disabled;
    await this.outlook.updateContact(contact);
    this.cancelContact();
  }
  async deleteContact() {
    const contact = this.$contact.getValue();
    if (!contact) { return; }
    const id = contact.id as string;
    await this.outlook.removeContact(id);
    this.removeContact(id);
    this.cancelContact();
  }

  writeValue(contacts: string[]) {
    this.contacts = contacts;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  async focused() {
    /*if (this.$contacts.getValue().length === 0 && !this.$book.getValue()) {
      await this.openBook();
    }*/
  }
}
