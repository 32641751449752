import { Component, OnInit, OnDestroy, Inject, Input, Output, EventEmitter, Injector, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { NzMessageService } from "ng-zorro-antd/message";

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { AuthService } from "@lib/auth/frontend";
import {
  ActionService,
  cleanObject, toInitials,
  CustomValidators, CustomFormatter,
  ISitemap, ISitemapCommand, SITEMAP,
} from "@lib/common/frontend";

import { IGutachten, IPostGutachtenRequest, IPostGutachtenResponse } from "@fa-kt-evaluation/gutachten/types";
import { GutachtenService, PostGutachtenCommandService } from "@fa-kt-evaluation/gutachten/frontend";
import { round } from "lodash";
import { ISelection } from "@lib/persistence/types";
import { ObjekteService } from "@fa-kt-evaluation/objekte/frontend";

/* << importing    */
/*    importing >> */

@Component({
  selector: 'fa-kt-evaluation-post-gutachten',
  templateUrl: './post-gutachten.component.html',
  styleUrls: ['./post-gutachten.component.css']
})
export class PostGutachtenCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  $objekte = new BehaviorSubject<ISelection[]>([]);

  @Input() set loading(loading: boolean) { this.$loading.next(this.$loading.getValue() || loading); }
  @Input() set value(value: IPostGutachtenRequest) { this.patch(value); }
  @Output() valueChanged: EventEmitter<IPostGutachtenRequest> = new EventEmitter<IPostGutachtenRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostGutachtenResponse> = new EventEmitter<IPostGutachtenResponse>();

/* << fields    */
  @Input() set checked(checked: boolean) {
    if (checked && typeof checked === 'boolean') {
      this.gutachtenSicherheitscheck?.patchValue(checked)
    }
  };
  @Output() sicherheitscheck = new EventEmitter<string>();

  @Input() set objekt(objekt: string) {
    if (objekt && typeof objekt === 'string') {
      this.gutachtenObjekt?.patchValue(objekt)
    }
  };
  @Output() selected = new EventEmitter<void>();
/*    fields >> */

  get modalRef() {
    return this.injector.get(NzModalRef)
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public postGutachten: PostGutachtenCommandService,
/* << more    */
    public objekte: ObjekteService,
/*    more >> */
  ) {
    this.command = sitemap.GUTACHTEN.Commands.POST_GUTACHTEN;
    this.form = this.fb.group({
      gutachten: this.fb.group({
        id: [null, []],
        projekt: [null, [Validators.required]],
        objekt: [null, [Validators.required]],
        bewertungsStatus: [null, [Validators.required]],
        objektNutzung: [null, []],
        objektArt: [null, []],
        sicherheitscheck: [null, []],
        abgabeDraft: [null, []],
        abgabeFinal: [null, []],
        datum_besichtigung: [null, []],
        erbbaurecht: [null, []],
        wohnenJRoE: [null, []],
        wohnenJRoEFlaeche: [null, []],
        gewerbeJRoE: [null, []],
        gewerbeJRoEFlaeche: [null, []],
        bueroJRoE: [null, []],
        bueroJRoEFlaeche: [null, []],
        handelJRoE: [null, []],
        handelJRoEFlaeche: [null, []],
        lagerJRoE: [null, []],
        lagerJRoEFlaeche: [null, []],
        sonstigesJRoE: [null, []],
        sonstigesJRoEFlaeche: [null, []],
        baujahr: [null, []],
        modernisierung: [null, []],
        marktwert: [null, []],
        stichtagMarktwert: [null, []],
        kaufpreis: [null, []],
        stichtagKaufpreis: [null, []],
        fortschritt: [null, []],
        aktiv: [null, []],
        kommentar: [null, []],
        tourenplanung: [null, []],
        jahresrohertrag: [null, []],
        flaeche: [null, []],
        gebaeudefaktor: [null, []],
        vervielfaeltiger: [null, []],
        besichtigung_kontakt: [null, []],
        besichtigung_email: [null, []],
        besichtigung_festnetz: [null, []],
        besichtigung_mobil: [null, []]
      })
    });
/* << constructor    */
    this.gutachtenJahresrohertrag?.disable();
    this.gutachtenFlaeche?.disable();
/*    constructor >> */
    this.patch();
  }

  ngOnInit() {
    this.postGutachten.prepare().catch(e => console.error(e));
    this.subscriptions.push(
      this.objekte.$result.subscribe(result => {
        if (!result) { return; }
        this.$objekte.next(result.objekte.map(row => row.objekt).map(objekt => ({
          label: `${objekt.addresse.strasse ? objekt.addresse.strasse + ', ' : ''}${ objekt.addresse.plz ? objekt.addresse.plz + ' ' : ''}${ objekt.addresse.land ? objekt.addresse.land : ''}`,
          value: objekt.id
        })))
      }),
      this.finished.subscribe((result) => { if (this.modalRef) { this.modalRef.destroy(result); }}),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() })
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostGutachtenRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),
      this.gutachtenId?.valueChanges.subscribe(async (id) => {
/* << on-gutachten-id-change    */
/*    on-gutachten-id-change >> */
      }) as Subscription,
      this.gutachtenProjekt?.valueChanges.subscribe(async (projekt) => {
/* << on-gutachten-projekt-change    */
/*    on-gutachten-projekt-change >> */
      }) as Subscription,
      this.gutachtenObjekt?.valueChanges.subscribe(async (objekt) => {
/* << on-gutachten-objekt-change    */
        if (this.gutachtenObjekt?.touched) {
          this.gutachtenSicherheitscheck?.patchValue(false);
        }
/*    on-gutachten-objekt-change >> */
      }) as Subscription,
      this.gutachtenBewertungsStatus?.valueChanges.subscribe(async (bewertungsStatus) => {
/* << on-gutachten-bewertungs-status-change    */
/*    on-gutachten-bewertungs-status-change >> */
      }) as Subscription,
      this.gutachtenObjektNutzung?.valueChanges.subscribe(async (objektNutzung) => {
/* << on-gutachten-objekt-nutzung-change    */
/*    on-gutachten-objekt-nutzung-change >> */
      }) as Subscription,
      this.gutachtenObjektArt?.valueChanges.subscribe(async (objektArt) => {
/* << on-gutachten-objekt-art-change    */
/*    on-gutachten-objekt-art-change >> */
      }) as Subscription,
      this.gutachtenSicherheitscheck?.valueChanges.subscribe(async (sicherheitscheck) => {
/* << on-gutachten-sicherheitscheck-change    */
/*    on-gutachten-sicherheitscheck-change >> */
      }) as Subscription,
      this.gutachtenAbgabeDraft?.valueChanges.subscribe(async (abgabeDraft) => {
/* << on-gutachten-abgabe-draft-change    */
/*    on-gutachten-abgabe-draft-change >> */
      }) as Subscription,
      this.gutachtenAbgabeFinal?.valueChanges.subscribe(async (abgabeFinal) => {
/* << on-gutachten-abgabe-final-change    */
/*    on-gutachten-abgabe-final-change >> */
      }) as Subscription,
      this.gutachtenErbbaurecht?.valueChanges.subscribe(async (erbbaurecht) => {
/* << on-gutachten-erbbaurecht-change    */
/*    on-gutachten-erbbaurecht-change >> */
      }) as Subscription,

      this.gutachtenWohnenJRoE?.valueChanges.subscribe(async (wohnenJRoE) => this.calculateJahresrohertrag()) as Subscription,
      this.gutachtenGewerbeJRoE?.valueChanges.subscribe(async (gewerbeJRoE) => this.calculateJahresrohertrag()) as Subscription,
      this.gutachtenBueroJRoE?.valueChanges.subscribe(async (bueroJRoE) => this.calculateJahresrohertrag()) as Subscription,
      this.gutachtenHandelJRoE?.valueChanges.subscribe(async (handelJRoE) => this.calculateJahresrohertrag()) as Subscription,
      this.gutachtenLagerJRoE?.valueChanges.subscribe(async (lagerJRoE) => this.calculateJahresrohertrag()) as Subscription,
      this.gutachtenSonstigesJRoE?.valueChanges.subscribe(async (sonstigesJRoE) => this.calculateJahresrohertrag()) as Subscription,

      this.gutachtenLagerJRoEFlaeche?.valueChanges.subscribe(async (lagerJRoEFlaeche) => this.calculateFlaeche()) as Subscription,
      this.gutachtenWohnenJRoEFlaeche?.valueChanges.subscribe(async (wohnenJRoEFlaeche) => this.calculateFlaeche()) as Subscription,
      this.gutachtenGewerbeJRoEFlaeche?.valueChanges.subscribe(async (gewerbeJRoEFlaeche) => this.calculateFlaeche()) as Subscription,
      this.gutachtenBueroJRoEFlaeche?.valueChanges.subscribe(async (bueroJRoEFlaeche) => this.calculateFlaeche()) as Subscription,
      this.gutachtenHandelJRoEFlaeche?.valueChanges.subscribe(async (handelJRoEFlaeche) => this.calculateFlaeche()) as Subscription,
      this.gutachtenSonstigesJRoEFlaeche?.valueChanges.subscribe(async (sonstigesJRoEFlaeche) => this.calculateFlaeche()) as Subscription,

      this.gutachtenKaufpreis?.valueChanges.subscribe(async (kaufpreis) => this.calculateVervielfaeltiger()) as Subscription,
      this.gutachtenJahresrohertrag?.valueChanges.subscribe(async (jahresrohertrag) => this.calculateVervielfaeltiger()) as Subscription,

      this.gutachtenFlaeche?.valueChanges.subscribe(() => this.calculateGebaeudefaktor()) as Subscription,
      this.gutachtenMarktwert?.valueChanges.subscribe(() => this.calculateGebaeudefaktor()) as Subscription,

      this.gutachtenBaujahr?.valueChanges.subscribe(async (baujahr) => {
/* << on-gutachten-baujahr-change    */
/*    on-gutachten-baujahr-change >> */
      }) as Subscription,
      this.gutachtenModernisierung?.valueChanges.subscribe(async (modernisierung) => {
/* << on-gutachten-modernisierung-change    */
/*    on-gutachten-modernisierung-change >> */
      }) as Subscription,
      this.gutachtenMarktwert?.valueChanges.subscribe(async (marktwert) => {
/* << on-gutachten-marktwert-change    */
/*    on-gutachten-marktwert-change >> */
      }) as Subscription,
      this.gutachtenStichtagMarktwert?.valueChanges.subscribe(async (stichtagMarktwert) => {
/* << on-gutachten-stichtag-marktwert-change    */
/*    on-gutachten-stichtag-marktwert-change >> */
      }) as Subscription,

      this.gutachtenStichtagKaufpreis?.valueChanges.subscribe(async (stichtagKaufpreis) => {
/* << on-gutachten-stichtag-kaufpreis-change    */
/*    on-gutachten-stichtag-kaufpreis-change >> */
      }) as Subscription,
      this.gutachtenFortschritt?.valueChanges.subscribe(async (fortschritt) => {
/* << on-gutachten-fortschritt-change    */
/*    on-gutachten-fortschritt-change >> */
      }) as Subscription,
      this.gutachtenAktiv?.valueChanges.subscribe(async (aktiv) => {
/* << on-gutachten-aktiv-change    */
/*    on-gutachten-aktiv-change >> */
      }) as Subscription,
      this.gutachtenKommentar?.valueChanges.subscribe(async (kommentar) => {
/* << on-gutachten-kommentar-change    */
/*    on-gutachten-kommentar-change >> */
      }) as Subscription,
      this.gutachtenTourenplanung?.valueChanges.subscribe(async (tourenplanung) => {
/* << on-gutachten-tourenplanung-change    */
        if (tourenplanung) {
          this.form.controls.gutachten.get('datum_besichtigung')?.patchValue('');
        }
/*    on-gutachten-tourenplanung-change >> */
      }) as Subscription,
/* << subscibe    */
/*    subscibe >> */
    );
/* << init    */
/*    init >> */
  }

  ngOnDestroy() {
/* << end    */
/*    end >> */
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IPostGutachtenRequest) {
    if (value) {
      value = cleanObject(value);
      if (value.gutachten) {
        value.gutachten = cleanObject(value.gutachten);
      }
      this.form.patchValue(value);
/* << custom    */
/*    custom >> */
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload.gutachten) {
        payload.gutachten = cleanObject(payload.gutachten);
      }
      const response: IPostGutachtenResponse = await this.postGutachten.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of (error.errors ? error.errors : [])) {
        for (const children of (validation.children ? validation.children : [])) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

/* << methods    */
  check() {
    this.sicherheitscheck.emit(this.gutachtenObjekt?.value);
  }

  calculateJahresrohertrag() {
    const gutachten: IGutachten = this.form.getRawValue().gutachten;
    const flaeche =
      Number(gutachten.wohnenJRoE) +
      Number(gutachten.bueroJRoE) +
      Number(gutachten.gewerbeJRoE) +
      Number(gutachten.lagerJRoE) +
      Number(gutachten.handelJRoE) +
      Number(gutachten.sonstigesJRoE);
    this.gutachtenJahresrohertrag?.patchValue(round(flaeche, 2));
  }

  calculateFlaeche() {
    const gutachten: IGutachten = this.form.getRawValue().gutachten;
    const flaeche =
      Number(gutachten.wohnenJRoEFlaeche) +
      Number(gutachten.bueroJRoEFlaeche) +
      Number(gutachten.gewerbeJRoEFlaeche) +
      Number(gutachten.lagerJRoEFlaeche) +
      Number(gutachten.handelJRoEFlaeche) +
      Number(gutachten.sonstigesJRoEFlaeche);
    this.gutachtenFlaeche?.patchValue(round(flaeche, 2));
  }


  calculateVervielfaeltiger() {
    const gutachten: IGutachten = this.form.getRawValue().gutachten;
    if (gutachten.kaufpreis && gutachten.jahresrohertrag) {
      const vervielfaeltiger = Number(gutachten.kaufpreis) / Number(gutachten.jahresrohertrag);
      this.gutachtenVerfielfaeltiger?.patchValue(round(vervielfaeltiger, 2));
    }
  }

  calculateGebaeudefaktor() {
    const gutachten: IGutachten = this.form.getRawValue().gutachten;
    if (gutachten.marktwert && gutachten.flaeche) {
      const gebaeudefaktor = Number(gutachten.marktwert) / Number(gutachten.flaeche);
      this.gutachtenGebaeudefaktor?.patchValue(round(gebaeudefaktor, 2));
    }
  }
/*    methods >> */

  get gutachtenId() {
    return this.form.controls.gutachten.get('id');
  };
  get gutachtenProjekt() {
    return this.form.controls.gutachten.get('projekt');
  };
  get gutachtenObjekt() {
    return this.form.controls.gutachten.get('objekt');
  };
  get gutachtenBewertungsStatus() {
    return this.form.controls.gutachten.get('bewertungsStatus');
  };
  get gutachtenObjektNutzung() {
    return this.form.controls.gutachten.get('objektNutzung');
  };
  get gutachtenObjektArt() {
    return this.form.controls.gutachten.get('objektArt');
  };
  get gutachtenSicherheitscheck() {
    return this.form.controls.gutachten.get('sicherheitscheck');
  };
  get gutachtenAbgabeDraft() {
    return this.form.controls.gutachten.get('abgabeDraft');
  };
  get gutachtenAbgabeFinal() {
    return this.form.controls.gutachten.get('abgabeFinal');
  };
  get gutachtenErbbaurecht() {
    return this.form.controls.gutachten.get('erbbaurecht');
  };
  get gutachtenWohnenJRoE() {
    return this.form.controls.gutachten.get('wohnenJRoE');
  };
  get gutachtenWohnenJRoEFlaeche() {
    return this.form.controls.gutachten.get('wohnenJRoEFlaeche');
  };
  get gutachtenGewerbeJRoE() {
    return this.form.controls.gutachten.get('gewerbeJRoE');
  };
  get gutachtenGewerbeJRoEFlaeche() {
    return this.form.controls.gutachten.get('gewerbeJRoEFlaeche');
  };
  get gutachtenBueroJRoE() {
    return this.form.controls.gutachten.get('bueroJRoE');
  };
  get gutachtenBueroJRoEFlaeche() {
    return this.form.controls.gutachten.get('bueroJRoEFlaeche');
  };
  get gutachtenHandelJRoE() {
    return this.form.controls.gutachten.get('handelJRoE');
  };
  get gutachtenHandelJRoEFlaeche() {
    return this.form.controls.gutachten.get('handelJRoEFlaeche');
  };
  get gutachtenLagerJRoE() {
    return this.form.controls.gutachten.get('lagerJRoE');
  };
  get gutachtenLagerJRoEFlaeche() {
    return this.form.controls.gutachten.get('lagerJRoEFlaeche');
  };
  get gutachtenSonstigesJRoE() {
    return this.form.controls.gutachten.get('sonstigesJRoE');
  };
  get gutachtenSonstigesJRoEFlaeche() {
    return this.form.controls.gutachten.get('sonstigesJRoEFlaeche');
  };
  get gutachtenBaujahr() {
    return this.form.controls.gutachten.get('baujahr');
  };
  get gutachtenModernisierung() {
    return this.form.controls.gutachten.get('modernisierung');
  };
  get gutachtenMarktwert() {
    return this.form.controls.gutachten.get('marktwert');
  };
  get gutachtenStichtagMarktwert() {
    return this.form.controls.gutachten.get('stichtagMarktwert');
  };
  get gutachtenKaufpreis() {
    return this.form.controls.gutachten.get('kaufpreis');
  };
  get gutachtenStichtagKaufpreis() {
    return this.form.controls.gutachten.get('stichtagKaufpreis');
  };
  get gutachtenFortschritt() {
    return this.form.controls.gutachten.get('fortschritt');
  };
  get gutachtenAktiv() {
    return this.form.controls.gutachten.get('aktiv');
  };
  get gutachtenKommentar() {
    return this.form.controls.gutachten.get('kommentar');
  };
  get gutachtenTourenplanung() {
    return this.form.controls.gutachten.get('tourenplanung');
  };
  get gutachtenJahresrohertrag() {
    return this.form.controls.gutachten.get('jahresrohertrag');
  };
  get gutachtenFlaeche() {
    return this.form.controls.gutachten.get('flaeche');
  };
  get gutachtenGebaeudefaktor() {
    return this.form.controls.gutachten.get('gebaeudefaktor');
  };
  get gutachtenVerfielfaeltiger() {
    return this.form.controls.gutachten.get('vervielfaeltiger');
  };
  get gutachtenEmail() {
    return this.form.controls.gutachten.get('besichtigung_email');
  };
  get gutachtenFestnetz() {
    return this.form.controls.gutachten.get('vervielfaeltiger');
  };
  get gutachtenMobil() {
    return this.form.controls.gutachten.get('vervielfaeltiger');
  };
}
