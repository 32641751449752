import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostGutachtenCommandServiceModule } from '../';
import { PostGutachtenCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostGutachtenCommandServiceModule
  ],
  declarations: [PostGutachtenCommandComponent],
  exports: [PostGutachtenCommandComponent],

})
export class PostGutachtenCommandComponentModule { }
