import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { BerichtsVorlagenServiceModule } from '../';
import { BerichtsVorlagenSorterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    BerichtsVorlagenServiceModule
  ],
  declarations: [BerichtsVorlagenSorterComponent],
  exports: [BerichtsVorlagenSorterComponent],

})
export class BerichtsVorlagenSorterComponentModule { }
