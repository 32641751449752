import { NgModule } from '@angular/core';
import {
  VorlagenFilterComponentModule,
  VorlagenListComponentModule,
  VorlagenSorterComponentModule,
  SavedVorlagenFiltersComponentModule
} from './';

@NgModule({
  imports: [
    VorlagenFilterComponentModule,
    VorlagenListComponentModule,
    VorlagenSorterComponentModule,
    SavedVorlagenFiltersComponentModule
  ],
  exports: [
    VorlagenFilterComponentModule,
    VorlagenListComponentModule,
    VorlagenSorterComponentModule,
    SavedVorlagenFiltersComponentModule
  ],
})
export class VorlagenQueryModule { }
