import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostKalkulationHochladenCommandService } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [PostKalkulationHochladenCommandService],
  exports: []
})
export class PostKalkulationHochladenCommandServiceModule { }
