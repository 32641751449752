import { NgModule } from '@angular/core';
import {
  TourFilterComponentModule,
  TourDetailComponentModule,
  TourSorterComponentModule,
  SavedTourFiltersComponentModule
} from './';

@NgModule({
  imports: [
    TourFilterComponentModule,
    TourDetailComponentModule,
    TourSorterComponentModule,
    SavedTourFiltersComponentModule
  ],
  exports: [
    TourFilterComponentModule,
    TourDetailComponentModule,
    TourSorterComponentModule,
    SavedTourFiltersComponentModule
  ],
})
export class TourQueryModule { }
