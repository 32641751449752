import { Component } from '@angular/core';
import { BerichtsVorlagenService } from "@fa-kt-evaluation/texte/frontend";

/* << imports    */
 
/*    imports >> */
@Component({
  selector: 'fa-kt-evaluation-saved-berichts-vorlagen-filters',
  templateUrl: './saved-berichts-vorlagen-filters.component.html',
  styleUrls: ['./saved-berichts-vorlagen-filters.component.css']
})
export class SavedBerichtsVorlagenFiltersComponent {

  constructor(
    public berichtsVorlagen: BerichtsVorlagenService
/* << injection    */
/*    injection >> */
  ) {
  }
}