import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostZeileCommandServiceModule } from '../';
import { PostZeileCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostZeileCommandServiceModule
  ],
  declarations: [PostZeileCommandComponent],
  exports: [PostZeileCommandComponent],

})
export class PostZeileCommandComponentModule { }
