import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { DeleteHonorarVereinbarungCommandServiceModule } from '../';
import { DeleteHonorarVereinbarungCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteHonorarVereinbarungCommandServiceModule
  ],
  declarations: [DeleteHonorarVereinbarungCommandComponent],
  exports: [DeleteHonorarVereinbarungCommandComponent],

})
export class DeleteHonorarVereinbarungCommandComponentModule { }
