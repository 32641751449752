import { NgModule } from '@angular/core';
import { 
  PostDateiHochladenCommandModule,
  PostDokumentHinterlegenCommandModule
} from './';

@NgModule({
  declarations: [],
  imports: [
    PostDateiHochladenCommandModule,
    PostDokumentHinterlegenCommandModule
  ],
  exports: [
    PostDateiHochladenCommandModule,
    PostDokumentHinterlegenCommandModule
  ]
})
export class DokumenteCommandsModule { }
