<nz-layout>
<!--- << app    -->
  <nz-sider nzTheme="light" nzCollapsible [(nzCollapsed)]="isCollapsed" nzWidth="264px" style="position: fixed; height: 100vh; z-index: 112">
    <img src="assets/fakt.png" class="logo" (click)="actions.open()" alt="FA-KT Logo" style="cursor: pointer"/>
    <ul nz-menu nzTheme="light" nzMode="inline">
      <ng-container *ngFor="let section of $menu | async" [ngSwitch]="section.children.length">
        <li *ngSwitchCase="0" nz-menu-item>
          <ng-container [ngSwitch]="section.url && section.url.length > 0">
            <a title *ngSwitchCase="true" [routerLink]="section.url" nz-tooltip [nzTooltipTitle]="section.title" nzTooltipPlacement="right" nzTooltipColor="#004D7B" nzTooltipMouseEnterDelay="0.75">
              {{ section.emoji }}
              <span nz-typography *ngIf="!isCollapsed">{{ section.title }}</span>
            </a>
            <span title *ngSwitchDefault>
                {{ section.emoji }}
              <span nz-typography *ngIf="!isCollapsed">{{ section.title }}</span>
            </span>
          </ng-container>
        </li>
        <li *ngSwitchDefault nz-submenu [nzOpen]="!isCollapsed">
            <span title>
              {{ section.emoji }}
              <span nz-typography *ngIf="!isCollapsed">{{ section.title }}</span>
            </span>
          <ul>
            <li *ngFor="let entry of section.children" nz-menu-item [routerLink]="entry.url">
                <span title>
                  {{ entry.emoji }}
                  <span nz-typography>{{ entry.title }}</span>
                </span>
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>
  </nz-sider>
  <nz-layout (document:keydown.control.space)="actions.open()">
    <nz-header [style.background]="'#fff'" style="position: fixed; height: 64px; left: 264px; z-index: 111;" [style.width]="isCollapsed ? 'calc(100vw - 80px)': 'calc(100vw - 264px)'" [style.left]="isCollapsed ? '80px': '264px'">
      <ul nz-menu nzTheme="light" nzMode="horizontal">
        <li nz-menu-item (click)="actions.open()">
          <i nz-icon nzType="thunderbolt" nzTheme="outline"></i>
        </li>
        <codeboard-auth-popup-menu nz-menu-item style="float: right;"></codeboard-auth-popup-menu>
        <li  *ngIf="auth.$id | async" nz-menu-item style="float: right; margin-right: 16px;" nz-popover [nzPopoverContent]="AddTime" [(nzPopoverVisible)]="nzPopoverVisible" [nzPopoverTrigger]="($slot | async) ? null : 'hover'">
          <span title>
            <ng-container [ngSwitch]="!!($slot | async)">
              <ng-container *ngSwitchCase="true" [ngSwitch]="!($slot | async).paused">
                <button nz-button nzType="link" (click)="toggleClock()">
                  <i nz-icon nzType="check-circle" nzTheme="fill"></i>
                </button>
                <ng-container *ngSwitchCase="true">
                  <span nz-typography (click)="toggleClock()">
                    Arbeit: {{ ($working | async) }}
                    <nz-divider nzType="vertical"></nz-divider>
                  </span>
                  <span nz-typography nzType="secondary" (click)="toggleBreak()">
                    Pause: {{ ($pausing | async) }}
                  </span>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <span nz-typography (click)="toggleBreak()">
                    Pause: {{ ($pausing | async) }}
                    <nz-divider nzType="vertical"></nz-divider>
                  </span>
                  <span nz-typography nzType="secondary" (click)="toggleClock()">
                    Arbeit: {{ ($working | async) }}
                  </span>
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <i nz-icon nzType="clock-circle" nzTheme="outline" (click)="startClock()"></i>
              </ng-container>
            </ng-container>
          </span>
          <ng-template #AddTime>
          <ul nz-menu nzTheme="light" nzMode="inline">
            <li nz-menu-item>
              <h3 nz-title>
                Zeitslot hinzufügen
              </h3>
            </li>
            <li nz-menu-item (click)="addTimeSlot(10 * 60)">
            <span nz-title>
              <span nz-typography><i nz-icon nzType="plus" nzTheme="outline"></i></span>
              10 Minuten
            </span>
            </li>
            <li nz-menu-item (click)="addTimeSlot(30 * 60)">
            <span nz-title>
              <span nz-typography><i nz-icon nzType="plus" nzTheme="outline"></i></span>
              30 Minuten
            </span>
            </li>
            <li nz-menu-item (click)="addTimeSlot(1 * 60 * 60)">
            <span nz-title>
              <span nz-typography><i nz-icon nzType="plus" nzTheme="outline"></i></span>
              1 Stunde
            </span>
            </li>
            <li nz-menu-item (click)="addTimeSlot(4  * 60 * 60)">
            <span nz-title>
              <span nz-typography><i nz-icon nzType="plus" nzTheme="outline"></i></span>
              4 Stunden
            </span>
            </li>
            <li nz-menu-item (click)="addTimeSlot(7.5  * 60 * 60)">
            <span nz-title>
              <span nz-typography><i nz-icon nzType="plus" nzTheme="outline"></i></span>
              7,5 Stunden
            </span>
            </li>
          </ul>
        </ng-template>
        <fa-kt-evaluation-zeit [seconds]="$seconds | async"  [style.width]="'100%'" ></fa-kt-evaluation-zeit>
        </li>
        <li *ngIf="offlineCache.http.$offline | async" style="float: right; margin-right: 16px;" nz-menu-item
            nz-tooltip nzTooltipTitle="Das Gerät ist offline" nzTooltipPlacement="left" nzTooltipColor="blue">
          <i nz-icon nzType="disconnect" nzTheme="outline"></i>
        </li>
      </ul>
    </nz-header>
    <nz-content class="outer-content" style="margin-top: 64px;" [style.marginLeft]="isCollapsed ? '80px': '264px'">
      <nz-layout class="inner-layout">
        <nz-content class="inner-content">
          <div *ngIf="resolverLoader.$resolving | async">
            <codeboard-loading-spinner></codeboard-loading-spinner>
          </div>
          <router-outlet></router-outlet>
        </nz-content>
      </nz-layout>
    </nz-content>
  </nz-layout>
<!---    app >> -->
</nz-layout>
