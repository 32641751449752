import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { first, map, debounceTime, distinctUntilChanged } from "rxjs/operators";

import { isEqual } from "lodash";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { IPersistedFilter, IPersistedFilterList } from "@lib/filter/types";
import { ISelection } from "@lib/persistence/types";

import {
  IIchRequest,
  IIchResponse,
  IIchResponseGroup,
  IIchQueryFilterShapes
} from '@fa-kt-evaluation/personen/types';

@Injectable({
  providedIn: 'root'
})
export class IchService {

  protected route = 'personen/ich';
  protected dependencies = [
    'personen/written-rolle',
    'personen/removed-rolle',
    'personen/written-standort',
    'personen/removed-standort',

    'besichtigungen/besichtigung-entfernt',
    'besichtigungen/besichtigung-geordnet',
    'besichtigungen/besichtigung-gespeichert',
    'besichtigungen/tour-entfernt',
    'besichtigungen/tour-gespeichert',
    'besichtigungen/tour-gestartet',
    'finanzen/reisekostenabrechnung-entfernt',
    'finanzen/reisekostenabrechnung-gespeichert',
    'gutachten/gutachten-entfernt',
    'gutachten/gutachten-gespeichert',
    'kommunikation/erinnerung-abgemeldet',
    'kommunikation/erinnerung-entfernt',
    'kommunikation/erinnerung-gemerkt',
    'kommunikation/vermerk-entfernt',
    'kommunikation/vermerkt',
    'personen/mitarbeiter-aktualisiert',
    'projekte/projekt-archiviert',
    'projekte/projekt-entfernt',
    'projekte/projekt-gespeichert',
    'projekte/projekt-kopiert',
    'projekte/projekt-reaktiviert',
    'zeiten/zeit-entfernt',
    'zeiten/zeit-gespeichert'
  ];

  readonly $result = new BehaviorSubject<IIchResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IIchRequest|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IIchQueryFilterShapes|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private device: DeviceConfigService,
  ) {
/* << changes    */
    this.dependencies = [...this.dependencies, 'personen/azure-active-directory-synchronisiert' ]
/*    changes >> */
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IIchRequest): IIchRequest {
    if (payload) {
      this.$payload.next(payload as IIchRequest);
    } else {
      payload = this.$payload.getValue() as IIchRequest;
    }
    return payload;
  }

  public async request(payload: IIchRequest, force = false): Promise<IIchResponse> {
    const state = this.$result.getValue();
    if (!force && !this.device.alwaysRefresh && state && isEqual(payload, this.payload())) { return state; }
    payload = this.payload(payload);
    // if (!payload) { return this.$result.getValue() as IIchResponse; }
    const query = this.http.serialize(payload);
    const { response, shapes } = await this.http.get<IIchResponseGroup>(this.route + query);
    this.$result.next(response);
    this.$shapes.next(shapes);
    return response;
  }



}
