import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { ZeitenServiceModule } from '../';
import { ZeitenListComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ZeitenServiceModule
  ],
  declarations: [ZeitenListComponent],
  exports: [ZeitenListComponent],

})
export class ZeitenListComponentModule { }
