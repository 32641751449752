<!---
  <div formGroupName="zeit">
    <input class="cdk-visually-hidden" formControlName="id" id="id"/>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="mitarbeiter">{{ command.Inputs.MITARBEITER.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MitarbeiterErrorTip">
        <ng-template #MitarbeiterErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MITARBEITER.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="mitarbeiter" id="mitarbeiter" [shapes]="postZeit.zeitMitarbeiter | async" [placeholder]="command.Inputs.MITARBEITER.placeholder" cdkFocusInitial required=true ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="projekt">{{ command.Inputs.PROJEKT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ProjektErrorTip">
        <ng-template #ProjektErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.PROJEKT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="projekt" id="projekt" [shapes]="postZeit.zeitProjekt | async" [placeholder]="command.Inputs.PROJEKT.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="gutachten">{{ command.Inputs.GUTACHTEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="GutachtenErrorTip">
        <ng-template #GutachtenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.GUTACHTEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value mode="multiple" formControlName="gutachten" id="gutachten" [shapes]="postZeit.zeitGutachten | async" [placeholder]="command.Inputs.GUTACHTEN.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kunde">{{ command.Inputs.KUNDE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KundeErrorTip">
        <ng-template #KundeErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KUNDE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="kunde" id="kunde" [shapes]="postZeit.zeitKunde | async" [placeholder]="command.Inputs.KUNDE.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="zeitArt">{{ command.Inputs.ZEIT_ART.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ZeitArtErrorTip">
        <ng-template #ZeitArtErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ZEIT_ART.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="zeitArt" id="zeitArt" [shapes]="postZeit.zeitZeitArt | async" [placeholder]="command.Inputs.ZEIT_ART.placeholder" required=true ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="zeitUnterart">{{ command.Inputs.ZEIT_UNTERART.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ZeitUnterartErrorTip">
        <ng-template #ZeitUnterartErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ZEIT_UNTERART.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="zeitUnterart" id="zeitUnterart" [shapes]="postZeit.zeitZeitUnterart | async" [placeholder]="command.Inputs.ZEIT_UNTERART.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="datum">{{ command.Inputs.DATUM.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DatumErrorTip">
        <ng-template #DatumErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.DATUM.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <nz-date-picker formControlName="datum" id="datum"  nzFormat="dd.MM.yyyy"></nz-date-picker>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="stunden">{{ command.Inputs.STUNDEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="StundenErrorTip">
        <ng-template #StundenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STUNDEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="stunden" id="stunden" [nzPlaceHolder]="command.Inputs.STUNDEN.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="minuten">{{ command.Inputs.MINUTEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MinutenErrorTip">
        <ng-template #MinutenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MINUTEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="minuten" id="minuten" [nzPlaceHolder]="command.Inputs.MINUTEN.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kommentar">{{ command.Inputs.KOMMENTAR.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KommentarErrorTip">
        <ng-template #KommentarErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KOMMENTAR.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <textarea nz-input formControlName="kommentar" id="kommentar" [placeholder]="command.Inputs.KOMMENTAR.placeholder"></textarea>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kosten">{{ command.Inputs.KOSTEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KostenErrorTip">
        <ng-template #KostenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KOSTEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="kosten" id="kosten" [nzPlaceHolder]="command.Inputs.KOSTEN.placeholder"/>
      </nz-form-control>
    </nz-form-item>
  </div>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
<!--- << custom    -->
    <div formGroupName="zeit">
      <input class="cdk-visually-hidden" formControlName="id" id="id"/>
      <input class="cdk-visually-hidden" formControlName="gutachten" id="gutachten"/>
      <input class="cdk-visually-hidden" formControlName="kosten" id="kosten"/>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="mitarbeiter">{{ command.Inputs.MITARBEITER.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MitarbeiterErrorTip">
          <ng-template #MitarbeiterErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MITARBEITER.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <codeboard-select-value formControlName="mitarbeiter" id="mitarbeiter" [shapes]="postZeit.zeitMitarbeiter | async" [placeholder]="command.Inputs.MITARBEITER.placeholder" cdkFocusInitial required=true ></codeboard-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="zeitArt">{{ command.Inputs.ZEIT_ART.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ZeitArtErrorTip">
          <ng-template #ZeitArtErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ZEIT_ART.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <codeboard-select-value formControlName="zeitArt" id="zeitArt" [shapes]="(postZeit.$shapes | async)?.['zeit.zeitArt']" [placeholder]="command.Inputs.ZEIT_ART.placeholder" required=true ></codeboard-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="zeitUnterart">{{ command.Inputs.ZEIT_UNTERART.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ZeitUnterartErrorTip">
          <ng-template #ZeitUnterartErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ZEIT_UNTERART.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <codeboard-select-value [filter]="zeitZeitArt?.value" formControlName="zeitUnterart" id="zeitUnterart" [shapes]="(postZeit.$shapes | async)?.['zeit.zeitUnterart']" [placeholder]="command.Inputs.ZEIT_UNTERART.placeholder" ></codeboard-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="datum">{{ command.Inputs.DATUM.title}}</nz-form-label>
        <nz-form-control [nzSm]="4" [nzXs]="24" [nzErrorTip]="DatumErrorTip">
          <ng-template #DatumErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.DATUM.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <nz-date-picker formControlName="datum" id="datum"  nzFormat="dd.MM.yyyy"></nz-date-picker>
        </nz-form-control>
        <nz-form-label [nzSm]="4" [nzXs]="24" nzFor="datum">Wiederholen bis</nz-form-label>
        <nz-form-control [nzSm]="4" [nzXs]="24" [nzErrorTip]="DatumErrorTip">
        <ng-template #DatumErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.DATUM.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
        <nz-date-picker formControlName="wiederholenBis" id="wiederholenBis"  nzFormat="dd.MM.yyyy"></nz-date-picker>
      </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%;">
        <nz-form-control [nzOffset]="8" [nzSm]="6" [nzXs]="24" nzRequired  [nzSm]="3" [nzXs]="24" [nzErrorTip]="StundenErrorTip">
          <ng-template #StundenErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STUNDEN.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" style="width: 75px" formControlName="stunden" id="stunden" [placeholder]="command.Inputs.STUNDEN.placeholder" max="12"/> S
        </nz-form-control>
        <nz-form-control [nzSm]="3" [nzXs]="24" [nzErrorTip]="MinutenErrorTip">
          <ng-template #MinutenErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MINUTEN.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" style="width: 75px" formControlName="minuten" id="minuten" [placeholder]="command.Inputs.MINUTEN.placeholder" max="59"/> M
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kunde">{{ command.Inputs.KUNDE.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KundeErrorTip">
          <ng-template #KundeErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KUNDE.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <codeboard-select-value formControlName="kunde" id="kunde" [shapes]="(postZeit.$shapes | async)?.['zeit.kunde']" [placeholder]="command.Inputs.KUNDE.placeholder" ></codeboard-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="projekt">{{ command.Inputs.PROJEKT.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ProjektErrorTip">
          <ng-template #ProjektErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.PROJEKT.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <codeboard-select-value [filter]="zeitKunde?.value" formControlName="projekt" id="projekt" [shapes]="$projekte | async" [placeholder]="command.Inputs.PROJEKT.placeholder" ></codeboard-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kommentar">{{ command.Inputs.KOMMENTAR.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KommentarErrorTip">
          <ng-template #KommentarErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KOMMENTAR.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <textarea nz-input formControlName="kommentar" id="kommentar" [placeholder]="command.Inputs.KOMMENTAR.placeholder"></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>
<!---    custom >> -->
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button"
          type="submit"
          [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)"
          nz-tooltip [nzTooltipTitle]="SubmitTooltip">
<!--- << confirm    -->
          <i nz-icon nzType="clock-circle" nzTheme="outline"></i>
          Zeit eintragen
<!---    confirm >> -->
          <ng-template #SubmitTooltip>
<!--- << hint    -->
<!---    hint >> -->
          </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
