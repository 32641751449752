import { NgModule } from '@angular/core';

import {
  PostReisekostenabrechnungCommandServiceModule,
  PostReisekostenabrechnungCommandComponentModule
} from './';

@NgModule({
  imports: [
    PostReisekostenabrechnungCommandServiceModule,
    PostReisekostenabrechnungCommandComponentModule
  ],
  exports: [
    PostReisekostenabrechnungCommandServiceModule,
    PostReisekostenabrechnungCommandComponentModule
  ]
})
export class PostReisekostenabrechnungCommandModule { }
