export enum DevicePerformance {
  S,
  M,
  L
}

export interface IDeviceConfig {
  devicePerformance: DevicePerformance;
  alwaysRefresh: boolean;
}
