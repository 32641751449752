import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from "@lib/auth/frontend";

@Component({
  selector: 'codeboard-unauthorized',
  templateUrl: './unauthorized.page.html',
  styleUrls: ['./unauthorized.page.css']
})
export class UnauthorizedPage implements OnDestroy, OnInit{
  constructor(public auth: AuthService) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

}
