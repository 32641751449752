import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { ObjekteServiceModule } from '../';
import { ObjekteListComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ObjekteServiceModule
  ],
  declarations: [ObjekteListComponent],
  exports: [ObjekteListComponent],

})
export class ObjekteListComponentModule { }
