import { Injectable } from '@angular/core';
import { AbstractControl } from "@angular/forms";

import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, first, map } from "rxjs/operators";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { FilterPipe, SearchPipe, SorterPipe } from "@lib/filter/frontend";
import { ISelection } from "@lib/persistence/types";

import {
  IProjektStatiRequest,
  IProjektStatiResponse,
  IProjektStatiQuerySorter,
  IProjektStatiQueryFilter,
  IProjektStatiQueryFilterShapes,
  IProjektStatiResponseGroup,
} from '@fa-kt-evaluation/projekte/types';

@Injectable({
  providedIn: 'root'
})
export class ProjektStatiService {

  protected route = 'projekte/settings/projekt-stati';
  protected dependencies = [
    'projekte/written-projekt-status',
    'projekte/removed-projekt-status',
  ];

  readonly $result = new BehaviorSubject<IProjektStatiResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IProjektStatiRequest|undefined>(undefined);
  readonly $response = new BehaviorSubject<IProjektStatiResponse|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IProjektStatiQueryFilterShapes|undefined>(undefined);
  readonly $filter = new BehaviorSubject<IProjektStatiQueryFilter>({});
  readonly $sorter = new BehaviorSubject<IProjektStatiQuerySorter>({});
  readonly $search = new BehaviorSubject<string>('');
  private readonly $filtered = new BehaviorSubject<IProjektStatiResponse|undefined>(undefined);
  private readonly $searched = new BehaviorSubject<IProjektStatiResponse|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private search: SearchPipe,
    private filter: FilterPipe,
    private sorter: SorterPipe,
    private device: DeviceConfigService
  ) {
    combineLatest([
      this.$response,
      this.$filter,
    ]).subscribe(([response, filter]) => {
      if (response && response.projektStati) {
        const projektStati = this.filter.transform(response.projektStati, filter, true);
        this.$filtered.next( { ...response, projektStati });
      } else {
        this.$filtered.next(response);
      }
    });
    combineLatest([
      this.$filtered,
      this.$search.pipe(debounceTime(device.debounceTime), distinctUntilChanged()),
      this.$shapes
    ]).subscribe(([filtered, search, shapes]) => {
      if (filtered && filtered.projektStati) {
        const projektStati = this.search.transform(filtered.projektStati, search, '_search', shapes);
        this.$searched.next( { ...filtered, projektStati });
      } else {
        this.$searched.next(filtered);
      }
    });
    combineLatest([
      this.$searched,
      this.$sorter,
      this.$shapes
    ]).pipe(debounceTime(10)).subscribe(([searched, sorter, shapes]) => {
      if (searched && searched.projektStati) {
        const projektStati = this.sorter.transform(searched.projektStati, sorter, shapes);
        this.$result.next( { ...searched, projektStati });
      } else {
        this.$result.next(searched);
      }
    });
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IProjektStatiRequest): IProjektStatiRequest {
    if (payload) {
      this.$payload.next(payload as IProjektStatiRequest);
    } else {
      payload = this.$payload.getValue() as IProjektStatiRequest;
    }
    return payload;
  }

  public async request(payload?: IProjektStatiRequest, force = false): Promise<IProjektStatiResponse> {
    payload = this.payload(payload);
    const state = this.$response.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const { response, shapes } = await this.http.get<IProjektStatiResponseGroup>(this.route + this.http.serialize(payload));
    this.$shapes.next(shapes);
    this.$response.next(response);
    return response;
  }
  


}
