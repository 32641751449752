import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IPostSortiereAbschnitteRequest, IPostSortiereAbschnitteResponse, IPostSortiereAbschnitteCommandShapes } from '@fa-kt-evaluation/texte/types';

@Injectable({
  providedIn: 'root'
})
export class PostSortiereAbschnitteCommandService {

  readonly route = 'texte/sortiere-abschnitte';
  protected dependencies = [
      'texte/abschnitte-sortiert'
  ];

  readonly $shapes = new BehaviorSubject<IPostSortiereAbschnitteCommandShapes|undefined>(undefined);
  
  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IPostSortiereAbschnitteCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IPostSortiereAbschnitteCommandShapes>(this.route + '/post/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IPostSortiereAbschnitteRequest): Promise<IPostSortiereAbschnitteResponse> {
    return await this.http.post<IPostSortiereAbschnitteResponse>(this.route, payload) as unknown as IPostSortiereAbschnitteResponse;
  }
  
  getAbschnitteVorlage(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['abschnitte.vorlage']?.find(v => v.value === value)
  }

  get abschnitteVorlage(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['abschnitte.vorlage'] as ISelection[] : []));
  }

}
