import { NgModule } from '@angular/core';

import {
  DeleteBerichtCommandServiceModule,
  DeleteBerichtCommandComponentModule
} from './';

@NgModule({
  imports: [
    DeleteBerichtCommandServiceModule,
    DeleteBerichtCommandComponentModule
  ],
  exports: [
    DeleteBerichtCommandServiceModule,
    DeleteBerichtCommandComponentModule
  ]
})
export class DeleteBerichtCommandModule { }
