import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { DeleteVermerkCommandService } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [DeleteVermerkCommandService],
  exports: []
})
export class DeleteVermerkCommandServiceModule { }
