import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { ZeitServiceModule } from '../';
import { ZeitFilterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ZeitServiceModule
  ],
  declarations: [ZeitFilterComponent],
  exports: [ZeitFilterComponent],

})
export class ZeitFilterComponentModule { }
