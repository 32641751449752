import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { first, map, debounceTime, distinctUntilChanged } from "rxjs/operators";

import { isEqual } from "lodash";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { IPersistedFilter, IPersistedFilterList } from "@lib/filter/types";
import { ISelection } from "@lib/persistence/types";

import {
  ITourRequest,
  ITourResponse,
  ITourResponseGroup,
  ITourQueryFilterShapes
} from '@fa-kt-evaluation/besichtigungen/types';

@Injectable({
  providedIn: 'root'
})
export class TourService {

  protected route = 'besichtigungen/tour';
  protected dependencies = [
    'besichtigungen/written-besichtigungs-status',
    'besichtigungen/removed-besichtigungs-status',
    'besichtigungen/written-tour-status',
    'besichtigungen/removed-tour-status',
    'finanzen/written-reisekostenabrechnung-status',
    'finanzen/removed-reisekostenabrechnung-status',

    'besichtigungen/besichtigung-entfernt',
    'besichtigungen/besichtigung-geordnet',
    'besichtigungen/besichtigung-gespeichert',
    'besichtigungen/tour-entfernt',
    'besichtigungen/tour-gespeichert',
    'besichtigungen/tour-gestartet',
    'finanzen/rechnung-erstellt',
    'finanzen/reisekostenabrechnung-entfernt',
    'finanzen/reisekostenabrechnung-gespeichert',
    'formulare/durchgang-gestartet'
  ];

  readonly $result = new BehaviorSubject<ITourResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<ITourRequest|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<ITourQueryFilterShapes|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private device: DeviceConfigService,
  ) {
/* << changes    */
/*    changes >> */
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: ITourRequest): ITourRequest {
    if (payload) {
      this.$payload.next(payload as ITourRequest);
    } else {
      payload = this.$payload.getValue() as ITourRequest;
    }
    return payload;
  }

  public async request(payload: ITourRequest, force = false): Promise<ITourResponse> {
    const state = this.$result.getValue();
    if (!force && !this.device.alwaysRefresh && state && isEqual(payload, this.payload())) { return state; }
    payload = this.payload(payload);
    // if (!payload) { return this.$result.getValue() as ITourResponse; }
    const query = this.http.serialize(payload);
    const { response, shapes } = await this.http.get<ITourResponseGroup>(this.route + query);
    this.$result.next(response);
    this.$shapes.next(shapes);
    return response;
  }


  getBesichtigungenBesichtigungsStatus(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['besichtigungen.besichtigungsStatus']?.find(v => v.value === value)
  }

  get besichtigungenBesichtigungsStatus(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['besichtigungen.besichtigungsStatus'] as ISelection[] : []));
  }

  getTourMitarbeiter(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['tour.mitarbeiter']?.find(v => v.value === value)
  }

  get tourMitarbeiter(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['tour.mitarbeiter'] as ISelection[] : []));
  }

  getBesichtigungenMitarbeiter(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['besichtigungen.mitarbeiter']?.find(v => v.value === value)
  }

  get besichtigungenMitarbeiter(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['besichtigungen.mitarbeiter'] as ISelection[] : []));
  }

  getReisekostenerstattungMitarbeiter(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['reisekostenerstattung.mitarbeiter']?.find(v => v.value === value)
  }

  get reisekostenerstattungMitarbeiter(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['reisekostenerstattung.mitarbeiter'] as ISelection[] : []));
  }

  getReisekostenerstattungReisekostenabrechnungStatus(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['reisekostenerstattung.reisekostenabrechnungStatus']?.find(v => v.value === value)
  }

  get reisekostenerstattungReisekostenabrechnungStatus(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['reisekostenerstattung.reisekostenabrechnungStatus'] as ISelection[] : []));
  }

  getReisekostenerstattungTouren(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['reisekostenerstattung.touren']?.find(v => v.value === value)
  }

  get reisekostenerstattungTouren(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['reisekostenerstattung.touren'] as ISelection[] : []));
  }

  getTourTourStatus(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['tour.tourStatus']?.find(v => v.value === value)
  }

  get tourTourStatus(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['tour.tourStatus'] as ISelection[] : []));
  }


}
