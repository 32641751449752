import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IPostReisekostenabrechnungRequest, IPostReisekostenabrechnungResponse, IPostReisekostenabrechnungCommandShapes } from '@fa-kt-evaluation/finanzen/types';

@Injectable({
  providedIn: 'root'
})
export class PostReisekostenabrechnungCommandService {

  readonly route = 'finanzen/reisekostenabrechnung';
  protected dependencies = [
      'finanzen/written-reisekostenabrechnung-status',
      'finanzen/removed-reisekostenabrechnung-status',
      'finanzen/reisekostenabrechnung-gespeichert'
  ];

  readonly $shapes = new BehaviorSubject<IPostReisekostenabrechnungCommandShapes|undefined>(undefined);
  
  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IPostReisekostenabrechnungCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IPostReisekostenabrechnungCommandShapes>(this.route + '/post/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IPostReisekostenabrechnungRequest): Promise<IPostReisekostenabrechnungResponse> {
    return await this.http.post<IPostReisekostenabrechnungResponse>(this.route, payload) as unknown as IPostReisekostenabrechnungResponse;
  }
  
  getReisekostenabrechnungMitarbeiter(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['reisekostenabrechnung.mitarbeiter']?.find(v => v.value === value)
  }

  get reisekostenabrechnungMitarbeiter(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['reisekostenabrechnung.mitarbeiter'] as ISelection[] : []));
  }

  getReisekostenabrechnungReisekostenabrechnungStatus(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['reisekostenabrechnung.reisekostenabrechnungStatus']?.find(v => v.value === value)
  }

  get reisekostenabrechnungReisekostenabrechnungStatus(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['reisekostenabrechnung.reisekostenabrechnungStatus'] as ISelection[] : []));
  }

  getReisekostenabrechnungTouren(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['reisekostenabrechnung.touren']?.find(v => v.value === value)
  }

  get reisekostenabrechnungTouren(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['reisekostenabrechnung.touren'] as ISelection[] : []));
  }

}
