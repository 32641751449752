import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { BerichteServiceModule } from '../';
import { BerichteSorterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    BerichteServiceModule
  ],
  declarations: [BerichteSorterComponent],
  exports: [BerichteSorterComponent],

})
export class BerichteSorterComponentModule { }
