import { InjectionToken } from "@angular/core";

export let SITEMAP = new InjectionToken("app.sitemap");
export interface ISitemap {
  [key: string]: ISitemapContext;
};

export interface ISitemapContext {
  emoji: string;
  title: string;
  subtitle: string;
  url: string[];
  Pages: { [key: string]: ISitemapPage };
  Settings: { [key: string]: ISitemapPage };
  Models: { [key: string]: ISitemapModel };
  Queries: { [key: string]: ISitemapQuery };
  Commands: { [key: string]: ISitemapCommand };
  Events: { [key: string]: ISitemapEvent };
}

export interface ISitemapPage {
  emoji: string;
  title: string;
  subtitle: string;
  url: string[];
  breadcrumbs: string[];
  access: ISitemapAccess;
}

export interface ISitemapAccess {
  context: string[];
  roles: ISitemapAccessRoles;
}
export interface ISitemapAccessRoles {
  read: string[];
  write: string[];
}

export interface ISitemapProperty {
  title: string;
  placeholder: string;
  hint: string;
}
export interface ISitemapModel {
  single: string;
  many: string;
  emoji: string;
  Propertys: { [key: string]: ISitemapProperty };
}
export interface ISitemapQuery {
  translation: string;
  Inputs: { [key: string]: ISitemapProperty };
  Outputs: { [key: string]: ISitemapProperty };
}
export interface ISitemapCommand {
  translation: string;
  Inputs: { [key: string]: ISitemapProperty };
  Outputs: { [key: string]: ISitemapProperty };
}
export interface ISitemapEvent {
  translation: string;
}
