import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { first, map, debounceTime, distinctUntilChanged } from "rxjs/operators";

import { isEqual } from "lodash";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { IPersistedFilter, IPersistedFilterList } from "@lib/filter/types";
import { ISelection } from "@lib/persistence/types";

import {
  IDurchgangRequest,
  IDurchgangResponse,
  IDurchgangResponseGroup,
  IDurchgangQueryFilterShapes
} from '@fa-kt-evaluation/formulare/types';

@Injectable({
  providedIn: 'root'
})
export class DurchgangService {

  protected route = 'formulare/durchgang';
  protected dependencies = [
    'felder/written-feld-kategorie',
    'felder/removed-feld-kategorie',
    'felder/written-feld-unterkategorie',
    'felder/removed-feld-unterkategorie',
    'gutachten/written-bewertungs-anlass',
    'gutachten/removed-bewertungs-anlass',
    'kunden/written-kunden-art',
    'kunden/removed-kunden-art',
    'objekte/written-objekt-art',
    'objekte/removed-objekt-art',

    'felder/bewertet',
    'felder/feld-entfernt',
    'felder/feld-gespeichert',
    'felder/feld-option-entfernt',
    'felder/feld-option-gespeichert',
    'felder/feld-optionen-geordnet',
    'formulare/durchgang-gestartet',
    'formulare/formular-entfernt',
    'formulare/formular-gespeichert',
    'formulare/formular-kopiert'
  ];

  readonly $result = new BehaviorSubject<IDurchgangResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IDurchgangRequest|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IDurchgangQueryFilterShapes|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private device: DeviceConfigService,
  ) {
/* << changes    */
/*    changes >> */
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IDurchgangRequest): IDurchgangRequest {
    if (payload) {
      this.$payload.next(payload as IDurchgangRequest);
    } else {
      payload = this.$payload.getValue() as IDurchgangRequest;
    }
    return payload;
  }

  public async request(payload: IDurchgangRequest, force = false): Promise<IDurchgangResponse> {
    const state = this.$result.getValue();
    if (!force && !this.device.alwaysRefresh && state && isEqual(payload, this.payload())) { return state; }
    payload = this.payload(payload);
    // if (!payload) { return this.$result.getValue() as IDurchgangResponse; }
    const query = this.http.serialize(payload);
    const { response, shapes } = await this.http.get<IDurchgangResponseGroup>(this.route + query);
    this.$result.next(response);
    this.$shapes.next(shapes);
    return response;
  }


  getFormularBewertungsAnlaesse(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['formular.bewertungsAnlaesse']?.find(v => v.value === value)
  }

  get formularBewertungsAnlaesse(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['formular.bewertungsAnlaesse'] as ISelection[] : []));
  }

  getFormularOhneFelder(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['formular.ohneFelder']?.find(v => v.value === value)
  }

  get formularOhneFelder(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['formular.ohneFelder'] as ISelection[] : []));
  }

  getEintraegeFeld(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['eintraege.feld']?.find(v => v.value === value)
  }

  get eintraegeFeld(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['eintraege.feld'] as ISelection[] : []));
  }

  getFeldUnterkategorienFeldKategorie(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['feldUnterkategorien.feldKategorie']?.find(v => v.value === value)
  }

  get feldUnterkategorienFeldKategorie(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['feldUnterkategorien.feldKategorie'] as ISelection[] : []));
  }

  getFelderFeldKategorie(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['felder.feldKategorie']?.find(v => v.value === value)
  }

  get felderFeldKategorie(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['felder.feldKategorie'] as ISelection[] : []));
  }

  getFelderFeldUnterkategorie(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['felder.feldUnterkategorie']?.find(v => v.value === value)
  }

  get felderFeldUnterkategorie(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['felder.feldUnterkategorie'] as ISelection[] : []));
  }

  getEintraegeGutachten(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['eintraege.gutachten']?.find(v => v.value === value)
  }

  get eintraegeGutachten(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['eintraege.gutachten'] as ISelection[] : []));
  }

  getFormularKunden(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['formular.kunden']?.find(v => v.value === value)
  }

  get formularKunden(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['formular.kunden'] as ISelection[] : []));
  }

  getFormularKundenArten(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['formular.kundenArten']?.find(v => v.value === value)
  }

  get formularKundenArten(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['formular.kundenArten'] as ISelection[] : []));
  }

  getFormularObjektArten(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['formular.objektArten']?.find(v => v.value === value)
  }

  get formularObjektArten(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['formular.objektArten'] as ISelection[] : []));
  }


}
