import { compile } from "@lib/templates/types";
import { ISitemap, ISitemapContext, ISitemapEvent, ISitemapPage } from "./types";
import * as changeCase from "change-case";

export function translateSitemap(sitemap: ISitemap) {
  return prepare(prepare(sitemap));
}

function prepare(sitemap: ISitemap): ISitemap {
  return JSON.parse(compile(JSON.stringify(sitemap), sitemap));
}

export function getEntryFromSitemap(sitemap: ISitemap, key: string): ISitemapPage | ISitemapContext {
  key = key.toUpperCase();
  key = (key === "SETTINGS") ? "EINSTELLUNGEN" : key;
  return Object.entries(sitemap).filter(([entry, value]) => entry === key || value.Pages[key] || value.Settings[key]).map(([entry, value]) => value.Pages[key] ? value.Pages[key] : value.Settings[key] ? value.Settings[key] : value)[0];
}

export function getEventFromSitemap(sitemap: ISitemap, eventName: string): ISitemapEvent | undefined {
  if (!eventName) {
    return;
  }
  const [context, event] = eventName.split("/");
  return sitemap[context.toUpperCase()] ? sitemap[context.toUpperCase()].Events[event] : undefined;
}

export function cleanObject<T>(obj: T, value: any = null): T {
  if (!obj) {
    return obj;
  }
  Object.entries(obj).filter(([key, value]) => typeof value !== "boolean" && typeof value !== "number" && !value).forEach(([key]) => delete obj[key as keyof typeof obj]);
  return obj;
}

export function checkSpecialBrowser(): boolean {
  return window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1 || /iPad/i.test(window.navigator.userAgent) || /iPhone/i.test(window.navigator.userAgent);
}

export function toInitials(term: string): string {
  if (!term) {
    return "";
  }
  return term.replace(/[^a-zA-Z ]+/g, "").split(" ").filter(s => s).map(p => p[0].toUpperCase()).join("");
}

export function toConstant(text: string) {
  return changeCase.constantCase(text)
}

export function pushFiles(data: any, files: File[] = [], path = ''): File[] {
  if (!files) { files = []; }
  if (!data) {
    return files;
  } else if (Array.isArray(data)) {
    data.forEach((nested, i) => files = pushFiles(nested, files, path + i));
  } else if (typeof data === "object" && !(data instanceof Date) && !isFile(data)) {
    Object.entries(data).forEach(([key, nested]) => {
      files = pushFiles(nested, files, path ? `${path}.${key}` : key);
    });
  } else if (isFile(data)){
    files.push(data);
  }
  return files;
}

export function prepareCommand(data: any | object, path?: string, fileContainerName?: string) {
  if (!data) {
    return data;
  } else if (isFile(data)){
    data = fileContainerName + '/' + data.name;
  } else if (data.latitude && data.longitude) {
    data.point = {
      type: "Point",
      coordinates: [data.latitude, data.longitude]
    }
  } else if (Array.isArray(data)) {
    data = data.map(nested => prepareCommand(nested, path, fileContainerName));
  } else if (typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
    Object.entries(data).forEach(([key, nested]) => {
      data[key] = prepareCommand(nested, path ? `${path}.${key}` : key, fileContainerName);
    });
  }
  return data;
}


export function propertiesToArray(obj: any): string[] {
  const isObject = (val: any) =>
    val && typeof val === 'object' && !Array.isArray(val);

  const addDelimiter = (a: any, b: any) =>
    a ? `${a}.${b}` : b;

  const paths: any = (obj = {}, head = '') => {
    return Object.entries(obj).filter(e => e[0] && e[0] !== '_')
      .reduce((product, [key, value]) =>
      {
        let fullPath = addDelimiter(head, key)
        return isObject(value) ?
          product.concat(paths(value, fullPath))
          : Array.isArray(value) ?
            product.concat(addDelimiter(key, propertiesToArray(value)))
            : product.concat(fullPath)
      }, []);
  }

  return paths(obj);
}

function isFile(possibleFile: any) {
  return possibleFile instanceof File || (possibleFile.name && possibleFile.size);
}



export function join(arr: string[]): string {
  if (!arr || arr.length === 0) { return ''; }
  if (arr.length === 1) { return arr[0]; }
  const last = arr.pop();
  return arr.join(', ') + ' & ' + last;
}
