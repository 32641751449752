import { Component } from '@angular/core';
import { OffeneGutachtenService } from "@fa-kt-evaluation/besichtigungen/frontend";

/* << imports    */
 
/*    imports >> */
@Component({
  selector: 'fa-kt-evaluation-saved-offene-gutachten-filters',
  templateUrl: './saved-offene-gutachten-filters.component.html',
  styleUrls: ['./saved-offene-gutachten-filters.component.css']
})
export class SavedOffeneGutachtenFiltersComponent {

  constructor(
    public offeneGutachten: OffeneGutachtenService
/* << injection    */
/*    injection >> */
  ) {
  }
}