import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { OffeneGutachtenServiceModule } from '../';
import { OffeneGutachtenListComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    OffeneGutachtenServiceModule
  ],
  declarations: [OffeneGutachtenListComponent],
  exports: [OffeneGutachtenListComponent],

})
export class OffeneGutachtenListComponentModule { }
