import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostReferenzenHochladenCommandServiceModule } from '../';
import { PostReferenzenHochladenCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostReferenzenHochladenCommandServiceModule
  ],
  declarations: [PostReferenzenHochladenCommandComponent],
  exports: [PostReferenzenHochladenCommandComponent],

})
export class PostReferenzenHochladenCommandComponentModule { }
