import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostReisekostenabrechnungCommandServiceModule } from '../';
import { PostReisekostenabrechnungCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostReisekostenabrechnungCommandServiceModule
  ],
  declarations: [PostReisekostenabrechnungCommandComponent],
  exports: [PostReisekostenabrechnungCommandComponent],

})
export class PostReisekostenabrechnungCommandComponentModule { }
