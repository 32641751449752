import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';

import { ZeitQueryModule } from '@fa-kt-evaluation/zeiten/frontend';
import { PostZeitCommandModule, DeleteZeitCommandModule } from '@fa-kt-evaluation/zeiten/frontend';

import { ZeitComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ZeitQueryModule,
    PostZeitCommandModule,
    DeleteZeitCommandModule,
  ],
  declarations: [ZeitComponent],
  exports: [ZeitComponent],

})
export class ZeitComponentModule { }
