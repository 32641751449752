import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { ObjekteServiceModule } from '../';
import { SavedObjekteFiltersComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ObjekteServiceModule
  ],
  declarations: [SavedObjekteFiltersComponent],
  exports: [SavedObjekteFiltersComponent],

})
export class SavedObjekteFiltersComponentModule { }
