<!---
  <div formGroupName="kunde">
    <input class="cdk-visually-hidden" formControlName="id" id="id"/>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">{{ command.Inputs.NAME.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NameErrorTip">
        <ng-template #NameErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NAME.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="name" id="name" [placeholder]="command.Inputs.NAME.placeholder" cdkFocusInitial/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="initialen">{{ command.Inputs.INITIALEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="InitialenErrorTip">
        <ng-template #InitialenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.INITIALEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="initialen" id="initialen" [placeholder]="command.Inputs.INITIALEN.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="kundenGruppe">{{ command.Inputs.KUNDEN_GRUPPE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KundenGruppeErrorTip">
        <ng-template #KundenGruppeErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KUNDEN_GRUPPE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="kundenGruppe" id="kundenGruppe" [shapes]="postKunde.kundeKundenGruppe | async" [placeholder]="command.Inputs.KUNDEN_GRUPPE.placeholder" required=true ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kundenArt">{{ command.Inputs.KUNDEN_ART.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KundenArtErrorTip">
        <ng-template #KundenArtErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KUNDEN_ART.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="kundenArt" id="kundenArt" [shapes]="postKunde.kundeKundenArt | async" [placeholder]="command.Inputs.KUNDEN_ART.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <codeboard-address-input formControlName="addresse" id="addresse" required="false" ></codeboard-address-input>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="website">{{ command.Inputs.WEBSITE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="WebsiteErrorTip">
        <ng-template #WebsiteErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.WEBSITE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="website" id="website" [shapes]="postKunde.kundeWebsite | async" [placeholder]="command.Inputs.WEBSITE.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="outlookKontakte">{{ command.Inputs.OUTLOOK_KONTAKTE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="OutlookKontakteErrorTip">
        <ng-template #OutlookKontakteErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.OUTLOOK_KONTAKTE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="outlookKontakte" id="outlookKontakte" [placeholder]="command.Inputs.OUTLOOK_KONTAKTE.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kontaktNotizen">{{ command.Inputs.KONTAKT_NOTIZEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KontaktNotizenErrorTip">
        <ng-template #KontaktNotizenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KONTAKT_NOTIZEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <textarea nz-input formControlName="kontaktNotizen" id="kontaktNotizen" [placeholder]="command.Inputs.KONTAKT_NOTIZEN.placeholder"></textarea>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="anforderungenNotizen">{{ command.Inputs.ANFORDERUNGEN_NOTIZEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AnforderungenNotizenErrorTip">
        <ng-template #AnforderungenNotizenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ANFORDERUNGEN_NOTIZEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <textarea nz-input formControlName="anforderungenNotizen" id="anforderungenNotizen" [placeholder]="command.Inputs.ANFORDERUNGEN_NOTIZEN.placeholder"></textarea>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="rechnungNotizen">{{ command.Inputs.RECHNUNG_NOTIZEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="RechnungNotizenErrorTip">
        <ng-template #RechnungNotizenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.RECHNUNG_NOTIZEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <textarea nz-input formControlName="rechnungNotizen" id="rechnungNotizen" [placeholder]="command.Inputs.RECHNUNG_NOTIZEN.placeholder"></textarea>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fahrtkostenAbrechnung">{{ command.Inputs.FAHRTKOSTEN_ABRECHNUNG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FahrtkostenAbrechnungErrorTip">
        <ng-template #FahrtkostenAbrechnungErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FAHRTKOSTEN_ABRECHNUNG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="fahrtkostenAbrechnung" id="fahrtkostenAbrechnung" [shapes]="postKunde.kundeFahrtkostenAbrechnung | async" [placeholder]="command.Inputs.FAHRTKOSTEN_ABRECHNUNG.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="steuerId">{{ command.Inputs.STEUER_ID.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="SteuerIdErrorTip">
        <ng-template #SteuerIdErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STEUER_ID.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="steuerId" id="steuerId" [placeholder]="command.Inputs.STEUER_ID.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fahrtkostenAbRadius">{{ command.Inputs.FAHRTKOSTEN_AB_RADIUS.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FahrtkostenAbRadiusErrorTip">
        <ng-template #FahrtkostenAbRadiusErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FAHRTKOSTEN_AB_RADIUS.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="fahrtkostenAbRadius" id="fahrtkostenAbRadius" [nzPlaceHolder]="command.Inputs.FAHRTKOSTEN_AB_RADIUS.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fahrtkostenProKm">{{ command.Inputs.FAHRTKOSTEN_PRO_KM.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FahrtkostenProKmErrorTip">
        <ng-template #FahrtkostenProKmErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FAHRTKOSTEN_PRO_KM.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="fahrtkostenProKm" id="fahrtkostenProKm" [nzPlaceHolder]="command.Inputs.FAHRTKOSTEN_PRO_KM.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fahrtkostenProStunde">{{ command.Inputs.FAHRTKOSTEN_PRO_STUNDE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FahrtkostenProStundeErrorTip">
        <ng-template #FahrtkostenProStundeErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FAHRTKOSTEN_PRO_STUNDE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="fahrtkostenProStunde" id="fahrtkostenProStunde" [nzPlaceHolder]="command.Inputs.FAHRTKOSTEN_PRO_STUNDE.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="skonto">{{ command.Inputs.SKONTO.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="SkontoErrorTip">
        <ng-template #SkontoErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.SKONTO.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="skonto" id="skonto" [nzPlaceHolder]="command.Inputs.SKONTO.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="zahlungsziel">{{ command.Inputs.ZAHLUNGSZIEL.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ZahlungszielErrorTip">
        <ng-template #ZahlungszielErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ZAHLUNGSZIEL.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="zahlungsziel" id="zahlungsziel" [nzPlaceHolder]="command.Inputs.ZAHLUNGSZIEL.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="tageOhneSkonto">{{ command.Inputs.TAGE_OHNE_SKONTO.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="TageOhneSkontoErrorTip">
        <ng-template #TageOhneSkontoErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.TAGE_OHNE_SKONTO.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="tageOhneSkonto" id="tageOhneSkonto" [nzPlaceHolder]="command.Inputs.TAGE_OHNE_SKONTO.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="aktiv">{{ command.Inputs.AKTIV.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AktivErrorTip">
        <ng-template #AktivErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.AKTIV.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <label nz-checkbox formControlName="aktiv" id="aktiv"></label>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="dateipfad">{{ command.Inputs.DATEIPFAD.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DateipfadErrorTip">
        <ng-template #DateipfadErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.DATEIPFAD.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="dateipfad" id="dateipfad" [placeholder]="command.Inputs.DATEIPFAD.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="loraName">{{ command.Inputs.LORA_NAME.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="LoraNameErrorTip">
        <ng-template #LoraNameErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.LORA_NAME.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="loraName" id="loraName" [placeholder]="command.Inputs.LORA_NAME.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="unterlagenEmail">{{ command.Inputs.UNTERLAGEN_EMAIL.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="UnterlagenEmailErrorTip">
        <ng-template #UnterlagenEmailErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.UNTERLAGEN_EMAIL.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="unterlagenEmail" id="unterlagenEmail" [shapes]="postKunde.kundeUnterlagenEmail | async" [placeholder]="command.Inputs.UNTERLAGEN_EMAIL.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
  </div>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
<!--- << custom    -->
    <div formGroupName="kunde">
      <input class="cdk-visually-hidden" formControlName="id" id="id"/>
      <input class="cdk-visually-hidden" formControlName="outlookKontakte" id="outlookKontakte"/>

      <input class="cdk-visually-hidden" formControlName="skonto" id="skonto"/>
      <input class="cdk-visually-hidden" formControlName="zahlungsziel" id="zahlungsziel"/>
      <input class="cdk-visually-hidden" formControlName="tageOhneSkonto" id="tageOhneSkonto"/>

      <nz-row>
        <nz-col nzSpan=24>
          <nz-divider id="allgemein" nzText="Allgemein" nzOrientation="left"></nz-divider>

          <nz-form-item style="width: 100%;">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">{{ command.Inputs.NAME.title}}</nz-form-label>
            <nz-form-control [nzSm]="8" [nzXs]="24" [nzErrorTip]="NameErrorTip">
              <ng-template #NameErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NAME.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <input nz-input formControlName="name" id="name" [placeholder]="command.Inputs.NAME.placeholder" cdkFocusInitial/>
            </nz-form-control>
            <nz-form-control [nzOffset]="1" [nzSm]="3" [nzXs]="24" [nzErrorTip]="InitialenErrorTip">
              <ng-template #InitialenErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.INITIALEN.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('unique')">{{ control?.getError('unique') }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <input nz-input formControlName="initialen" id="initialen" [placeholder]="command.Inputs.INITIALEN.placeholder"/>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item style="width: 100%;">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="kundenGruppe">{{ command.Inputs.KUNDEN_GRUPPE.title}}</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KundenGruppeErrorTip">
              <ng-template #KundenGruppeErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KUNDEN_GRUPPE.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <codeboard-select-value formControlName="kundenGruppe" id="kundenGruppe" [shapes]="postKunde.kundeKundenGruppe | async" [placeholder]="command.Inputs.KUNDEN_GRUPPE.placeholder" required=true ></codeboard-select-value>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item style="width: 100%;">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kundenArt">{{ command.Inputs.KUNDEN_ART.title}}</nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KundenArtErrorTip">
            <ng-template #KundenArtErrorTip let-control>
              <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KUNDEN_ART.hint }}</ng-container>
              <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
            </ng-template>
            <codeboard-select-value formControlName="kundenArt" id="kundenArt" [shapes]="postKunde.kundeKundenArt | async" [filter]="kundenGruppeValue" [placeholder]="command.Inputs.KUNDEN_ART.placeholder" ></codeboard-select-value>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item style="width: 100%;">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="dateipfad">{{ command.Inputs.DATEIPFAD.title}}</nz-form-label>
          <nz-form-control [nzSm]="5" [nzXs]="24" [nzErrorTip]="DateipfadErrorTip">
            <ng-template #DateipfadErrorTip let-control>
              <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.DATEIPFAD.hint }}</ng-container>
              <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
            </ng-template>
            <input nz-input formControlName="dateipfad" id="dateipfad" [placeholder]="command.Inputs.DATEIPFAD.placeholder"/>
          </nz-form-control>
          <nz-form-label [nzSm]="2" [nzXs]="24" nzFor="loraName">{{ command.Inputs.LORA_NAME.title}}</nz-form-label>
          <nz-form-control [nzSm]="5" [nzXs]="24" [nzErrorTip]="LoraNameErrorTip">
            <ng-template #LoraNameErrorTip let-control>
              <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.LORA_NAME.hint }}</ng-container>
              <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
            </ng-template>
            <input nz-input formControlName="loraName" id="loraName" [placeholder]="command.Inputs.LORA_NAME.placeholder"/>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col nzSpan="24">
          <nz-divider id="erreichbarkeit" nzText="Erreichbarkeit" nzOrientation="left"></nz-divider>
          <nz-form-item style="width: 100%;">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="website">{{ command.Inputs.WEBSITE.title}}</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24">
              <nz-input-group [nzAddOnAfter]="OpenWebsite">
                <input type="url" nz-input formControlName="website" id="website" [placeholder]="command.Inputs.WEBSITE.placeholder"/>
                <ng-template #OpenWebsite>
                  <a [href]="website.value" target="_blank">
                    <i nz-icon nzType="global" nzTheme="outline"></i>
                  </a>
                </ng-template>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item style="width: 100%;">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="unterlagenEmail">{{ command.Inputs.UNTERLAGEN_EMAIL.title}}</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="UnterlagenEmailErrorTip">
              <ng-template #UnterlagenEmailErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.UNTERLAGEN_EMAIL.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <nz-input-group [nzAddOnAfter]="OpenEmail">
                <input type="url" nz-input formControlName="unterlagenEmail" id="unterlagenEmail" [placeholder]="command.Inputs.UNTERLAGEN_EMAIL.placeholder"/>
                <ng-template #OpenEmail>
                  <a [href]="'mailto:' + kundeUnterlagenEmail.value" target="_blank">
                    <i nz-icon nzType="mail" nzTheme="outline"></i>
                  </a>
                </ng-template>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col nzSpan="24">
          <nz-divider id="adresse" nzText="Adresse" nzOrientation="left"></nz-divider>
          <codeboard-address-input formControlName="addresse" id="addresse"></codeboard-address-input>
        </nz-col>
        <nz-col nzSpan="24">
          <nz-divider id="abrechnung" nzText="Abrechnung" nzOrientation="left"></nz-divider>
          <nz-form-item style="width: 100%;">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="steuerId">{{ command.Inputs.STEUER_ID.title}}</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="SteuerIdErrorTip">
              <ng-template #SteuerIdErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STEUER_ID.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <input nz-input formControlName="steuerId" id="steuerId" [placeholder]="command.Inputs.STEUER_ID.placeholder"/>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item style="width: 100%;">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fahrtkostenAbrechnung">{{ command.Inputs.FAHRTKOSTEN_ABRECHNUNG.title}}</nz-form-label>
            <nz-form-control [nzSm]="5" [nzXs]="24" >
              <nz-radio-group formControlName="fahrtkostenAbrechnung" id="fahrtkostenAbrechnung">
                <label nz-radio-button *ngFor="let shape of (postKunde.$shapes | async)?.['kunde.fahrtkostenAbrechnung']" [nzValue]="shape.value">{{ shape.label }}</label>
              </nz-radio-group>
            </nz-form-control>
            <ng-container [ngSwitch]="fahrtkostenAbrechnung?.value">
              <ng-container *ngSwitchCase="'proKm'">

                <nz-form-label nzFlex="0 1 100px" [nzXs]="24" nzFor="fahrtkostenProKm">{{ command.Inputs.FAHRTKOSTEN_PRO_KM.title}}</nz-form-label>
                <nz-form-control nzFlex="0 1 150px" [nzErrorTip]="FahrtkostenProKmErrorTip">
                  <ng-template #FahrtkostenProKmErrorTip let-control>
                    <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FAHRTKOSTEN_PRO_KM.hint }}</ng-container>
                    <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
                  </ng-template>
                  <input nz-input type="number" style="width: 100px" style="width: 126px;" formControlName="fahrtkostenProKm" id="fahrtkostenProKm" [nzPlaceHolder]="command.Inputs.FAHRTKOSTEN_PRO_KM.placeholder"/> €
                </nz-form-control>

                <nz-form-label nzFlex="0 1 100px" [nzXs]="24" nzFor="fahrtkostenAbRadius">{{ command.Inputs.FAHRTKOSTEN_AB_RADIUS.title}}</nz-form-label>
                <nz-form-control nzFlex="0 1 150px" [nzXs]="24" [nzErrorTip]="FahrtkostenAbRadiusErrorTip">
                  <ng-template #FahrtkostenAbRadiusErrorTip let-control>
                    <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FAHRTKOSTEN_AB_RADIUS.hint }}</ng-container>
                    <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
                  </ng-template>
                  <input nz-input type="number" style="width: 100px" style="width: 126px;" formControlName="fahrtkostenAbRadius" id="fahrtkostenAbRadiusProKM" [nzPlaceHolder]="command.Inputs.FAHRTKOSTEN_AB_RADIUS.placeholder"/> km
                </nz-form-control>

                <input class="cdk-visually-hidden" formControlName="fahrtkostenProStunde"/>

              </ng-container>
              <ng-container *ngSwitchCase="'proStunde'">

                <nz-form-label nzFlex="0 1 100px" [nzXs]="24" nzFor="fahrtkostenProStunde">{{ command.Inputs.FAHRTKOSTEN_PRO_STUNDE.title}}</nz-form-label>
                <nz-form-control nzFlex="0 1 150px" [nzXs]="24" [nzErrorTip]="FahrtkostenProStundeErrorTip">
                  <ng-template #FahrtkostenProStundeErrorTip let-control>
                    <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FAHRTKOSTEN_PRO_STUNDE.hint }}</ng-container>
                    <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
                  </ng-template>
                  <input nz-input type="number" style="width: 100px" style="width: 126px;" formControlName="fahrtkostenProStunde" id="fahrtkostenProStunde" [nzPlaceHolder]="command.Inputs.FAHRTKOSTEN_PRO_STUNDE.placeholder"/> €
                </nz-form-control>
                <input *ngSwitchDefault class="cdk-visually-hidden" formControlName="fahrtkostenAbRadius"/>
                <input *ngSwitchDefault class="cdk-visually-hidden" formControlName="fahrtkostenProKm"/>

              </ng-container>
              <ng-container *ngSwitchDefault>

                <input *ngSwitchDefault class="cdk-visually-hidden" formControlName="fahrtkostenAbRadius"/>
                <input *ngSwitchDefault class="cdk-visually-hidden" formControlName="fahrtkostenProKm"/>
                <input *ngSwitchDefault class="cdk-visually-hidden" formControlName="fahrtkostenProStunde"/>

              </ng-container>
            </ng-container>
          </nz-form-item>
          <nz-form-item style="width: 100%;">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="rechnungNotizen">{{ command.Inputs.RECHNUNG_NOTIZEN.title}}</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="RechnungNotizenErrorTip">
              <ng-template #RechnungNotizenErrorTip let-control>
                <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.RECHNUNG_NOTIZEN.hint }}</ng-container>
                <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
              </ng-template>
              <textarea nz-input formControlName="rechnungNotizen" id="rechnungNotizen" [placeholder]="command.Inputs.RECHNUNG_NOTIZEN.placeholder"></textarea>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col nzSpan="24">
          <nz-divider id="notizen" nzText="Notizen" nzOrientation="left"></nz-divider>
          <nz-form-item style="width: 100%;">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kontaktNotizen">{{ command.Inputs.KONTAKT_NOTIZEN.title}}</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24">
              <textarea nz-input formControlName="kontaktNotizen" id="kontaktNotizen" [placeholder]="command.Inputs.KONTAKT_NOTIZEN.placeholder"></textarea>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item style="width: 100%;">
            <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="anforderungenNotizen">{{ command.Inputs.ANFORDERUNGEN_NOTIZEN.title}}</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24">
              <textarea nz-input formControlName="anforderungenNotizen" id="anforderungenNotizen" [placeholder]="command.Inputs.ANFORDERUNGEN_NOTIZEN.placeholder"></textarea>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
    </div>
<!---    custom >> -->
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button"
          type="submit"
          [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)"
          nz-tooltip [nzTooltipTitle]="SubmitTooltip">
<!--- << confirm    -->
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Speichern
<!---    confirm >> -->
          <ng-template #SubmitTooltip>
<!--- << hint    -->
            Kunde speichern
<!---    hint >> -->
          </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
