<nz-breadcrumb-item *ngFor="let page of sites">
  <a *ngIf="page.url" [routerLink]="page.url">
    {{ page.emoji }}
    {{ page.title }}
  </a>
  <span *ngIf="!page.url">
    {{ page.emoji }}
    {{ page.title }}
  </span>
</nz-breadcrumb-item>
<nz-breadcrumb-item *ngIf="extra">
  <span>
    {{ extra }}
  </span>
</nz-breadcrumb-item>

