import { NgModule } from '@angular/core';

import {
  DeleteAbschnittCommandServiceModule,
  DeleteAbschnittCommandComponentModule
} from './';

@NgModule({
  imports: [
    DeleteAbschnittCommandServiceModule,
    DeleteAbschnittCommandComponentModule
  ],
  exports: [
    DeleteAbschnittCommandServiceModule,
    DeleteAbschnittCommandComponentModule
  ]
})
export class DeleteAbschnittCommandModule { }
