import { Component } from '@angular/core';
import { ObjekteService } from "@fa-kt-evaluation/objekte/frontend";

/* << imports    */
 
/*    imports >> */
@Component({
  selector: 'fa-kt-evaluation-saved-objekte-filters',
  templateUrl: './saved-objekte-filters.component.html',
  styleUrls: ['./saved-objekte-filters.component.css']
})
export class SavedObjekteFiltersComponent {

  constructor(
    public objekte: ObjekteService
/* << injection    */
/*    injection >> */
  ) {
  }
/* << fields   */
 
/*   fields >> */
/* << methods   */
 
/*   methods >> */
}