import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { first, map, debounceTime, distinctUntilChanged } from "rxjs/operators";

import { isEqual, orderBy } from "lodash";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { FilterPipe, SearchPipe, SorterPipe } from "@lib/filter/frontend";
import { IPersistedFilter, IPersistedFilterList } from "@lib/filter/types";
import { ISelection } from "@lib/persistence/types";

import {
  IProjekteRequest,
  IProjekteResponse,
  IProjekteResponseGroup,
  IProjekteQuerySorter,
  IProjekteQueryFilter,
  IProjekteQueryFilterShapes
} from '@fa-kt-evaluation/projekte/types';

@Injectable({
  providedIn: 'root'
})
export class ProjekteService {

  protected route = 'projekte/projekte';
  protected dependencies = [
    'besichtigungen/written-besichtigungs-status',
    'besichtigungen/removed-besichtigungs-status',
    'geo/written-bereich',
    'geo/removed-bereich',
    'gutachten/written-bewertungs-anlass',
    'gutachten/removed-bewertungs-anlass',
    'gutachten/written-gutachten-status',
    'gutachten/removed-gutachten-status',
    'kunden/written-kunden-art',
    'kunden/removed-kunden-art',
    'kunden/written-kunden-gruppe',
    'kunden/removed-kunden-gruppe',
    'objekte/written-objekt-art',
    'objekte/removed-objekt-art',
    'objekte/written-objekt-nutzung',
    'objekte/removed-objekt-nutzung',
    'personen/written-standort',
    'personen/removed-standort',
    'projekte/written-projekt-art',
    'projekte/removed-projekt-art',
    'projekte/written-projekt-status',
    'projekte/removed-projekt-status',

    'besichtigungen/besichtigung-entfernt',
    'besichtigungen/besichtigung-geordnet',
    'besichtigungen/besichtigung-gespeichert',
    'dokumente/datei-gespeichert',
    'dokumente/dokument-hinterlegt',
    'einstellungen/kalkulation-hochgeladen',
    'felder/bewertet',
    'finanzen/extra-vereinbarung-entfernt',
    'finanzen/extra-vereinbarung-gespeichert',
    'finanzen/honorar-vereinbarung-entfernt',
    'finanzen/honorar-vereinbarung-gespeichert',
    'finanzen/rechnung-erstellt',
    'finanzen/zahlungseingang-entfernt',
    'finanzen/zahlungseingang-gespeichert',
    'formulare/durchgang-gestartet',
    'formulare/formular-entfernt',
    'formulare/formular-gespeichert',
    'formulare/formular-kopiert',
    'gutachten/gutachten-entfernt',
    'gutachten/gutachten-gespeichert',
    'kommunikation/erinnerung-abgemeldet',
    'kommunikation/erinnerung-entfernt',
    'kommunikation/erinnerung-gemerkt',
    'kommunikation/vermerk-entfernt',
    'kommunikation/vermerkt',
    'kunden/abteilung-entfernt',
    'kunden/abteilung-gespeichert',
    'kunden/fond-entfernt',
    'kunden/fond-gespeichert',
    'kunden/kunde-entfernt',
    'kunden/kunde-gespeichert',
    'kunden/kunden-finanzen-gespeichert',
    'objekte/objekt-entfernt',
    'objekte/objekt-gespeichert',
    'projekte/projekt-archiviert',
    'projekte/projekt-entfernt',
    'projekte/projekt-gespeichert',
    'projekte/projekt-kopiert',
    'projekte/projekt-reaktiviert',
    'texte/vorlage-entfernt',
    'texte/vorlage-gespeichert',
    'zeiten/zeit-entfernt',
    'zeiten/zeit-gespeichert'
  ];

  readonly $result = new BehaviorSubject<IProjekteResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IProjekteRequest|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IProjekteQueryFilterShapes|undefined>(undefined);
  readonly $response = new BehaviorSubject<IProjekteResponse|undefined>(undefined);
  readonly $filter = new BehaviorSubject<IProjekteQueryFilter>({});
  readonly $sorter = new BehaviorSubject<IProjekteQuerySorter>({});
  readonly $search = new BehaviorSubject<string>('');
  readonly $presets = new BehaviorSubject<IPersistedFilter<IProjekteResponse>[]>([]);
  readonly $preset = new BehaviorSubject<IPersistedFilter<IProjekteResponse>|undefined>(undefined);
  private readonly $filtered = new BehaviorSubject<IProjekteResponse|undefined>(undefined);
  private readonly $searched = new BehaviorSubject<IProjekteResponse|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private device: DeviceConfigService,
    private search: SearchPipe,
    private filter: FilterPipe,
    private sorter: SorterPipe,
  ) {
/* << changes    */
    this.dependencies = [ ...this.dependencies, 'projekte/importiert'];
/*    changes >> */
    combineLatest([
      this.$response,
      this.$filter,
    ]).subscribe(([response, filter]) => {
      if (response && response.projekte) {
        const projekte = this.filter.transform(response.projekte, filter);
        this.$filtered.next( { ...response, projekte });
      } else {
        this.$filtered.next(response);
      }
    });
    combineLatest([
      this.$filtered,
      this.$search.pipe(debounceTime(device.debounceTime), distinctUntilChanged()),
      this.$shapes.pipe(distinctUntilChanged((a, b) => isEqual(a, b)))
    ]).subscribe(([filtered, search, shapes]) => {
      if (filtered && filtered.projekte) {
        const projekte = this.search.transform(filtered.projekte, search, '_search', shapes);
        this.$searched.next( { ...filtered, projekte });
      } else {
        this.$searched.next(filtered);
      }
    });
    combineLatest([
      this.$searched,
      this.$sorter.pipe(distinctUntilChanged((a, b) => isEqual(a, b))),
      this.$shapes.pipe(distinctUntilChanged((a, b) => isEqual(a, b)))
    ]).pipe(debounceTime(10)).subscribe(([sorted, sorter, shapes]) => {
      if (sorted && sorted.projekte) {
        const projekte = this.sorter.transform(sorted.projekte, sorter, shapes);
        this.$result.next( { ...sorted, projekte });
      } else {
        this.$result.next(sorted);
      }
    });
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IProjekteRequest): IProjekteRequest {
    if (payload) {
      this.$payload.next(payload as IProjekteRequest);
    } else {
      payload = this.$payload.getValue() as IProjekteRequest;
    }
    return payload;
  }

  public async request(payload: IProjekteRequest, force = false): Promise<IProjekteResponse> {
    const state = this.$response.getValue();
    if (!force && !this.device.alwaysRefresh && state && isEqual(payload, this.payload())) { return state; }
    payload = this.payload(payload);
    if (!payload) { return this.$response.getValue() as IProjekteResponse; }
    const query = this.http.serialize(payload);
    const { response, shapes, filter } = await this.http.get<IProjekteResponseGroup>(this.route + query);
    if (response.projekte) {
      response.projekte = orderBy(response.projekte, 'projekt.projektZaehler').reverse();
    }
    this.$response.next(response);
    this.$shapes.next(shapes);
    this.$presets.next(filter && filter.filters ? filter.filters : []);
    return response;
  }

  public async writeFilter(filter: IPersistedFilter<IProjekteResponse>): Promise<IPersistedFilter<IProjekteResponse>|undefined> {
    filter = { ...filter, term: this.$search.getValue(), sorter: this.$sorter.getValue(), filter: this.$filter.getValue() };
    const result = await this.http.post(this.route + '/filters', filter) as IPersistedFilterList<IProjekteResponse>;
    filter = result.filters.find(f => f.name === filter.name) as IPersistedFilter<IProjekteResponse>;
    this.$presets.next(result.filters ? result.filters : []);
    this.setFilter(filter);
    return filter;
  }

  public async deleteFilter(id: string): Promise<void> {
    const result = await this.http.delete(this.route + '/filters?id=' + id) as IPersistedFilterList<IProjekteResponse>;
    this.$presets.next(result.filters ? result.filters : []);
    this.setFilter();
  }

  public setFilter(filter?: IPersistedFilter<IProjekteResponse>): void {
    this.$search.next(filter && filter.term ? filter.term : '');
    this.$filter.next(filter && filter.filter ? filter.filter : {});
    this.$sorter.next(filter && filter.sorter ? filter.sorter : {});
    this.$preset.next(filter ? filter : undefined);
  }


  getProjektAbteilung(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.abteilung']?.find(v => v.value === value)
  }

  get projektAbteilung(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.abteilung'] as ISelection[] : []));
  }

  getObjekteBereiche(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['objekte.bereiche']?.find(v => v.value === value)
  }

  get objekteBereiche(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['objekte.bereiche'] as ISelection[] : []));
  }

  getBesichtigungenBesichtigungsStatus(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['besichtigungen.besichtigungsStatus']?.find(v => v.value === value)
  }

  get besichtigungenBesichtigungsStatus(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['besichtigungen.besichtigungsStatus'] as ISelection[] : []));
  }

  getGutachtenBewertungsAnlass(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['gutachten.bewertungsAnlass']?.find(v => v.value === value)
  }

  get gutachtenBewertungsAnlass(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['gutachten.bewertungsAnlass'] as ISelection[] : []));
  }

  getProjektFond(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.fond']?.find(v => v.value === value)
  }

  get projektFond(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.fond'] as ISelection[] : []));
  }

  getGutachtenBewertungsStatus(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['gutachten.bewertungsStatus']?.find(v => v.value === value)
  }

  get gutachtenBewertungsStatus(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['gutachten.bewertungsStatus'] as ISelection[] : []));
  }

  getProjektKunde(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.kunde']?.find(v => v.value === value)
  }

  get projektKunde(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.kunde'] as ISelection[] : []));
  }

  getProjektBank(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.bank']?.find(v => v.value === value)
  }

  get projektBank(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.bank'] as ISelection[] : []));
  }

  getKundeKundenArt(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['kunde.kundenArt']?.find(v => v.value === value)
  }

  get kundeKundenArt(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['kunde.kundenArt'] as ISelection[] : []));
  }

  getKundeKundenGruppe(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['kunde.kundenGruppe']?.find(v => v.value === value)
  }

  get kundeKundenGruppe(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['kunde.kundenGruppe'] as ISelection[] : []));
  }

  getProjektBesichtigungen(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.besichtigungen']?.find(v => v.value === value)
  }

  get projektBesichtigungen(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.besichtigungen'] as ISelection[] : []));
  }

  getProjektGutachten(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.gutachten']?.find(v => v.value === value)
  }

  get projektGutachten(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.gutachten'] as ISelection[] : []));
  }

  getProjektPruefung(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.pruefung']?.find(v => v.value === value)
  }

  get projektPruefung(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.pruefung'] as ISelection[] : []));
  }

  getBesichtigungenMitarbeiter(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['besichtigungen.mitarbeiter']?.find(v => v.value === value)
  }

  get besichtigungenMitarbeiter(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['besichtigungen.mitarbeiter'] as ISelection[] : []));
  }

  getVermerkeVerfasser(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['vermerke.verfasser']?.find(v => v.value === value)
  }

  get vermerkeVerfasser(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['vermerke.verfasser'] as ISelection[] : []));
  }

  getGutachtenObjekt(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['gutachten.objekt']?.find(v => v.value === value)
  }

  get gutachtenObjekt(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['gutachten.objekt'] as ISelection[] : []));
  }

  getGutachtenObjektArt(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['gutachten.objektArt']?.find(v => v.value === value)
  }

  get gutachtenObjektArt(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['gutachten.objektArt'] as ISelection[] : []));
  }

  getGutachtenObjektNutzung(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['gutachten.objektNutzung']?.find(v => v.value === value)
  }

  get gutachtenObjektNutzung(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['gutachten.objektNutzung'] as ISelection[] : []));
  }

  getProjektProjektArt(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.projektArt']?.find(v => v.value === value)
  }

  get projektProjektArt(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.projektArt'] as ISelection[] : []));
  }

  getProjektProjektStatus(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.projektStatus']?.find(v => v.value === value)
  }

  get projektProjektStatus(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.projektStatus'] as ISelection[] : []));
  }

  getProjektStandort(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.standort']?.find(v => v.value === value)
  }

  get projektStandort(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.standort'] as ISelection[] : []));
  }


}
