import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { IchServiceModule } from '../';
import { SavedIchFiltersComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    IchServiceModule
  ],
  declarations: [SavedIchFiltersComponent],
  exports: [SavedIchFiltersComponent],

})
export class SavedIchFiltersComponentModule { }
