import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IDeleteBerichtRequest, IDeleteBerichtResponse, IDeleteBerichtCommandShapes } from '@fa-kt-evaluation/markt/types';

@Injectable({
  providedIn: 'root'
})
export class DeleteBerichtCommandService {

  readonly route = 'markt/bericht';
  protected dependencies = [
      'markt/written-herausgeber',
      'markt/removed-herausgeber',
      'markt/bericht-entfernt'
  ];

  readonly $shapes = new BehaviorSubject<IDeleteBerichtCommandShapes|undefined>(undefined);
  
  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IDeleteBerichtCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IDeleteBerichtCommandShapes>(this.route + '/delete/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IDeleteBerichtRequest): Promise<IDeleteBerichtResponse> {
    return await this.http.delete<IDeleteBerichtResponse>(this.route + this.http.serialize(payload)) as unknown as IDeleteBerichtResponse;
  }
  
}
