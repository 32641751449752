import { NgModule } from '@angular/core';
import {
  DashboardFilterComponentModule,
  DashboardDetailComponentModule,
  DashboardSorterComponentModule,
  SavedDashboardFiltersComponentModule
} from './';

@NgModule({
  imports: [
    DashboardFilterComponentModule,
    DashboardDetailComponentModule,
    DashboardSorterComponentModule,
    SavedDashboardFiltersComponentModule
  ],
  exports: [
    DashboardFilterComponentModule,
    DashboardDetailComponentModule,
    DashboardSorterComponentModule,
    SavedDashboardFiltersComponentModule
  ],
})
export class DashboardQueryModule { }
