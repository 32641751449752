import { NgModule } from '@angular/core';

import {
  PostProjekteHochzaehlenCommandServiceModule,
  PostProjekteHochzaehlenCommandComponentModule
} from './';

@NgModule({
  imports: [
    PostProjekteHochzaehlenCommandServiceModule,
    PostProjekteHochzaehlenCommandComponentModule
  ],
  exports: [
    PostProjekteHochzaehlenCommandServiceModule,
    PostProjekteHochzaehlenCommandComponentModule
  ]
})
export class PostProjekteHochzaehlenCommandModule { }
