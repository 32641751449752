import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { FormulareServiceModule } from '../';
import { FormulareListComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    FormulareServiceModule
  ],
  declarations: [FormulareListComponent],
  exports: [FormulareListComponent],

})
export class FormulareListComponentModule { }
