import { NgModule } from '@angular/core';
import { 
  PostAbschnittCommandModule,
  DeleteAbschnittCommandModule,
  DeleteBerichtsVorlageCommandModule,
  PostBerichtsVorlageCommandModule,
  PostSortiereAbschnitteCommandModule,
  DeleteVorlageCommandModule,
  PostVorlageCommandModule
} from './';

@NgModule({
  declarations: [],
  imports: [
    PostAbschnittCommandModule,
    DeleteAbschnittCommandModule,
    DeleteBerichtsVorlageCommandModule,
    PostBerichtsVorlageCommandModule,
    PostSortiereAbschnitteCommandModule,
    DeleteVorlageCommandModule,
    PostVorlageCommandModule
  ],
  exports: [
    PostAbschnittCommandModule,
    DeleteAbschnittCommandModule,
    DeleteBerichtsVorlageCommandModule,
    PostBerichtsVorlageCommandModule,
    PostSortiereAbschnitteCommandModule,
    DeleteVorlageCommandModule,
    PostVorlageCommandModule
  ]
})
export class TexteCommandsModule { }
