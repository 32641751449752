import { Component } from '@angular/core';
import { KundenService } from "@fa-kt-evaluation/kunden/frontend";

/* << imports    */
 
/*    imports >> */
@Component({
  selector: 'fa-kt-evaluation-saved-kunden-filters',
  templateUrl: './saved-kunden-filters.component.html',
  styleUrls: ['./saved-kunden-filters.component.css']
})
export class SavedKundenFiltersComponent {

  constructor(
    public kunden: KundenService
/* << injection    */
/*    injection >> */
  ) {
  }
/* << fields   */
 
/*   fields >> */
/* << methods   */
 
/*   methods >> */
}