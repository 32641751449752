import { NgModule } from '@angular/core';
import {
  ReisekostenabrechnungenFilterComponentModule,
  ReisekostenabrechnungenListComponentModule,
  ReisekostenabrechnungenSorterComponentModule,
  SavedReisekostenabrechnungenFiltersComponentModule
} from './';

@NgModule({
  imports: [
    ReisekostenabrechnungenFilterComponentModule,
    ReisekostenabrechnungenListComponentModule,
    ReisekostenabrechnungenSorterComponentModule,
    SavedReisekostenabrechnungenFiltersComponentModule
  ],
  exports: [
    ReisekostenabrechnungenFilterComponentModule,
    ReisekostenabrechnungenListComponentModule,
    ReisekostenabrechnungenSorterComponentModule,
    SavedReisekostenabrechnungenFiltersComponentModule
  ],
})
export class ReisekostenabrechnungenQueryModule { }
