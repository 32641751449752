import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { BerichtsVorlagenServiceModule } from '../';
import { BerichtsVorlagenListComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    BerichtsVorlagenServiceModule
  ],
  declarations: [BerichtsVorlagenListComponent],
  exports: [BerichtsVorlagenListComponent],

})
export class BerichtsVorlagenListComponentModule { }
