import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostDateiHochladenCommandServiceModule } from '../';
import { PostDateiHochladenCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostDateiHochladenCommandServiceModule
  ],
  declarations: [PostDateiHochladenCommandComponent],
  exports: [PostDateiHochladenCommandComponent],

})
export class PostDateiHochladenCommandComponentModule { }
