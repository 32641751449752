import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IPostFormularKopierenRequest, IPostFormularKopierenResponse, IPostFormularKopierenCommandShapes } from '@fa-kt-evaluation/formulare/types';

@Injectable({
  providedIn: 'root'
})
export class PostFormularKopierenCommandService {

  readonly route = 'formulare/formular-kopieren';
  protected dependencies = [
      'gutachten/written-bewertungs-anlass',
      'gutachten/removed-bewertungs-anlass',
      'kunden/written-kunden-art',
      'kunden/removed-kunden-art',
      'objekte/written-objekt-art',
      'objekte/removed-objekt-art',
      'formulare/formular-kopiert'
  ];

  readonly $shapes = new BehaviorSubject<IPostFormularKopierenCommandShapes|undefined>(undefined);
  
  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IPostFormularKopierenCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IPostFormularKopierenCommandShapes>(this.route + '/post/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IPostFormularKopierenRequest): Promise<IPostFormularKopierenResponse> {
    return await this.http.post<IPostFormularKopierenResponse>(this.route, payload) as unknown as IPostFormularKopierenResponse;
  }
  
}
