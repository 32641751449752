import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostExtraVereinbarungCommandServiceModule } from '../';
import { PostExtraVereinbarungCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostExtraVereinbarungCommandServiceModule
  ],
  declarations: [PostExtraVereinbarungCommandComponent],
  exports: [PostExtraVereinbarungCommandComponent],

})
export class PostExtraVereinbarungCommandComponentModule { }
