import { NgModule } from '@angular/core';
import {
  ReisekostenabrechnungFilterComponentModule,
  ReisekostenabrechnungDetailComponentModule,
  ReisekostenabrechnungSorterComponentModule,
  SavedReisekostenabrechnungFiltersComponentModule
} from './';

@NgModule({
  imports: [
    ReisekostenabrechnungFilterComponentModule,
    ReisekostenabrechnungDetailComponentModule,
    ReisekostenabrechnungSorterComponentModule,
    SavedReisekostenabrechnungFiltersComponentModule
  ],
  exports: [
    ReisekostenabrechnungFilterComponentModule,
    ReisekostenabrechnungDetailComponentModule,
    ReisekostenabrechnungSorterComponentModule,
    SavedReisekostenabrechnungFiltersComponentModule
  ],
})
export class ReisekostenabrechnungQueryModule { }
