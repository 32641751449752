import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { first, map, debounceTime, distinctUntilChanged } from "rxjs/operators";

import { isEqual } from "lodash";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { IPersistedFilter, IPersistedFilterList } from "@lib/filter/types";
import { ISelection } from "@lib/persistence/types";

import {
  IBerichtRequest,
  IBerichtResponse,
  IBerichtResponseGroup,
  IBerichtQueryFilterShapes
} from '@fa-kt-evaluation/markt/types';

@Injectable({
  providedIn: 'root'
})
export class BerichtService {

  protected route = 'markt/bericht';
  protected dependencies = [
    'geo/written-bereich',
    'geo/removed-bereich',
    'markt/written-herausgeber',
    'markt/removed-herausgeber',

    'markt/bericht-entfernt',
    'markt/bericht-gespeichert',
    'markt/bericht-hochgeladen',
    'markt/zeile-korrigiert'
  ];

  readonly $result = new BehaviorSubject<IBerichtResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IBerichtRequest|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IBerichtQueryFilterShapes|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private device: DeviceConfigService,
  ) {
/* << changes    */
/*    changes >> */
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IBerichtRequest): IBerichtRequest {
    if (payload) {
      this.$payload.next(payload as IBerichtRequest);
    } else {
      payload = this.$payload.getValue() as IBerichtRequest;
    }
    return payload;
  }

  public async request(payload: IBerichtRequest, force = false): Promise<IBerichtResponse> {
    const state = this.$result.getValue();
    if (!force && !this.device.alwaysRefresh && state && isEqual(payload, this.payload())) { return state; }
    payload = this.payload(payload);
    // if (!payload) { return this.$result.getValue() as IBerichtResponse; }
    const query = this.http.serialize(payload);
    const { response, shapes } = await this.http.get<IBerichtResponseGroup>(this.route + query);
    this.$result.next(response);
    this.$shapes.next(shapes);
    return response;
  }


  getZeilenBereiche(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['zeilen.bereiche']?.find(v => v.value === value)
  }

  get zeilenBereiche(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['zeilen.bereiche'] as ISelection[] : []));
  }

  getBerichtHerausgeber(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['bericht.herausgeber']?.find(v => v.value === value)
  }

  get berichtHerausgeber(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['bericht.herausgeber'] as ISelection[] : []));
  }


}
