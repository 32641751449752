import { Component, OnInit, OnDestroy, Inject, Input, Output, EventEmitter, Injector, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { NzMessageService } from "ng-zorro-antd/message";

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { AuthService } from "@lib/auth/frontend";
import {
  ActionService,
  cleanObject, toInitials,
  CustomValidators, CustomFormatter,
  ISitemap, ISitemapCommand, SITEMAP,
} from "@lib/common/frontend";

import { IPostTourRequest, IPostTourResponse } from "@fa-kt-evaluation/besichtigungen/types";
import { PostTourCommandService } from '@fa-kt-evaluation/besichtigungen/frontend'

/* << importing    */
import { DependenciesService } from "@lib/auth/frontend";

/*    importing >> */

@Component({
  selector: 'fa-kt-evaluation-post-tour',
  templateUrl: './post-tour.component.html',
  styleUrls: ['./post-tour.component.css']
})
export class PostTourCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) { this.$loading.next(this.$loading.getValue() || loading); }
  @Input() set value(value: IPostTourRequest) { this.patch(value); }
  @Output() valueChanged: EventEmitter<IPostTourRequest> = new EventEmitter<IPostTourRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostTourResponse> = new EventEmitter<IPostTourResponse>();

/* << fields    */
/*    fields >> */

  get modalRef() {
    return this.injector.get(NzModalRef)
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public postTour: PostTourCommandService,
/* << more    */
    private dependencies: DependenciesService
/*    more >> */
  ) {
    this.command = sitemap.BESICHTIGUNGEN.Commands.POST_TOUR;
    this.form = this.fb.group({
      tour: this.fb.group({
        id: [null, []],
        tourStatus: [null, [Validators.required]],
        mitarbeiter: [null, [Validators.required]],
        verantwortlich: [null, []],
        am: [null, [Validators.required]],
        startIstPrivatAdresse: [null, []],
        endeIstPrivatAdresse: [null, []],
        start: [],
        ende: [],
        strecke: [null, []],
        kosten: [null, []],
        abgerechnet: [null, []],
        kommentar: [null, []],
      })
    });
/* << constructor    */
/*    constructor >> */
    this.patch();
  }

  ngOnInit() {
    this.postTour.prepare().catch(e => console.error(e));
    this.subscriptions.push(
      this.finished.subscribe((result) => { if (this.modalRef) { this.modalRef.destroy(result); }}),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() })
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostTourRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),
      this.tourId?.valueChanges.subscribe(async (id) => {
/* << on-tour-id-change    */
/*    on-tour-id-change >> */
      }) as Subscription,
      this.tourTourStatus?.valueChanges.subscribe(async (tourStatus) => {
/* << on-tour-tour-status-change    */
/*    on-tour-tour-status-change >> */
      }) as Subscription,
      this.tourMitarbeiter?.valueChanges.subscribe(async (mitarbeiter) => {
/* << on-tour-mitarbeiter-change    */
/*    on-tour-mitarbeiter-change >> */
      }) as Subscription,
      this.tourAm?.valueChanges.subscribe(async (am) => {
/* << on-tour-am-change    */
/*    on-tour-am-change >> */
      }) as Subscription,
      this.tourStartIstPrivatAdresse?.valueChanges.subscribe(async (startIstPrivatAdresse) => {
/* << on-tour-start-ist-privat-adresse-change    */
/*    on-tour-start-ist-privat-adresse-change >> */
      }) as Subscription,
      this.tourEndeIstPrivatAdresse?.valueChanges.subscribe(async (endeIstPrivatAdresse) => {
/* << on-tour-ende-ist-privat-adresse-change    */
/*    on-tour-ende-ist-privat-adresse-change >> */
      }) as Subscription,
      this.tourStart?.valueChanges.subscribe(async (start) => {
/* << on-tour-start-change    */
/*    on-tour-start-change >> */
      }) as Subscription,
      this.tourEnde?.valueChanges.subscribe(async (ende) => {
/* << on-tour-ende-change    */
/*    on-tour-ende-change >> */
      }) as Subscription,
      this.tourStrecke?.valueChanges.subscribe(async (strecke) => {
/* << on-tour-strecke-change    */
/*    on-tour-strecke-change >> */
      }) as Subscription,
      this.tourKosten?.valueChanges.subscribe(async (kosten) => {
/* << on-tour-kosten-change    */
/*    on-tour-kosten-change >> */
      }) as Subscription,
      this.tourAbgerechnet?.valueChanges.subscribe(async (abgerechnet) => {
/* << on-tour-abgerechnet-change    */
/*    on-tour-abgerechnet-change >> */
      }) as Subscription,
      this.tourKommentar?.valueChanges.subscribe(async (kommentar) => {
/* << on-tour-kommentar-change    */
/*    on-tour-kommentar-change >> */
      }) as Subscription,
/* << subscibe    */
/*    subscibe >> */
    );
/* << init    */
/*    init >> */
  }

  ngOnDestroy() {
/* << end    */
/*    end >> */
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IPostTourRequest) {
    if (value) {
      value = cleanObject(value);
      if (value.tour) {
        value.tour = cleanObject(value.tour);
      }
      this.form.patchValue(value);
/* << custom    */
/*    custom >> */
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload.tour) {
        payload.tour = cleanObject(payload.tour);
      }
      const response: IPostTourResponse = await this.postTour.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of (error.errors ? error.errors : [])) {
        for (const children of (validation.children ? validation.children : [])) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

/* << methods    */

  mitarbeiterAdresse() {
    const mitarbeiter = this.dependencies.$users.getValue();
    const addresse = mitarbeiter?.mitarbeiter.find(m => m.mitarbeiter.id === this.tourMitarbeiter?.value)?.mitarbeiter.addresse;
    return addresse ? `"${addresse?.strasse}, ${addresse?.plz}"`: 'unbekannt';
  }
/*    methods >> */

  get tourId() {
    return this.form.controls.tour.get('id');
  };
  get tourTourStatus() {
    return this.form.controls.tour.get('tourStatus');
  };
  get tourMitarbeiter() {
    return this.form.controls.tour.get('mitarbeiter');
  };
  get tourAm() {
    return this.form.controls.tour.get('am');
  };
  get tourStartIstPrivatAdresse() {
    return this.form.controls.tour.get('startIstPrivatAdresse');
  };
  get tourEndeIstPrivatAdresse() {
    return this.form.controls.tour.get('endeIstPrivatAdresse');
  };
  get tourStart() {
    return this.form.controls.tour.get('start');
  };
  get tourEnde() {
    return this.form.controls.tour.get('ende');
  };
  get tourStrecke() {
    return this.form.controls.tour.get('strecke');
  };
  get tourKosten() {
    return this.form.controls.tour.get('kosten');
  };
  get tourAbgerechnet() {
    return this.form.controls.tour.get('abgerechnet');
  };
  get tourKommentar() {
    return this.form.controls.tour.get('kommentar');
  };


}
