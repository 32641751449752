import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { FormulareCommandsModule } from './commands'
import {
  FormulareQuerysModule,
  DurchgangService,
  FormularService,
  FormulareService
} from './querys'
import {
} from './settings';

const routes: Routes = [
  {
    path: 'formulare',
    loadChildren: () => import('./pages').then(m => m.FormularePagesModule)
  },
  {
    path: 'formulare',
    children: [
      {
        path: 'settings',
        loadChildren: () => import('./settings').then(m => m.FormulareSettingsModule),
      }
    ]
  },
];

@NgModule({
  imports: [
    FormulareCommandsModule,
    FormulareQuerysModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    FormulareCommandsModule,
    FormulareQuerysModule,
  ]
})
export class FormulareModule {
  static forRoot(): ModuleWithProviders< FormulareModule> {
    return {
      ngModule: FormulareModule,
      providers: [
        DurchgangService,
        FormularService,
        FormulareService,
      ]
    };
  }
}
