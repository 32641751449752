import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { first, map, debounceTime, distinctUntilChanged } from "rxjs/operators";

import { isEqual } from "lodash";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { FilterPipe, SearchPipe, SorterPipe } from "@lib/filter/frontend";
import { IPersistedFilter, IPersistedFilterList } from "@lib/filter/types";
import { ISelection } from "@lib/persistence/types";

import {
  IFormulareRequest,
  IFormulareResponse,
  IFormulareResponseGroup,
  IFormulareQuerySorter,
  IFormulareQueryFilter,
  IFormulareQueryFilterShapes
} from '@fa-kt-evaluation/formulare/types';

@Injectable({
  providedIn: 'root'
})
export class FormulareService {

  protected route = 'formulare/formulare';
  protected dependencies = [
    'gutachten/written-bewertungs-anlass',
    'gutachten/removed-bewertungs-anlass',
    'kunden/written-kunden-art',
    'kunden/removed-kunden-art',
    'objekte/written-objekt-art',
    'objekte/removed-objekt-art',

    'formulare/durchgang-gestartet',
    'formulare/formular-entfernt',
    'formulare/formular-gespeichert',
    'formulare/formular-kopiert'
  ];

  readonly $result = new BehaviorSubject<IFormulareResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IFormulareRequest|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IFormulareQueryFilterShapes|undefined>(undefined);
  readonly $response = new BehaviorSubject<IFormulareResponse|undefined>(undefined);
  readonly $filter = new BehaviorSubject<IFormulareQueryFilter>({});
  readonly $sorter = new BehaviorSubject<IFormulareQuerySorter>({});
  readonly $search = new BehaviorSubject<string>('');
  readonly $presets = new BehaviorSubject<IPersistedFilter<IFormulareResponse>[]>([]);
  readonly $preset = new BehaviorSubject<IPersistedFilter<IFormulareResponse>|undefined>(undefined);
  private readonly $filtered = new BehaviorSubject<IFormulareResponse|undefined>(undefined);
  private readonly $searched = new BehaviorSubject<IFormulareResponse|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private device: DeviceConfigService,
    private search: SearchPipe,
    private filter: FilterPipe,
    private sorter: SorterPipe,
  ) {
/* << changes    */
/*    changes >> */
    combineLatest([
      this.$response,
      this.$filter,
    ]).subscribe(([response, filter]) => {
      if (response && response.formulare) {
        const formulare = this.filter.transform(response.formulare, filter);
        this.$filtered.next( { ...response, formulare });
      } else {
        this.$filtered.next(response);
      }
    });
    combineLatest([
      this.$filtered,
      this.$search.pipe(debounceTime(device.debounceTime), distinctUntilChanged()),
      this.$shapes.pipe(distinctUntilChanged((a, b) => isEqual(a, b)))
    ]).subscribe(([filtered, search, shapes]) => {
      if (filtered && filtered.formulare) {
        const formulare = this.search.transform(filtered.formulare, search, '_search', shapes);
        this.$searched.next( { ...filtered, formulare });
      } else {
        this.$searched.next(filtered);
      }
    });
    combineLatest([
      this.$searched,
      this.$sorter.pipe(distinctUntilChanged((a, b) => isEqual(a, b))),
      this.$shapes.pipe(distinctUntilChanged((a, b) => isEqual(a, b)))
    ]).pipe(debounceTime(10)).subscribe(([sorted, sorter, shapes]) => {
      if (sorted && sorted.formulare) {
        const formulare = this.sorter.transform(sorted.formulare, sorter, shapes);
        this.$result.next( { ...sorted, formulare });
      } else {
        this.$result.next(sorted);
      }
    });
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IFormulareRequest): IFormulareRequest {
    if (payload) {
      this.$payload.next(payload as IFormulareRequest);
    } else {
      payload = this.$payload.getValue() as IFormulareRequest;
    }
    return payload;
  }

  public async request(payload: IFormulareRequest, force = false): Promise<IFormulareResponse> {
    const state = this.$response.getValue();
    if (!force && !this.device.alwaysRefresh && state && isEqual(payload, this.payload())) { return state; }
    payload = this.payload(payload);
    if (!payload) { return this.$response.getValue() as IFormulareResponse; }
    const query = this.http.serialize(payload);
    const { response, shapes, filter } = await this.http.get<IFormulareResponseGroup>(this.route + query);
    this.$response.next(response);
    this.$shapes.next(shapes);
    this.$presets.next(filter && filter.filters ? filter.filters : []);
    return response;
  }

  public async writeFilter(filter: IPersistedFilter<IFormulareResponse>): Promise<IPersistedFilter<IFormulareResponse>|undefined> {
    filter = { ...filter, term: this.$search.getValue(), sorter: this.$sorter.getValue(), filter: this.$filter.getValue() };
    const result = await this.http.post(this.route + '/filters', filter) as IPersistedFilterList<IFormulareResponse>;
    filter = result.filters.find(f => f.name === filter.name) as IPersistedFilter<IFormulareResponse>;
    this.$presets.next(result.filters ? result.filters : []);
    this.setFilter(filter);
    return filter;
  }

  public async deleteFilter(id: string): Promise<void> {
    const result = await this.http.delete(this.route + '/filters?id=' + id) as IPersistedFilterList<IFormulareResponse>;
    this.$presets.next(result.filters ? result.filters : []);
    this.setFilter();
  }

  public setFilter(filter?: IPersistedFilter<IFormulareResponse>): void {
    this.$search.next(filter && filter.term ? filter.term : '');
    this.$filter.next(filter && filter.filter ? filter.filter : {});
    this.$sorter.next(filter && filter.sorter ? filter.sorter : {});
    this.$preset.next(filter ? filter : undefined);
  }


  getFormularBewertungsAnlaesse(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['formular.bewertungsAnlaesse']?.find(v => v.value === value)
  }

  get formularBewertungsAnlaesse(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['formular.bewertungsAnlaesse'] as ISelection[] : []));
  }

  getFormularOhneFelder(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['formular.ohneFelder']?.find(v => v.value === value)
  }

  get formularOhneFelder(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['formular.ohneFelder'] as ISelection[] : []));
  }

  getFormularKunden(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['formular.kunden']?.find(v => v.value === value)
  }

  get formularKunden(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['formular.kunden'] as ISelection[] : []));
  }

  getFormularKundenArten(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['formular.kundenArten']?.find(v => v.value === value)
  }

  get formularKundenArten(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['formular.kundenArten'] as ISelection[] : []));
  }

  getFormularObjektArten(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['formular.objektArten']?.find(v => v.value === value)
  }

  get formularObjektArten(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['formular.objektArten'] as ISelection[] : []));
  }


}
