import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { ProjektServiceModule } from '../';
import { SavedProjektFiltersComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ProjektServiceModule
  ],
  declarations: [SavedProjektFiltersComponent],
  exports: [SavedProjektFiltersComponent],

})
export class SavedProjektFiltersComponentModule { }
