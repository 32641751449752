import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IPostStartTourRequest, IPostStartTourResponse, IPostStartTourCommandShapes } from '@fa-kt-evaluation/besichtigungen/types';

@Injectable({
  providedIn: 'root'
})
export class PostStartTourCommandService {

  readonly route = 'besichtigungen/start-tour';
  protected dependencies = [
      'besichtigungen/written-besichtigungs-status',
      'besichtigungen/removed-besichtigungs-status',
      'besichtigungen/written-tour-status',
      'besichtigungen/removed-tour-status',
      'besichtigungen/tour-gestartet'
  ];

  readonly $shapes = new BehaviorSubject<IPostStartTourCommandShapes|undefined>(undefined);
  
  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IPostStartTourCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IPostStartTourCommandShapes>(this.route + '/post/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IPostStartTourRequest): Promise<IPostStartTourResponse> {
    return await this.http.post<IPostStartTourResponse>(this.route, payload) as unknown as IPostStartTourResponse;
  }
  
  getBesichtigungBesichtigungsStatus(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['besichtigung.besichtigungsStatus']?.find(v => v.value === value)
  }

  get besichtigungBesichtigungsStatus(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['besichtigung.besichtigungsStatus'] as ISelection[] : []));
  }

  getTourMitarbeiter(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['tour.mitarbeiter']?.find(v => v.value === value)
  }

  get tourMitarbeiter(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['tour.mitarbeiter'] as ISelection[] : []));
  }

  getBesichtigungMitarbeiter(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['besichtigung.mitarbeiter']?.find(v => v.value === value)
  }

  get besichtigungMitarbeiter(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['besichtigung.mitarbeiter'] as ISelection[] : []));
  }

  getTourTourStatus(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['tour.tourStatus']?.find(v => v.value === value)
  }

  get tourTourStatus(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['tour.tourStatus'] as ISelection[] : []));
  }

}
