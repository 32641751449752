import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { BerichtsVorlagenServiceModule } from '../';
import { SavedBerichtsVorlagenFiltersComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    BerichtsVorlagenServiceModule
  ],
  declarations: [SavedBerichtsVorlagenFiltersComponent],
  exports: [SavedBerichtsVorlagenFiltersComponent],

})
export class SavedBerichtsVorlagenFiltersComponentModule { }
