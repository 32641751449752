import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { ReisekostenabrechnungenServiceModule } from '../';
import { ReisekostenabrechnungenSorterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ReisekostenabrechnungenServiceModule
  ],
  declarations: [ReisekostenabrechnungenSorterComponent],
  exports: [ReisekostenabrechnungenSorterComponent],

})
export class ReisekostenabrechnungenSorterComponentModule { }
