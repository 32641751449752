import { NgModule } from '@angular/core';

import {
  PostProjektCommandServiceModule,
  PostProjektCommandComponentModule
} from './';

@NgModule({
  imports: [
    PostProjektCommandServiceModule,
    PostProjektCommandComponentModule
  ],
  exports: [
    PostProjektCommandServiceModule,
    PostProjektCommandComponentModule
  ]
})
export class PostProjektCommandModule { }
