import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { DeleteObjektCommandService } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [DeleteObjektCommandService],
  exports: []
})
export class DeleteObjektCommandServiceModule { }
