<nz-content style="padding: 64px">
  <nz-row nzSpan="24">
    <nz-col>
      <nz-breadcrumb>
        <nz-breadcrumb-item> <span nz-typography nzType="secondary">👋</span></nz-breadcrumb-item>
      </nz-breadcrumb>
    </nz-col>
    <nz-col nzSpan="24" style="padding-top: 36px">
      <br>
      <h1 nz-title>Willkommen,</h1>
    </nz-col>
    <nz-col nzSpan="24">
      <h4 nz-title>bei den Apps für <a href="https://www.fa-kt-valuation.de/kontakt.html">FA|KT Valuation Chartered Surveyors</a></h4>
    </nz-col>
  <ng-container  *ngIf="!(auth.$id | async)">
      <nz-col nzSpan="24" style="padding-top: 36px">
        <button nz-button nzType="primary" (click)="auth.login()">Anmelden</button>
      </nz-col>
      <nz-col nzSpan="24">
        <span nz-typography nzType="secondary" (click)="auth.login()"> Bitte mit Ihrem Windows-Account anmelden. <br> Dazu sollten Pop-Ups für diese Seite erlaubt werden.</span>
      </nz-col>
  </ng-container>
    <nz-col nzSpan="24" style="padding-top: 36px">
      <codeboard-health></codeboard-health>
    </nz-col>
  </nz-row>
</nz-content>
