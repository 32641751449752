import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { NzButtonModule } from "ng-zorro-antd/button";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import { NzSpaceModule } from "ng-zorro-antd/space";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzFormModule } from "ng-zorro-antd/form";

import {
  FilterComponent,
  FilterPresetComponent,
  SearchBarComponent,
  SelectValueComponent,
  SorterComponent,
  MultiValueComponent
} from "./components";
import { PipeLoadingService } from "./services";
import { FilterPipe, SearchPipe, SorterPipe, CheckPipe, TypePipe, SafePipe, OrderPipe, ReversePipe } from "./pipes";
import { LIB_AuthModule } from "@lib/auth/frontend";
import { NzEmptyModule } from "ng-zorro-antd/empty";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzInputNumberModule } from "ng-zorro-antd/input-number";
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzDropDownModule,
    NzModalModule,
    NzFormModule,
    NzPageHeaderModule,
    NzSpaceModule,
    NzGridModule,
    NzInputModule,
    NzSelectModule,
    NzAvatarModule,
    NzIconModule,
    NzEmptyModule,
    NzDatePickerModule,
    NzCheckboxModule,
    NzInputNumberModule,
    NzPopconfirmModule
  ],
  declarations: [
    FilterComponent,
    SorterComponent,
    FilterPresetComponent,
    SearchBarComponent,
    SelectValueComponent,
    MultiValueComponent,
    FilterPipe,
    SorterPipe,
    SearchPipe,
    TypePipe,
    CheckPipe,
    OrderPipe,
    SafePipe,
    ReversePipe
  ],
  providers: [
    PipeLoadingService,
    PipeLoadingService,
  ],
  exports: [
    FilterComponent,
    SorterComponent,
    FilterPresetComponent,
    SearchBarComponent,
    SelectValueComponent,
    MultiValueComponent,
    FilterPipe,
    SorterPipe,
    SearchPipe,
    TypePipe,
    CheckPipe,
    OrderPipe,
    SafePipe,
    ReversePipe
  ]
})
export class LIB_FilterModule {}
