import { Inject, Injectable } from "@angular/core";

import { addDays } from "date-fns";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";

import { IIchResponse, IMitarbeiterResponse, IRollesResponse } from "@fa-kt-evaluation/personen/types";
import {
  IchService,
  RollesService,
  MitarbeiterService
} from "@fa-kt-evaluation/personen/frontend";

import { IKonstantesResponse } from "@fa-kt-evaluation/einstellungen/types";
import { KonstantesService } from "@fa-kt-evaluation/einstellungen/frontend";

@Injectable({
  providedIn: 'root'
})
export class DependenciesService {


  private readonly ME_KEY = "me"
  private readonly USERS_KEY = "users"
  private readonly GROUPS_KEY = "groups"
  private readonly CONSTANTS_KEY = "constants"

  $me = new BehaviorSubject<IIchResponse|undefined>(undefined);
  $users = new BehaviorSubject<IMitarbeiterResponse|undefined>(undefined);
  $groups = new BehaviorSubject<IRollesResponse|undefined>(undefined);
  $constants = new BehaviorSubject<IKonstantesResponse|undefined>(undefined);

  constructor(
    @Inject(IchService) private meService: IchService,
    @Inject(MitarbeiterService) private usersService: MitarbeiterService,
    @Inject(RollesService) private groupsService: RollesService,
    @Inject(KonstantesService) private constantsService: KonstantesService,
  ) {
    this.meService.$result.subscribe(result => { if (result) { this.$me.next(result) } });
    this.usersService.$result.subscribe(result => { if (result) { this.$users.next(result) } });
    this.groupsService.$result.subscribe(result => { if (result) { this.$groups.next(result) } });
    this.constantsService.$result.subscribe(result => { if (result) { this.$constants.next(result) } });
    try {
      const { me, expires } = JSON.parse(localStorage.getItem(this.ME_KEY) as string);
      if (me && new Date() > expires) {
        this.$me.next(me);
      }
    } catch (e) {}
    try {
      const { users, expires } = JSON.parse(localStorage.getItem(this.USERS_KEY) as string);
      if (users && new Date() > expires) {
        this.$users.next(users);
      }
    } catch (e) {}
    try {
      const { groups, expires } = JSON.parse(localStorage.getItem(this.GROUPS_KEY) as string);
      if (groups && new Date() > expires) {
        this.$groups.next(groups);
      }
    } catch (e) {}
    try {
      const { constants, expires } = JSON.parse(localStorage.getItem(this.CONSTANTS_KEY) as string);
      if (constants && new Date() > expires) {
        this.$constants.next(constants);
      }
    } catch (e) {}
    this.$me.subscribe(me => { if (me) { localStorage.setItem(this.ME_KEY, JSON.stringify({ me, expires: addDays(new Date(), 14) })); } });
    this.$users.subscribe(users => { if (users) { localStorage.setItem(this.USERS_KEY, JSON.stringify({ users, expires: addDays(new Date(), 14) })); } });
    this.$groups.subscribe(groups => { if (groups) { localStorage.setItem(this.GROUPS_KEY, JSON.stringify({ groups, expires: addDays(new Date(), 14) })); } });
    this.$constants.subscribe(constants => { if (constants) { localStorage.setItem(this.CONSTANTS_KEY, JSON.stringify({ constants, expires: addDays(new Date(), 14) })); } });
  }

  async get(id: string) {
    const [me] = await Promise.all([
      this.meService.request({ id }),
      this.usersService.request({}),
      this.groupsService.request({}),
      this.constantsService.request({}),
    ])
    console.debug(me);
    return me;
  }

  get me() {
    return this.$me;
  }
  get users() {
    return this.$users.pipe(map((state) => state ? state.mitarbeiter.map(e => e.mitarbeiter) : []));
  }
  get groups() {
    return this.$groups.pipe(map((state) => state ? state.rolles : []));
  }

  get constants() {
    return this.$constants.pipe(map((state) => state ? state.konstantes : []));
  }

  get projekt_dauer(): number {
    const constants = this.$constants.getValue()?.konstantes || [];
    return Number(constants.find(c => c.art === 'projekt_dauer')?.inhalt) || 14;
  }
}
