import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { TourServiceModule } from '../';
import { TourFilterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    TourServiceModule
  ],
  declarations: [TourFilterComponent],
  exports: [TourFilterComponent],

})
export class TourFilterComponentModule { }
