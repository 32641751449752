import { Component, OnInit, OnDestroy, Inject, Input, Output, EventEmitter, Injector, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { NzMessageService } from "ng-zorro-antd/message";

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { AuthService } from "@lib/auth/frontend";
import {
  ActionService,
  cleanObject, toInitials,
  CustomValidators, CustomFormatter, 
  ISitemap, ISitemapCommand, SITEMAP,
} from "@lib/common/frontend";

import { IPostBesichtigungRequest, IPostBesichtigungResponse } from "@fa-kt-evaluation/besichtigungen/types";
import { PostBesichtigungCommandService } from '@fa-kt-evaluation/besichtigungen/frontend'

/* << importing    */
/*    importing >> */

@Component({
  selector: 'fa-kt-evaluation-post-besichtigung',
  templateUrl: './post-besichtigung.component.html',
  styleUrls: ['./post-besichtigung.component.css']
})
export class PostBesichtigungCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) { this.$loading.next(this.$loading.getValue() || loading); }
  @Input() set value(value: IPostBesichtigungRequest) { this.patch(value); }
  @Output() valueChanged: EventEmitter<IPostBesichtigungRequest> = new EventEmitter<IPostBesichtigungRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostBesichtigungResponse> = new EventEmitter<IPostBesichtigungResponse>();

/* << fields    */
/*    fields >> */

  get modalRef() {
    return this.injector.get(NzModalRef)
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public postBesichtigung: PostBesichtigungCommandService,
/* << more    */
/*    more >> */
  ) {
    this.command = sitemap.BESICHTIGUNGEN.Commands.POST_BESICHTIGUNG;
    this.form = this.fb.group({
      besichtigungen: this.fb.group({
        id: [null, []],
        projekt: [null, []],
        tour: [null, []],
        gutachten: [null, []],
        objekt: [null, []],
        besichtigungsStatus: [null, [Validators.required]],
        mitarbeiter: [null, []],
        kommentar: [null, []],
        rueckfahrt: [null, []],
        von: [null, []],
        bis: [null, []],
        start: [],
        ende: [],
        aufenthalt: [null, []],
        fahrtzeit: [null, []],
        fahrtstrecke: [null, []],
        fahrtzeitOhneVerkehr: [null, []],
        kosten: [null, []],
        abgerechnet: [null, []],
        route: [null, []],
      })
    });
/* << constructor    */
/*    constructor >> */
    this.patch();
  }

  ngOnInit() {
    this.postBesichtigung.prepare().catch(e => console.error(e));
    this.subscriptions.push(
      this.finished.subscribe((result) => { if (this.modalRef) { this.modalRef.destroy(result); }}),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() })
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostBesichtigungRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),
      this.besichtigungenId?.valueChanges.subscribe(async (id) => {
/* << on-besichtigungen-id-change    */
/*    on-besichtigungen-id-change >> */
      }) as Subscription,
      this.besichtigungenProjekt?.valueChanges.subscribe(async (projekt) => {
/* << on-besichtigungen-projekt-change    */
/*    on-besichtigungen-projekt-change >> */
      }) as Subscription,
      this.besichtigungenTour?.valueChanges.subscribe(async (tour) => {
/* << on-besichtigungen-tour-change    */
/*    on-besichtigungen-tour-change >> */
      }) as Subscription,
      this.besichtigungenGutachten?.valueChanges.subscribe(async (gutachten) => {
/* << on-besichtigungen-gutachten-change    */
/*    on-besichtigungen-gutachten-change >> */
      }) as Subscription,
      this.besichtigungenObjekt?.valueChanges.subscribe(async (objekt) => {
/* << on-besichtigungen-objekt-change    */
/*    on-besichtigungen-objekt-change >> */
      }) as Subscription,
      this.besichtigungenBesichtigungsStatus?.valueChanges.subscribe(async (besichtigungsStatus) => {
/* << on-besichtigungen-besichtigungs-status-change    */
/*    on-besichtigungen-besichtigungs-status-change >> */
      }) as Subscription,
      this.besichtigungenMitarbeiter?.valueChanges.subscribe(async (mitarbeiter) => {
/* << on-besichtigungen-mitarbeiter-change    */
/*    on-besichtigungen-mitarbeiter-change >> */
      }) as Subscription,
      this.besichtigungenKommentar?.valueChanges.subscribe(async (kommentar) => {
/* << on-besichtigungen-kommentar-change    */
/*    on-besichtigungen-kommentar-change >> */
      }) as Subscription,
      this.besichtigungenRueckfahrt?.valueChanges.subscribe(async (rueckfahrt) => {
/* << on-besichtigungen-rueckfahrt-change    */
/*    on-besichtigungen-rueckfahrt-change >> */
      }) as Subscription,
      this.besichtigungenVon?.valueChanges.subscribe(async (von) => {
/* << on-besichtigungen-von-change    */
/*    on-besichtigungen-von-change >> */
      }) as Subscription,
      this.besichtigungenBis?.valueChanges.subscribe(async (bis) => {
/* << on-besichtigungen-bis-change    */
/*    on-besichtigungen-bis-change >> */
      }) as Subscription,
      this.besichtigungenStart?.valueChanges.subscribe(async (start) => {
/* << on-besichtigungen-start-change    */
/*    on-besichtigungen-start-change >> */
      }) as Subscription,
      this.besichtigungenEnde?.valueChanges.subscribe(async (ende) => {
/* << on-besichtigungen-ende-change    */
/*    on-besichtigungen-ende-change >> */
      }) as Subscription,
      this.besichtigungenAufenthalt?.valueChanges.subscribe(async (aufenthalt) => {
/* << on-besichtigungen-aufenthalt-change    */
/*    on-besichtigungen-aufenthalt-change >> */
      }) as Subscription,
      this.besichtigungenFahrtzeit?.valueChanges.subscribe(async (fahrtzeit) => {
/* << on-besichtigungen-fahrtzeit-change    */
/*    on-besichtigungen-fahrtzeit-change >> */
      }) as Subscription,
      this.besichtigungenFahrtstrecke?.valueChanges.subscribe(async (fahrtstrecke) => {
/* << on-besichtigungen-fahrtstrecke-change    */
/*    on-besichtigungen-fahrtstrecke-change >> */
      }) as Subscription,
      this.besichtigungenFahrtzeitOhneVerkehr?.valueChanges.subscribe(async (fahrtzeitOhneVerkehr) => {
/* << on-besichtigungen-fahrtzeit-ohne-verkehr-change    */
/*    on-besichtigungen-fahrtzeit-ohne-verkehr-change >> */
      }) as Subscription,
      this.besichtigungenKosten?.valueChanges.subscribe(async (kosten) => {
/* << on-besichtigungen-kosten-change    */
/*    on-besichtigungen-kosten-change >> */
      }) as Subscription,
      this.besichtigungenAbgerechnet?.valueChanges.subscribe(async (abgerechnet) => {
/* << on-besichtigungen-abgerechnet-change    */
/*    on-besichtigungen-abgerechnet-change >> */
      }) as Subscription,
      this.besichtigungenRoute?.valueChanges.subscribe(async (route) => {
/* << on-besichtigungen-route-change    */
/*    on-besichtigungen-route-change >> */
      }) as Subscription,
/* << subscibe    */
/*    subscibe >> */
    );
/* << init    */
/*    init >> */
  }

  ngOnDestroy() {
/* << end    */
/*    end >> */
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IPostBesichtigungRequest) {
    if (value) {
      value = cleanObject(value);
      if (value.besichtigungen) {
        value.besichtigungen = cleanObject(value.besichtigungen);
      }
      this.form.patchValue(value);
/* << custom    */
/*    custom >> */
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload.besichtigungen) {
        payload.besichtigungen = cleanObject(payload.besichtigungen);
      }
      const response: IPostBesichtigungResponse = await this.postBesichtigung.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of (error.errors ? error.errors : [])) {
        for (const children of (validation.children ? validation.children : [])) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

/* << methods    */
/*    methods >> */

  get besichtigungenId() {
    return this.form.controls.besichtigungen.get('id');
  };
  get besichtigungenProjekt() {
    return this.form.controls.besichtigungen.get('projekt');
  };
  get besichtigungenTour() {
    return this.form.controls.besichtigungen.get('tour');
  };
  get besichtigungenGutachten() {
    return this.form.controls.besichtigungen.get('gutachten');
  };
  get besichtigungenObjekt() {
    return this.form.controls.besichtigungen.get('objekt');
  };
  get besichtigungenBesichtigungsStatus() {
    return this.form.controls.besichtigungen.get('besichtigungsStatus');
  };
  get besichtigungenMitarbeiter() {
    return this.form.controls.besichtigungen.get('mitarbeiter');
  };
  get besichtigungenKommentar() {
    return this.form.controls.besichtigungen.get('kommentar');
  };
  get besichtigungenRueckfahrt() {
    return this.form.controls.besichtigungen.get('rueckfahrt');
  };
  get besichtigungenVon() {
    return this.form.controls.besichtigungen.get('von');
  };
  get besichtigungenBis() {
    return this.form.controls.besichtigungen.get('bis');
  };
  get besichtigungenStart() {
    return this.form.controls.besichtigungen.get('start');
  };
  get besichtigungenEnde() {
    return this.form.controls.besichtigungen.get('ende');
  };
  get besichtigungenAufenthalt() {
    return this.form.controls.besichtigungen.get('aufenthalt');
  };
  get besichtigungenFahrtzeit() {
    return this.form.controls.besichtigungen.get('fahrtzeit');
  };
  get besichtigungenFahrtstrecke() {
    return this.form.controls.besichtigungen.get('fahrtstrecke');
  };
  get besichtigungenFahrtzeitOhneVerkehr() {
    return this.form.controls.besichtigungen.get('fahrtzeitOhneVerkehr');
  };
  get besichtigungenKosten() {
    return this.form.controls.besichtigungen.get('kosten');
  };
  get besichtigungenAbgerechnet() {
    return this.form.controls.besichtigungen.get('abgerechnet');
  };
  get besichtigungenRoute() {
    return this.form.controls.besichtigungen.get('route');
  };


}
