import { Component, OnInit } from '@angular/core';
import { HTTPS_METHOD } from '@lib/https/types';
import { OfflineCacheService } from "../../services";

@Component({
  selector: 'codeboard-offline-cache',
  templateUrl: './offline-cache.component.html',
  styleUrls: ['./offline-cache.component.css']
})
export class OfflineCacheComponent implements OnInit {

  public HTTPS_METHOD = HTTPS_METHOD;

  constructor(public offlineCache: OfflineCacheService) { }

  ngOnInit(): void {
  }

}
