import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { RechnungsVorlagenServiceModule } from '../';
import { SavedRechnungsVorlagenFiltersComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    RechnungsVorlagenServiceModule
  ],
  declarations: [SavedRechnungsVorlagenFiltersComponent],
  exports: [SavedRechnungsVorlagenFiltersComponent],

})
export class SavedRechnungsVorlagenFiltersComponentModule { }
