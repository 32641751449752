import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { DurchsuchenServiceModule } from '../';
import { DurchsuchenFilterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    DurchsuchenServiceModule
  ],
  declarations: [DurchsuchenFilterComponent],
  exports: [DurchsuchenFilterComponent],

})
export class DurchsuchenFilterComponentModule { }
