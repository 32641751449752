import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { ZeitServiceModule } from '../';
import { ZeitSorterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ZeitServiceModule
  ],
  declarations: [ZeitSorterComponent],
  exports: [ZeitSorterComponent],

})
export class ZeitSorterComponentModule { }
