import { NgModule } from '@angular/core';

import {
  PostRechnungRunterzaehlenCommandServiceModule,
  PostRechnungRunterzaehlenCommandComponentModule
} from './';

@NgModule({
  imports: [
    PostRechnungRunterzaehlenCommandServiceModule,
    PostRechnungRunterzaehlenCommandComponentModule
  ],
  exports: [
    PostRechnungRunterzaehlenCommandServiceModule,
    PostRechnungRunterzaehlenCommandComponentModule
  ]
})
export class PostRechnungRunterzaehlenCommandModule { }
