import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { TourenServiceModule } from '../';
import { TourenFilterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    TourenServiceModule
  ],
  declarations: [TourenFilterComponent],
  exports: [TourenFilterComponent],

})
export class TourenFilterComponentModule { }
