import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostRechnungenHochzaehlenCommandService } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [PostRechnungenHochzaehlenCommandService],
  exports: []
})
export class PostRechnungenHochzaehlenCommandServiceModule { }
