import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { BerichteServiceModule } from '../';
import { BerichteListComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    BerichteServiceModule
  ],
  declarations: [BerichteListComponent],
  exports: [BerichteListComponent],

})
export class BerichteListComponentModule { }
