import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostTourCommandServiceModule } from '../';
import { PostTourCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostTourCommandServiceModule
  ],
  declarations: [PostTourCommandComponent],
  exports: [PostTourCommandComponent],

})
export class PostTourCommandComponentModule { }
