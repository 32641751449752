import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostArchivierenCommandServiceModule } from '../';
import { PostArchivierenCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostArchivierenCommandServiceModule
  ],
  declarations: [PostArchivierenCommandComponent],
  exports: [PostArchivierenCommandComponent],

})
export class PostArchivierenCommandComponentModule { }
