import { Component } from '@angular/core';
import { UmkreissucheService } from "@fa-kt-evaluation/gutachten/frontend";

/* << imports    */
 
/*    imports >> */
@Component({
  selector: 'fa-kt-evaluation-saved-umkreissuche-filters',
  templateUrl: './saved-umkreissuche-filters.component.html',
  styleUrls: ['./saved-umkreissuche-filters.component.css']
})
export class SavedUmkreissucheFiltersComponent {

  constructor(
    public umkreissuche: UmkreissucheService
/* << injection    */
/*    injection >> */
  ) {
  }
}