import { Pipe, PipeTransform } from "@angular/core";

import get from "lodash/get";
import * as fuzzysort from 'fuzzysort';

import { ISelection } from "@lib/persistence/types";
import { PipeLoadingService } from "../services";

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  constructor(private loading: PipeLoadingService) {
  }

  transform(items?: Array<any>, term?: any, key= '_search', shapes: any|null = null): Array<any> {
    if (!items || items.length === 0){ return [] };
    if (!term || term.length === 0){ return items };
    this.loading.loading();
    let replacements: ISelection[] = [];
    let results: Array<any>
    if (/\d/g.test(term) && key === '_search') {
      term = this.equalize(term, replacements);
      const scope = items.map((original) => ({ original, 'a': this.resolve(original, key, replacements) }));
      results = scope.filter(r => r.a.includes(term)).map((result) => result.original);
    } else {
      if (shapes) {
        replacements = [].concat(...Object.entries(shapes).filter(([key]) => key[0] !== '_' && key !== 'id')
          .map(([key]) => get(shapes, key, 'unknown')).filter((value) => value !== 'unknown'));
      }
      term = this.equalize(term, replacements);
      const scope = items.map((original) => ({ original, 'a': this.resolve(original, key, replacements) }));
      results = scope.filter(r => r.a.includes(term)).map((result) => result.original);
    }
    this.loading.loaded();
    return results;
  }

  resolve(item: any, key: string, replacements: ISelection[]): string {
    let resolved: string = key ? get(item, key, JSON.stringify(item)) : JSON.stringify(item);
    return this.equalize(resolved, replacements);
  }

  equalize(term: string, replacements: ISelection[] = []): string {
    term = term.toLowerCase().split(' ').join('');
    replacements.forEach(replacement => term = term.split(replacement.value).join(replacement.label))
    return term;
  }

}
