<!---
  <div formGroupName="gutachten">
    <input class="cdk-visually-hidden" formControlName="id" id="id"/>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="projekt">{{ command.Inputs.PROJEKT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ProjektErrorTip">
        <ng-template #ProjektErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.PROJEKT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="projekt" id="projekt" [shapes]="postGutachten.gutachtenProjekt | async" [placeholder]="command.Inputs.PROJEKT.placeholder" cdkFocusInitial required=true ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="objekt">{{ command.Inputs.OBJEKT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ObjektErrorTip">
        <ng-template #ObjektErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.OBJEKT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="objekt" id="objekt" [shapes]="postGutachten.gutachtenObjekt | async" [placeholder]="command.Inputs.OBJEKT.placeholder" required=true ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="bewertungsAnlass">{{ command.Inputs.BEWERTUNGS_ANLASS.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BewertungsAnlassErrorTip">
        <ng-template #BewertungsAnlassErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BEWERTUNGS_ANLASS.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="bewertungsAnlass" id="bewertungsAnlass" [shapes]="postGutachten.gutachtenBewertungsAnlass | async" [placeholder]="command.Inputs.BEWERTUNGS_ANLASS.placeholder" required=true ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="bewertungsStatus">{{ command.Inputs.BEWERTUNGS_STATUS.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BewertungsStatusErrorTip">
        <ng-template #BewertungsStatusErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BEWERTUNGS_STATUS.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="bewertungsStatus" id="bewertungsStatus" [shapes]="postGutachten.gutachtenBewertungsStatus | async" [placeholder]="command.Inputs.BEWERTUNGS_STATUS.placeholder" required=true ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="objektNutzung">{{ command.Inputs.OBJEKT_NUTZUNG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ObjektNutzungErrorTip">
        <ng-template #ObjektNutzungErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.OBJEKT_NUTZUNG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="objektNutzung" id="objektNutzung" [shapes]="postGutachten.gutachtenObjektNutzung | async" [placeholder]="command.Inputs.OBJEKT_NUTZUNG.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="objektArt">{{ command.Inputs.OBJEKT_ART.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ObjektArtErrorTip">
        <ng-template #ObjektArtErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.OBJEKT_ART.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="objektArt" id="objektArt" [shapes]="postGutachten.gutachtenObjektArt | async" [placeholder]="command.Inputs.OBJEKT_ART.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="sicherheitscheck">{{ command.Inputs.SICHERHEITSCHECK.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="SicherheitscheckErrorTip">
        <ng-template #SicherheitscheckErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.SICHERHEITSCHECK.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="sicherheitscheck" id="sicherheitscheck" [shapes]="postGutachten.gutachtenSicherheitscheck | async" [placeholder]="command.Inputs.SICHERHEITSCHECK.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="abgabeDraft">{{ command.Inputs.ABGABE_DRAFT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AbgabeDraftErrorTip">
        <ng-template #AbgabeDraftErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ABGABE_DRAFT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <nz-date-picker formControlName="abgabeDraft" id="abgabeDraft"  nzFormat="dd.MM.yyyy"></nz-date-picker>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="abgabeFinal">{{ command.Inputs.ABGABE_FINAL.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AbgabeFinalErrorTip">
        <ng-template #AbgabeFinalErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ABGABE_FINAL.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <nz-date-picker formControlName="abgabeFinal" id="abgabeFinal"  nzFormat="dd.MM.yyyy"></nz-date-picker>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="erbbaurecht">{{ command.Inputs.ERBBAURECHT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ErbbaurechtErrorTip">
        <ng-template #ErbbaurechtErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ERBBAURECHT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <label nz-checkbox formControlName="erbbaurecht" id="erbbaurecht"></label>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="wohnenJRoE">{{ command.Inputs.WOHNEN_J_RO_E.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="WohnenJRoEErrorTip">
        <ng-template #WohnenJRoEErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.WOHNEN_J_RO_E.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="wohnenJRoE" id="wohnenJRoE" [placeholder]="command.Inputs.WOHNEN_J_RO_E.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="wohnenJRoEFlaeche">{{ command.Inputs.WOHNEN_J_RO_E_FLAECHE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="WohnenJRoEFlaecheErrorTip">
        <ng-template #WohnenJRoEFlaecheErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.WOHNEN_J_RO_E_FLAECHE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="wohnenJRoEFlaeche" id="wohnenJRoEFlaeche" [placeholder]="command.Inputs.WOHNEN_J_RO_E_FLAECHE.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="gewerbeJRoE">{{ command.Inputs.GEWERBE_J_RO_E.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="GewerbeJRoEErrorTip">
        <ng-template #GewerbeJRoEErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.GEWERBE_J_RO_E.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="gewerbeJRoE" id="gewerbeJRoE" [placeholder]="command.Inputs.GEWERBE_J_RO_E.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="gewerbeJRoEFlaeche">{{ command.Inputs.GEWERBE_J_RO_E_FLAECHE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="GewerbeJRoEFlaecheErrorTip">
        <ng-template #GewerbeJRoEFlaecheErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.GEWERBE_J_RO_E_FLAECHE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="gewerbeJRoEFlaeche" id="gewerbeJRoEFlaeche" [placeholder]="command.Inputs.GEWERBE_J_RO_E_FLAECHE.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="bueroJRoE">{{ command.Inputs.BUERO_J_RO_E.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BueroJRoEErrorTip">
        <ng-template #BueroJRoEErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BUERO_J_RO_E.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="bueroJRoE" id="bueroJRoE" [placeholder]="command.Inputs.BUERO_J_RO_E.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="bueroJRoEFlaeche">{{ command.Inputs.BUERO_J_RO_E_FLAECHE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BueroJRoEFlaecheErrorTip">
        <ng-template #BueroJRoEFlaecheErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BUERO_J_RO_E_FLAECHE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="bueroJRoEFlaeche" id="bueroJRoEFlaeche" [placeholder]="command.Inputs.BUERO_J_RO_E_FLAECHE.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="handelJRoE">{{ command.Inputs.HANDEL_J_RO_E.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="HandelJRoEErrorTip">
        <ng-template #HandelJRoEErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.HANDEL_J_RO_E.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="handelJRoE" id="handelJRoE" [placeholder]="command.Inputs.HANDEL_J_RO_E.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="handelJRoEFlaeche">{{ command.Inputs.HANDEL_J_RO_E_FLAECHE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="HandelJRoEFlaecheErrorTip">
        <ng-template #HandelJRoEFlaecheErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.HANDEL_J_RO_E_FLAECHE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="handelJRoEFlaeche" id="handelJRoEFlaeche" [placeholder]="command.Inputs.HANDEL_J_RO_E_FLAECHE.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="lagerJRoE">{{ command.Inputs.LAGER_J_RO_E.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="LagerJRoEErrorTip">
        <ng-template #LagerJRoEErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.LAGER_J_RO_E.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="lagerJRoE" id="lagerJRoE" [placeholder]="command.Inputs.LAGER_J_RO_E.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="lagerJRoEFlaeche">{{ command.Inputs.LAGER_J_RO_E_FLAECHE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="LagerJRoEFlaecheErrorTip">
        <ng-template #LagerJRoEFlaecheErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.LAGER_J_RO_E_FLAECHE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="lagerJRoEFlaeche" id="lagerJRoEFlaeche" [placeholder]="command.Inputs.LAGER_J_RO_E_FLAECHE.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="sonstigesJRoE">{{ command.Inputs.SONSTIGES_J_RO_E.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="SonstigesJRoEErrorTip">
        <ng-template #SonstigesJRoEErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.SONSTIGES_J_RO_E.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="sonstigesJRoE" id="sonstigesJRoE" [placeholder]="command.Inputs.SONSTIGES_J_RO_E.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="sonstigesJRoEFlaeche">{{ command.Inputs.SONSTIGES_J_RO_E_FLAECHE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="SonstigesJRoEFlaecheErrorTip">
        <ng-template #SonstigesJRoEFlaecheErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.SONSTIGES_J_RO_E_FLAECHE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="sonstigesJRoEFlaeche" id="sonstigesJRoEFlaeche" [placeholder]="command.Inputs.SONSTIGES_J_RO_E_FLAECHE.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="baujahr">{{ command.Inputs.BAUJAHR.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BaujahrErrorTip">
        <ng-template #BaujahrErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BAUJAHR.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="baujahr" id="baujahr" [placeholder]="command.Inputs.BAUJAHR.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="modernisierung">{{ command.Inputs.MODERNISIERUNG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ModernisierungErrorTip">
        <ng-template #ModernisierungErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MODERNISIERUNG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="modernisierung" id="modernisierung" [placeholder]="command.Inputs.MODERNISIERUNG.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="marktwert">{{ command.Inputs.MARKTWERT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MarktwertErrorTip">
        <ng-template #MarktwertErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MARKTWERT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="marktwert" id="marktwert" [placeholder]="command.Inputs.MARKTWERT.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="stichtagMarktwert">{{ command.Inputs.STICHTAG_MARKTWERT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="StichtagMarktwertErrorTip">
        <ng-template #StichtagMarktwertErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STICHTAG_MARKTWERT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <nz-date-picker formControlName="stichtagMarktwert" id="stichtagMarktwert"  nzFormat="dd.MM.yyyy"></nz-date-picker>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kaufpreis">{{ command.Inputs.KAUFPREIS.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KaufpreisErrorTip">
        <ng-template #KaufpreisErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KAUFPREIS.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="kaufpreis" id="kaufpreis" [placeholder]="command.Inputs.KAUFPREIS.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="stichtagKaufpreis">{{ command.Inputs.STICHTAG_KAUFPREIS.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="StichtagKaufpreisErrorTip">
        <ng-template #StichtagKaufpreisErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STICHTAG_KAUFPREIS.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <nz-date-picker formControlName="stichtagKaufpreis" id="stichtagKaufpreis"  nzFormat="dd.MM.yyyy"></nz-date-picker>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fortschritt">{{ command.Inputs.FORTSCHRITT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FortschrittErrorTip">
        <ng-template #FortschrittErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FORTSCHRITT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="fortschritt" id="fortschritt" [placeholder]="command.Inputs.FORTSCHRITT.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="aktiv">{{ command.Inputs.AKTIV.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AktivErrorTip">
        <ng-template #AktivErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.AKTIV.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <label nz-checkbox formControlName="aktiv" id="aktiv"></label>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kommentar">{{ command.Inputs.KOMMENTAR.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KommentarErrorTip">
        <ng-template #KommentarErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KOMMENTAR.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <textarea nz-input formControlName="kommentar" id="kommentar" [placeholder]="command.Inputs.KOMMENTAR.placeholder"></textarea>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="tourenplanung">{{ command.Inputs.TOURENPLANUNG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="TourenplanungErrorTip">
        <ng-template #TourenplanungErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.TOURENPLANUNG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <label nz-checkbox formControlName="tourenplanung" id="tourenplanung"></label>
      </nz-form-control>
    </nz-form-item>
  </div>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
<!--- << custom    -->
    <div formGroupName="gutachten">
      <input class="cdk-visually-hidden" formControlName="id" id="id"/>
      <input class="cdk-visually-hidden" formControlName="projekt" id="projekt"/>
      <input class="cdk-visually-hidden" formControlName="objekt" id="objekt"/>
      <input class="cdk-visually-hidden" formControlName="sicherheitscheck" id="sicherheitscheck"/>
      <input class="cdk-visually-hidden" formControlName="fortschritt" id="fortschritt"/>
      <input class="cdk-visually-hidden" formControlName="aktiv" id="aktiv"/>

      <nz-divider id="allgemein" nzText="Allgemein" nzOrientation="left"></nz-divider>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="objekt">{{ command.Inputs.OBJEKT.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ObjektErrorTip">
          <ng-template #ObjektErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.OBJEKT.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <nz-input-group [style.width]="'100%'">
            <button nz-button nzType="primary" type="button" (click)="selected.emit()"><i nz-icon nzType="edit" nzTheme="outline"></i></button>
            <span nz-typography> {{ gutachtenObjekt.value | type: ($objekte | async) }}</span>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="abgabeDraft">{{ command.Inputs.ABGABE_DRAFT.title}}</nz-form-label>
        <nz-form-control [nzSm]="2" [nzXs]="24" [nzErrorTip]="AbgabeDraftErrorTip">
          <ng-template #AbgabeDraftErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ABGABE_DRAFT.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <nz-date-picker formControlName="abgabeDraft" id="abgabeDraft"  nzFormat="dd.MM.yyyy"></nz-date-picker>
        </nz-form-control>
        <nz-form-label [nzSm]="3" [nzXs]="24" nzFor="datum_besichtigung">Besichtigung</nz-form-label>
        <nz-form-control [nzSm]="2" [nzXs]="24" [nzErrorTip]="AbgabeBesErrorTip">
          <ng-template #AbgabeBesErrorTip let-control>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <nz-date-picker formControlName="datum_besichtigung" id="datum_besichtigung"  nzFormat="dd.MM.yyyy"></nz-date-picker>
        </nz-form-control>
        <nz-form-label [nzSm]="3" [nzXs]="24" nzFor="abgabeFinal">{{ command.Inputs.ABGABE_FINAL.title}}</nz-form-label>
        <nz-form-control [nzSm]="2" [nzXs]="24" [nzErrorTip]="AbgabeFinalErrorTip">
          <ng-template #AbgabeFinalErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ABGABE_FINAL.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <nz-date-picker formControlName="abgabeFinal" id="abgabeFinal"  nzFormat="dd.MM.yyyy"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="bewertungsStatus">{{ command.Inputs.BEWERTUNGS_STATUS.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BewertungsStatusErrorTip">
          <ng-template #BewertungsStatusErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BEWERTUNGS_STATUS.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <codeboard-select-value formControlName="bewertungsStatus" id="bewertungsStatus" [shapes]="postGutachten.gutachtenBewertungsStatus | async" [placeholder]="command.Inputs.BEWERTUNGS_STATUS.placeholder" required=true ></codeboard-select-value>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="sicherheitscheck">{{ command.Inputs.SICHERHEITSCHECK.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="SicherheitscheckErrorTip">
          <ng-template #SicherheitscheckErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.SICHERHEITSCHECK.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <ng-container [ngSwitch]="!!(gutachtenSicherheitscheck?.value)">
            <button *ngSwitchCase="true" nz-button [nzBlock]="true" nzType="default" type="button" (click)="check()" class="confirmed">
              Sicherheitsprüfung
              <i nz-icon nzType="check-circle" nzTheme="outline"></i>
            </button>
            <button *ngSwitchCase="false" nz-button [nzBlock]="true" nzType="default" type="button" (click)="check()" nzDanger>
              Sicherheitsprüfung
              <i nz-icon nzType="close-circle" nzTheme="outline"></i>
            </button>
          </ng-container>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="tourenplanung">{{ command.Inputs.TOURENPLANUNG.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="TourenplanungErrorTip">
          <ng-template #TourenplanungErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.TOURENPLANUNG.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <label nz-checkbox formControlName="tourenplanung" id="tourenplanung"></label>
        </nz-form-control>
      </nz-form-item>


      <nz-divider id="besichtigung" nzText="Besichtigung" nzOrientation="left"></nz-divider>

      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="besichtigung_kontakt">Kontakt</nz-form-label>
        <nz-form-control [nzSm]="5" [nzXs]="24" [nzErrorTip]="MarktwertErrorTip">
          <ng-template #MarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input formControlName="besichtigung_kontakt" id="besichtigung_kontakt" [placeholder]="'Kontakt'" />
        </nz-form-control>
        <nz-form-label [nzSm]="4" [nzXs]="24" nzFor="besichtigung_email">E-Mail</nz-form-label>
        <nz-form-control [nzSm]="3" [nzXs]="24" [nzErrorTip]="StichtagMarktwertErrorTip">
          <ng-template #StichtagMarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <nz-input-group [nzAddOnAfter]="OpenEmail">
            <input nz-input formControlName="besichtigung_email" id="besichtigung_email" [placeholder]="'E-Mail'" />
            <ng-template #OpenEmail>
              <a [href]="'mailto:' + gutachtenEmail?.value" target="_blank">
                <i nz-icon nzType="mail" nzTheme="outline"></i>
              </a>
            </ng-template>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="besichtigung_festnetz">Festnetz</nz-form-label>
        <nz-form-control [nzSm]="5" [nzXs]="24" [nzErrorTip]="MarktwertErrorTip">
          <ng-template #MarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <nz-input-group [nzAddOnAfter]="CallMobilePhone">
            <input nz-input formControlName="besichtigung_festnetz" id="besichtigung_festnetz" [placeholder]="'Festnetz'" />
            <ng-template #CallMobilePhone>
              <a [href]="'tel:' + gutachtenFestnetz.value" target="_blank">
                <i nz-icon nzType="phone" nzTheme="outline"></i>
              </a>
            </ng-template>
          </nz-input-group>
        </nz-form-control>
        <nz-form-label [nzSm]="4" [nzXs]="24" nzFor="besichtigung_mobil">Mobil</nz-form-label>
        <nz-form-control [nzSm]="3" [nzXs]="24" [nzErrorTip]="StichtagMarktwertErrorTip">
          <ng-template #StichtagMarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <nz-input-group [nzAddOnAfter]="CallMobilePhone">
            <input nz-input formControlName="besichtigung_mobil" id="besichtigung_mobil" [placeholder]="'Mobil'" />
            <ng-template #CallMobilePhone>
              <a [href]="'tel:' + gutachtenMobil.value" target="_blank">
                <i nz-icon nzType="phone" nzTheme="outline"></i>
              </a>
            </ng-template>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-divider id="bewertung" nzText="Bewertungsdaten" nzOrientation="left"></nz-divider>

      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="objektNutzung">{{ command.Inputs.OBJEKT_NUTZUNG.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ObjektNutzungErrorTip">
          <ng-template #ObjektNutzungErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.OBJEKT_NUTZUNG.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <codeboard-select-value formControlName="objektNutzung" id="objektNutzung" [shapes]="postGutachten.gutachtenObjektNutzung | async" [placeholder]="command.Inputs.OBJEKT_NUTZUNG.placeholder" required=true ></codeboard-select-value>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="objektArt">{{ command.Inputs.OBJEKT_ART.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ObjektArtErrorTip">
          <ng-template #ObjektArtErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.OBJEKT_ART.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <codeboard-select-value formControlName="objektArt" id="objektArt" [shapes]="postGutachten.gutachtenObjektArt | async" [filter]="gutachtenObjektNutzung?.value" [placeholder]="command.Inputs.OBJEKT_ART.placeholder" required=true></codeboard-select-value>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="erbbaurecht">Erbbaurecht</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="TourenplanungErrorTip">
          <ng-template #TourenplanungErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Erbbaurecht</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <label nz-checkbox formControlName="erbbaurecht" id="erbbaurecht"></label>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="wohnenJRoE">Wohnen: JRoE 	</nz-form-label>
        <nz-form-control [nzSm]="5" [nzXs]="24" [nzErrorTip]="MarktwertErrorTip">
          <ng-template #MarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="wohnenJRoE" id="wohnenJRoE" [placeholder]="'Wohnen: JRoE'"/> €
        </nz-form-control>
        <nz-form-label [nzSm]="4" [nzXs]="24" nzFor="wohnenJRoEFlaeche">Fläche</nz-form-label>
        <nz-form-control [nzSm]="3" [nzXs]="24" [nzErrorTip]="StichtagMarktwertErrorTip">
          <ng-template #StichtagMarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="wohnenJRoEFlaeche" id="wohnenJRoEFlaeche" [placeholder]="'Wohnen'"/> m²
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="gewerbeJRoE">Gewerbe: JRoE (alt)</nz-form-label>
        <nz-form-control [nzSm]="5" [nzXs]="24" [nzErrorTip]="MarktwertErrorTip">
          <ng-template #MarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="gewerbeJRoE" id="gewerbeJRoE" [placeholder]="'Gewerbe: JRoE (alt)'"/> €
        </nz-form-control>
        <nz-form-label [nzSm]="4" [nzXs]="24" nzFor="gewerbeJRoEFlaeche">Fläche</nz-form-label>
        <nz-form-control [nzSm]="3" [nzXs]="24" [nzErrorTip]="StichtagMarktwertErrorTip">
          <ng-template #StichtagMarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="gewerbeJRoEFlaeche" id="gewerbeJRoEFlaeche" [placeholder]="'Gewerbe'"/> m²
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="bueroJRoE">Büro: JRoE</nz-form-label>
        <nz-form-control [nzSm]="5" [nzXs]="24" [nzErrorTip]="MarktwertErrorTip">
          <ng-template #MarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="bueroJRoE" id="bueroJRoE" [placeholder]="'Büro: JRoE'"/> €
        </nz-form-control>
        <nz-form-label [nzSm]="4" [nzXs]="24" nzFor="bueroJRoEFlaeche">Fläche</nz-form-label>
        <nz-form-control [nzSm]="3" [nzXs]="24" [nzErrorTip]="StichtagMarktwertErrorTip">
          <ng-template #StichtagMarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="bueroJRoEFlaeche" id="bueroJRoEFlaeche" [placeholder]="'Büro'"/> m²
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="handelJRoE">Handel: JRoE</nz-form-label>
        <nz-form-control [nzSm]="5" [nzXs]="24" [nzErrorTip]="MarktwertErrorTip">
          <ng-template #MarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="handelJRoE" id="handelJRoE" [placeholder]="'Handel: JRoE'"/> €
        </nz-form-control>
        <nz-form-label [nzSm]="4" [nzXs]="24" nzFor="handelJRoEFlaeche">Fläche</nz-form-label>
        <nz-form-control [nzSm]="3" [nzXs]="24" [nzErrorTip]="StichtagMarktwertErrorTip">
          <ng-template #StichtagMarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="handelJRoEFlaeche" id="handelJRoEFlaeche" [placeholder]="'Handel'"/> m²
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="lagerJRoE">Lager: JRoE</nz-form-label>
        <nz-form-control [nzSm]="5" [nzXs]="24" [nzErrorTip]="MarktwertErrorTip">
          <ng-template #MarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="lagerJRoE" id="lagerJRoE" [placeholder]="'Lager: JRoE'"/> €
        </nz-form-control>
        <nz-form-label [nzSm]="4" [nzXs]="24" nzFor="lagerJRoEFlaeche">Fläche</nz-form-label>
        <nz-form-control [nzSm]="3" [nzXs]="24" [nzErrorTip]="StichtagMarktwertErrorTip">
          <ng-template #StichtagMarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="lagerJRoEFlaeche" id="lagerJRoEFlaeche" [placeholder]="'Lager'"/> m²
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="sonstigesJRoE">Sonstiges: JRoE</nz-form-label>
        <nz-form-control [nzSm]="5" [nzXs]="24" [nzErrorTip]="MarktwertErrorTip">
          <ng-template #MarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="sonstigesJRoE" id="sonstigesJRoE" [placeholder]="'Sonstiges: JRoE'"/> €
        </nz-form-control>
        <nz-form-label [nzSm]="4" [nzXs]="24" nzFor="sonstigesJRoEFlaeche">Fläche</nz-form-label>
        <nz-form-control [nzSm]="3" [nzXs]="24" [nzErrorTip]="StichtagMarktwertErrorTip">
          <ng-template #StichtagMarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="sonstigesJRoEFlaeche" id="sonstigesJRoEFlaeche" [placeholder]="'Sonstiges'"/> m²
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="jahresrohertrag">Summe JRoE</nz-form-label>
        <nz-form-control [nzSm]="5" [nzXs]="24" [nzErrorTip]="MarktwertErrorTip">
          <ng-template #MarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="jahresrohertrag" id="jahresrohertrag" [placeholder]="'JRoE'"/> €
        </nz-form-control>
        <nz-form-label [nzSm]="4" [nzXs]="24" nzFor="flaeche">Summe</nz-form-label>
        <nz-form-control [nzSm]="3" [nzXs]="24" [nzErrorTip]="StichtagMarktwertErrorTip">
          <ng-template #StichtagMarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="flaeche" id="flaeche" [placeholder]="'Fläche'"/> m²
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="baujahr">Baujahr</nz-form-label>
        <nz-form-control [nzSm]="5" [nzXs]="24" [nzErrorTip]="MarktwertErrorTip">
          <ng-template #MarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Baujahr</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="baujahr" id="baujahr" [placeholder]="'Baujahr'"/>
        </nz-form-control>
        <nz-form-label [nzSm]="4" [nzXs]="24" nzFor="modernisierung">Modernisierung</nz-form-label>
        <nz-form-control [nzSm]="3" [nzXs]="24" [nzErrorTip]="StichtagMarktwertErrorTip">
          <ng-template #StichtagMarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Modernisierung</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="modernisierung" id="modernisierung" [placeholder]="'Modernisierung'"/>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="marktwert">{{ command.Inputs.MARKTWERT.title}}</nz-form-label>
        <nz-form-control [nzSm]="5" [nzXs]="24" [nzErrorTip]="MarktwertErrorTip">
          <ng-template #MarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MARKTWERT.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="marktwert" id="marktwert" [placeholder]="command.Inputs.MARKTWERT.placeholder"/> €
        </nz-form-control>
        <nz-form-label [nzSm]="4" [nzXs]="24" nzFor="stichtagMarktwert">Stichtag</nz-form-label>
        <nz-form-control [nzSm]="3" [nzXs]="24" [nzErrorTip]="StichtagMarktwertErrorTip">
          <ng-template #StichtagMarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STICHTAG_MARKTWERT.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <nz-date-picker formControlName="stichtagMarktwert" id="stichtagMarktwert"  nzFormat="dd.MM.yyyy"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kaufpreis">Kaufpreis</nz-form-label>
        <nz-form-control [nzSm]="5" [nzXs]="24" [nzErrorTip]="MarktwertErrorTip">
          <ng-template #MarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Kaufpreis</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="kaufpreis" id="kaufpreis" [placeholder]="'Kaufpreis'"/> €
        </nz-form-control>
        <nz-form-label [nzSm]="4" [nzXs]="24" nzFor="stichtagKaufpreis">Stichtag</nz-form-label>
        <nz-form-control [nzSm]="3" [nzXs]="24" [nzErrorTip]="StichtagMarktwertErrorTip">
          <ng-template #StichtagMarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STICHTAG_MARKTWERT.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <nz-date-picker formControlName="stichtagKaufpreis" id="stichtagKaufpreis"  nzFormat="dd.MM.yyyy"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="gebaeudefaktor">Gebäudefaktor</nz-form-label>
        <nz-form-control [nzSm]="5" [nzXs]="24" [nzErrorTip]="MarktwertErrorTip">
          <ng-template #MarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="gebaeudefaktor" id="gebaeudefaktor" [placeholder]="'Gebäudefaktor'"/> €/m²
        </nz-form-control>
        <nz-form-label [nzSm]="4" [nzXs]="24" nzFor="vervielfaeltiger">Vervielfältiger</nz-form-label>
        <nz-form-control [nzSm]="3" [nzXs]="24" [nzErrorTip]="StichtagMarktwertErrorTip">
          <ng-template #StichtagMarktwertErrorTip let-control>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="vervielfaeltiger" id="vervielfaeltiger" [placeholder]="'Vervielfältiger'"/>
        </nz-form-control>
      </nz-form-item>


      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kommentar">{{ command.Inputs.KOMMENTAR.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KommentarErrorTip">
          <ng-template #KommentarErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KOMMENTAR.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <textarea nz-input formControlName="kommentar" id="kommentar" [placeholder]="command.Inputs.KOMMENTAR.placeholder"></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>
<!---    custom >> -->
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button"
          type="submit"
          [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)"
          nz-tooltip [nzTooltipTitle]="SubmitTooltip">
<!--- << confirm    -->
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Gutachten Speichern
<!---    confirm >> -->
          <ng-template #SubmitTooltip>
<!--- << hint    -->
            Gutachten Speichern
<!---    hint >> -->
          </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
