import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { ZeitenServiceModule } from '../';
import { ZeitenSorterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ZeitenServiceModule
  ],
  declarations: [ZeitenSorterComponent],
  exports: [ZeitenSorterComponent],

})
export class ZeitenSorterComponentModule { }
