import { NgModule } from '@angular/core';

import {
  DeleteVermerkCommandServiceModule,
  DeleteVermerkCommandComponentModule
} from './';

@NgModule({
  imports: [
    DeleteVermerkCommandServiceModule,
    DeleteVermerkCommandComponentModule
  ],
  exports: [
    DeleteVermerkCommandServiceModule,
    DeleteVermerkCommandComponentModule
  ]
})
export class DeleteVermerkCommandModule { }
