import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { TexteCommandsModule } from './commands'
import {
  TexteQuerysModule,
  BerichtsVorlagenService,
  VorlageService,
  VorlagenService
} from './querys'
import {
} from './settings';

const routes: Routes = [
  {
    path: 'texte',
    loadChildren: () => import('./pages').then(m => m.TextePagesModule)
  },
  {
    path: 'texte',
    children: [
      {
        path: 'settings',
        loadChildren: () => import('./settings').then(m => m.TexteSettingsModule),
      }
    ]
  },
];

@NgModule({
  imports: [
    TexteCommandsModule,
    TexteQuerysModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    TexteCommandsModule,
    TexteQuerysModule,
  ]
})
export class TexteModule {
  static forRoot(): ModuleWithProviders< TexteModule> {
    return {
      ngModule: TexteModule,
      providers: [
        BerichtsVorlagenService,
        VorlageService,
        VorlagenService,
      ]
    };
  }
}
