import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { ZeitServiceModule } from '../';
import { SavedZeitFiltersComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ZeitServiceModule
  ],
  declarations: [SavedZeitFiltersComponent],
  exports: [SavedZeitFiltersComponent],

})
export class SavedZeitFiltersComponentModule { }
