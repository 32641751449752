import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { DeleteGutachtenCommandServiceModule } from '../';
import { DeleteGutachtenCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteGutachtenCommandServiceModule
  ],
  declarations: [DeleteGutachtenCommandComponent],
  exports: [DeleteGutachtenCommandComponent],

})
export class DeleteGutachtenCommandComponentModule { }
