import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostRechnungsVorlageCommandServiceModule } from '../';
import { PostRechnungsVorlageCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostRechnungsVorlageCommandServiceModule
  ],
  declarations: [PostRechnungsVorlageCommandComponent],
  exports: [PostRechnungsVorlageCommandComponent],

})
export class PostRechnungsVorlageCommandComponentModule { }
