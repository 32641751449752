import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IPostDurchgangRequest, IPostDurchgangResponse, IPostDurchgangCommandShapes } from '@fa-kt-evaluation/formulare/types';

@Injectable({
  providedIn: 'root'
})
export class PostDurchgangCommandService {

  readonly route = 'formulare/durchgang';
  protected dependencies = [
      'formulare/durchgang-gestartet'
  ];

  readonly $shapes = new BehaviorSubject<IPostDurchgangCommandShapes|undefined>(undefined);
  
  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IPostDurchgangCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IPostDurchgangCommandShapes>(this.route + '/post/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IPostDurchgangRequest): Promise<IPostDurchgangResponse> {
    return await this.http.post<IPostDurchgangResponse>(this.route, payload) as unknown as IPostDurchgangResponse;
  }
  
}
