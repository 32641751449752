import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostKopierenCommandServiceModule } from '../';
import { PostKopierenCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostKopierenCommandServiceModule
  ],
  declarations: [PostKopierenCommandComponent],
  exports: [PostKopierenCommandComponent],

})
export class PostKopierenCommandComponentModule { }
