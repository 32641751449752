import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IPostProjektRequest, IPostProjektResponse, IPostProjektCommandShapes } from '@fa-kt-evaluation/projekte/types';

@Injectable({
  providedIn: 'root'
})
export class PostProjektCommandService {

  readonly route = 'projekte/projekt';
  protected dependencies = [
      'gutachten/written-bewertungs-anlass',
      'gutachten/removed-bewertungs-anlass',
      'projekte/written-projekt-art',
      'projekte/removed-projekt-art',
      'projekte/written-projekt-status',
      'projekte/removed-projekt-status',
      'personen/written-standort',
      'personen/removed-standort',
      'projekte/projekt-gespeichert'
  ];

  readonly $shapes = new BehaviorSubject<IPostProjektCommandShapes|undefined>(undefined);

  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IPostProjektCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IPostProjektCommandShapes>(this.route + '/post/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IPostProjektRequest): Promise<IPostProjektResponse> {
    return await this.http.post<IPostProjektResponse>(this.route, payload) as unknown as IPostProjektResponse;
  }

  getProjektAbteilung(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.abteilung']?.find(v => v.value === value)
  }

  get projektAbteilung(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.abteilung'] as ISelection[] : []));
  }

  getProjektFond(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.fond']?.find(v => v.value === value)
  }

  get projektFond(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.fond'] as ISelection[] : []));
  }

  getProjektKunde(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.kunde']?.find(v => v.value === value)
  }

  get projektKunde(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.kunde'] as ISelection[] : []));
  }

  getProjektBank(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.bank']?.find(v => v.value === value)
  }

  get projektBank(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.bank'] as ISelection[] : []));
  }

  getProjektBesichtigungen(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.besichtigungen']?.find(v => v.value === value)
  }

  get projektBesichtigungen(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.besichtigungen'] as ISelection[] : []));
  }

  getProjektGutachten(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.gutachten']?.find(v => v.value === value)
  }

  get projektGutachten(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.gutachten'] as ISelection[] : []));
  }

  getProjektPruefung(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.pruefung']?.find(v => v.value === value)
  }

  get projektPruefung(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.pruefung'] as ISelection[] : []));
  }

  getProjektProjektArt(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.projektArt']?.find(v => v.value === value)
  }

  get projektProjektArt(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.projektArt'] as ISelection[] : []));
  }

  getProjektProjektStatus(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.projektStatus']?.find(v => v.value === value)
  }

  get projektProjektStatus(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.projektStatus'] as ISelection[] : []));
  }

  get projektBewertungsAnlass(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.bewertungsAnlass'] as ISelection[] : []));
  }

  getProjektStandort(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.standort']?.find(v => v.value === value)
  }

  get projektStandort(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.standort'] as ISelection[] : []));
  }

}
