import { Injectable } from '@angular/core';
import { AbstractControl } from "@angular/forms";

import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, first, map } from "rxjs/operators";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { FilterPipe, SearchPipe, SorterPipe } from "@lib/filter/frontend";
import { ISelection } from "@lib/persistence/types";

import {
  IReisekostenabrechnungStatiRequest,
  IReisekostenabrechnungStatiResponse,
  IReisekostenabrechnungStatiQuerySorter,
  IReisekostenabrechnungStatiQueryFilter,
  IReisekostenabrechnungStatiQueryFilterShapes,
  IReisekostenabrechnungStatiResponseGroup,
} from '@fa-kt-evaluation/finanzen/types';

@Injectable({
  providedIn: 'root'
})
export class ReisekostenabrechnungStatiService {

  protected route = 'finanzen/settings/reisekostenabrechnung-stati';
  protected dependencies = [
    'finanzen/written-reisekostenabrechnung-status',
    'finanzen/removed-reisekostenabrechnung-status',
  ];

  readonly $result = new BehaviorSubject<IReisekostenabrechnungStatiResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IReisekostenabrechnungStatiRequest|undefined>(undefined);
  readonly $response = new BehaviorSubject<IReisekostenabrechnungStatiResponse|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IReisekostenabrechnungStatiQueryFilterShapes|undefined>(undefined);
  readonly $filter = new BehaviorSubject<IReisekostenabrechnungStatiQueryFilter>({});
  readonly $sorter = new BehaviorSubject<IReisekostenabrechnungStatiQuerySorter>({});
  readonly $search = new BehaviorSubject<string>('');
  private readonly $filtered = new BehaviorSubject<IReisekostenabrechnungStatiResponse|undefined>(undefined);
  private readonly $searched = new BehaviorSubject<IReisekostenabrechnungStatiResponse|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private search: SearchPipe,
    private filter: FilterPipe,
    private sorter: SorterPipe,
    private device: DeviceConfigService
  ) {
    combineLatest([
      this.$response,
      this.$filter,
    ]).subscribe(([response, filter]) => {
      if (response && response.reisekostenabrechnungStati) {
        const reisekostenabrechnungStati = this.filter.transform(response.reisekostenabrechnungStati, filter, true);
        this.$filtered.next( { ...response, reisekostenabrechnungStati });
      } else {
        this.$filtered.next(response);
      }
    });
    combineLatest([
      this.$filtered,
      this.$search.pipe(debounceTime(device.debounceTime), distinctUntilChanged()),
      this.$shapes
    ]).subscribe(([filtered, search, shapes]) => {
      if (filtered && filtered.reisekostenabrechnungStati) {
        const reisekostenabrechnungStati = this.search.transform(filtered.reisekostenabrechnungStati, search, '_search', shapes);
        this.$searched.next( { ...filtered, reisekostenabrechnungStati });
      } else {
        this.$searched.next(filtered);
      }
    });
    combineLatest([
      this.$searched,
      this.$sorter,
      this.$shapes
    ]).pipe(debounceTime(10)).subscribe(([searched, sorter, shapes]) => {
      if (searched && searched.reisekostenabrechnungStati) {
        const reisekostenabrechnungStati = this.sorter.transform(searched.reisekostenabrechnungStati, sorter, shapes);
        this.$result.next( { ...searched, reisekostenabrechnungStati });
      } else {
        this.$result.next(searched);
      }
    });
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IReisekostenabrechnungStatiRequest): IReisekostenabrechnungStatiRequest {
    if (payload) {
      this.$payload.next(payload as IReisekostenabrechnungStatiRequest);
    } else {
      payload = this.$payload.getValue() as IReisekostenabrechnungStatiRequest;
    }
    return payload;
  }

  public async request(payload?: IReisekostenabrechnungStatiRequest, force = false): Promise<IReisekostenabrechnungStatiResponse> {
    payload = this.payload(payload);
    const state = this.$response.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const { response, shapes } = await this.http.get<IReisekostenabrechnungStatiResponseGroup>(this.route + this.http.serialize(payload));
    this.$shapes.next(shapes);
    this.$response.next(response);
    return response;
  }
  


}
