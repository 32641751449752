import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostOrdneBesichtigungenCommandServiceModule } from '../';
import { PostOrdneBesichtigungenCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostOrdneBesichtigungenCommandServiceModule
  ],
  declarations: [PostOrdneBesichtigungenCommandComponent],
  exports: [PostOrdneBesichtigungenCommandComponent],

})
export class PostOrdneBesichtigungenCommandComponentModule { }
