import { Component } from '@angular/core';
import { HealthService } from "@lib/common/frontend";

@Component({
  selector: 'codeboard-health',
  templateUrl: './health.component.html',
  styleUrls: ['./health.component.css']
})
export class HealthComponent {

  constructor(public health: HealthService) {
  }

}
