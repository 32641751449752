import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostEintragCommandServiceModule } from '../';
import { PostEintragCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostEintragCommandServiceModule
  ],
  declarations: [PostEintragCommandComponent],
  exports: [PostEintragCommandComponent],

})
export class PostEintragCommandComponentModule { }
