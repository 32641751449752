import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { VorlagenServiceModule } from '../';
import { VorlagenFilterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    VorlagenServiceModule
  ],
  declarations: [VorlagenFilterComponent],
  exports: [VorlagenFilterComponent],

})
export class VorlagenFilterComponentModule { }
