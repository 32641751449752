import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'codeboard-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ImageUploadComponent
    }
  ]
})
export class ImageUploadComponent implements OnInit, OnDestroy, ControlValueAccessor  {

  onChange = (src: string) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;
  visible = false;

  constructor(private modal: NzModalService) {
  }

  image: string = '';
  event: any;

  @Input() set src(url: string|null) {
    this.Src = url ? url : undefined;
  }
  Src: string | undefined;
  @Input()
  text: string = '';
  @Input()
  color: string = '#000';

  @Output()
  uploaded: EventEmitter<string> = new EventEmitter()

  @ViewChild('FileInput')
  fileInput!: ElementRef;

  @ViewChild('ImageCropper')
  imageCropper!: TemplateRef<any>;

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  uploadedImage(event: any): void {
    this.event = event;
    this.modal.confirm({
      nzTitle: 'Neuen Avatar hochladen',
      nzContent: this.imageCropper,
      nzOnOk: () => this.finished()
    });
  }

  imageCropped(event: ImageCroppedEvent) {
    this.image = event.base64 as string;
  }

  finished() {
    this.uploaded.emit(this.image);
    this.markAsTouched();
    this.onChange(this.image);
    this.writeValue(this.image);
    this.image = '';
    this.event = null;
    this.fileInput.nativeElement.value = '';
  }

  reset() {
    this.uploaded.emit('');
    this.markAsTouched();
    this.onChange('');
    this.writeValue('');
    this.image = '';
    this.event = null;
    this.fileInput.nativeElement.value = '';
  }



  writeValue(src: string) {
    this.src = src;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

}
