import { NgModule } from '@angular/core';

import {
  PostZahlungseingangCommandServiceModule,
  PostZahlungseingangCommandComponentModule
} from './';

@NgModule({
  imports: [
    PostZahlungseingangCommandServiceModule,
    PostZahlungseingangCommandComponentModule
  ],
  exports: [
    PostZahlungseingangCommandServiceModule,
    PostZahlungseingangCommandComponentModule
  ]
})
export class PostZahlungseingangCommandModule { }
