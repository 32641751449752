<nz-row>
  <nz-col nzSpan="8">
    <button nz-button type="button" nzType="default" (click)="showObject = !showObject">
      <i *ngIf="!showObject" nz-icon nzType="question-circle" nzTheme="outline"></i>
      <i *ngIf="showObject" nz-icon nzType="play-circle" nzTheme="outline"></i>
      {{ showObject ? 'Ergebnis' : 'Baum'}} anzeigen
    </button>
  </nz-col>
  <nz-col nzSpan="8">
    <button nz-button nzType="primary" type="button" (click)="createPlaceholder()">
      Neues Referenzfeld
    </button>
  </nz-col>
  <nz-col nzSpan="8">
    <button nz-button nzType="primary" type="button" (click)="createCondition()">
      Neue Bedingung
    </button>
  </nz-col>
  <nz-col nzSpan="24"  *ngIf="showPlaceholderSelection || showConditionSelection">
    <br>
    <br>

    <nz-row *ngIf="showPlaceholderSelection">
      <nz-col nzSpan='24'>
        <nz-input-group [nzPrefix]='prefixIconSearch'>
          <ng-template #prefixIconSearch>
            <i nz-icon nzType='search'></i>
          </ng-template>
          <input nz-input type='text'
                 [(ngModel)]="search"
                 placeholder='Suche nach Referenzfeldern'
                 cdkFocusInitial/>
        </nz-input-group>
      </nz-col>
      <nz-col nzSpan='24'>
        <ul nz-menu style="max-height: 500px; overflow-y: auto">
          <li nz-menu-item *ngFor='let placeholder of $placeholders | async | search:search' (click)="insertPlaceholder(placeholder.schluessel)" tabindex="-1">
            <span nz-typography nzEllipsis [nzContent]="placeholder.name"></span> <i style="margin-left: 16px" *ngIf="placeholder.name !== placeholder.schluessel" nz-typography>{{placeholder.schluessel}}</i>
          </li>
        </ul>
      </nz-col>
    </nz-row>

    <nz-row *ngIf="showConditionSelection">
      <nz-col nzSpan='24'>
        <nz-input-group [nzPrefix]='prefixIconSearch'>
          <ng-template #prefixIconSearch>
            <i nz-icon nzType='search'></i>
          </ng-template>
          <input nz-input type='text'
                 [(ngModel)]="search"
                 placeholder='Suche nach Referenzfeldern'
                 cdkFocusInitial/>
        </nz-input-group>
      </nz-col>
      <nz-col nzSpan='24'>
        <ul nz-menu style="max-height: 500px; overflow-y: auto">
          <li nz-menu-item *ngFor='let placeholder of $ifs | async | search:search' (click)="insertCondition(placeholder.schluessel)" tabindex="-1">
            <span nz-typography nzEllipsis [nzContent]="placeholder.name"></span> <i style="margin-left: 16px" *ngIf="placeholder.name !== placeholder.schluessel" nz-typography>{{placeholder.schluessel}}</i>
          </li>
        </ul>
      </nz-col>
    </nz-row>

    <br>
    <br>
  </nz-col>
  <nz-col nzSpan="24">
    <br>
    <p *ngFor="let error of errors">
      <nz-alert nzType="error" [nzMessage]="error">
        <ng-template #error>
          <code>{{error | json}}</code>
        </ng-template>
      </nz-alert>
    </p>
  </nz-col>
  <nz-col nzSpan="24">
    <br>
    <div class="document-editor">
      <div class="document-editor__toolbar"></div>
      <ckeditor class="document-editor__editable-container" #editor [editor]="Editor" [data]="$text | async" (change)="change($event)" [disabled]="disabled" [config]="config" (ready)="onReady($event)"></ckeditor>
    </div>
  </nz-col>
  <nz-col nzSpan="24" *ngIf="!showObject">
    <br>
    <div [innerHTML]="$rendered | async | safe"></div>
  </nz-col>
  <nz-col nzSpan="24" *ngIf="showObject">
    <br>
    <pre>{{ $object | async | json }}</pre>
  </nz-col>
</nz-row>

