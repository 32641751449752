import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { DeleteVorlageCommandServiceModule } from '../';
import { DeleteVorlageCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteVorlageCommandServiceModule
  ],
  declarations: [DeleteVorlageCommandComponent],
  exports: [DeleteVorlageCommandComponent],

})
export class DeleteVorlageCommandComponentModule { }
