import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { ObjekteCommandsModule } from './commands'
import {
  ObjekteQuerysModule,
  ObjektService,
  ObjekteService
} from './querys'
import {
  ObjektArtsServiceModule, ObjektArtsService,
  ObjektNutzungsServiceModule, ObjektNutzungsService
} from './settings';

const routes: Routes = [
  {
    path: 'objekte',
    loadChildren: () => import('./pages').then(m => m.ObjektePagesModule)
  },
  {
    path: 'objekte',
    children: [
      {
        path: 'settings',
        loadChildren: () => import('./settings').then(m => m.ObjekteSettingsModule),
      }
    ]
  },
];

@NgModule({
  imports: [
    ObjekteCommandsModule,
    ObjekteQuerysModule,
    ObjektArtsServiceModule,
    ObjektNutzungsServiceModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    ObjekteCommandsModule,
    ObjekteQuerysModule,
    ObjektArtsServiceModule,
    ObjektNutzungsServiceModule
  ]
})
export class ObjekteModule {
  static forRoot(): ModuleWithProviders< ObjekteModule> {
    return {
      ngModule: ObjekteModule,
      providers: [
        ObjektService,
        ObjekteService,
        ObjektArtsService,
        ObjektNutzungsService
      ]
    };
  }
}
