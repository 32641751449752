import { NgModule } from '@angular/core';
import {
  RechnungsVorlagenFilterComponentModule,
  RechnungsVorlagenListComponentModule,
  RechnungsVorlagenSorterComponentModule,
  SavedRechnungsVorlagenFiltersComponentModule
} from './';

@NgModule({
  imports: [
    RechnungsVorlagenFilterComponentModule,
    RechnungsVorlagenListComponentModule,
    RechnungsVorlagenSorterComponentModule,
    SavedRechnungsVorlagenFiltersComponentModule
  ],
  exports: [
    RechnungsVorlagenFilterComponentModule,
    RechnungsVorlagenListComponentModule,
    RechnungsVorlagenSorterComponentModule,
    SavedRechnungsVorlagenFiltersComponentModule
  ],
})
export class RechnungsVorlagenQueryModule { }
