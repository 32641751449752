<nz-content style="padding: 64px">
  <nz-row>
    <nz-col>
      <nz-breadcrumb>
        <nz-breadcrumb-item> <span nz-typography nzType="warning">401</span></nz-breadcrumb-item>
      </nz-breadcrumb>
    </nz-col>
  </nz-row>
  <nz-row>
    <nz-col>
      <h1 nz-title>Kein Zugriff</h1>
    </nz-col>
  </nz-row>
  <ng-container  *ngIf="!(auth.$id | async)">
    <nz-row>
      <nz-col>
        <button nz-button nzType="primary" (click)="auth.login()">Anmelden</button>
      </nz-col>
    </nz-row>
    <nz-row  style="margin-top: 16px">
      <nz-col>
        <span nz-typography nzType="secondary" (click)="auth.login()"> Bitte mit Ihrem Windows-Account anmelden. <br> Dazu sollten Pop-Ups für diese Seite erlaubt werden.</span>
      </nz-col>
    </nz-row>
  </ng-container>
  <nz-row>
    <nz-col>
      <img src="assets/401.png" class="logo" alt="404 Bild - nicht gefunden" style="cursor: pointer"/>
    </nz-col>
  </nz-row>
</nz-content>
