import { Component, OnInit, OnDestroy, Inject, Input, Output, EventEmitter, Injector, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { NzMessageService } from "ng-zorro-antd/message";

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { AuthService } from "@lib/auth/frontend";
import {
  ActionService,
  cleanObject, toInitials,
  CustomValidators, CustomFormatter,
  ISitemap, ISitemapCommand, SITEMAP,
} from "@lib/common/frontend";

import { IPostFormularRequest, IPostFormularResponse } from "@fa-kt-evaluation/formulare/types";
import { PostFormularCommandService } from '@fa-kt-evaluation/formulare/frontend'

/* << importing    */
/*    importing >> */

@Component({
  selector: 'fa-kt-evaluation-post-formular',
  templateUrl: './post-formular.component.html',
  styleUrls: ['./post-formular.component.css']
})
export class PostFormularCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) { this.$loading.next(this.$loading.getValue() || loading); }
  @Input() set value(value: IPostFormularRequest) { this.patch(value); }
  @Output() valueChanged: EventEmitter<IPostFormularRequest> = new EventEmitter<IPostFormularRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostFormularResponse> = new EventEmitter<IPostFormularResponse>();

/* << fields    */
/*    fields >> */

  get modalRef() {
    return this.injector.get(NzModalRef)
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public postFormular: PostFormularCommandService,
/* << more    */
/*    more >> */
  ) {
    this.command = sitemap.FORMULARE.Commands.POST_FORMULAR;
    this.form = this.fb.group({
      formular: this.fb.group({
        id: [null, []],
        name: [null, [Validators.required]],
        initialen: [null, []],
        objektArten: [[], []],
        bewertungsAnlaesse: [[], []],
        kundenArten: [[], []],
        kunden: [[], []],
        felder: [[], []],
        kommentar: [null, []],
      })
    });
/* << constructor    */
/*    constructor >> */
    this.patch();
  }

  ngOnInit() {
    this.postFormular.prepare().catch(e => console.error(e));
    this.subscriptions.push(
      this.finished.subscribe((result) => { if (this.modalRef) { this.modalRef.destroy(result); }}),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() })
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostFormularRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),
      this.formularId?.valueChanges.subscribe(async (id) => {
/* << on-formular-id-change    */
/*    on-formular-id-change >> */
      }) as Subscription,
      this.formularName?.valueChanges.subscribe(async (name) => {
/* << on-formular-name-change    */
/*    on-formular-name-change >> */
      }) as Subscription,
      this.formularInitialen?.valueChanges.subscribe(async (initialen) => {
/* << on-formular-initialen-change    */
/*    on-formular-initialen-change >> */
      }) as Subscription,
      this.formularObjektArten?.valueChanges.subscribe(async (objektArten) => {
/* << on-formular-objekt-arten-change    */
/*    on-formular-objekt-arten-change >> */
      }) as Subscription,
      this.formularBewertungsAnlaesse?.valueChanges.subscribe(async (bewertungsAnlaesse) => {
/* << on-formular-bewertungs-anlaesse-change    */
/*    on-formular-bewertungs-anlaesse-change >> */
      }) as Subscription,
      this.formularKundenArten?.valueChanges.subscribe(async (kundenArten) => {
/* << on-formular-kunden-arten-change    */
/*    on-formular-kunden-arten-change >> */
      }) as Subscription,
      this.formularKunden?.valueChanges.subscribe(async (kunden) => {
/* << on-formular-kunden-change    */
/*    on-formular-kunden-change >> */
      }) as Subscription,
      this.formularOhneFelder?.valueChanges.subscribe(async (ohneFelder) => {
/* << on-formular-ohne-felder-change    */
/*    on-formular-ohne-felder-change >> */
      }) as Subscription,
      this.formularKommentar?.valueChanges.subscribe(async (kommentar) => {
/* << on-formular-kommentar-change    */
/*    on-formular-kommentar-change >> */
      }) as Subscription,
/* << subscibe    */
      this.formularName?.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged()
      ).subscribe((name: string) => {
        if (this.formularInitialen && (!this.formularInitialen.touched || !this.formularInitialen.value)) {
          this.formularInitialen.patchValue(toInitials(name));
        }
      }) as Subscription,
/*    subscibe >> */
    );
/* << init    */
/*    init >> */
  }

  ngOnDestroy() {
/* << end    */
/*    end >> */
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IPostFormularRequest) {
    if (value) {
      value = cleanObject(value);
      if (value.formular) {
        value.formular = cleanObject(value.formular);
      }
      this.form.patchValue(value);
/* << custom    */
/*    custom >> */
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload.formular) {
        payload.formular = cleanObject(payload.formular);
      }
      const response: IPostFormularResponse = await this.postFormular.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of (error.errors ? error.errors : [])) {
        for (const children of (validation.children ? validation.children : [])) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

/* << methods    */
/*    methods >> */

  get formularId() {
    return this.form.controls.formular.get('id');
  };
  get formularName() {
    return this.form.controls.formular.get('name');
  };
  get formularInitialen() {
    return this.form.controls.formular.get('initialen');
  };
  get formularObjektArten() {
    return this.form.controls.formular.get('objektArten');
  };
  get formularBewertungsAnlaesse() {
    return this.form.controls.formular.get('bewertungsAnlaesse');
  };
  get formularKundenArten() {
    return this.form.controls.formular.get('kundenArten');
  };
  get formularKunden() {
    return this.form.controls.formular.get('kunden');
  };
  get formularOhneFelder() {
    return this.form.controls.formular.get('ohneFelder');
  };
  get formularKommentar() {
    return this.form.controls.formular.get('kommentar');
  };


}
