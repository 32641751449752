import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { DeleteAbschnittCommandServiceModule } from '../';
import { DeleteAbschnittCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteAbschnittCommandServiceModule
  ],
  declarations: [DeleteAbschnittCommandComponent],
  exports: [DeleteAbschnittCommandComponent],

})
export class DeleteAbschnittCommandComponentModule { }
