import { NgModule } from '@angular/core';

import { LIB_CommonModule } from "@lib/common/frontend";

import { ProfilService } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [
    ProfilService,
  ],
  exports: []
})
export class ProfilServiceModule { }
