import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { DeleteProjektCommandServiceModule } from '../';
import { DeleteProjektCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteProjektCommandServiceModule
  ],
  declarations: [DeleteProjektCommandComponent],
  exports: [DeleteProjektCommandComponent],

})
export class DeleteProjektCommandComponentModule { }
