import { Pipe, PipeTransform } from "@angular/core";
import { IAction } from "../types";

@Pipe({
  name: 'actionSearch'
})
export class ActionSearchFilterPipe implements PipeTransform {

  transform(actions: Array<IAction|{ key: string; url: string[] }>, term?: any): any {
    if(!actions || actions.length === 0){ return [] };
    if(!term || term.length === 0){ return actions };
    term = term.toLowerCase().replace(' ', '');
    return actions.filter((action) => action && action.key && action.key.toLowerCase().replace(' ', '').includes(term));
  }

}
