import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { VorlagenServiceModule } from '../';
import { VorlagenSorterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    VorlagenServiceModule
  ],
  declarations: [VorlagenSorterComponent],
  exports: [VorlagenSorterComponent],

})
export class VorlagenSorterComponentModule { }
