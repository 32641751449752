import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IPostBerichtRequest, IPostBerichtResponse, IPostBerichtCommandShapes } from '@fa-kt-evaluation/markt/types';

@Injectable({
  providedIn: 'root'
})
export class PostBerichtCommandService {

  readonly route = 'markt/bericht';
  protected dependencies = [
      'markt/written-herausgeber',
      'markt/removed-herausgeber',
      'markt/bericht-gespeichert'
  ];

  readonly $shapes = new BehaviorSubject<IPostBerichtCommandShapes|undefined>(undefined);
  
  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IPostBerichtCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IPostBerichtCommandShapes>(this.route + '/post/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IPostBerichtRequest): Promise<IPostBerichtResponse> {
    return await this.http.post<IPostBerichtResponse>(this.route, payload) as unknown as IPostBerichtResponse;
  }
  
  getBerichtHerausgeber(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['bericht.herausgeber']?.find(v => v.value === value)
  }

  get berichtHerausgeber(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['bericht.herausgeber'] as ISelection[] : []));
  }

}
