import { Component } from '@angular/core';
import { ProjekteService } from "@fa-kt-evaluation/projekte/frontend";

/* << imports    */
 
/*    imports >> */
@Component({
  selector: 'fa-kt-evaluation-saved-projekte-filters',
  templateUrl: './saved-projekte-filters.component.html',
  styleUrls: ['./saved-projekte-filters.component.css']
})
export class SavedProjekteFiltersComponent {

  constructor(
    public projekte: ProjekteService
/* << injection    */
/*    injection >> */
  ) {
  }
/* << fields   */
 
/*   fields >> */
/* << methods   */
 
/*   methods >> */
}