import { NgModule } from '@angular/core';

import {
  DeleteExtraVereinbarungCommandServiceModule,
  DeleteExtraVereinbarungCommandComponentModule
} from './';

@NgModule({
  imports: [
    DeleteExtraVereinbarungCommandServiceModule,
    DeleteExtraVereinbarungCommandComponentModule
  ],
  exports: [
    DeleteExtraVereinbarungCommandServiceModule,
    DeleteExtraVereinbarungCommandComponentModule
  ]
})
export class DeleteExtraVereinbarungCommandModule { }
