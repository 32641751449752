import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IPostHochladenRequest, IPostHochladenResponse, IPostHochladenCommandShapes } from '@fa-kt-evaluation/markt/types';

@Injectable({
  providedIn: 'root'
})
export class PostHochladenCommandService {

  readonly route = 'markt/hochladen';
  protected dependencies = [
      'markt/written-herausgeber',
      'markt/removed-herausgeber',
      'markt/bericht-hochgeladen'
  ];

  readonly $shapes = new BehaviorSubject<IPostHochladenCommandShapes|undefined>(undefined);
  
  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IPostHochladenCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IPostHochladenCommandShapes>(this.route + '/post/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IPostHochladenRequest): Promise<IPostHochladenResponse> {
    return await this.http.upload<IPostHochladenResponse>(this.route, payload) as unknown as IPostHochladenResponse;
  }
  
}
