import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { AuswertungenCommandsModule } from './commands'
import {
  AuswertungenQuerysModule,
} from './querys'
import {
} from './settings';

const routes: Routes = [
  {
    path: 'auswertungen',
    loadChildren: () => import('./pages').then(m => m.AuswertungenPagesModule)
  },
  {
    path: 'auswertungen',
    children: [
      {
        path: 'settings',
        loadChildren: () => import('./settings').then(m => m.AuswertungenSettingsModule),
      }
    ]
  },
];

@NgModule({
  imports: [
    AuswertungenCommandsModule,
    AuswertungenQuerysModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    AuswertungenCommandsModule,
    AuswertungenQuerysModule,
  ]
})
export class AuswertungenModule {
  static forRoot(): ModuleWithProviders< AuswertungenModule> {
    return {
      ngModule: AuswertungenModule,
      providers: [
      ]
    };
  }
}
