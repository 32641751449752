import { Injectable } from '@angular/core';
import { AbstractControl } from "@angular/forms";

import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, first, map } from "rxjs/operators";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { FilterPipe, SearchPipe, SorterPipe } from "@lib/filter/frontend";
import { ISelection } from "@lib/persistence/types";

import {
  ITourStatiRequest,
  ITourStatiResponse,
  ITourStatiQuerySorter,
  ITourStatiQueryFilter,
  ITourStatiQueryFilterShapes,
  ITourStatiResponseGroup,
} from '@fa-kt-evaluation/besichtigungen/types';

@Injectable({
  providedIn: 'root'
})
export class TourStatiService {

  protected route = 'besichtigungen/settings/tour-stati';
  protected dependencies = [
    'besichtigungen/written-tour-status',
    'besichtigungen/removed-tour-status',
  ];

  readonly $result = new BehaviorSubject<ITourStatiResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<ITourStatiRequest|undefined>(undefined);
  readonly $response = new BehaviorSubject<ITourStatiResponse|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<ITourStatiQueryFilterShapes|undefined>(undefined);
  readonly $filter = new BehaviorSubject<ITourStatiQueryFilter>({});
  readonly $sorter = new BehaviorSubject<ITourStatiQuerySorter>({});
  readonly $search = new BehaviorSubject<string>('');
  private readonly $filtered = new BehaviorSubject<ITourStatiResponse|undefined>(undefined);
  private readonly $searched = new BehaviorSubject<ITourStatiResponse|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private search: SearchPipe,
    private filter: FilterPipe,
    private sorter: SorterPipe,
    private device: DeviceConfigService
  ) {
    combineLatest([
      this.$response,
      this.$filter,
    ]).subscribe(([response, filter]) => {
      if (response && response.tourStati) {
        const tourStati = this.filter.transform(response.tourStati, filter, true);
        this.$filtered.next( { ...response, tourStati });
      } else {
        this.$filtered.next(response);
      }
    });
    combineLatest([
      this.$filtered,
      this.$search.pipe(debounceTime(device.debounceTime), distinctUntilChanged()),
      this.$shapes
    ]).subscribe(([filtered, search, shapes]) => {
      if (filtered && filtered.tourStati) {
        const tourStati = this.search.transform(filtered.tourStati, search, '_search', shapes);
        this.$searched.next( { ...filtered, tourStati });
      } else {
        this.$searched.next(filtered);
      }
    });
    combineLatest([
      this.$searched,
      this.$sorter,
      this.$shapes
    ]).pipe(debounceTime(10)).subscribe(([searched, sorter, shapes]) => {
      if (searched && searched.tourStati) {
        const tourStati = this.sorter.transform(searched.tourStati, sorter, shapes);
        this.$result.next( { ...searched, tourStati });
      } else {
        this.$result.next(searched);
      }
    });
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: ITourStatiRequest): ITourStatiRequest {
    if (payload) {
      this.$payload.next(payload as ITourStatiRequest);
    } else {
      payload = this.$payload.getValue() as ITourStatiRequest;
    }
    return payload;
  }

  public async request(payload?: ITourStatiRequest, force = false): Promise<ITourStatiResponse> {
    payload = this.payload(payload);
    const state = this.$response.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const { response, shapes } = await this.http.get<ITourStatiResponseGroup>(this.route + this.http.serialize(payload));
    this.$shapes.next(shapes);
    this.$response.next(response);
    return response;
  }
  


}
