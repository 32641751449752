import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { FelderServiceModule } from '../';
import { FelderListComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    FelderServiceModule
  ],
  declarations: [FelderListComponent],
  exports: [FelderListComponent],

})
export class FelderListComponentModule { }
