import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { KundeServiceModule } from '../';
import { KundeSorterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    KundeServiceModule
  ],
  declarations: [KundeSorterComponent],
  exports: [KundeSorterComponent],

})
export class KundeSorterComponentModule { }
