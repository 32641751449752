<!--
-->
<nz-row style="width: 100%; margin-top: 16px; margin-bottom: 16px">
  <nz-col *ngIf="($unused | async).length > 0" nzFlex>
    <nz-select (ngModelChange)="$newFilterField.next($event)"
               [ngModel]="$newFilterField | async" class="filter-selection" nzAllowClear
               nzPlaceHolder="Neuer Filter" nzShowSearch>
      <nz-option *ngFor="let field of ($unused | async)" [nzLabel]="field.label" [nzValue]="field.value"></nz-option>
    </nz-select>
  </nz-col>
  <nz-col *ngFor="let filter of $filter | async; let i = index" class="filter-item-container">
    <nz-input-group [nzAddOnAfter]="addOnAfterTemplate" [nzAddOnBefore]="addOnBeforeTemplate" class="filter-item" [style.minWidth]="isWide(filter) ? '358px' : '100px'">
      <ng-template #addOnBeforeTemplate>
        <ng-container [ngSwitch]="filter.operators && filter.operators.length > 1">
          <span>{{ filter.title }}</span>
          <nz-select *ngSwitchCase="true" (ngModelChange)="emitOperator($event, i)" [(ngModel)]="_filter[i].operator"
                     [id]="'filter-' + filter.key" nzPlaceHolder="Wie wird gefiltert?"
                     nzShowSearch style="padding-left: 6px">
            <nz-option *ngFor="let operator of filter.operators" [nzLabel]="operator" [nzValue]="operator"></nz-option>
          </nz-select>
          <span *ngSwitchDefault>
                {{ filter.operator }}
              </span>
        </ng-container>
      </ng-template>
      <ng-container [ngSwitch]="!!filter.selections">
        <codeboard-select-value (valueChange)="emitValue($event, i)" *ngSwitchCase="true" [(value)]="_filter[i].value"
                                [mode]="'multiple'" [placeholder]="filter.title"
                                [shapes]="filter.selections" [width]="'100px'" nzShowSearch>
        </codeboard-select-value>
        <ng-container *ngSwitchDefault [ngSwitch]="filter.type === FilterType.Boolean">
          <label *ngSwitchCase="true" [(ngModel)]="_filter[i].value" (ngModelChange)="emit()" nz-checkbox></label>
          <ng-container *ngSwitchDefault [ngSwitch]="filter.operator === 'in'">
            <ng-container *ngSwitchCase="true">
              <ng-container *ngIf="filter.type === FilterType.Number">
                <input nz-input type="number" style="width: 100px" (ngModelChange)="emitValue($event, i, 'min')" [(ngModel)]="_filter[i].value.min" nz-input
                       placeholder="Min" style="width: 55px"/>
                <input nz-input type="number" style="width: 100px" (ngModelChange)="emitValue($event, i, 'max')" [(ngModel)]="_filter[i].value.max" nz-input
                       placeholder="Max" style="width: 55px"/>
              </ng-container>
              <ng-container *ngIf="filter.type === FilterType.Date">
                <nz-range-picker (ngModelChange)="onDateRangePickerChange(i, $event)"
                                 [ngModel]="[_filter[i].value.min, _filter[i].value.max]" nzFormat="dd.MM.yyyy"></nz-range-picker>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <ng-container *ngIf="filter.type === FilterType.Number">
                <input nz-input type="number" style="width: 100px" (ngModelChange)="emitValue($event, i)" [(ngModel)]="_filter[i].value" [placeholder]="filter.title" style="width: 55px"/>
              </ng-container>
              <ng-container *ngIf="filter.type !== FilterType.Number">
                <input nz-input type="text" (ngModelChange)="emit()" [(ngModel)]="_filter[i].value" [placeholder]="filter.title"
                       />
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-template #addOnAfterTemplate>
        <a (click)="removeFilter(filter.key)"><i [style.color]="'red'" nz-icon nzTheme="outline"
                                                 nzType="close-circle"></i></a>
      </ng-template>
    </nz-input-group>
  </nz-col>
</nz-row>
