import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { ZeitenServiceModule } from '../';
import { ZeitenFilterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ZeitenServiceModule
  ],
  declarations: [ZeitenFilterComponent],
  exports: [ZeitenFilterComponent],

})
export class ZeitenFilterComponentModule { }
