import { Component } from '@angular/core';
import { DurchsuchenService } from "@fa-kt-evaluation/markt/frontend";

/* << imports    */
 
/*    imports >> */
@Component({
  selector: 'fa-kt-evaluation-saved-durchsuchen-filters',
  templateUrl: './saved-durchsuchen-filters.component.html',
  styleUrls: ['./saved-durchsuchen-filters.component.css']
})
export class SavedDurchsuchenFiltersComponent {

  constructor(
    public durchsuchen: DurchsuchenService
/* << injection    */
/*    injection >> */
  ) {
  }
}