import { Component, OnInit, OnDestroy, Inject, Input, Output, EventEmitter, Injector, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { NzMessageService } from "ng-zorro-antd/message";

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { AuthService } from "@lib/auth/frontend";
import {
  ActionService,
  cleanObject, toInitials,
  CustomValidators, CustomFormatter,
  ISitemap, ISitemapCommand, SITEMAP,
} from "@lib/common/frontend";

import { IPostRechnungRequest, IPostRechnungResponse, IReisekostenabrechnung } from "@fa-kt-evaluation/finanzen/types";
import { PostRechnungCommandService } from '@fa-kt-evaluation/finanzen/frontend'

/* << importing    */
import { groupBy, orderBy, round } from "lodash";
import { ISelection } from "@lib/persistence/types";
import { IRechnung } from "@fa-kt-evaluation/finanzen/types";
import { IAbteilung, IKunde } from "@fa-kt-evaluation/kunden/types";
import { IProjekt } from "@fa-kt-evaluation/projekte/types";
import { IGutachtenResponseRow } from "@fa-kt-evaluation/gutachten/types";
import { IAddress } from "@lib/geo/types";
import { format, parseISO } from "date-fns";
import { SecretService } from "@lib/common/frontend";
import { DependenciesService } from "@lib/auth/frontend";
import { HttpService } from "@lib/https/frontend";
import { IMitarbeiterResponseRow, IStandort } from "@fa-kt-evaluation/personen/types";
import { TourenService } from "@fa-kt-evaluation/besichtigungen/frontend";
import { IBesichtigung } from "@fa-kt-evaluation/besichtigungen/types";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import { FileService } from "@lib/files/frontend";
import { GutachtenService } from "@fa-kt-evaluation/gutachten/frontend";
import { ProjekteService } from "@fa-kt-evaluation/projekte/frontend";
import { DecimalPipe } from "@angular/common";
/*    importing >> */

@Component({
  selector: 'fa-kt-evaluation-post-rechnung',
  templateUrl: './post-rechnung.component.html',
  styleUrls: ['./post-rechnung.component.css']
})
export class PostRechnungCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) { this.$loading.next(this.$loading.getValue() || loading); }
  @Input() set value(value: IPostRechnungRequest) { this.patch(value); }
  @Output() valueChanged: EventEmitter<IPostRechnungRequest> = new EventEmitter<IPostRechnungRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostRechnungResponse> = new EventEmitter<IPostRechnungResponse>();

/* << fields    */
  @Input() projekt: IProjekt|undefined = undefined;
  @Input() kunde: IKunde|undefined = undefined;
  @Input() abteilung: IAbteilung|undefined = undefined;
  @Input() gutachtenResponse: IGutachtenResponseRow[] = [];
  @Input() mitarbeiter: IMitarbeiterResponseRow[] = [];
  @Input() standorte: IStandort[] = [];
  $besichtigungen = new BehaviorSubject<ISelection[]>([])
  $calculating = new BehaviorSubject<boolean>(false);

  $extraErklärung = new BehaviorSubject<string>('');
  $fahrtkostenErklärung = new BehaviorSubject<string>('');
/*    fields >> */

  get modalRef() {
    return this.injector.get(NzModalRef)
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public postRechnung: PostRechnungCommandService,
/* << more    */
    private secrets: SecretService,
    private https: HttpService,
    private dependencies: DependenciesService,
    private touren: TourenService,
    private projekte: ProjekteService,
    private gutachten: GutachtenService,
    public files: FileService,
/*    more >> */
  ) {
    this.command = sitemap.FINANZEN.Commands.POST_RECHNUNG;
    this.form = this.fb.group({
      rechnung: this.fb.group({
        id: [null, []],
        projekt: [null, [Validators.required]],
        kunde: [null, []],
        abteilung: [null, []],

        bezeichnung: [null, [Validators.required]],
        rechnungZaehler: [null, []],
        datum: [null, [Validators.required]],
        ansprechpartner: [null, [Validators.required]],
        plz: [null, [Validators.required]],
        ort: [null, [Validators.required]],
        addresse: [null, [Validators.required]],

        honorarVereinbarung: [null, []],
        honorarVereinbarungSumme: [null, []],

        gutachten: [null, []],
        stunden: [null, []],
        anschlaege: [null, []],
        seiten: [null, []],
        fotos: [null, []],
        extraVereinbarungs: [[], []],
        extraVereinbarungsSumme: [null, []],

        strecke: [null, []],
        fahrtzeit: [null, []],
        fahrtenSumme: [null, []],
        besichtigungen: [[], []],
        besichtigungenSumme: [null, []],

        auslagenSumme: [null, []],
        sonstigeKosten: [null, []],
        nachlass: [null, []],
        nettoSumme: [null, [Validators.required]],

        mwstSatz: [null, [Validators.required]],
        mwstSumme: [null, [Validators.required]],
        bruttoSumme: [null, [Validators.required]],

        auslagenOhneMwstSumme: [null, []],
        finaleSumme: [null, [Validators.required]],

        skonto: [null, []],
        zahlungsziel: [null, []],
        tageOhneSkonto: [null, []],
        skontoSumme: [null, []],
        nachSkontoSumme: [null, []],

        datei: [null, []],
      })
    });
/* << constructor    */
    this.rechnungNettoSumme?.disable();
    this.rechnungMwstSatzSumme?.disable();
    this.rechnungBruttoSumme?.disable();
    this.rechnungFinaleSumme?.disable();
/*    constructor >> */
    this.patch();
  }

  ngOnInit() {
    this.postRechnung.prepare().catch(e => console.error(e));
    this.touren.request({ inklusiveInaktive: true }).catch(e => console.error(e));
    this.subscriptions.push(
      this.finished.subscribe((result) => { if (this.modalRef) { this.modalRef.destroy(result); }}),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() })
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostRechnungRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),

      this.rechnungHonorarVereinbarung?.valueChanges.subscribe( () => this.calculateHonorarVereinbarungsSumme()) as Subscription,

      this.rechnungExtraVereinbarungs?.valueChanges.subscribe(() => this.calculateExtraVereinbarungenSumme()) as Subscription,
      this.rechnungGutachten?.valueChanges.subscribe(() => this.calculateExtraVereinbarungenSumme()) as Subscription,
      this.rechnungStunden?.valueChanges.subscribe(() => this.calculateExtraVereinbarungenSumme()) as Subscription,
      this.rechnungAnschlaege?.valueChanges.subscribe(() => this.calculateExtraVereinbarungenSumme()) as Subscription,
      this.rechnungSeiten?.valueChanges.subscribe(() => this.calculateExtraVereinbarungenSumme()) as Subscription,
      this.rechnungFotos?.valueChanges.subscribe(() => this.calculateExtraVereinbarungenSumme()) as Subscription,

      this.rechnungStrecke?.valueChanges.subscribe(() => this.calculateFahrten()) as Subscription,
      this.rechnungFahrtzeit?.valueChanges.subscribe(() => this.calculateFahrten()) as Subscription,

      this.rechnungBesichtigungen?.valueChanges.subscribe(async (besichtigungen) => await this.calculateBesichtigungen(besichtigungen)) as Subscription,

      this.rechnungHonorarVereinbarungSumme?.valueChanges.subscribe(() => this.calculateNetto()) as Subscription,
      this.rechnungExtraVereinbarungsSumme?.valueChanges.subscribe(() => this.calculateNetto()) as Subscription,
      this.rechnungFahrtenSumme?.valueChanges.subscribe(() => this.calculateNetto()) as Subscription,
      this.rechnungNachlass?.valueChanges.subscribe(() => this.calculateNetto()) as Subscription,
      this.rechnungAuslagenSumme?.valueChanges.subscribe(() => this.calculateNetto()) as Subscription,
      this.rechnungSonstigeKosten?.valueChanges.subscribe(() => this.calculateNetto()) as Subscription,

      this.rechnungNettoSumme?.valueChanges.subscribe(() => this.calculateBrutto()) as Subscription,
      this.rechnungMwstSatz?.valueChanges.subscribe(() => this.calculateBrutto()) as Subscription,

      this.rechnungBruttoSumme?.valueChanges.subscribe(() => this.calculateFinaleSumme()) as Subscription,
      this.rechnungAuslagenOhneMwstSumme?.valueChanges.subscribe(() => this.calculateFinaleSumme()) as Subscription,
/* << subscibe    */
      this.touren.$result.subscribe(async (result) => {
        const standort = this.standorte.find(std => this.projekt?.standort === std.id);
        let rechnungBesichtigungen: ISelection[] = [];
        result?.touren?.filter(t => t.besichtigungen).forEach(t => t.besichtigungen?.forEach(b => {
          rechnungBesichtigungen.push({
            value: b.besichtigung?.id,
            label: `${b.objekt ? b.objekt.name + ' ': ''}am ${format(new Date(b.besichtigung?.von as Date), 'dd.MM.yyyy HH:mm')}`,
            filter: b.besichtigung.projekt as string,
            order: b.besichtigung.order,
            tour: b.besichtigung.tour as string,
            mitarbeiter: b.besichtigung.mitarbeiter as string,
            position: { latitude: b.besichtigung?.ende?.latitude, longitude: b.besichtigung?.ende?.longitude }
          })
        }));
        rechnungBesichtigungen = rechnungBesichtigungen ? rechnungBesichtigungen.filter(b => b.filter && b.filter === this.projekt?.id) : [];
        const distances = await Promise.all(rechnungBesichtigungen.map(async (b) => {
          const { summary } = await this.loadAzureRoute(standort?.addresse as IAddress, b.position as IAddress, new Date());
          return round(summary.lengthInMeters / 1000, 2);
        }))
        rechnungBesichtigungen = rechnungBesichtigungen.map((b, i) => ({ ...b, label: '(' + distances[i] + ' km) ' + b.label, distance: distances[i] }))
        this.$besichtigungen.next(rechnungBesichtigungen)
      }),
/*    subscibe >> */
    );
/* << init    */
/*    init >> */
  }

  ngOnDestroy() {
/* << end    */
/*    end >> */
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IPostRechnungRequest) {
    if (value) {
      value = cleanObject(value);
      if (value.rechnung) {
        value.rechnung = cleanObject(value.rechnung);
      }
      this.form.patchValue(value);
/* << custom    */
/*    custom >> */
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload.rechnung) {
        payload.rechnung = cleanObject(payload.rechnung);
      }
      const response: IPostRechnungResponse = await this.postRechnung.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of (error.errors ? error.errors : [])) {
        for (const children of (validation.children ? validation.children : [])) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

/* << methods    */
  async loadAzureRoute(start: IAddress, ende: IAddress, am: Date): Promise<any> {
    const query = `${start.latitude},${start.longitude}%3A${ende.latitude},${ende.longitude}`;
    const vonISO = parseISO(new Date(am).toISOString());
    const departAt = format(vonISO, "yyyy-MM-dd") + 'T' + format(vonISO, "HH:mm:ss");
    if (!this.secrets.$secrets.getValue()) {
      await this.secrets.get();
    }
    const key = this.secrets.$secrets.getValue()?.maps.key;
    const url = `https://atlas.microsoft.com/route/directions/json?api-version=1.0&query=${query}&departAt=${departAt}&language=de-DE&computeTravelTimeFor=all&traffic=true&subscription-key=${key}`;
    const result = await this.https.get<any>(url);
    const route = result.routes[0];
    return { summary: route.summary, url };
  }

  calculateHonorarVereinbarungsSumme(): void {
    this.$calculating.next(true);
    const rechnung: IRechnung = this.form.value.rechnung;

    let honorarVereinbarungsSumme;
    if (rechnung.honorarVereinbarung) {
      const honorarVereinbarung = this.postRechnung.getRechnungHonorarVereinbarung(rechnung.honorarVereinbarung as string);
      if (this.projekt?.objektArt === "spezial") {
        honorarVereinbarungsSumme = Number(honorarVereinbarung?.spezial);
      }
      if (this.projekt?.objektArt === "gewerbe" || !honorarVereinbarungsSumme) {
        honorarVereinbarungsSumme = Number(honorarVereinbarung?.gewerbe);
      }
      if (this.projekt?.objektArt === "wohnen" || !honorarVereinbarungsSumme) {
        honorarVereinbarungsSumme = Number(honorarVereinbarung?.wohnen);
      }
    }
    this.rechnungHonorarVereinbarungSumme?.patchValue(honorarVereinbarungsSumme ?? 0)
    this.$calculating.next(false);
    this.calculateNetto();
  }

  calculateExtraVereinbarungenSumme(): void {
    this.$calculating.next(true);
    const rechnung: IRechnung = this.form.getRawValue().rechnung;
    const extraVereinbarungen = rechnung.extraVereinbarungs?.map((extraVereinbarung: string) => this.postRechnung.getRechnungExtraVereinbarungs(extraVereinbarung));
    let extraVereinbarungenSumme: number = 0;
    let erklärung = "";
    extraVereinbarungen?.forEach((ev => {
      switch (ev?.pro) {
        case "projekt":
          extraVereinbarungenSumme = Number(extraVereinbarungenSumme) + Number(ev.summe);
          erklärung = erklärung + "EUR " + ev.summe + " nach Projekt (" + ev?.label + ")\n";
          break;
        case "gutachten":
          extraVereinbarungenSumme = Number(extraVereinbarungenSumme) + Number(ev.summe) * Number(rechnung.gutachten);
          erklärung = erklärung + "EUR " + ev.summe + " nach " + rechnung.gutachten + " Gutachten (" + ev?.label + ")\n";
          break;
        case "stunde":
          extraVereinbarungenSumme = Number(extraVereinbarungenSumme) + Number(ev.summe) * Number(rechnung.stunden);
          erklärung = erklärung + "EUR " + ev.summe + " nach " + rechnung.stunden + " Stunden (" + ev?.label + ")\n";
          break;
        case "seite":
          extraVereinbarungenSumme = Number(extraVereinbarungenSumme) + Number(ev.summe) * Number(rechnung.seiten);
          erklärung = erklärung + "EUR " + ev.summe + " nach " + rechnung.seiten + " Seiten (" + ev?.label + ")\n";
          break;
        case "anschlag":
          extraVereinbarungenSumme = Number(extraVereinbarungenSumme) + Number(ev.summe) * Number(rechnung.anschlaege);
          erklärung = erklärung + "EUR " + ev.summe + " nach " + rechnung.anschlaege + " Anschläge (" + ev?.label + ")\n";
          break;
        case "foto":
          extraVereinbarungenSumme = Number(extraVereinbarungenSumme) + Number(ev.summe) * Number(rechnung.fotos);
          erklärung = erklärung + "EUR " + ev.summe + " nach " + rechnung.fotos + " Fotos (" + ev?.label + ")\n";
          break;
      }
    }));
    this.rechnungExtraVereinbarungsSumme?.patchValue(round(extraVereinbarungenSumme, 2))
    this.$extraErklärung.next(erklärung);
    this.$calculating.next(false);
  }

  async calculateBesichtigungen(value: string[]): Promise<void> {
    this.$calculating.next(true);
    const tourenBesichtigungen = this.$besichtigungen.getValue();
    const besichtigungen = value?.map(besichtigung => tourenBesichtigungen.find(b => b.value === besichtigung));
    const touren = groupBy(besichtigungen, "tour");
    const summaries = [];
    for (let [, besichtigungen] of Object.entries(touren).filter(([key, besichtigungen]) => key && besichtigungen.length > 0)) {
      const mitarbeiter = this.mitarbeiter.find(m => m.mitarbeiter.id === besichtigungen[0]?.mitarbeiter)?.mitarbeiter;
      const standort = this.standorte.find(st => st.id === mitarbeiter?.standort);
      let start = standort?.addresse;
      const ende = standort?.addresse;
      for (const besichtigung of orderBy(besichtigungen, "order")) {
        const { summary } = await this.loadAzureRoute(start as IAddress, besichtigung?.position as IAddress, new Date());
        start = besichtigung?.position as IAddress;
        summaries.push(summary);
      }
      const { summary } = await this.loadAzureRoute(start as IAddress, ende as IAddress, new Date());
      summaries.push(summary);
    }
    const strecke = round(summaries.reduce((n, { lengthInMeters }) => n + (isNaN(lengthInMeters) ? 0 : lengthInMeters), 0) / 1000, 2);
    const fahrtzeit = round(summaries.reduce((n, { travelTimeInSeconds }) => n + (isNaN(travelTimeInSeconds) ? 0 : travelTimeInSeconds), 0) / 60 / 60, 2);
    this.rechnungFahrtzeit?.patchValue(fahrtzeit);
    this.rechnungStrecke?.patchValue(strecke);
    this.$calculating.next(false);
  }


  private calculateFahrten() {
    this.$calculating.next(true);
    const rechnung: IRechnung = this.form.getRawValue().rechnung;
    let fahrtkostenSumme = 0;
    let erklärung = "";
    if (this.kunde && this.kunde.fahrtkostenAbrechnung !== 'keine') {
      switch (this.kunde.fahrtkostenAbrechnung) {
        case "proStunde":
          fahrtkostenSumme = Number(rechnung.fahrtzeit) * Number(this.kunde?.fahrtkostenProStunde);
          erklärung = this.format(rechnung.fahrtzeit) + "Std. * EUR " + this.format(this.kunde?.fahrtkostenProStunde);
          break;
        case "proKm":
          if (!this.kunde?.fahrtkostenAbRadius || (Number(rechnung.strecke) >= (this.kunde.fahrtkostenAbRadius as number))) {
            fahrtkostenSumme = Number(rechnung.strecke) * Number(this.kunde?.fahrtkostenProKm);
            erklärung = this.format(rechnung.strecke) + "km * " + this.format(this.kunde?.fahrtkostenProKm) + " EUR/km";
          }
          break;
      }
    }
    this.rechnungFahrtenSumme?.patchValue(round(fahrtkostenSumme, 2));
    this.$fahrtkostenErklärung.next(erklärung);
    this.$calculating.next(false)
  }

  calculateNetto() {
    this.$calculating.next(true);
    const rechnung: IRechnung = this.form.getRawValue().rechnung;
    const netto =
      (Number(rechnung.honorarVereinbarungSumme) +
        Number(rechnung.extraVereinbarungsSumme) +
        Number(rechnung.fahrtenSumme) +
        Number(rechnung.sonstigeKosten) +
        Number(rechnung.auslagenSumme))
      * (1 - (Number(rechnung.nachlass) / 100));
    this.rechnungNettoSumme?.patchValue(round(netto, 2));
    this.$calculating.next(false)
  }

  calculateBrutto() {
    this.$calculating.next(true);
    const rechnung: IRechnung = this.form.getRawValue().rechnung;
    const mwstSumme = round(Number(rechnung.nettoSumme) * (Number(rechnung.mwstSatz) / 100), 2);
    this.rechnungMwstSatzSumme?.patchValue(mwstSumme);
    this.rechnungBruttoSumme?.patchValue(round(Number(rechnung.nettoSumme) + mwstSumme, 2));
    this.$calculating.next(false)
  }

  calculateFinaleSumme() {
    this.$calculating.next(true);
    const rechnung: IRechnung = this.form.getRawValue().rechnung;
    const finaleSumme = Number(rechnung.bruttoSumme) + Number(rechnung.auslagenOhneMwstSumme);
    this.rechnungFinaleSumme?.patchValue(round(finaleSumme, 2));
    this.$calculating.next(false)
  }
/*    methods >> */

  get rechnungId() {
    return this.form.controls.rechnung.get('id');
  };
  get rechnungProjekt() {
    return this.form.controls.rechnung.get('projekt');
  };
  get rechnungKunde() {
    return this.form.controls.rechnung.get('kunde');
  };
  get rechnungAbteilung() {
    return this.form.controls.rechnung.get('abteilung');
  };
  get rechnungBezeichnung() {
    return this.form.controls.rechnung.get('bezeichnung');
  };
  get rechnungRechnungZaehler() {
    return this.form.controls.rechnung.get('rechnungZaehler');
  };
  get rechnungDatum() {
    return this.form.controls.rechnung.get('datum');
  };
  get rechnungHonorarVereinbarung() {
    return this.form.controls.rechnung.get('honorarVereinbarung');
  };
  get rechnungHonorarVereinbarungSumme() {
    return this.form.controls.rechnung.get('honorarVereinbarungSumme');
  };
  get rechnungStunden() {
    return this.form.controls.rechnung.get('stunden');
  };
  get rechnungGutachten() {
    return this.form.controls.rechnung.get('gutachten');
  };
  get rechnungAnschlaege() {
    return this.form.controls.rechnung.get('anschlaege');
  };
  get rechnungSeiten() {
    return this.form.controls.rechnung.get('seiten');
  };
  get rechnungFotos() {
    return this.form.controls.rechnung.get('fotos');
  };
  get rechnungExtraVereinbarungs() {
    return this.form.controls.rechnung.get('extraVereinbarungs');
  };
  get rechnungExtraVereinbarungsSumme() {
    return this.form.controls.rechnung.get('extraVereinbarungsSumme');
  };
  get rechnungBesichtigungen() {
    return this.form.controls.rechnung.get('besichtigungen');
  };
  get rechnungStrecke() {
    return this.form.controls.rechnung.get('strecke');
  };
  get rechnungFahrtzeit() {
    return this.form.controls.rechnung.get('fahrtzeit');
  };
  get rechnungFahrtenSumme() {
    return this.form.controls.rechnung.get('fahrtenSumme');
  };
  get rechnungAuslagenSumme() {
    return this.form.controls.rechnung.get('auslagenSumme');
  };
  get rechnungSonstigeKosten() {
    return this.form.controls.rechnung.get('sonstigeKosten');
  };
  get rechnungNettoSumme() {
    return this.form.controls.rechnung.get('nettoSumme');
  };
  get rechnungAuslagenOhneMwstSumme() {
    return this.form.controls.rechnung.get('auslagenOhneMwstSumme');
  };
  get rechnungNachlass() {
    return this.form.controls.rechnung.get('nachlass');
  };
  get rechnungMwstSatz() {
    return this.form.controls.rechnung.get('mwstSatz');
  };
  get rechnungMwstSatzSumme() {
    return this.form.controls.rechnung.get('mwstSumme');
  };
  get rechnungBruttoSumme() {
    return this.form.controls.rechnung.get('bruttoSumme');
  };
  get rechnungFinaleSumme() {
    return this.form.controls.rechnung.get('finaleSumme');
  };
  get rechnungDatei() {
    return this.form.controls.rechnung.get('datei');
  };


  format(number: number|undefined): string|null {
    if (!number) { return ''; }
    const pipe = new DecimalPipe('de');
    const digitsInfo = '0.2-2';
    return pipe.transform(number, digitsInfo);
  }

  async download() {
    this.$loading.next(true);
    const rechnung: IRechnung = this.form.getRawValue().rechnung;

    let kurz = "";
    let portfolio = "";
    if (
      Number(rechnung.extraVereinbarungsSumme) +
      Number(rechnung.fahrtenSumme) +
      Number(rechnung.auslagenSumme) +
      Number(rechnung.sonstigeKosten) +
      Number(rechnung.auslagenOhneMwstSumme) === 0
    ) {
      kurz = "_kurz"
    }

    let wert = 0;
    let objekte = "";
    let objekt = "";
    let anlass = "";
    let objektArt = "";
    let stichtag = "";
    let leistungsdatum = "";

    if (this.gutachtenResponse.length > 1) {
      portfolio = '_Portfolio'
      objekte = this.gutachtenResponse.map(row => {
        wert = wert + Number(row.gutachten.marktwert);
        let gutachten = row.objekt.addresse?.land + ', ' + row.objekt.addresse?.gemeinde_stadt + ' ' + row.objekt.addresse?.plz + ', ' + row.objekt.addresse?.strasse + ' ' + row.objekt.addresse?.extra;
        gutachten = gutachten + ': ' +  this.gutachten.getGutachtenBewertungsAnlass(row.gutachten.bewertungsStatus)?.label + ', ' + this.gutachten.getGutachtenObjektArt(row.gutachten.objektArt as string)?.label?.split(' | ').pop();
        gutachten = gutachten + ' - ' + (row.gutachten?.stichtagMarktwert ? new Date(row.gutachten?.stichtagMarktwert as Date).toLocaleDateString() : '') + ''
        leistungsdatum = this.mapMonth((new Date(row.gutachten?.abgabeFinal)).getMonth()) + ' ' + (new Date(row.gutachten?.abgabeFinal)).getFullYear()
        return gutachten;
      }).join('\n');
    } else if (this.gutachtenResponse.length === 1){
      const row = this.gutachtenResponse.shift();
      wert = wert + Number(row?.gutachten.marktwert);
      objekt = row?.objekt.addresse?.land + ', ' + row?.objekt.addresse?.gemeinde_stadt + ' ' + row?.objekt.addresse?.plz + ', ' + row?.objekt.addresse?.strasse + ' ' + row?.objekt.addresse?.extra;
      anlass = row?.gutachten.bewertungsStatus ? this.gutachten.getGutachtenBewertungsAnlass(row?.gutachten.bewertungsStatus as string)?.label as string : '';
      objektArt = row?.gutachten.objektArt ? this.gutachten.getGutachtenObjektArt(row?.gutachten.objektArt as string)?.label?.split(' | ').pop() as string : '';
      stichtag = (row?.gutachten?.stichtagMarktwert ? new Date(row?.gutachten?.stichtagMarktwert as Date).toLocaleDateString() : '');
      leistungsdatum = this.mapMonth((new Date(row?.gutachten?.abgabeFinal as Date)).getMonth()) + ' ' + (new Date(row?.gutachten?.abgabeFinal as Date)).getFullYear()
    }

    let marktwert = "";
    if (wert > 0) {
      marktwert = "Marktwert: EUR " + this.format(wert);
    }

    const [blob] = await Promise.all([this.files.get("fa-kt-apps/Rechnung" + portfolio + kurz + ".docx")]);
    const zip = new PizZip(await this.files.readFileAsync(blob as File));
    const doc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true,
      delimiters: { start: "{", end: "}" },
    });


    const obj = {
      kundeName: (this.kunde?.name ?? ''),
      abteilungName: (rechnung?.ansprechpartner ?? ''),
      strasse: (rechnung.addresse ?? ''),
      plz: (rechnung.plz ?? '') + ' ' + (rechnung.ort ?? ''),
      datum: new Date().toLocaleDateString(),
      id: rechnung.bezeichnung,
      auftragsNr: (this.projekt?.kundenZeichen ?? ''),
      auftragsArt: this.projekte.getProjektProjektArt(this.projekt?.projektArt as string)?.label.split(' | ').pop(),
      objekt,
      anlass,
      leistungsdatum,
      objektArt,
      stichtag,
      objekte,
      marktwert,
      honorar: this.format(rechnung.honorarVereinbarungSumme ?? 0),
      extra: this.format(rechnung.extraVereinbarungsSumme ?? 0),
      fahrten: this.format(rechnung.fahrtenSumme ?? 0),
      auslagen: this.format(rechnung.auslagenSumme ?? 0),
      sonstige: this.format(rechnung.sonstigeKosten ?? 0),
      netto: this.format(rechnung.nettoSumme ?? 0),
      mwstSatz: this.format(rechnung.mwstSatz ?? 0),
      mwstSumme: this.format(rechnung.mwstSumme ?? 0),
      brutto: this.format(rechnung.bruttoSumme ?? 0),
      auslagenOhne: this.format(rechnung.auslagenOhneMwstSumme ?? 0),
      final: this.format(rechnung.finaleSumme ?? 0),
      fahrtkostenErklärung: this.$fahrtkostenErklärung.getValue() ? 'Erklärung der Fahrtkosten: ' + this.$fahrtkostenErklärung.getValue() : '',
      extraErklärung: this.$extraErklärung.getValue() ? 'Erklärung der Extra-Vereinbarungen: ' + this.$extraErklärung.getValue() : '',
    };
    doc.render(obj);
    const out = doc.getZip().generate({
      type: "blob",
      mimeType:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
    this.files.downloadBlob(out, rechnung.bezeichnung + ".docx");
    this.$loading.next(false);
  }

  private mapMonth(month: number): string {
    switch (month) {
      case 0:
        return "Januar";
      case 1:
        return "Februar";
      case 2:
        return "März";
      case 3:
        return "April";
      case 4:
        return "Mai";
      case 5:
        return "Juni";
      case 6:
        return "Juli";
      case 7:
        return "August";
      case 8:
        return "September";
      case 9:
        return "Oktober";
      case 10:
        return "November";
      default:
        return "Dezember";
    }
  }
}
