import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { GutachtenServiceModule } from '../';
import { SavedGutachtenFiltersComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    GutachtenServiceModule
  ],
  declarations: [SavedGutachtenFiltersComponent],
  exports: [SavedGutachtenFiltersComponent],

})
export class SavedGutachtenFiltersComponentModule { }
