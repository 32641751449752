import { Component } from '@angular/core';
import { VorlagenService } from "@fa-kt-evaluation/texte/frontend";

/* << imports    */
 
/*    imports >> */
@Component({
  selector: 'fa-kt-evaluation-saved-vorlagen-filters',
  templateUrl: './saved-vorlagen-filters.component.html',
  styleUrls: ['./saved-vorlagen-filters.component.css']
})
export class SavedVorlagenFiltersComponent {

  constructor(
    public vorlagen: VorlagenService
/* << injection    */
/*    injection >> */
  ) {
  }
}