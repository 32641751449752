import { NgModule } from '@angular/core';

import {
  PostZeitCommandServiceModule,
  PostZeitCommandComponentModule
} from './';

@NgModule({
  imports: [
    PostZeitCommandServiceModule,
    PostZeitCommandComponentModule
  ],
  exports: [
    PostZeitCommandServiceModule,
    PostZeitCommandComponentModule
  ]
})
export class PostZeitCommandModule { }
