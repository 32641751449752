<!---
 -->
<form nz-form [formGroup]="form" (ngSubmit)="submit()">
  <input class="cdk-visually-hidden" formControlName="id" id="id"/>
  <button nz-button nzType="danger" [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)"
          nz-popconfirm nzPopconfirmTitle="Wirklich löschen?" [nzIcon]="DeleteIcon" (nzOnConfirm)="submit()">
    <i nz-icon nzType="delete" nzTheme="outline" style="color: red;"></i>
    Löschen
    <ng-template #DeleteIcon>
      <i nz-icon nzType="delete" nzTheme="outline" style="color: red;"></i>
    </ng-template>
  </button>
</form>
