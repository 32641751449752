import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { NzUploadChangeParam, NzUploadFile } from "ng-zorro-antd/upload";
import { format } from "date-fns";

@Component({
  selector: 'codeboard-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true
    }
  ]
})
export class FileUploadComponent implements OnInit {
  @Input() icon: string = 'file';
  @Input() text: string|undefined;
  @Input() hint: string|undefined;
  @Input() multi = false;
  @Output() uploaded = new EventEmitter<File>();

  fileList: NzUploadFile[] = []
  file: File | null = null;
  onChange: Function | null = null;
  onError: Function | null = null;


  constructor(private host: ElementRef<HTMLInputElement> ) {
  }

  ngOnInit(): void {
  }

  writeValue( value: null ) {
    this.host.nativeElement.value = '';
    this.file = null;
  }

  registerOnChange( fn: Function ) {
    this.onChange = fn;
  }

  registerOnError( fn: Function ) {
    this.onError = fn;
  }

  registerOnTouched( fn: Function ) {
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    this.onChange && this.onChange(file);
    if (file && file.name) {
      this.file = file as NzUploadFile as unknown as File;
      if (!this.multi) {
        this.fileList = [];
        this.uploaded.emit(this.file);
      }
    }
    return false;
  };

  async handleChange(info: NzUploadChangeParam): Promise<void> {
  }

}
