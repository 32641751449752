import { Injectable } from '@angular/core';

import { BehaviorSubject } from "rxjs";

import { HttpService } from "@lib/https/frontend";
import { ISecrets } from "@lib/common/types";
import { addDays } from "date-fns";


@Injectable({
  providedIn: 'root'
})
export class SecretService {

  private readonly SECRETS_KEY = "secrets"
  readonly $secrets = new BehaviorSubject<ISecrets|undefined>(undefined);

  constructor(private http: HttpService) {
    try {
      const storage = localStorage.getItem(this.SECRETS_KEY);
      const { secrets, expires } = (storage ? JSON.parse(storage) : { secrets: null, expires: addDays(new Date(), -1) });
      if (secrets && new Date() > expires) {
        this.$secrets.next(secrets);
      }
    } catch (e) {
    }
    this.$secrets.subscribe(secrets => localStorage.setItem(this.SECRETS_KEY, JSON.stringify({ secrets, expires: addDays(new Date(), 14) })));
  }

  async get() {
    const secrets = await this.http.get<ISecrets>('secrets');
    this.$secrets.next(secrets);
  }

}


