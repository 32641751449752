import { NgModule } from '@angular/core';
import { 
  GutachtenQueryModule,
  UmkreissucheQueryModule
} from './';

@NgModule({
  declarations: [],
  imports: [
    GutachtenQueryModule,
    UmkreissucheQueryModule
  ],
  exports: [
    GutachtenQueryModule,
    UmkreissucheQueryModule
  ]
})
export class GutachtenQuerysModule { }
