<!---
  <div formGroupName="reisekostenabrechnung">
    <input class="cdk-visually-hidden" formControlName="id" id="id"/>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="mitarbeiter">{{ command.Inputs.MITARBEITER.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MitarbeiterErrorTip">
        <ng-template #MitarbeiterErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MITARBEITER.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="mitarbeiter" id="mitarbeiter" [shapes]="postReisekostenabrechnung.reisekostenabrechnungMitarbeiter | async" [placeholder]="command.Inputs.MITARBEITER.placeholder" cdkFocusInitial required=true ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="touren">{{ command.Inputs.TOUREN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="TourenErrorTip">
        <ng-template #TourenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.TOUREN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value mode="multiple" formControlName="touren" id="touren" [shapes]="postReisekostenabrechnung.reisekostenabrechnungTouren | async" [placeholder]="command.Inputs.TOUREN.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="reisekostenabrechnungStatus">{{ command.Inputs.REISEKOSTENABRECHNUNG_STATUS.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ReisekostenabrechnungStatusErrorTip">
        <ng-template #ReisekostenabrechnungStatusErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.REISEKOSTENABRECHNUNG_STATUS.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="reisekostenabrechnungStatus" id="reisekostenabrechnungStatus" [shapes]="postReisekostenabrechnung.reisekostenabrechnungReisekostenabrechnungStatus | async" [placeholder]="command.Inputs.REISEKOSTENABRECHNUNG_STATUS.placeholder" required=true ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="tagesgeldAcht">{{ command.Inputs.TAGESGELD_ACHT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="TagesgeldAchtErrorTip">
        <ng-template #TagesgeldAchtErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.TAGESGELD_ACHT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="tagesgeldAcht" id="tagesgeldAcht" [nzPlaceHolder]="command.Inputs.TAGESGELD_ACHT.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="tagesgeldVierundzwanzig">{{ command.Inputs.TAGESGELD_VIERUNDZWANZIG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="TagesgeldVierundzwanzigErrorTip">
        <ng-template #TagesgeldVierundzwanzigErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.TAGESGELD_VIERUNDZWANZIG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="tagesgeldVierundzwanzig" id="tagesgeldVierundzwanzig" [nzPlaceHolder]="command.Inputs.TAGESGELD_VIERUNDZWANZIG.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="anUndAbreisetag">{{ command.Inputs.AN_UND_ABREISETAG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AnUndAbreisetagErrorTip">
        <ng-template #AnUndAbreisetagErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.AN_UND_ABREISETAG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="anUndAbreisetag" id="anUndAbreisetag" [nzPlaceHolder]="command.Inputs.AN_UND_ABREISETAG.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fruehstuecke">{{ command.Inputs.FRUEHSTUECKE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FruehstueckeErrorTip">
        <ng-template #FruehstueckeErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FRUEHSTUECKE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="fruehstuecke" id="fruehstuecke" [nzPlaceHolder]="command.Inputs.FRUEHSTUECKE.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="mittagessen">{{ command.Inputs.MITTAGESSEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MittagessenErrorTip">
        <ng-template #MittagessenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MITTAGESSEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="mittagessen" id="mittagessen" [nzPlaceHolder]="command.Inputs.MITTAGESSEN.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="abendessen">{{ command.Inputs.ABENDESSEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AbendessenErrorTip">
        <ng-template #AbendessenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ABENDESSEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="abendessen" id="abendessen" [nzPlaceHolder]="command.Inputs.ABENDESSEN.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="uebernachtungspauschale">{{ command.Inputs.UEBERNACHTUNGSPAUSCHALE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="UebernachtungspauschaleErrorTip">
        <ng-template #UebernachtungspauschaleErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.UEBERNACHTUNGSPAUSCHALE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="uebernachtungspauschale" id="uebernachtungspauschale" [nzPlaceHolder]="command.Inputs.UEBERNACHTUNGSPAUSCHALE.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="normaleKm">{{ command.Inputs.NORMALE_KM.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NormaleKmErrorTip">
        <ng-template #NormaleKmErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NORMALE_KM.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="normaleKm" id="normaleKm" [nzPlaceHolder]="command.Inputs.NORMALE_KM.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="mitnahmeentschaedigung">{{ command.Inputs.MITNAHMEENTSCHAEDIGUNG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MitnahmeentschaedigungErrorTip">
        <ng-template #MitnahmeentschaedigungErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MITNAHMEENTSCHAEDIGUNG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="mitnahmeentschaedigung" id="mitnahmeentschaedigung" [nzPlaceHolder]="command.Inputs.MITNAHMEENTSCHAEDIGUNG.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="sonstigePositionen">{{ command.Inputs.SONSTIGE_POSITIONEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="SonstigePositionenErrorTip">
        <ng-template #SonstigePositionenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.SONSTIGE_POSITIONEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <textarea nz-input formControlName="sonstigePositionen" id="sonstigePositionen" [placeholder]="command.Inputs.SONSTIGE_POSITIONEN.placeholder"></textarea>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="sonstigeSumme">{{ command.Inputs.SONSTIGE_SUMME.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="SonstigeSummeErrorTip">
        <ng-template #SonstigeSummeErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.SONSTIGE_SUMME.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="sonstigeSumme" id="sonstigeSumme" [nzPlaceHolder]="command.Inputs.SONSTIGE_SUMME.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="summe">{{ command.Inputs.SUMME.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="SummeErrorTip">
        <ng-template #SummeErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.SUMME.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="summe" id="summe" [nzPlaceHolder]="command.Inputs.SUMME.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="antrag">{{ command.Inputs.ANTRAG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AntragErrorTip">
        <ng-template #AntragErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ANTRAG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="antrag" id="antrag" [shapes]="postReisekostenabrechnung.reisekostenabrechnungAntrag | async" [placeholder]="command.Inputs.ANTRAG.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
  </div>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
<!--- << custom    -->
    <div formGroupName="reisekostenabrechnung">
      <input class="cdk-visually-hidden" formControlName="id" id="id"/>
      <input class="cdk-visually-hidden" formControlName="antrag" id="antrag"/>
      <input class="cdk-visually-hidden" formControlName="summe" id="summe"/>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="reisekostenabrechnungStatus">{{ command.Inputs.REISEKOSTENABRECHNUNG_STATUS.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ReisekostenabrechnungStatusErrorTip">
          <ng-template #ReisekostenabrechnungStatusErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.REISEKOSTENABRECHNUNG_STATUS.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <codeboard-select-value formControlName="reisekostenabrechnungStatus" id="reisekostenabrechnungStatus" [shapes]="postReisekostenabrechnung.reisekostenabrechnungReisekostenabrechnungStatus | async" [placeholder]="command.Inputs.REISEKOSTENABRECHNUNG_STATUS.placeholder" required=true ></codeboard-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="mitarbeiter">{{ command.Inputs.MITARBEITER.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MitarbeiterErrorTip">
          <ng-template #MitarbeiterErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MITARBEITER.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <codeboard-select-value formControlName="mitarbeiter" id="mitarbeiter" [shapes]="postReisekostenabrechnung.reisekostenabrechnungMitarbeiter | async" [placeholder]="command.Inputs.MITARBEITER.placeholder" cdkFocusInitial required=true ></codeboard-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="touren">{{ command.Inputs.TOUREN.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="TourenErrorTip">
          <ng-template #TourenErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.TOUREN.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <codeboard-select-value [filter]="reisekostenabrechnungMitarbeiter?.value" mode="multiple" formControlName="touren" id="touren" [shapes]="$touren | async" [placeholder]="command.Inputs.TOUREN.placeholder" ></codeboard-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="tagesgeldAcht">{{ command.Inputs.TAGESGELD_ACHT.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="TagesgeldAchtErrorTip">
          <ng-template #TagesgeldAchtErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.TAGESGELD_ACHT.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="tagesgeldAcht" id="tagesgeldAcht" [placeholder]="command.Inputs.TAGESGELD_ACHT.placeholder"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="tagesgeldVierundzwanzig">{{ command.Inputs.TAGESGELD_VIERUNDZWANZIG.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="TagesgeldVierundzwanzigErrorTip">
          <ng-template #TagesgeldVierundzwanzigErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.TAGESGELD_VIERUNDZWANZIG.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="tagesgeldVierundzwanzig" id="tagesgeldVierundzwanzig" [placeholder]="command.Inputs.TAGESGELD_VIERUNDZWANZIG.placeholder"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="anUndAbreisetag">{{ command.Inputs.AN_UND_ABREISETAG.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AnUndAbreisetagErrorTip">
          <ng-template #AnUndAbreisetagErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.AN_UND_ABREISETAG.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="anUndAbreisetag" id="anUndAbreisetag" [placeholder]="command.Inputs.AN_UND_ABREISETAG.placeholder"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fruehstuecke">{{ command.Inputs.FRUEHSTUECKE.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FruehstueckeErrorTip">
          <ng-template #FruehstueckeErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FRUEHSTUECKE.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="fruehstuecke" id="fruehstuecke" [placeholder]="command.Inputs.FRUEHSTUECKE.placeholder"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="mittagessen">{{ command.Inputs.MITTAGESSEN.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MittagessenErrorTip">
          <ng-template #MittagessenErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MITTAGESSEN.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="mittagessen" id="mittagessen" [placeholder]="command.Inputs.MITTAGESSEN.placeholder"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="abendessen">{{ command.Inputs.ABENDESSEN.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AbendessenErrorTip">
          <ng-template #AbendessenErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ABENDESSEN.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="abendessen" id="abendessen" [placeholder]="command.Inputs.ABENDESSEN.placeholder"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="uebernachtungspauschale">{{ command.Inputs.UEBERNACHTUNGSPAUSCHALE.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="UebernachtungspauschaleErrorTip">
          <ng-template #UebernachtungspauschaleErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.UEBERNACHTUNGSPAUSCHALE.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="uebernachtungspauschale" id="uebernachtungspauschale" [placeholder]="command.Inputs.UEBERNACHTUNGSPAUSCHALE.placeholder"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="normaleKm">{{ command.Inputs.NORMALE_KM.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NormaleKmErrorTip">
          <ng-template #NormaleKmErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NORMALE_KM.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="normaleKm" id="normaleKm" [placeholder]="command.Inputs.NORMALE_KM.placeholder"/> km
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="mitnahmeentschaedigung">{{ command.Inputs.MITNAHMEENTSCHAEDIGUNG.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MitnahmeentschaedigungErrorTip">
          <ng-template #MitnahmeentschaedigungErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MITNAHMEENTSCHAEDIGUNG.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="mitnahmeentschaedigung" id="mitnahmeentschaedigung" [placeholder]="command.Inputs.MITNAHMEENTSCHAEDIGUNG.placeholder"/> km
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="sonstigePositionen">{{ command.Inputs.SONSTIGE_POSITIONEN.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="SonstigePositionenErrorTip">
          <ng-template #SonstigePositionenErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.SONSTIGE_POSITIONEN.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <textarea nz-input formControlName="sonstigePositionen" id="sonstigePositionen" [placeholder]="command.Inputs.SONSTIGE_POSITIONEN.placeholder"></textarea>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="sonstigeSumme">{{ command.Inputs.SONSTIGE_SUMME.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="SonstigeSummeErrorTip">
          <ng-template #SonstigeSummeErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.SONSTIGE_SUMME.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="sonstigeSumme" id="sonstigeSumme" [placeholder]="command.Inputs.SONSTIGE_SUMME.placeholder"/> €
        </nz-form-control>
      </nz-form-item>
    </div>
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="default" class="save-button" type="button" (click)="download()"
                [nzLoading]="$loading | async" [disabled]="(!form.valid || ($loading | async) || !reisekostenabrechnungId.value)">
          <i nz-icon nzType="download" nzTheme="outline"></i>
          Herunterladen
        </button>
      </nz-form-control>
    </nz-form-item>
<!---    custom >> -->
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button"
          type="submit"
          [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)"
          nz-tooltip [nzTooltipTitle]="SubmitTooltip">
<!--- << confirm    -->
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Speichern
<!---    confirm >> -->
          <ng-template #SubmitTooltip>
<!--- << hint    -->
            {{ command.translation }}
<!---    hint >> -->
          </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
