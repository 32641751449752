import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { ObjektServiceModule } from '../';
import { ObjektSorterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ObjektServiceModule
  ],
  declarations: [ObjektSorterComponent],
  exports: [ObjektSorterComponent],

})
export class ObjektSorterComponentModule { }
