import { NgModule } from '@angular/core';

import {
  PostFormularCommandServiceModule,
  PostFormularCommandComponentModule
} from './';

@NgModule({
  imports: [
    PostFormularCommandServiceModule,
    PostFormularCommandComponentModule
  ],
  exports: [
    PostFormularCommandServiceModule,
    PostFormularCommandComponentModule
  ]
})
export class PostFormularCommandModule { }
