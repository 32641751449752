import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { ProjektServiceModule } from '../';
import { ProjektDetailComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ProjektServiceModule
  ],
  declarations: [ProjektDetailComponent],
  exports: [ProjektDetailComponent],

})
export class ProjektDetailComponentModule { }
