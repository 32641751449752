import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostFormularCommandServiceModule } from '../';
import { PostFormularCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostFormularCommandServiceModule
  ],
  declarations: [PostFormularCommandComponent],
  exports: [PostFormularCommandComponent],

})
export class PostFormularCommandComponentModule { }
