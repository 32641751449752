import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostBerichtCommandService } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [PostBerichtCommandService],
  exports: []
})
export class PostBerichtCommandServiceModule { }
