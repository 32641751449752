import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { first, map, debounceTime, distinctUntilChanged } from "rxjs/operators";

import { isEqual } from "lodash";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { FilterPipe, SearchPipe, SorterPipe } from "@lib/filter/frontend";
import { IPersistedFilter, IPersistedFilterList } from "@lib/filter/types";
import { ISelection } from "@lib/persistence/types";

import {
  IDurchsuchenRequest,
  IDurchsuchenResponse,
  IDurchsuchenResponseGroup,
  IDurchsuchenQuerySorter,
  IDurchsuchenQueryFilter,
  IDurchsuchenQueryFilterShapes
} from '@fa-kt-evaluation/markt/types';

@Injectable({
  providedIn: 'root'
})
export class DurchsuchenService {

  protected route = 'markt/durchsuchen';
  protected dependencies = [
    'geo/written-bereich',
    'geo/removed-bereich',
    'markt/written-herausgeber',
    'markt/removed-herausgeber',

    'markt/bericht-entfernt',
    'markt/bericht-gespeichert',
    'markt/bericht-hochgeladen',
    'markt/zeile-korrigiert'
  ];

  readonly $result = new BehaviorSubject<IDurchsuchenResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IDurchsuchenRequest|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IDurchsuchenQueryFilterShapes|undefined>(undefined);
  readonly $response = new BehaviorSubject<IDurchsuchenResponse|undefined>(undefined);
  readonly $filter = new BehaviorSubject<IDurchsuchenQueryFilter>({});
  readonly $sorter = new BehaviorSubject<IDurchsuchenQuerySorter>({});
  readonly $search = new BehaviorSubject<string>('');
  readonly $presets = new BehaviorSubject<IPersistedFilter<IDurchsuchenResponse>[]>([]);
  readonly $preset = new BehaviorSubject<IPersistedFilter<IDurchsuchenResponse>|undefined>(undefined);
  private readonly $filtered = new BehaviorSubject<IDurchsuchenResponse|undefined>(undefined);
  private readonly $searched = new BehaviorSubject<IDurchsuchenResponse|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private device: DeviceConfigService,
    private search: SearchPipe,
    private filter: FilterPipe,
    private sorter: SorterPipe,
  ) {
/* << changes    */
/*    changes >> */
    combineLatest([
      this.$response,
      this.$filter,
    ]).subscribe(([response, filter]) => {
      if (response && response.durchsuchen) {
        const durchsuchen = this.filter.transform(response.durchsuchen, filter);
        this.$filtered.next( { ...response, durchsuchen });
      } else {
        this.$filtered.next(response);
      }
    });
    combineLatest([
      this.$filtered,
      this.$search.pipe(debounceTime(device.debounceTime), distinctUntilChanged()),
      this.$shapes.pipe(distinctUntilChanged((a, b) => isEqual(a, b)))
    ]).subscribe(([filtered, search, shapes]) => {
      if (filtered && filtered.durchsuchen) {
        const durchsuchen = this.search.transform(filtered.durchsuchen, search, '_search', shapes);
        this.$searched.next( { ...filtered, durchsuchen });
      } else {
        this.$searched.next(filtered);
      }
    });
    combineLatest([
      this.$searched,
      this.$sorter.pipe(distinctUntilChanged((a, b) => isEqual(a, b))),
      this.$shapes.pipe(distinctUntilChanged((a, b) => isEqual(a, b)))
    ]).pipe(debounceTime(10)).subscribe(([sorted, sorter, shapes]) => {
      if (sorted && sorted.durchsuchen) {
        const durchsuchen = this.sorter.transform(sorted.durchsuchen, sorter, shapes);
        this.$result.next( { ...sorted, durchsuchen });
      } else {
        this.$result.next(sorted);
      }
    });
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IDurchsuchenRequest): IDurchsuchenRequest {
    if (payload) {
      this.$payload.next(payload as IDurchsuchenRequest);
    } else {
      payload = this.$payload.getValue() as IDurchsuchenRequest;
    }
    return payload;
  }

  public async request(payload: IDurchsuchenRequest, force = false): Promise<IDurchsuchenResponse> {
    const state = this.$response.getValue();
    if (!force && !this.device.alwaysRefresh && state && isEqual(payload, this.payload())) { return state; }
    payload = this.payload(payload);
    if (!payload) { return this.$response.getValue() as IDurchsuchenResponse; }
    const query = this.http.serialize(payload);
    const { response, shapes, filter } = await this.http.get<IDurchsuchenResponseGroup>(this.route + query);
    this.$response.next(response);
    this.$shapes.next(shapes);
    this.$presets.next(filter && filter.filters ? filter.filters : []);
    return response;
  }

  public async writeFilter(filter: IPersistedFilter<IDurchsuchenResponse>): Promise<IPersistedFilter<IDurchsuchenResponse>|undefined> {
    filter = { ...filter, term: this.$search.getValue(), sorter: this.$sorter.getValue(), filter: this.$filter.getValue() };
    const result = await this.http.post(this.route + '/filters', filter) as IPersistedFilterList<IDurchsuchenResponse>;
    filter = result.filters.find(f => f.name === filter.name) as IPersistedFilter<IDurchsuchenResponse>;
    this.$presets.next(result.filters ? result.filters : []);
    this.setFilter(filter);
    return filter;
  }

  public async deleteFilter(id: string): Promise<void> {
    const result = await this.http.delete(this.route + '/filters?id=' + id) as IPersistedFilterList<IDurchsuchenResponse>;
    this.$presets.next(result.filters ? result.filters : []);
    this.setFilter();
  }

  public setFilter(filter?: IPersistedFilter<IDurchsuchenResponse>): void {
    this.$search.next(filter && filter.term ? filter.term : '');
    this.$filter.next(filter && filter.filter ? filter.filter : {});
    this.$sorter.next(filter && filter.sorter ? filter.sorter : {});
    this.$preset.next(filter ? filter : undefined);
  }


  getZeileBereiche(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['zeile.bereiche']?.find(v => v.value === value)
  }

  get zeileBereiche(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['zeile.bereiche'] as ISelection[] : []));
  }


}
