import { Component, OnInit, OnDestroy, Inject, Input, Output, EventEmitter, Injector, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { NzMessageService } from "ng-zorro-antd/message";

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { AuthService } from "@lib/auth/frontend";
import {
  ActionService,
  cleanObject, toInitials,
  CustomValidators, CustomFormatter,
  ISitemap, ISitemapCommand, SITEMAP,
} from "@lib/common/frontend";

import { IPostProjektRequest, IPostProjektResponse } from "@fa-kt-evaluation/projekte/types";
import { PostProjektCommandService } from '@fa-kt-evaluation/projekte/frontend'

/* << importing    */
import {
  KundeService,
  PostAbteilungCommandComponent, PostFondCommandComponent,
  PostKundeCommandComponent
} from "@fa-kt-evaluation/kunden/frontend";
import { IObjekt } from "@fa-kt-evaluation/objekte/types";
import { IAbteilung, IFond, IKunde } from "@fa-kt-evaluation/kunden/types";
import { toConstant } from "@lib/common/frontend";
/*    importing >> */

@Component({
  selector: 'fa-kt-evaluation-post-projekt',
  templateUrl: './post-projekt.component.html',
  styleUrls: ['./post-projekt.component.css']
})
export class PostProjektCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) { this.$loading.next(this.$loading.getValue() || loading); }
  @Input() set value(value: IPostProjektRequest) { this.patch(value); }
  @Output() valueChanged: EventEmitter<IPostProjektRequest> = new EventEmitter<IPostProjektRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostProjektResponse> = new EventEmitter<IPostProjektResponse>();

/* << fields    */
  @Input() objekte: IObjekt[] = [];

  get kunden() {
    return this.injector.get(KundeService);
  }
/*    fields >> */

  get modalRef() {
    return this.injector.get(NzModalRef)
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public postProjekt: PostProjektCommandService,
/* << more    */
/*    more >> */
  ) {
    this.command = sitemap.PROJEKTE.Commands.POST_PROJEKT;
    this.form = this.fb.group({
      projekt: this.fb.group({
        id: [null, []],
        bezeichnung: [null, [Validators.required]],
        nummer: [null, [Validators.required]],
        projektZaehler: [null, []],
        projektArt: [null, [Validators.required]],
        projektStatus: [null, [Validators.required]],
        bewertungsAnlass: [null, [Validators.required]],
        standort: [null, [Validators.required]],
        beauftragung: [null, []],
        deadline: [null, []],
        kundenAnfertigung: [null, []],
        keineBesichtigungErforderlich: [null, []],
        besichtigungen: [[], []],
        gutachten: [[], []],
        pruefung: [null, []],
        kunde: [null, [Validators.required]],
        abteilung: [null, []],
        fond: [null, []],
        bank: [null, []],
        objektArt: [null, [Validators.required]],
        nachlass: [null, []],
        kundenZeichen: [null, []],
        kontakteInOutlook: [[], []],
        dateiVerzeichnis: [null, []],
        kommentar: [null, []],
        notizenFuerRechnung: [null, []],
        fortschritt: [null, []],
        strecke: [null, []],
        kalkulierteStrecke: [null, []],
        fahrtzeit: [null, []],
        kalkulierteFahrtzeit: [null, []],
        stunden: [null, []],
        kostenNachStunden: [null, []],
        verhandeltesHonorar: [null, []],
        kosten: [null, []],
        kostenBeglichen: [null, []],
        aktiv: [null, []],
        darlehensnehmer: [null, []],
        notizenBesichtigung: [null, []],
        kreditsachbearbeiterVorname: [null, []],
        kreditsachbearbeiterName: [null, []],
        kreditsachbearbeiterTelefon: [null, []],
        kreditsachbearbeiterEMail: [null, []],
      })
    });
/* << constructor    */
    this.projektNummer?.disable();
    // this.fond?.disable();
    // this.gutachten?.disable();
    // this.pruefung?.disable();
/*    constructor >> */
    this.patch();
  }

  ngOnInit() {
    this.postProjekt.prepare().catch(e => console.error(e));
    this.subscriptions.push(
      this.finished.subscribe((result) => { if (this.modalRef) { this.modalRef.destroy(result); }}),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() })
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostProjektRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),
      this.projektId?.valueChanges.subscribe(async (id) => {
/* << on-projekt-id-change    */
/*    on-projekt-id-change >> */
      }) as Subscription,
      this.projektBezeichnung?.valueChanges.subscribe(async (bezeichnung) => {
/* << on-projekt-bezeichnung-change    */
/*    on-projekt-bezeichnung-change >> */
      }) as Subscription,
      this.projektNummer?.valueChanges.subscribe(async (nummer) => {
/* << on-projekt-nummer-change    */
/*    on-projekt-nummer-change >> */
      }) as Subscription,
      this.projektProjektZaehler?.valueChanges.subscribe(async (projektZaehler) => {
/* << on-projekt-projekt-zaehler-change    */
/*    on-projekt-projekt-zaehler-change >> */
      }) as Subscription,
      this.projektProjektArt?.valueChanges.subscribe(async (projektArt) => {
/* << on-projekt-projekt-art-change    */
/*    on-projekt-projekt-art-change >> */
      }) as Subscription,
      this.projektProjektStatus?.valueChanges.subscribe(async (projektStatus) => {
/* << on-projekt-projekt-status-change    */
/*    on-projekt-projekt-status-change >> */
      }) as Subscription,
      this.projektStandort?.valueChanges.subscribe(async (standort) => {
/* << on-projekt-standort-change    */
/*    on-projekt-standort-change >> */
      }) as Subscription,
      this.projektBeauftragung?.valueChanges.subscribe(async (beauftragung) => {
/* << on-projekt-beauftragung-change    */
/*    on-projekt-beauftragung-change >> */
      }) as Subscription,
      this.projektDeadline?.valueChanges.subscribe(async (deadline) => {
/* << on-projekt-deadline-change    */
/*    on-projekt-deadline-change >> */
      }) as Subscription,
      this.projektKundenAnfertigung?.valueChanges.subscribe(async (kundenAnfertigung) => {
/* << on-projekt-kunden-anfertigung-change    */
/*    on-projekt-kunden-anfertigung-change >> */
      }) as Subscription,
      this.projektKeineBesichtigungErforderlich?.valueChanges.subscribe(async (keineBesichtigungErforderlich) => {
/* << on-projekt-keine-besichtigung-erforderlich-change    */
/*    on-projekt-keine-besichtigung-erforderlich-change >> */
      }) as Subscription,
      this.projektBesichtigungen?.valueChanges.subscribe(async (besichtigungen) => {
/* << on-projekt-besichtigungen-change    */
/*    on-projekt-besichtigungen-change >> */
      }) as Subscription,
      this.projektGutachten?.valueChanges.subscribe(async (gutachten) => {
/* << on-projekt-gutachten-change    */
/*    on-projekt-gutachten-change >> */
      }) as Subscription,
      this.projektPruefung?.valueChanges.subscribe(async (pruefung) => {
/* << on-projekt-pruefung-change    */
/*    on-projekt-pruefung-change >> */
      }) as Subscription,
      this.projektKunde?.valueChanges.subscribe(async (kunde) => {
/* << on-projekt-kunde-change    */
        if (!this.projektNummer || !this.projektProjektZaehler) { return; }
        let selection = await this.postProjekt.getProjektKunde(kunde);
        kunde = selection?.label?.split(' |')[0];
        const jahr = new Date().getFullYear().toString().slice(2);
        if (kunde) {
          this.projektNummer.patchValue(jahr + '-' + kunde + '-' + this.projektProjektZaehler.value);
        }
/*    on-projekt-kunde-change >> */
      }) as Subscription,
      this.projektAbteilung?.valueChanges.subscribe(async (abteilung) => {
/* << on-projekt-abteilung-change    */
/*    on-projekt-abteilung-change >> */
      }) as Subscription,
      this.projektFond?.valueChanges.subscribe(async (fond) => {
/* << on-projekt-fond-change    */
/*    on-projekt-fond-change >> */
      }) as Subscription,
      this.projektBank?.valueChanges.subscribe(async (bank) => {
/* << on-projekt-bank-change    */
/*    on-projekt-bank-change >> */
      }) as Subscription,
      this.projektObjektArt?.valueChanges.subscribe(async (objektArt) => {
/* << on-projekt-objekt-art-change    */
/*    on-projekt-objekt-art-change >> */
      }) as Subscription,
      this.projektNachlass?.valueChanges.subscribe(async (nachlass) => {
/* << on-projekt-nachlass-change    */
/*    on-projekt-nachlass-change >> */
      }) as Subscription,
      this.projektKundenZeichen?.valueChanges.subscribe(async (kundenZeichen) => {
/* << on-projekt-kunden-zeichen-change    */
/*    on-projekt-kunden-zeichen-change >> */
      }) as Subscription,
      this.projektKontakteInOutlook?.valueChanges.subscribe(async (kontakteInOutlook) => {
/* << on-projekt-kontakte-in-outlook-change    */
/*    on-projekt-kontakte-in-outlook-change >> */
      }) as Subscription,
      this.projektDateiVerzeichnis?.valueChanges.subscribe(async (dateiVerzeichnis) => {
/* << on-projekt-datei-verzeichnis-change    */
/*    on-projekt-datei-verzeichnis-change >> */
      }) as Subscription,
      this.projektKommentar?.valueChanges.subscribe(async (kommentar) => {
/* << on-projekt-kommentar-change    */
/*    on-projekt-kommentar-change >> */
      }) as Subscription,
      this.projektNotizenFuerRechnung?.valueChanges.subscribe(async (notizenFuerRechnung) => {
/* << on-projekt-notizen-fuer-rechnung-change    */
/*    on-projekt-notizen-fuer-rechnung-change >> */
      }) as Subscription,
      this.projektFortschritt?.valueChanges.subscribe(async (fortschritt) => {
/* << on-projekt-fortschritt-change    */
/*    on-projekt-fortschritt-change >> */
      }) as Subscription,
      this.projektStrecke?.valueChanges.subscribe(async (strecke) => {
/* << on-projekt-strecke-change    */
/*    on-projekt-strecke-change >> */
      }) as Subscription,
      this.projektKalkulierteStrecke?.valueChanges.subscribe(async (kalkulierteStrecke) => {
/* << on-projekt-kalkulierte-strecke-change    */
/*    on-projekt-kalkulierte-strecke-change >> */
      }) as Subscription,
      this.projektFahrtzeit?.valueChanges.subscribe(async (fahrtzeit) => {
/* << on-projekt-fahrtzeit-change    */
/*    on-projekt-fahrtzeit-change >> */
      }) as Subscription,
      this.projektKalkulierteFahrtzeit?.valueChanges.subscribe(async (kalkulierteFahrtzeit) => {
/* << on-projekt-kalkulierte-fahrtzeit-change    */
/*    on-projekt-kalkulierte-fahrtzeit-change >> */
      }) as Subscription,
      this.projektStunden?.valueChanges.subscribe(async (stunden) => {
/* << on-projekt-stunden-change    */
/*    on-projekt-stunden-change >> */
      }) as Subscription,
      this.projektKostenNachStunden?.valueChanges.subscribe(async (kostenNachStunden) => {
/* << on-projekt-kosten-nach-stunden-change    */
/*    on-projekt-kosten-nach-stunden-change >> */
      }) as Subscription,
      this.projektVerhandeltesHonorar?.valueChanges.subscribe(async (verhandeltesHonorar) => {
/* << on-projekt-verhandeltes-honorar-change    */
/*    on-projekt-verhandeltes-honorar-change >> */
      }) as Subscription,
      this.projektKosten?.valueChanges.subscribe(async (kosten) => {
/* << on-projekt-kosten-change    */
/*    on-projekt-kosten-change >> */
      }) as Subscription,
      this.projektKostenBeglichen?.valueChanges.subscribe(async (kostenBeglichen) => {
/* << on-projekt-kosten-beglichen-change    */
/*    on-projekt-kosten-beglichen-change >> */
      }) as Subscription,
      this.projektAktiv?.valueChanges.subscribe(async (aktiv) => {
/* << on-projekt-aktiv-change    */
/*    on-projekt-aktiv-change >> */
      }) as Subscription,
      this.projektDarlehensnehmer?.valueChanges.subscribe(async (darlehensnehmer) => {
/* << on-projekt-darlehensnehmer-change    */
/*    on-projekt-darlehensnehmer-change >> */
      }) as Subscription,
      this.projektNotizenBesichtigung?.valueChanges.subscribe(async (notizenBesichtigung) => {
/* << on-projekt-notizen-besichtigung-change    */
/*    on-projekt-notizen-besichtigung-change >> */
      }) as Subscription,
      this.projektKreditsachbearbeiterVorname?.valueChanges.subscribe(async (kreditsachbearbeiterVorname) => {
/* << on-projekt-kreditsachbearbeiter-vorname-change    */
/*    on-projekt-kreditsachbearbeiter-vorname-change >> */
      }) as Subscription,
      this.projektKreditsachbearbeiterName?.valueChanges.subscribe(async (kreditsachbearbeiterName) => {
/* << on-projekt-kreditsachbearbeiter-name-change    */
/*    on-projekt-kreditsachbearbeiter-name-change >> */
      }) as Subscription,
      this.projektKreditsachbearbeiterTelefon?.valueChanges.subscribe(async (kreditsachbearbeiterTelefon) => {
/* << on-projekt-kreditsachbearbeiter-telefon-change    */
/*    on-projekt-kreditsachbearbeiter-telefon-change >> */
      }) as Subscription,
      this.projektKreditsachbearbeiterEMail?.valueChanges.subscribe(async (kreditsachbearbeiterEMail) => {
/* << on-projekt-kreditsachbearbeiter-e-mail-change    */
/*    on-projekt-kreditsachbearbeiter-e-mail-change >> */
      }) as Subscription,
/* << subscibe    */
/*    subscibe >> */
    );
/* << init    */
/*    init >> */
  }

  ngOnDestroy() {
/* << end    */
/*    end >> */
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IPostProjektRequest) {
    if (value) {
      value = cleanObject(value);
      if (value.projekt) {

        if (value.projekt.dateiVerzeichnis && new Date(value.projekt._createdAt as Date).getFullYear() - 2 > new Date().getFullYear()) {
          value.projekt.dateiVerzeichnis = value.projekt.dateiVerzeichnis.replace('OneDrive', 'O:\\')
          value.projekt.dateiVerzeichnis = value.projekt.dateiVerzeichnis.replace('F:\\', 'O:\\')

        }

        value.projekt = cleanObject(value.projekt);
      }
      this.form.patchValue(value);
/* << custom    */
/*    custom >> */
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload.projekt) {
        payload.projekt = cleanObject(payload.projekt);
      }
      const response: IPostProjektResponse = await this.postProjekt.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of (error.errors ? error.errors : [])) {
        for (const children of (validation.children ? validation.children : [])) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

/* << methods    */
  get kunde() {
    return this.form.controls.projekt.get('kunde');
  }
  get bank() {
    return this.form.controls.projekt.get('bank');
  }
  get fond() {
    return this.form.controls.projekt.get('fond');
  }
  get abteilung() {
    return this.form.controls.projekt.get('abteilung');
  }
  get dateiVerzeichnis() {
    return this.form.controls.projekt.get('dateiVerzeichnis');
  }
  get gutachten() {
    return this.form.controls.projekt.get('gutachten');
  }
  get pruefung() {
    return this.form.controls.projekt.get('pruefung');
  }

  createKunde(name: string) {
    const nil: string = null as unknown as string;
    const kunde = { name, initialen: toInitials(name), id: nil, kundenGruppe: nil  };
    const createKunde = this.modal.create({
      nzTitle: 'Neuen Kunden hinzufügen',
      nzComponentParams: { value: { kunde } },
      nzContent: PostKundeCommandComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: []
    });
    const $ = createKunde.afterClose.subscribe(async (result) => {
      if (result) {
        await this.postProjekt.prepare(true);
        this.kunde?.patchValue( result.kunde.id);
      }
      $.unsubscribe();
    });
  }


  createBank(name: string) {
    const nil: string = null as unknown as string;
    const kunde = { name, initialen: toInitials(name), id: nil, kundenGruppe: nil  };
    const createKunde = this.modal.create({
      nzTitle: 'Neuen Kunden hinzufügen',
      nzComponentParams: { value: { kunde } },
      nzContent: PostKundeCommandComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: []
    });
    const $ = createKunde.afterClose.subscribe(async (result) => {
      if (result) {
        await this.postProjekt.prepare(true);
        this.bank?.patchValue( result.kunde.id);
      }
      $.unsubscribe();
    });

  }

  createAbteilung(name: string) {
    const nil: string = null as unknown as string;
    const kunde = this.kunde?.value;
    if (!kunde) { return; }
    const abteilung: IAbteilung = { name, kunde, aktiv: true, id: nil  };
    const createAbteilung = this.modal.create({
      nzTitle: 'Neue Abteilung hinzufügen',
      nzComponentParams: { value: { abteilung } },
      nzContent: PostAbteilungCommandComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: []
    });
    const $ = createAbteilung.afterClose.subscribe(async (result) => {
      if (result) {
        await this.postProjekt.prepare(true);
        this.abteilung?.patchValue(result.abteilung.id);
      }
      $.unsubscribe();
    });
  }

  createFond(name: string) {
    const nil: string = null as unknown as string;
    const kunde = this.kunde?.value;
    if (!kunde) { return; }
    const fond: IFond = { name, kunde, aktiv: true, id: nil  };
    const createAbteilung = this.modal.create({
      nzTitle: 'Neuen Fond hinzufügen',
      nzComponentParams: { value: { fond } },
      nzContent: PostFondCommandComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: []
    });
    const $ = createAbteilung.afterClose.subscribe(async (result) => {
      if (result) {
        await this.postProjekt.prepare(true);
        this.fond?.patchValue(result.fond.id);
      }
      $.unsubscribe();
    });
  }
/*    methods >> */

  get projektId() {
    return this.form.controls.projekt.get('id');
  };
  get projektBezeichnung() {
    return this.form.controls.projekt.get('bezeichnung');
  };
  get projektNummer() {
    return this.form.controls.projekt.get('nummer');
  };
  get projektProjektZaehler() {
    return this.form.controls.projekt.get('projektZaehler');
  };
  get projektProjektArt() {
    return this.form.controls.projekt.get('projektArt');
  };
  get projektProjektStatus() {
    return this.form.controls.projekt.get('projektStatus');
  };
  get projektStandort() {
    return this.form.controls.projekt.get('standort');
  };
  get projektBeauftragung() {
    return this.form.controls.projekt.get('beauftragung');
  };
  get projektDeadline() {
    return this.form.controls.projekt.get('deadline');
  };
  get projektKundenAnfertigung() {
    return this.form.controls.projekt.get('kundenAnfertigung');
  };
  get projektKeineBesichtigungErforderlich() {
    return this.form.controls.projekt.get('keineBesichtigungErforderlich');
  };
  get projektBesichtigungen() {
    return this.form.controls.projekt.get('besichtigungen');
  };
  get projektGutachten() {
    return this.form.controls.projekt.get('gutachten');
  };
  get projektPruefung() {
    return this.form.controls.projekt.get('pruefung');
  };
  get projektKunde() {
    return this.form.controls.projekt.get('kunde');
  };
  get projektAbteilung() {
    return this.form.controls.projekt.get('abteilung');
  };
  get projektFond() {
    return this.form.controls.projekt.get('fond');
  };
  get projektBank() {
    return this.form.controls.projekt.get('bank');
  };
  get projektObjektArt() {
    return this.form.controls.projekt.get('objektArt');
  };
  get projektNachlass() {
    return this.form.controls.projekt.get('nachlass');
  };
  get projektKundenZeichen() {
    return this.form.controls.projekt.get('kundenZeichen');
  };
  get projektKontakteInOutlook() {
    return this.form.controls.projekt.get('kontakteInOutlook');
  };
  get projektDateiVerzeichnis() {
    return this.form.controls.projekt.get('dateiVerzeichnis');
  };
  get projektKommentar() {
    return this.form.controls.projekt.get('kommentar');
  };
  get projektNotizenFuerRechnung() {
    return this.form.controls.projekt.get('notizenFuerRechnung');
  };
  get projektFortschritt() {
    return this.form.controls.projekt.get('fortschritt');
  };
  get projektStrecke() {
    return this.form.controls.projekt.get('strecke');
  };
  get projektKalkulierteStrecke() {
    return this.form.controls.projekt.get('kalkulierteStrecke');
  };
  get projektFahrtzeit() {
    return this.form.controls.projekt.get('fahrtzeit');
  };
  get projektKalkulierteFahrtzeit() {
    return this.form.controls.projekt.get('kalkulierteFahrtzeit');
  };
  get projektStunden() {
    return this.form.controls.projekt.get('stunden');
  };
  get projektKostenNachStunden() {
    return this.form.controls.projekt.get('kostenNachStunden');
  };
  get projektVerhandeltesHonorar() {
    return this.form.controls.projekt.get('verhandeltesHonorar');
  };
  get projektKosten() {
    return this.form.controls.projekt.get('kosten');
  };
  get projektKostenBeglichen() {
    return this.form.controls.projekt.get('kostenBeglichen');
  };
  get projektAktiv() {
    return this.form.controls.projekt.get('aktiv');
  };
  get projektDarlehensnehmer() {
    return this.form.controls.projekt.get('darlehensnehmer');
  };
  get projektNotizenBesichtigung() {
    return this.form.controls.projekt.get('notizenBesichtigung');
  };
  get projektKreditsachbearbeiterVorname() {
    return this.form.controls.projekt.get('kreditsachbearbeiterVorname');
  };
  get projektKreditsachbearbeiterName() {
    return this.form.controls.projekt.get('kreditsachbearbeiterName');
  };
  get projektKreditsachbearbeiterTelefon() {
    return this.form.controls.projekt.get('kreditsachbearbeiterTelefon');
  };
  get projektKreditsachbearbeiterEMail() {
    return this.form.controls.projekt.get('kreditsachbearbeiterEMail');
  };
}
