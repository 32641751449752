import { NgModule } from '@angular/core';

import {
  PostMitarbeiterCommandServiceModule,
  PostMitarbeiterCommandComponentModule
} from './';

@NgModule({
  imports: [
    PostMitarbeiterCommandServiceModule,
    PostMitarbeiterCommandComponentModule
  ],
  exports: [
    PostMitarbeiterCommandServiceModule,
    PostMitarbeiterCommandComponentModule
  ]
})
export class PostMitarbeiterCommandModule { }
