import { Component, OnInit, OnDestroy, Inject, Input, Output, EventEmitter, Injector, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { NzMessageService } from "ng-zorro-antd/message";

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { AuthService } from "@lib/auth/frontend";
import {
  ActionService,
  cleanObject, toInitials,
  CustomValidators, CustomFormatter, 
  ISitemap, ISitemapCommand, SITEMAP,
} from "@lib/common/frontend";

import { IPostBerichtRequest, IPostBerichtResponse } from "@fa-kt-evaluation/markt/types";
import { PostBerichtCommandService } from '@fa-kt-evaluation/markt/frontend'

/* << importing    */
/*    importing >> */

@Component({
  selector: 'fa-kt-evaluation-post-bericht',
  templateUrl: './post-bericht.component.html',
  styleUrls: ['./post-bericht.component.css']
})
export class PostBerichtCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) { this.$loading.next(this.$loading.getValue() || loading); }
  @Input() set value(value: IPostBerichtRequest) { this.patch(value); }
  @Output() valueChanged: EventEmitter<IPostBerichtRequest> = new EventEmitter<IPostBerichtRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostBerichtResponse> = new EventEmitter<IPostBerichtResponse>();

/* << fields    */
/*    fields >> */

  get modalRef() {
    return this.injector.get(NzModalRef)
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public postBericht: PostBerichtCommandService,
/* << more    */
/*    more >> */
  ) {
    this.command = sitemap.MARKT.Commands.POST_BERICHT;
    this.form = this.fb.group({
      bericht: this.fb.group({
        id: [null, []],
        excel: [null, []],
        art: [null, [Validators.required]],
        herausgeber: [null, [Validators.required]],
        quelle: [null, [Validators.required]],
        jahr: [null, []],
        quartal: [null, []],
        monat: [null, []],
        kommentar: [null, []],
      })
    });
/* << constructor    */
/*    constructor >> */
    this.patch();
  }

  ngOnInit() {
    this.postBericht.prepare().catch(e => console.error(e));
    this.subscriptions.push(
      this.finished.subscribe((result) => { if (this.modalRef) { this.modalRef.destroy(result); }}),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() })
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostBerichtRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),
      this.berichtId?.valueChanges.subscribe(async (id) => {
/* << on-bericht-id-change    */
/*    on-bericht-id-change >> */
      }) as Subscription,
      this.berichtExcel?.valueChanges.subscribe(async (excel) => {
/* << on-bericht-excel-change    */
/*    on-bericht-excel-change >> */
      }) as Subscription,
      this.berichtArt?.valueChanges.subscribe(async (art) => {
/* << on-bericht-art-change    */
/*    on-bericht-art-change >> */
      }) as Subscription,
      this.berichtHerausgeber?.valueChanges.subscribe(async (herausgeber) => {
/* << on-bericht-herausgeber-change    */
/*    on-bericht-herausgeber-change >> */
      }) as Subscription,
      this.berichtQuelle?.valueChanges.subscribe(async (quelle) => {
/* << on-bericht-quelle-change    */
/*    on-bericht-quelle-change >> */
      }) as Subscription,
      this.berichtJahr?.valueChanges.subscribe(async (jahr) => {
/* << on-bericht-jahr-change    */
/*    on-bericht-jahr-change >> */
      }) as Subscription,
      this.berichtQuartal?.valueChanges.subscribe(async (quartal) => {
/* << on-bericht-quartal-change    */
/*    on-bericht-quartal-change >> */
      }) as Subscription,
      this.berichtMonat?.valueChanges.subscribe(async (monat) => {
/* << on-bericht-monat-change    */
/*    on-bericht-monat-change >> */
      }) as Subscription,
      this.berichtKommentar?.valueChanges.subscribe(async (kommentar) => {
/* << on-bericht-kommentar-change    */
/*    on-bericht-kommentar-change >> */
      }) as Subscription,
/* << subscibe    */
/*    subscibe >> */
    );
/* << init    */
/*    init >> */
  }

  ngOnDestroy() {
/* << end    */
/*    end >> */
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IPostBerichtRequest) {
    if (value) {
      value = cleanObject(value);
      if (value.bericht) {
        value.bericht = cleanObject(value.bericht);
      }
      this.form.patchValue(value);
/* << custom    */
/*    custom >> */
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload.bericht) {
        payload.bericht = cleanObject(payload.bericht);
      }
      const response: IPostBerichtResponse = await this.postBericht.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of (error.errors ? error.errors : [])) {
        for (const children of (validation.children ? validation.children : [])) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

/* << methods    */
/*    methods >> */

  get berichtId() {
    return this.form.controls.bericht.get('id');
  };
  get berichtExcel() {
    return this.form.controls.bericht.get('excel');
  };
  get berichtArt() {
    return this.form.controls.bericht.get('art');
  };
  get berichtHerausgeber() {
    return this.form.controls.bericht.get('herausgeber');
  };
  get berichtQuelle() {
    return this.form.controls.bericht.get('quelle');
  };
  get berichtJahr() {
    return this.form.controls.bericht.get('jahr');
  };
  get berichtQuartal() {
    return this.form.controls.bericht.get('quartal');
  };
  get berichtMonat() {
    return this.form.controls.bericht.get('monat');
  };
  get berichtKommentar() {
    return this.form.controls.bericht.get('kommentar');
  };


}
