import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IPostOrdneBesichtigungenRequest, IPostOrdneBesichtigungenResponse, IPostOrdneBesichtigungenCommandShapes } from '@fa-kt-evaluation/besichtigungen/types';

@Injectable({
  providedIn: 'root'
})
export class PostOrdneBesichtigungenCommandService {

  readonly route = 'besichtigungen/ordne-besichtigungen';
  protected dependencies = [
      'besichtigungen/written-besichtigungs-status',
      'besichtigungen/removed-besichtigungs-status',
      'besichtigungen/besichtigung-geordnet'
  ];

  readonly $shapes = new BehaviorSubject<IPostOrdneBesichtigungenCommandShapes|undefined>(undefined);
  
  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IPostOrdneBesichtigungenCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IPostOrdneBesichtigungenCommandShapes>(this.route + '/post/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IPostOrdneBesichtigungenRequest): Promise<IPostOrdneBesichtigungenResponse> {
    return await this.http.post<IPostOrdneBesichtigungenResponse>(this.route, payload) as unknown as IPostOrdneBesichtigungenResponse;
  }
  
  getBesichtigungenBesichtigungsStatus(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['besichtigungen.besichtigungsStatus']?.find(v => v.value === value)
  }

  get besichtigungenBesichtigungsStatus(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['besichtigungen.besichtigungsStatus'] as ISelection[] : []));
  }

  getBesichtigungenMitarbeiter(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['besichtigungen.mitarbeiter']?.find(v => v.value === value)
  }

  get besichtigungenMitarbeiter(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['besichtigungen.mitarbeiter'] as ISelection[] : []));
  }

}
