import { Injectable } from '@angular/core';
import { AbstractControl } from "@angular/forms";

import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, first, map } from "rxjs/operators";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { FilterPipe, SearchPipe, SorterPipe } from "@lib/filter/frontend";
import { ISelection } from "@lib/persistence/types";

import {
  IProjektArtsRequest,
  IProjektArtsResponse,
  IProjektArtsQuerySorter,
  IProjektArtsQueryFilter,
  IProjektArtsQueryFilterShapes,
  IProjektArtsResponseGroup,
} from '@fa-kt-evaluation/projekte/types';

@Injectable({
  providedIn: 'root'
})
export class ProjektArtsService {

  protected route = 'projekte/settings/projekt-arts';
  protected dependencies = [
    'projekte/written-projekt-art',
    'projekte/removed-projekt-art',
  ];

  readonly $result = new BehaviorSubject<IProjektArtsResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IProjektArtsRequest|undefined>(undefined);
  readonly $response = new BehaviorSubject<IProjektArtsResponse|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IProjektArtsQueryFilterShapes|undefined>(undefined);
  readonly $filter = new BehaviorSubject<IProjektArtsQueryFilter>({});
  readonly $sorter = new BehaviorSubject<IProjektArtsQuerySorter>({});
  readonly $search = new BehaviorSubject<string>('');
  private readonly $filtered = new BehaviorSubject<IProjektArtsResponse|undefined>(undefined);
  private readonly $searched = new BehaviorSubject<IProjektArtsResponse|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private search: SearchPipe,
    private filter: FilterPipe,
    private sorter: SorterPipe,
    private device: DeviceConfigService
  ) {
    combineLatest([
      this.$response,
      this.$filter,
    ]).subscribe(([response, filter]) => {
      if (response && response.projektArts) {
        const projektArts = this.filter.transform(response.projektArts, filter, true);
        this.$filtered.next( { ...response, projektArts });
      } else {
        this.$filtered.next(response);
      }
    });
    combineLatest([
      this.$filtered,
      this.$search.pipe(debounceTime(device.debounceTime), distinctUntilChanged()),
      this.$shapes
    ]).subscribe(([filtered, search, shapes]) => {
      if (filtered && filtered.projektArts) {
        const projektArts = this.search.transform(filtered.projektArts, search, '_search', shapes);
        this.$searched.next( { ...filtered, projektArts });
      } else {
        this.$searched.next(filtered);
      }
    });
    combineLatest([
      this.$searched,
      this.$sorter,
      this.$shapes
    ]).pipe(debounceTime(10)).subscribe(([searched, sorter, shapes]) => {
      if (searched && searched.projektArts) {
        const projektArts = this.sorter.transform(searched.projektArts, sorter, shapes);
        this.$result.next( { ...searched, projektArts });
      } else {
        this.$result.next(searched);
      }
    });
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IProjektArtsRequest): IProjektArtsRequest {
    if (payload) {
      this.$payload.next(payload as IProjektArtsRequest);
    } else {
      payload = this.$payload.getValue() as IProjektArtsRequest;
    }
    return payload;
  }

  public async request(payload?: IProjektArtsRequest, force = false): Promise<IProjektArtsResponse> {
    payload = this.payload(payload);
    const state = this.$response.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const { response, shapes } = await this.http.get<IProjektArtsResponseGroup>(this.route + this.http.serialize(payload));
    this.$shapes.next(shapes);
    this.$response.next(response);
    return response;
  }
  
  public initialenIsUnique(control: AbstractControl|null): (initialen: string) => boolean {
    return (initialen: string) => {
      const id = control ? control.value : '';
      let isUnique = true;
      if (initialen) {
        const response = this.$response.getValue();
        if (response && response.projektArts) {
          isUnique = response.projektArts.filter(pa => pa.id !== id && pa.initialen === initialen).length === 0;
        } else {
          isUnique = true;
        }
      }
      return isUnique;
    }
  }



}
