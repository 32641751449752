import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { first, map, debounceTime, distinctUntilChanged } from "rxjs/operators";

import { isEqual } from "lodash";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { IPersistedFilter, IPersistedFilterList } from "@lib/filter/types";
import { ISelection } from "@lib/persistence/types";

import {
  IReisekostenabrechnungRequest,
  IReisekostenabrechnungResponse,
  IReisekostenabrechnungResponseGroup,
  IReisekostenabrechnungQueryFilterShapes
} from '@fa-kt-evaluation/finanzen/types';

@Injectable({
  providedIn: 'root'
})
export class ReisekostenabrechnungService {

  protected route = 'finanzen/reisekostenabrechnung';
  protected dependencies = [
    'finanzen/written-reisekostenabrechnung-status',
    'finanzen/removed-reisekostenabrechnung-status',

    'finanzen/reisekostenabrechnung-entfernt',
    'finanzen/reisekostenabrechnung-gespeichert'
  ];

  readonly $result = new BehaviorSubject<IReisekostenabrechnungResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IReisekostenabrechnungRequest|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IReisekostenabrechnungQueryFilterShapes|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private device: DeviceConfigService,
  ) {
/* << changes    */
/*    changes >> */
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IReisekostenabrechnungRequest): IReisekostenabrechnungRequest {
    if (payload) {
      this.$payload.next(payload as IReisekostenabrechnungRequest);
    } else {
      payload = this.$payload.getValue() as IReisekostenabrechnungRequest;
    }
    return payload;
  }

  public async request(payload: IReisekostenabrechnungRequest, force = false): Promise<IReisekostenabrechnungResponse> {
    const state = this.$result.getValue();
    if (!force && !this.device.alwaysRefresh && state && isEqual(payload, this.payload())) { return state; }
    payload = this.payload(payload);
    // if (!payload) { return this.$result.getValue() as IReisekostenabrechnungResponse; }
    const query = this.http.serialize(payload);
    const { response, shapes } = await this.http.get<IReisekostenabrechnungResponseGroup>(this.route + query);
    this.$result.next(response);
    this.$shapes.next(shapes);
    return response;
  }


  getReisekostenabrechnungMitarbeiter(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['reisekostenabrechnung.mitarbeiter']?.find(v => v.value === value)
  }

  get reisekostenabrechnungMitarbeiter(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['reisekostenabrechnung.mitarbeiter'] as ISelection[] : []));
  }

  getReisekostenabrechnungReisekostenabrechnungStatus(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['reisekostenabrechnung.reisekostenabrechnungStatus']?.find(v => v.value === value)
  }

  get reisekostenabrechnungReisekostenabrechnungStatus(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['reisekostenabrechnung.reisekostenabrechnungStatus'] as ISelection[] : []));
  }

  getReisekostenabrechnungTouren(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['reisekostenabrechnung.touren']?.find(v => v.value === value)
  }

  get reisekostenabrechnungTouren(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['reisekostenabrechnung.touren'] as ISelection[] : []));
  }


}
