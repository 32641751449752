import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { KundenServiceModule } from '../';
import { KundenSorterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    KundenServiceModule
  ],
  declarations: [KundenSorterComponent],
  exports: [KundenSorterComponent],

})
export class KundenSorterComponentModule { }
