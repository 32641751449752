import { Component } from '@angular/core';
import { TourenService } from "@fa-kt-evaluation/besichtigungen/frontend";

/* << imports    */
 
/*    imports >> */
@Component({
  selector: 'fa-kt-evaluation-saved-touren-filters',
  templateUrl: './saved-touren-filters.component.html',
  styleUrls: ['./saved-touren-filters.component.css']
})
export class SavedTourenFiltersComponent {

  constructor(
    public touren: TourenService
/* << injection    */
/*    injection >> */
  ) {
  }
}