import { NgModule } from '@angular/core';
import { 
  KundeQueryModule,
  KundenQueryModule
} from './';

@NgModule({
  declarations: [],
  imports: [
    KundeQueryModule,
    KundenQueryModule
  ],
  exports: [
    KundeQueryModule,
    KundenQueryModule
  ]
})
export class KundenQuerysModule { }
