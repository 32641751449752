import { NgModule } from '@angular/core';
import { 
  DeleteAbteilungCommandModule,
  PostAbteilungCommandModule,
  PostFondCommandModule,
  DeleteFondCommandModule,
  PostKundeCommandModule,
  DeleteKundeCommandModule,
  PostKundenFinanzenCommandModule
} from './';

@NgModule({
  declarations: [],
  imports: [
    DeleteAbteilungCommandModule,
    PostAbteilungCommandModule,
    PostFondCommandModule,
    DeleteFondCommandModule,
    PostKundeCommandModule,
    DeleteKundeCommandModule,
    PostKundenFinanzenCommandModule
  ],
  exports: [
    DeleteAbteilungCommandModule,
    PostAbteilungCommandModule,
    PostFondCommandModule,
    DeleteFondCommandModule,
    PostKundeCommandModule,
    DeleteKundeCommandModule,
    PostKundenFinanzenCommandModule
  ]
})
export class KundenCommandsModule { }
