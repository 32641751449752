import { IAppConfig, ISitemap, translateSitemap } from "@lib/common/frontend";

import { environment } from '../environments';
export const AppConfig: IAppConfig = environment;

export const AppTitle: string = 'FA|KT Apps';

import PackageJSON from '../../../../package.json';
export const AppVersion: string = PackageJSON.version;

import buildDateJSON from '../../../../build.json';
export const AppBuild: string = buildDateJSON.build.toString();


import sitemap from './sitemap.json';

sitemap.EINSTELLUNGEN.emoji = '🛠️️';
sitemap.EINSTELLUNGEN.Pages.EINSTELLUNGEN.emoji = '⚙️';

sitemap.AUSWERTUNGEN.emoji = '📈️';
sitemap.AUSWERTUNGEN.Pages.AUSWERTUNGEN.emoji = '📈️';

sitemap.EINSTELLUNGEN.Models.KONSTANTE.many = 'Konstanten';
sitemap.EINSTELLUNGEN.Models.KONSTANTE.single = 'Konstante ';
sitemap.EINSTELLUNGEN.Models.KONSTANTE.emoji = '🔩';

sitemap.PERSONEN.emoji = '🥳';

sitemap.PERSONEN.Pages.MITARBEITER.title = 'Mitarbeiter';
sitemap.PERSONEN.Pages.MITARBEITER.emoji = '👯';
sitemap.PERSONEN.Pages.MITARBEITER.breadcrumbs = ['settings', ...sitemap.PERSONEN.Pages.MITARBEITER.breadcrumbs ];
sitemap.PERSONEN.Pages.PROFIL.title = 'Profil';
sitemap.PERSONEN.Pages.PROFIL.emoji = '🥳';
sitemap.PERSONEN.Pages.PROFIL.breadcrumbs = ['settings', 'mitarbeiter', ...sitemap.PERSONEN.Pages.PROFIL.breadcrumbs ];
sitemap.PERSONEN.Pages.ICH.emoji = '💯';
sitemap.PERSONEN.Pages.ICH.breadcrumbs = ['settings', 'mitarbeiter', ...sitemap.PERSONEN.Pages.ICH.breadcrumbs ];

sitemap.PERSONEN.Models.MITARBEITER.Propertys.STANDORT.title = 'Standort';
sitemap.PERSONEN.Models.MITARBEITER.Propertys.STANDORT.hint = 'Bitte einen Standort auswählen';
sitemap.PERSONEN.Models.MITARBEITER.Propertys.STANDORT.placeholder = 'Standort';
sitemap.PERSONEN.Models.MITARBEITER.Propertys.ROLLEN.title = 'Rollen';
sitemap.PERSONEN.Models.MITARBEITER.Propertys.ROLLEN.hint = 'Mindestens eine Rolle auswählen';
sitemap.PERSONEN.Models.MITARBEITER.Propertys.ROLLEN.placeholder = 'Rollen';

sitemap.PERSONEN.Commands.POST_MITARBEITER.translation = 'Mitarbeiter:in speichern';
sitemap.PERSONEN.Events["mitarbeiter-aktualisiert"].translation = 'Mitarbeiter:in gespeichert.';

sitemap.PERSONEN.Commands.POST_SYNC_AZURE_ACTIVE_DIRECTORY.translation = 'Azure Active Directory Synchronisieren';
sitemap.PERSONEN.Events["azure-active-directory-synchronisiert"].translation = 'Azure Active Directory wurde synchronisiert.';

sitemap.PERSONEN.Models.ROLLE.many = 'Rollen';
sitemap.PERSONEN.Models.ROLLE.emoji = '👷';

sitemap.PERSONEN.Models.STANDORT.many = 'Standorte';
sitemap.PERSONEN.Models.STANDORT.emoji = '🏢';


sitemap.KUNDEN.title = 'Kunden';
sitemap.KUNDEN.emoji = '👔';

sitemap.KUNDEN.Pages.KUNDEN.emoji = '👔';
sitemap.KUNDEN.Pages.KUNDE.emoji = '✌️';
sitemap.KUNDEN.Pages.KUNDE.breadcrumbs = ['kunden', ...sitemap.KUNDEN.Pages.KUNDE.breadcrumbs]

sitemap.KUNDEN.Models.KUNDEN_ART.many = 'Kundenarten';
sitemap.KUNDEN.Models.KUNDEN_ART.single = 'Kundenart';
sitemap.KUNDEN.Models.KUNDEN_ART.emoji = '🧐';

sitemap.KUNDEN.Models.KUNDEN_GRUPPE.many = 'Kundengruppen';
sitemap.KUNDEN.Models.KUNDEN_GRUPPE.single = 'Kundengruppe';
sitemap.KUNDEN.Models.KUNDEN_GRUPPE.emoji = '🏛️';

sitemap.KUNDEN.Models.KUNDE.many = 'Kunden';
sitemap.KUNDEN.Models.KUNDE.emoji = '👋';
sitemap.KUNDEN.Models.KUNDE.Propertys.KUNDEN_ART.title = 'Kundenart';
sitemap.KUNDEN.Models.KUNDE.Propertys.KUNDEN_ART.hint = 'Bitte Kundenart auswählen';
sitemap.KUNDEN.Models.KUNDE.Propertys.KUNDEN_ART.placeholder = 'Kundenarten';
sitemap.KUNDEN.Models.KUNDE.Propertys.KONTAKT_NOTIZEN.title = 'Kontaktierung';
sitemap.KUNDEN.Models.KUNDE.Propertys.STEUER_ID.title = 'Steuer-ID';
sitemap.KUNDEN.Models.KUNDE.Propertys.STEUER_ID.placeholder = 'für Rechnungen, Steuererklärung, etc.';
sitemap.KUNDEN.Models.KUNDE.Propertys.KONTAKT_NOTIZEN.placeholder = 'Notizen für Kontaktierung';
sitemap.KUNDEN.Models.KUNDE.Propertys.ANFORDERUNGEN_NOTIZEN.title = 'Erforderliche Dokumente';
sitemap.KUNDEN.Models.KUNDE.Propertys.ANFORDERUNGEN_NOTIZEN.placeholder = 'Notizen für Erforderliche Dokumente';
sitemap.KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_ABRECHNUNG.title = 'Fahrtkosten';
sitemap.KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_ABRECHNUNG.placeholder = 'Art der Abrechnung';
sitemap.KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_AB_RADIUS.title = 'Ab Radius';
sitemap.KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_AB_RADIUS.placeholder = 'ab km';
sitemap.KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_PRO_KM.title = 'pro KM';
sitemap.KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_PRO_KM.placeholder = 'in €';
sitemap.KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_PRO_STUNDE.title = 'Pro Stunde';
sitemap.KUNDEN.Models.KUNDE.Propertys.FAHRTKOSTEN_PRO_STUNDE.placeholder = 'in €';

sitemap.KUNDEN.Commands.POST_KUNDE.translation = 'Kunde speichern';
sitemap.KUNDEN.Commands.DELETE_KUNDE.translation = 'Kunde entfernen';

sitemap.KUNDEN.Commands.POST_ABTEILUNG.translation = 'Abteilung speichern';
sitemap.KUNDEN.Commands.DELETE_ABTEILUNG.translation = 'Abteilung entfernen';


sitemap.PROJEKTE.emoji = '📗';

sitemap.PROJEKTE.Pages.DASHBOARD.emoji = '💥';
sitemap.PROJEKTE.Pages.PROJEKTE.emoji = '📚️';
sitemap.PROJEKTE.Pages.PROJEKT.emoji = '📖';
sitemap.PROJEKTE.Pages.PROJEKT.breadcrumbs = ['projekte', ...sitemap.PROJEKTE.Pages.PROJEKT.breadcrumbs ]

sitemap.PROJEKTE.Models.PROJEKT_ART.many = 'Projektarten';
sitemap.PROJEKTE.Models.PROJEKT_ART.single = 'Projektart';
sitemap.PROJEKTE.Models.PROJEKT_ART.emoji = '📥️';

sitemap.PROJEKTE.Models.PROJEKT_STATUS.many = 'Projektstati';
sitemap.PROJEKTE.Models.PROJEKT_STATUS.single = 'Projektstatus';
sitemap.PROJEKTE.Models.PROJEKT_STATUS.emoji = '🚦';

sitemap.PROJEKTE.Models.PROJEKT.Propertys.PROJEKT_ZAEHLER.title = 'Laufende Nummer';
sitemap.PROJEKTE.Models.PROJEKT.Propertys.FORTSCHRITT.title = 'Projektfortschritt';
sitemap.PROJEKTE.Models.PROJEKT.Propertys.BANK.title = 'Auftraggeber Kunde';
sitemap.PROJEKTE.Models.PROJEKT.Propertys.NOTIZEN_FUER_RECHNUNG.title = 'Notizen für Rechnung';
sitemap.PROJEKTE.Models.PROJEKT.Propertys.KUNDEN_ANFERTIGUNG.title = 'Kundenausfertigung';
sitemap.PROJEKTE.Models.PROJEKT.Propertys.KUNDE.title = 'Auftraggeber';
sitemap.PROJEKTE.Models.PROJEKT.Propertys.PROJEKT_ART.title = 'Projektart';
sitemap.PROJEKTE.Models.PROJEKT.Propertys.GUTACHTEN.title = 'Gutachter/-in';
sitemap.PROJEKTE.Models.PROJEKT.Propertys.BESICHTIGUNGEN.title = 'Besichtiger/-in';
sitemap.PROJEKTE.Models.PROJEKT.Propertys.PRUEFUNG.title = 'Prüfer/-in';
sitemap.PROJEKTE.Models.PROJEKT.Propertys.OBJEKT_ART.title = 'Objektart';
sitemap.PROJEKTE.Models.PROJEKT.Propertys.KOSTEN_NACH_STUNDEN.title = 'Bewertete Stunden';
sitemap.PROJEKTE.Models.PROJEKT.Propertys.KOSTEN.title = 'Rechnungsausgang';
sitemap.PROJEKTE.Models.PROJEKT.Propertys.KOSTEN_BEGLICHEN.title = 'Rechnungseingang';
sitemap.PROJEKTE.Models.PROJEKT.Propertys.OBJEKT_ART.title = 'Abrechnungsart';

sitemap.PROJEKTE.Commands.POST_PROJEKT.translation = 'Speichern';

sitemap.OBJEKTE.emoji = '🏡';

sitemap.OBJEKTE.Models.OBJEKT_NUTZUNG.many = 'Objektnutzungen';
sitemap.OBJEKTE.Models.OBJEKT_NUTZUNG.single = 'Objektnutzung';
sitemap.OBJEKTE.Models.OBJEKT_NUTZUNG.emoji = '🏟️';

sitemap.OBJEKTE.Models.OBJEKT_ART.many = 'Objektarten';
sitemap.OBJEKTE.Models.OBJEKT_ART.single = 'Objektart';
sitemap.OBJEKTE.Models.OBJEKT_ART.emoji = '🏡';

sitemap.OBJEKTE.Models.OBJEKT.Propertys.ANSPRACHE_OUTLOOK_KONTAKTS.placeholder = "Kontakte der Inhaber:innen";

sitemap.GUTACHTEN.emoji = '🏁';

sitemap.GUTACHTEN.Models.GUTACHTEN_STATUS.many = 'Gutachtenstati';
sitemap.GUTACHTEN.Models.GUTACHTEN_STATUS.single = 'Gutachtenstatus';
sitemap.GUTACHTEN.Models.GUTACHTEN_STATUS.emoji = '🚦';

sitemap.GUTACHTEN.Models.BEWERTUNGS_ANLASS.many = 'Bewertungsanlässe';
sitemap.GUTACHTEN.Models.BEWERTUNGS_ANLASS.single = 'Bewertungsanlass';
sitemap.GUTACHTEN.Models.BEWERTUNGS_ANLASS.emoji = '❓️';

sitemap.GUTACHTEN.Models.KALKULATION.Propertys.DATEI.title = "Kalkulation";
sitemap.GUTACHTEN.Models.KALKULATION.Propertys.DATEI.hint = "Bitte Kalkulations-Excel auswählen";

sitemap.GUTACHTEN.Models.GUTACHTEN.Propertys.FORTSCHRITT.title = 'Gutachtenfortschritt';
sitemap.GUTACHTEN.Models.GUTACHTEN.Propertys.BEWERTUNGS_ANLASS.title = 'Gutachtenanlass';
sitemap.GUTACHTEN.Models.GUTACHTEN.Propertys.BEWERTUNGS_STATUS.title = 'Gutachtenstatus';

sitemap.GUTACHTEN.Pages.UMKREISSUCHE.emoji = "🎯";

sitemap.FELDER.emoji = '📑';

sitemap.FELDER.Pages.FELDER.emoji = '📑️️️';
sitemap.FELDER.Pages.FELDER.breadcrumbs = [ 'EINSTELLUNGEN', 'FORMULARE', ...sitemap.FELDER.Pages.FELDER.breadcrumbs];
sitemap.FELDER.Pages.FELD.breadcrumbs = [ ...sitemap.FELDER.Pages.FELDER.breadcrumbs, 'FELDER' ];

sitemap.FELDER.Models.FELD_KATEGORIE.many = 'Feld-Kategorien';
sitemap.FELDER.Models.FELD_KATEGORIE.single = 'Feld-Kategorie';
sitemap.FELDER.Models.FELD_KATEGORIE.emoji = '📎';

sitemap.FELDER.Models.FELD_UNTERKATEGORIE.many = 'Feld-Unterkategorien';
sitemap.FELDER.Models.FELD_UNTERKATEGORIE.single = 'Feld-Unterkategorie';
sitemap.FELDER.Models.FELD_UNTERKATEGORIE.emoji = '📎';

sitemap.FORMULARE.Pages.FORMULARE.emoji = '✅';
sitemap.FORMULARE.Pages.FORMULAR.emoji = '✅';

sitemap.FORMULARE.Pages.DURCHGANG.title = 'Objektbesichtigung️';
sitemap.FORMULARE.Pages.DURCHGANG.emoji = '📑';
sitemap.FORMULARE.Pages.DURCHGANG.breadcrumbs = [ 'FORMULARE', ...sitemap.FORMULARE.Pages.DURCHGANG.breadcrumbs, 'TOUREN'];


sitemap.BESICHTIGUNGEN.emoji = '✅️️';

sitemap.BESICHTIGUNGEN.Models.BESICHTIGUNGS_STATUS.many = 'Besichtigungsstati';
sitemap.BESICHTIGUNGEN.Models.BESICHTIGUNGS_STATUS.single = 'Besichtigungsstatus';
sitemap.BESICHTIGUNGEN.Models.BESICHTIGUNGS_STATUS.emoji = '🚦';

sitemap.BESICHTIGUNGEN.Models.TOUR_STATUS.many = 'Tourenstati';
sitemap.BESICHTIGUNGEN.Models.TOUR_STATUS.single = 'Tourenstatus';
sitemap.BESICHTIGUNGEN.Models.TOUR_STATUS.emoji = '🚲️';

sitemap.BESICHTIGUNGEN.Models.TOUR.Propertys.START_IST_PRIVAT_ADRESSE.title = 'Start ist Privatadresse';
sitemap.BESICHTIGUNGEN.Models.TOUR.Propertys.ENDE_IST_PRIVAT_ADRESSE.title = 'Ende ist Privatadresse';

sitemap.BESICHTIGUNGEN.Models.BESICHTIGUNG.Propertys.VON.title = 'Besichtigung';

sitemap.BESICHTIGUNGEN.Pages.TOUREN.emoji = '🚲️';

sitemap.BESICHTIGUNGEN.Pages.TOUR.breadcrumbs = [ ...sitemap.BESICHTIGUNGEN.Pages.TOUR.breadcrumbs, 'TOUREN' ];
sitemap.BESICHTIGUNGEN.Pages.TOUR.emoji = '🚘️';

sitemap.ZEITEN.title = 'Zeiterfassung';
sitemap.ZEITEN.emoji = '⏳️️';

sitemap.ZEITEN.Pages.ZEITEN.emoji = '⏳️';

sitemap.ZEITEN.Models.ZEIT_ART.many = 'Zeitart';
sitemap.ZEITEN.Models.ZEIT_ART.single = 'Zeitarten';
sitemap.ZEITEN.Models.ZEIT_ART.emoji = '🕰️';

sitemap.ZEITEN.Models.ZEIT_UNTERART.emoji = '⏱️';
sitemap.ZEITEN.Models.ZEIT_UNTERART.single = 'Aktivität';
sitemap.ZEITEN.Models.ZEIT_UNTERART.many = 'Aktivitäten';

sitemap.FINANZEN.emoji = '💶';

sitemap.FINANZEN.Pages.FINANZEN.emoji = '💶';



sitemap.FINANZEN.Pages.REISEKOSTENABRECHNUNG.emoji = '🤑';
sitemap.FINANZEN.Pages.REISEKOSTENABRECHNUNG.breadcrumbs = [ 'FINANZEN', ...sitemap.FINANZEN.Pages.REISEKOSTENABRECHNUNG.breadcrumbs];

sitemap.FINANZEN.Models.REISEKOSTENABRECHNUNG_STATUS.many = "Reisekostenabrechnungsstati";
sitemap.FINANZEN.Models.REISEKOSTENABRECHNUNG_STATUS.emoji = "💸";

sitemap.FINANZEN.Models.RECHNUNG.Propertys.EXTRA_VEREINBARUNGS.title = 'Extra-Vereinbarungen';
sitemap.FINANZEN.Models.RECHNUNG.Propertys.ANSCHLAEGE.title = 'Anschläge';

sitemap.FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.TAGESGELD_ACHT.title = 'Tagesgeld (8€)️';
sitemap.FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.TAGESGELD_VIERUNDZWANZIG.title = 'Tagesgeld (24€)️';
sitemap.FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.AN_UND_ABREISETAG.title = 'An- und Abreistage️';
sitemap.FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.FRUEHSTUECKE.title = 'Frühstücke';
sitemap.FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.UEBERNACHTUNGSPAUSCHALE.title = 'Übernachtungspauschale';
sitemap.FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.MITNAHMEENTSCHAEDIGUNG.title = 'Mitnahmeentschädigung';
sitemap.FINANZEN.Models.REISEKOSTENABRECHNUNG.Propertys.SUMME.title = 'Summe (exkl. MwSt)';

sitemap.FINANZEN.Commands.POST_HONORAR_VEREINBARUNG.Inputs.LOHN_WOHNEN.title = "Honorar bei Wohnen";
sitemap.FINANZEN.Commands.POST_HONORAR_VEREINBARUNG.Inputs.LOHN_WOHNEN.placeholder = "Wohnen";
sitemap.FINANZEN.Commands.POST_HONORAR_VEREINBARUNG.Inputs.LOHN_GEWERBE.title = "Honorar bei Gewerbe";
sitemap.FINANZEN.Commands.POST_HONORAR_VEREINBARUNG.Inputs.LOHN_GEWERBE.placeholder = "Gewerbe";
sitemap.FINANZEN.Commands.POST_HONORAR_VEREINBARUNG.Inputs.LOHN_SPEZIAL.title = "Honorar bei Spezial";
sitemap.FINANZEN.Commands.POST_HONORAR_VEREINBARUNG.Inputs.LOHN_SPEZIAL.placeholder = "Spezial";

sitemap.DOKUMENTE.emoji = '🗄️️';

sitemap.DOKUMENTE.Models.DATEI_ART.emoji = '📁️️';
sitemap.DOKUMENTE.Models.DATEI_ART.single = 'Dateiarten';
sitemap.DOKUMENTE.Models.DATEI_ART.many = 'Dateiarten️️';

sitemap.DOKUMENTE.Models.ERFORDERLICHES_DOKUMENT.emoji = '📄';
sitemap.DOKUMENTE.Models.ERFORDERLICHES_DOKUMENT.single = 'Erforderliches Dokument';
sitemap.DOKUMENTE.Models.ERFORDERLICHES_DOKUMENT.many = 'Erforderliche Dokumente';


sitemap.MARKT.emoji = '📊';

sitemap.MARKT.Pages.MARKT_BERICHTE.emoji = '📊';
sitemap.MARKT.Pages.MARKT_BERICHTE.title = 'Marktberichte';

sitemap.MARKT.Pages.MARKT_BERICHT.emoji = '📈'
sitemap.MARKT.Pages.MARKT_BERICHT.title = 'Marktbericht';
sitemap.MARKT.Pages.MARKT_BERICHT.breadcrumbs = ['MARKT_BERICHTE', ...sitemap.MARKT.Pages.MARKT_BERICHT.breadcrumbs];

sitemap.MARKT.Pages.RECHERCHE.emoji = '📊';

sitemap.MARKT.Models.TEMPLATE.emoji = '♻️';
sitemap.MARKT.Models.TEMPLATE.single = 'Template';
sitemap.MARKT.Models.TEMPLATE.many = 'Templates';

sitemap.MARKT.Models.HERAUSGEBER.emoji = '📢';
sitemap.MARKT.Models.HERAUSGEBER.single = 'Herausgeber';
sitemap.MARKT.Models.HERAUSGEBER.many = 'Herausgeber';

sitemap.MARKT.Commands.POST_HOCHLADEN.Inputs.BERICHT_EXCEL.hint = 'Bitte Excel auswählen';

sitemap.GEO.Models.BEREICH.emoji = '🗺️';
sitemap.GEO.Models.BEREICH.many = 'Bereiche';
sitemap.GEO.Models.BEREICH.single = 'Bereich';

sitemap.TEXTE.emoji = '✍️️';

sitemap.TEXTE.Models.VORLAGE.many = 'Vorlagen';
sitemap.TEXTE.Models.VORLAGE.emoji = '📃';

sitemap.TEXTE.Pages.VORLAGEN.emoji = '✒️';
sitemap.TEXTE.Pages.VORLAGE.emoji = '📃';
sitemap.TEXTE.Pages.VORLAGE.breadcrumbs = [ 'VORLAGEN', ...sitemap.TEXTE.Pages.VORLAGE.breadcrumbs];

export const Sitemap: ISitemap = translateSitemap(sitemap);
