import { NgModule } from '@angular/core';
import {
  BerichtsVorlagenFilterComponentModule,
  BerichtsVorlagenListComponentModule,
  BerichtsVorlagenSorterComponentModule,
  SavedBerichtsVorlagenFiltersComponentModule
} from './';

@NgModule({
  imports: [
    BerichtsVorlagenFilterComponentModule,
    BerichtsVorlagenListComponentModule,
    BerichtsVorlagenSorterComponentModule,
    SavedBerichtsVorlagenFiltersComponentModule
  ],
  exports: [
    BerichtsVorlagenFilterComponentModule,
    BerichtsVorlagenListComponentModule,
    BerichtsVorlagenSorterComponentModule,
    SavedBerichtsVorlagenFiltersComponentModule
  ],
})
export class BerichtsVorlagenQueryModule { }
