import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { first, map, debounceTime, distinctUntilChanged } from "rxjs/operators";

import { isEqual } from "lodash";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { FilterPipe, SearchPipe, SorterPipe } from "@lib/filter/frontend";
import { IPersistedFilter, IPersistedFilterList } from "@lib/filter/types";
import { ISelection } from "@lib/persistence/types";

import {
  IBerichtsVorlagenRequest,
  IBerichtsVorlagenResponse,
  IBerichtsVorlagenResponseGroup,
  IBerichtsVorlagenQuerySorter,
  IBerichtsVorlagenQueryFilter,
  IBerichtsVorlagenQueryFilterShapes
} from '@fa-kt-evaluation/texte/types';

@Injectable({
  providedIn: 'root'
})
export class BerichtsVorlagenService {

  protected route = 'texte/berichts-vorlagen';
  protected dependencies = [

    'texte/berichts-vorlage-gespeichert',
    'texte/removed-berichts-vorlage'
  ];

  readonly $result = new BehaviorSubject<IBerichtsVorlagenResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IBerichtsVorlagenRequest|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IBerichtsVorlagenQueryFilterShapes|undefined>(undefined);
  readonly $response = new BehaviorSubject<IBerichtsVorlagenResponse|undefined>(undefined);
  readonly $filter = new BehaviorSubject<IBerichtsVorlagenQueryFilter>({});
  readonly $sorter = new BehaviorSubject<IBerichtsVorlagenQuerySorter>({});
  readonly $search = new BehaviorSubject<string>('');
  readonly $presets = new BehaviorSubject<IPersistedFilter<IBerichtsVorlagenResponse>[]>([]);
  readonly $preset = new BehaviorSubject<IPersistedFilter<IBerichtsVorlagenResponse>|undefined>(undefined);
  private readonly $filtered = new BehaviorSubject<IBerichtsVorlagenResponse|undefined>(undefined);
  private readonly $searched = new BehaviorSubject<IBerichtsVorlagenResponse|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private device: DeviceConfigService,
    private search: SearchPipe,
    private filter: FilterPipe,
    private sorter: SorterPipe,
  ) {
/* << changes    */
/*    changes >> */
    combineLatest([
      this.$response,
      this.$filter,
    ]).subscribe(([response, filter]) => {
      if (response && response.berichtsVorlagen) {
        const berichtsVorlagen = this.filter.transform(response.berichtsVorlagen, filter);
        this.$filtered.next( { ...response, berichtsVorlagen });
      } else {
        this.$filtered.next(response);
      }
    });
    combineLatest([
      this.$filtered,
      this.$search.pipe(debounceTime(device.debounceTime), distinctUntilChanged()),
      this.$shapes.pipe(distinctUntilChanged((a, b) => isEqual(a, b)))
    ]).subscribe(([filtered, search, shapes]) => {
      if (filtered && filtered.berichtsVorlagen) {
        const berichtsVorlagen = this.search.transform(filtered.berichtsVorlagen, search, '_search', shapes);
        this.$searched.next( { ...filtered, berichtsVorlagen });
      } else {
        this.$searched.next(filtered);
      }
    });
    combineLatest([
      this.$searched,
      this.$sorter.pipe(distinctUntilChanged((a, b) => isEqual(a, b))),
      this.$shapes.pipe(distinctUntilChanged((a, b) => isEqual(a, b)))
    ]).pipe(debounceTime(10)).subscribe(([sorted, sorter, shapes]) => {
      if (sorted && sorted.berichtsVorlagen) {
        const berichtsVorlagen = this.sorter.transform(sorted.berichtsVorlagen, sorter, shapes);
        this.$result.next( { ...sorted, berichtsVorlagen });
      } else {
        this.$result.next(sorted);
      }
    });
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IBerichtsVorlagenRequest): IBerichtsVorlagenRequest {
    if (payload) {
      this.$payload.next(payload as IBerichtsVorlagenRequest);
    } else {
      payload = this.$payload.getValue() as IBerichtsVorlagenRequest;
    }
    return payload;
  }

  public async request(payload: IBerichtsVorlagenRequest, force = false): Promise<IBerichtsVorlagenResponse> {
    const state = this.$response.getValue();
    if (!force && !this.device.alwaysRefresh && state && isEqual(payload, this.payload())) { return state; }
    payload = this.payload(payload);
    if (!payload) { return this.$response.getValue() as IBerichtsVorlagenResponse; }
    const query = this.http.serialize(payload);
    const { response, shapes, filter } = await this.http.get<IBerichtsVorlagenResponseGroup>(this.route + query);
    this.$response.next(response);
    this.$shapes.next(shapes);
    this.$presets.next(filter && filter.filters ? filter.filters : []);
    return response;
  }

  public async writeFilter(filter: IPersistedFilter<IBerichtsVorlagenResponse>): Promise<IPersistedFilter<IBerichtsVorlagenResponse>|undefined> {
    filter = { ...filter, term: this.$search.getValue(), sorter: this.$sorter.getValue(), filter: this.$filter.getValue() };
    const result = await this.http.post(this.route + '/filters', filter) as IPersistedFilterList<IBerichtsVorlagenResponse>;
    filter = result.filters.find(f => f.name === filter.name) as IPersistedFilter<IBerichtsVorlagenResponse>;
    this.$presets.next(result.filters ? result.filters : []);
    this.setFilter(filter);
    return filter;
  }

  public async deleteFilter(id: string): Promise<void> {
    const result = await this.http.delete(this.route + '/filters?id=' + id) as IPersistedFilterList<IBerichtsVorlagenResponse>;
    this.$presets.next(result.filters ? result.filters : []);
    this.setFilter();
  }

  public setFilter(filter?: IPersistedFilter<IBerichtsVorlagenResponse>): void {
    this.$search.next(filter && filter.term ? filter.term : '');
    this.$filter.next(filter && filter.filter ? filter.filter : {});
    this.$sorter.next(filter && filter.sorter ? filter.sorter : {});
    this.$preset.next(filter ? filter : undefined);
  }



}
