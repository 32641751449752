<!--
  <nz-row>
    <nz-col nzSpan='24'>
      <codeboard-search-bar [style.width]="'100%'" (searchChange)="zeit.$search.next($event)" [search]="zeit.$search | async"></codeboard-search-bar>
    </nz-col>
  </nz-row>
  <nz-row style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center; align-content: space-between">
    <nz-col style="order: 1; flex-grow: 2; flex-basis: auto; ">
      <fa-kt-evaluation-zeit-filter [style.width]="'100%'"></fa-kt-evaluation-zeit-filter>
    </nz-col>
    <nz-col style="order: 2; flex-grow: 1; flex-basis: auto; ">
      <fa-kt-evaluation-zeit-sorter [style.width]="'100%'"></fa-kt-evaluation-zeit-sorter>
    </nz-col>
  </nz-row>

  <fa-kt-evaluation-zeit-list [style.width]="'100%'" (select)="setZeit($event)" [loading]="$loading | async"></fa-kt-evaluation-zeit-list>


  <fa-kt-evaluation-post-zeit [style.width]="'100%'" [value]="$postZeit | async" (finished)="finishedPostZeit($event)"></fa-kt-evaluation-post-zeit>

  <fa-kt-evaluation-delete-zeit [style.width]="'100%'" [value]="$deleteZeit | async" (finished)="finishedDeleteZeit($event)"></fa-kt-evaluation-delete-zeit>



-->
<!--- << custom    -->
<nz-modal [nzTitle]="ModalTitle" [nzContent]="ModalContent" [nzFooter]="ModalFooter" (nzOnCancel)="openZeit(null)" [nzVisible]="!!($postZeit | async)">
  <ng-template #ModalTitle>
    <nz-page-header [nzTitle]="ModalHeaderTitle">
      <ng-template #ModalHeaderTitle>
        Zeiterfassung
      </ng-template>
      <nz-breadcrumb nz-page-header-breadcrumb>
      </nz-breadcrumb>
      <nz-page-header-extra>
        <fa-kt-evaluation-delete-zeit [style.width]="'100%'" [value]="$deleteZeit | async" (finished)="finishedDeleteZeit()"></fa-kt-evaluation-delete-zeit>
      </nz-page-header-extra>
    </nz-page-header>
  </ng-template>
  <ng-template #ModalContent>
    <nz-row>
      <fa-kt-evaluation-post-zeit [style.width]="'100%'" [value]="$postZeit | async" (finished)="finishedPostZeit()"></fa-kt-evaluation-post-zeit>
      <codeboard-meta [value]="($postZeit | async).zeit"></codeboard-meta>
    </nz-row>
  </ng-template>
  <ng-template #ModalFooter>
  </ng-template>
</nz-modal>
<!---    custom >> -->
