import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { first, map, debounceTime, distinctUntilChanged } from "rxjs/operators";

import { isEqual } from "lodash";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { IPersistedFilter, IPersistedFilterList } from "@lib/filter/types";
import { ISelection } from "@lib/persistence/types";

import {
  IFormularRequest,
  IFormularResponse,
  IFormularResponseGroup,
  IFormularQueryFilterShapes
} from '@fa-kt-evaluation/formulare/types';

@Injectable({
  providedIn: 'root'
})
export class FormularService {

  protected route = 'formulare/formular';
  protected dependencies = [
    'gutachten/written-bewertungs-anlass',
    'gutachten/removed-bewertungs-anlass',
    'kunden/written-kunden-art',
    'kunden/removed-kunden-art',
    'objekte/written-objekt-art',
    'objekte/removed-objekt-art',

    'formulare/durchgang-gestartet',
    'formulare/formular-entfernt',
    'formulare/formular-gespeichert',
    'formulare/formular-kopiert'
  ];

  readonly $result = new BehaviorSubject<IFormularResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IFormularRequest|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IFormularQueryFilterShapes|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private device: DeviceConfigService,
  ) {
/* << changes    */
/*    changes >> */
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IFormularRequest): IFormularRequest {
    if (payload) {
      this.$payload.next(payload as IFormularRequest);
    } else {
      payload = this.$payload.getValue() as IFormularRequest;
    }
    return payload;
  }

  public async request(payload: IFormularRequest, force = false): Promise<IFormularResponse> {
    const state = this.$result.getValue();
    if (!force && !this.device.alwaysRefresh && state && isEqual(payload, this.payload())) { return state; }
    payload = this.payload(payload);
    // if (!payload) { return this.$result.getValue() as IFormularResponse; }
    const query = this.http.serialize(payload);
    const { response, shapes } = await this.http.get<IFormularResponseGroup>(this.route + query);
    this.$result.next(response);
    this.$shapes.next(shapes);
    return response;
  }


  getFormularBewertungsAnlaesse(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['formular.bewertungsAnlaesse']?.find(v => v.value === value)
  }

  get formularBewertungsAnlaesse(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['formular.bewertungsAnlaesse'] as ISelection[] : []));
  }

  getFormularOhneFelder(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['formular.ohneFelder']?.find(v => v.value === value)
  }

  get formularOhneFelder(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['formular.ohneFelder'] as ISelection[] : []));
  }

  getFormularKunden(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['formular.kunden']?.find(v => v.value === value)
  }

  get formularKunden(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['formular.kunden'] as ISelection[] : []));
  }

  getFormularKundenArten(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['formular.kundenArten']?.find(v => v.value === value)
  }

  get formularKundenArten(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['formular.kundenArten'] as ISelection[] : []));
  }

  getFormularObjektArten(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['formular.objektArten']?.find(v => v.value === value)
  }

  get formularObjektArten(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['formular.objektArten'] as ISelection[] : []));
  }


}
