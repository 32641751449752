import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { ObjektServiceModule } from '../';
import { ObjektFilterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ObjektServiceModule
  ],
  declarations: [ObjektFilterComponent],
  exports: [ObjektFilterComponent],

})
export class ObjektFilterComponentModule { }
