import { NgModule } from '@angular/core';
import { 
  DeleteBerichtCommandModule,
  PostBerichtCommandModule,
  PostHochladenCommandModule,
  PostZeileCommandModule
} from './';

@NgModule({
  declarations: [],
  imports: [
    DeleteBerichtCommandModule,
    PostBerichtCommandModule,
    PostHochladenCommandModule,
    PostZeileCommandModule
  ],
  exports: [
    DeleteBerichtCommandModule,
    PostBerichtCommandModule,
    PostHochladenCommandModule,
    PostZeileCommandModule
  ]
})
export class MarktCommandsModule { }
