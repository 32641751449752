import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { TourServiceModule } from '../';
import { TourSorterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    TourServiceModule
  ],
  declarations: [TourSorterComponent],
  exports: [TourSorterComponent],

})
export class TourSorterComponentModule { }
