<!---
  <div formGroupName="abteilung">
    <input class="cdk-visually-hidden" formControlName="id" id="id"/>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="kunde">{{ command.Inputs.KUNDE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KundeErrorTip">
        <ng-template #KundeErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KUNDE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="kunde" id="kunde" [shapes]="postAbteilung.abteilungKunde | async" [placeholder]="command.Inputs.KUNDE.placeholder" cdkFocusInitial required=true ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">{{ command.Inputs.NAME.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NameErrorTip">
        <ng-template #NameErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NAME.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="name" id="name" [placeholder]="command.Inputs.NAME.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="ansprache">{{ command.Inputs.ANSPRACHE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AnspracheErrorTip">
        <ng-template #AnspracheErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ANSPRACHE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="ansprache" id="ansprache" [placeholder]="command.Inputs.ANSPRACHE.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="outlookKontakte">{{ command.Inputs.OUTLOOK_KONTAKTE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="OutlookKontakteErrorTip">
        <ng-template #OutlookKontakteErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.OUTLOOK_KONTAKTE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="outlookKontakte" id="outlookKontakte" [placeholder]="command.Inputs.OUTLOOK_KONTAKTE.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="website">{{ command.Inputs.WEBSITE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="WebsiteErrorTip">
        <ng-template #WebsiteErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.WEBSITE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="website" id="website" [shapes]="postAbteilung.abteilungWebsite | async" [placeholder]="command.Inputs.WEBSITE.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <codeboard-address-input formControlName="adresse" id="adresse" required="false" ></codeboard-address-input>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="aktiv">{{ command.Inputs.AKTIV.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AktivErrorTip">
        <ng-template #AktivErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.AKTIV.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <label nz-checkbox formControlName="aktiv" id="aktiv"></label>
      </nz-form-control>
    </nz-form-item>
  </div>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
<!--- << custom    -->
    <div formGroupName="abteilung">
      <input class="cdk-visually-hidden" formControlName="id" id="id"/>
      <input class="cdk-visually-hidden" formControlName="kundeId" id="kundeId"/>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">{{ command.Inputs.NAME.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="command.Inputs.NAME.hint">
          <input nz-input formControlName="name" id="name" [placeholder]="command.Inputs.NAME.placeholder"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="ansprache">{{ command.Inputs.ANSPRACHE.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AnspracheErrorTip">
          <ng-template #AnspracheErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ANSPRACHE.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input formControlName="ansprache" id="ansprache" [placeholder]="command.Inputs.ANSPRACHE.placeholder"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="website">{{ command.Inputs.WEBSITE.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" >
          <nz-input-group [nzAddOnAfter]="OpenWebsite">
            <input type="url" nz-input formControlName="website" id="website" [placeholder]="command.Inputs.WEBSITE.placeholder"/>
            <ng-template #OpenWebsite>
              <a [href]="website.value" target="_blank">
                <i nz-icon nzType="global" nzTheme="outline"></i>
              </a>
            </ng-template>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <codeboard-outlook-contacts nz-input formControlName="outlookKontakte" id="outlookKontakte" [department]="name.value" [businessHomePage]="businessHomePage" [companyName]="companyName"></codeboard-outlook-contacts>
      <codeboard-address-input formControlName="adresse" id="adresse" [required]="false" ></codeboard-address-input>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="aktiv">{{ command.Inputs.AKTIV.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AktivErrorTip">
          <ng-template #AktivErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.AKTIV.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <label nz-checkbox formControlName="aktiv" id="aktiv"></label>
        </nz-form-control>
      </nz-form-item>
    </div>
<!---    custom >> -->
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button"
          type="submit"
          [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)"
          nz-tooltip [nzTooltipTitle]="SubmitTooltip">
<!--- << confirm    -->
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Speichern
<!---    confirm >> -->
          <ng-template #SubmitTooltip>
<!--- << hint    -->
            Abteilung speichern
<!---    hint >> -->
          </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
