import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { first, map, debounceTime, distinctUntilChanged } from "rxjs/operators";

import { isEqual } from "lodash";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { IPersistedFilter, IPersistedFilterList } from "@lib/filter/types";
import { ISelection } from "@lib/persistence/types";

import {
  IZeitRequest,
  IZeitResponse,
  IZeitResponseGroup,
  IZeitQueryFilterShapes
} from '@fa-kt-evaluation/zeiten/types';

@Injectable({
  providedIn: 'root'
})
export class ZeitService {

  protected route = 'zeiten/zeit';
  protected dependencies = [
    'zeiten/written-zeit-art',
    'zeiten/removed-zeit-art',
    'zeiten/written-zeit-unterart',
    'zeiten/removed-zeit-unterart',

    'zeiten/zeit-entfernt',
    'zeiten/zeit-gespeichert'
  ];

  readonly $result = new BehaviorSubject<IZeitResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IZeitRequest|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IZeitQueryFilterShapes|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private device: DeviceConfigService,
  ) {
/* << changes    */
/*    changes >> */
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IZeitRequest): IZeitRequest {
    if (payload) {
      this.$payload.next(payload as IZeitRequest);
    } else {
      payload = this.$payload.getValue() as IZeitRequest;
    }
    return payload;
  }

  public async request(payload: IZeitRequest, force = false): Promise<IZeitResponse> {
    const state = this.$result.getValue();
    if (!force && !this.device.alwaysRefresh && state && isEqual(payload, this.payload())) { return state; }
    payload = this.payload(payload);
    // if (!payload) { return this.$result.getValue() as IZeitResponse; }
    const query = this.http.serialize(payload);
    const { response, shapes } = await this.http.get<IZeitResponseGroup>(this.route + query);
    this.$result.next(response);
    this.$shapes.next(shapes);
    return response;
  }


  getZeitKunde(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['zeit.kunde']?.find(v => v.value === value)
  }

  get zeitKunde(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['zeit.kunde'] as ISelection[] : []));
  }

  getZeitMitarbeiter(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['zeit.mitarbeiter']?.find(v => v.value === value)
  }

  get zeitMitarbeiter(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['zeit.mitarbeiter'] as ISelection[] : []));
  }

  getZeitProjekt(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['zeit.projekt']?.find(v => v.value === value)
  }

  get zeitProjekt(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['zeit.projekt'] as ISelection[] : []));
  }

  getZeitZeitArt(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['zeit.zeitArt']?.find(v => v.value === value)
  }

  get zeitZeitArt(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['zeit.zeitArt'] as ISelection[] : []));
  }

  getZeitZeitUnterart(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['zeit.zeitUnterart']?.find(v => v.value === value)
  }

  get zeitZeitUnterart(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['zeit.zeitUnterart'] as ISelection[] : []));
  }


}
