import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { BerichtServiceModule } from '../';
import { BerichtSorterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    BerichtServiceModule
  ],
  declarations: [BerichtSorterComponent],
  exports: [BerichtSorterComponent],

})
export class BerichtSorterComponentModule { }
