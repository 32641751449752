import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostKundenFinanzenCommandServiceModule } from '../';
import { PostKundenFinanzenCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostKundenFinanzenCommandServiceModule
  ],
  declarations: [PostKundenFinanzenCommandComponent],
  exports: [PostKundenFinanzenCommandComponent],

})
export class PostKundenFinanzenCommandComponentModule { }
