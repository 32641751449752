import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { DeleteVermerkCommandServiceModule } from '../';
import { DeleteVermerkCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteVermerkCommandServiceModule
  ],
  declarations: [DeleteVermerkCommandComponent],
  exports: [DeleteVermerkCommandComponent],

})
export class DeleteVermerkCommandComponentModule { }
