import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { BerichtsVorlagenServiceModule } from '../';
import { BerichtsVorlagenFilterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    BerichtsVorlagenServiceModule
  ],
  declarations: [BerichtsVorlagenFilterComponent],
  exports: [BerichtsVorlagenFilterComponent],

})
export class BerichtsVorlagenFilterComponentModule { }
