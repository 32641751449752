import { NgModule } from '@angular/core';
import {
  ZeitFilterComponentModule,
  ZeitDetailComponentModule,
  ZeitSorterComponentModule,
  SavedZeitFiltersComponentModule
} from './';

@NgModule({
  imports: [
    ZeitFilterComponentModule,
    ZeitDetailComponentModule,
    ZeitSorterComponentModule,
    SavedZeitFiltersComponentModule
  ],
  exports: [
    ZeitFilterComponentModule,
    ZeitDetailComponentModule,
    ZeitSorterComponentModule,
    SavedZeitFiltersComponentModule
  ],
})
export class ZeitQueryModule { }
