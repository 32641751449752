import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { ReisekostenabrechnungServiceModule } from '../';
import { ReisekostenabrechnungSorterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ReisekostenabrechnungServiceModule
  ],
  declarations: [ReisekostenabrechnungSorterComponent],
  exports: [ReisekostenabrechnungSorterComponent],

})
export class ReisekostenabrechnungSorterComponentModule { }
