import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { FinanzenCommandsModule } from './commands'
import {
  FinanzenQuerysModule,
  RechnungsVorlagenService,
  ReisekostenabrechnungService,
  ReisekostenabrechnungenService
} from './querys'
import {
  ReisekostenabrechnungStatiServiceModule, ReisekostenabrechnungStatiService
} from './settings';

const routes: Routes = [
  {
    path: 'finanzen',
    loadChildren: () => import('./pages').then(m => m.FinanzenPagesModule)
  },
  {
    path: 'finanzen',
    children: [
      {
        path: 'settings',
        loadChildren: () => import('./settings').then(m => m.FinanzenSettingsModule),
      }
    ]
  },
];

@NgModule({
  imports: [
    FinanzenCommandsModule,
    FinanzenQuerysModule,
    ReisekostenabrechnungStatiServiceModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    FinanzenCommandsModule,
    FinanzenQuerysModule,
    ReisekostenabrechnungStatiServiceModule
  ]
})
export class FinanzenModule {
  static forRoot(): ModuleWithProviders< FinanzenModule> {
    return {
      ngModule: FinanzenModule,
      providers: [
        RechnungsVorlagenService,
        ReisekostenabrechnungService,
        ReisekostenabrechnungenService,
        ReisekostenabrechnungStatiService
      ]
    };
  }
}
