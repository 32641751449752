import { NgModule } from '@angular/core';
import {
  TourenFilterComponentModule,
  TourenListComponentModule,
  TourenSorterComponentModule,
  SavedTourenFiltersComponentModule
} from './';

@NgModule({
  imports: [
    TourenFilterComponentModule,
    TourenListComponentModule,
    TourenSorterComponentModule,
    SavedTourenFiltersComponentModule
  ],
  exports: [
    TourenFilterComponentModule,
    TourenListComponentModule,
    TourenSorterComponentModule,
    SavedTourenFiltersComponentModule
  ],
})
export class TourenQueryModule { }
