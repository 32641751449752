import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostHonorarVereinbarungCommandServiceModule } from '../';
import { PostHonorarVereinbarungCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostHonorarVereinbarungCommandServiceModule
  ],
  declarations: [PostHonorarVereinbarungCommandComponent],
  exports: [PostHonorarVereinbarungCommandComponent],

})
export class PostHonorarVereinbarungCommandComponentModule { }
