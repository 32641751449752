<!---
  <div formGroupName="erinnerung">
    <input class="cdk-visually-hidden" formControlName="id" id="id"/>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="projekt">{{ command.Inputs.PROJEKT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ProjektErrorTip">
        <ng-template #ProjektErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.PROJEKT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="projekt" id="projekt" [shapes]="postErinnerung.erinnerungProjekt | async" [placeholder]="command.Inputs.PROJEKT.placeholder" cdkFocusInitial ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="gutachten">{{ command.Inputs.GUTACHTEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="GutachtenErrorTip">
        <ng-template #GutachtenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.GUTACHTEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="gutachten" id="gutachten" [shapes]="postErinnerung.erinnerungGutachten | async" [placeholder]="command.Inputs.GUTACHTEN.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="kunde">{{ command.Inputs.KUNDE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KundeErrorTip">
        <ng-template #KundeErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KUNDE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="kunde" id="kunde" [shapes]="postErinnerung.erinnerungKunde | async" [placeholder]="command.Inputs.KUNDE.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="kommentar">{{ command.Inputs.KOMMENTAR.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KommentarErrorTip">
        <ng-template #KommentarErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KOMMENTAR.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <textarea nz-input formControlName="kommentar" id="kommentar" [placeholder]="command.Inputs.KOMMENTAR.placeholder"></textarea>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="am">{{ command.Inputs.AM.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AmErrorTip">
        <ng-template #AmErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.AM.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <nz-date-picker formControlName="am" id="am"  nzFormat="dd.MM.yyyy"></nz-date-picker>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="mitarbeiter">{{ command.Inputs.MITARBEITER.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MitarbeiterErrorTip">
        <ng-template #MitarbeiterErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MITARBEITER.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value mode="multiple" formControlName="mitarbeiter" id="mitarbeiter" [shapes]="postErinnerung.erinnerungMitarbeiter | async" [placeholder]="command.Inputs.MITARBEITER.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="verfasser">{{ command.Inputs.VERFASSER.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="VerfasserErrorTip">
        <ng-template #VerfasserErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.VERFASSER.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="verfasser" id="verfasser" [shapes]="postErinnerung.erinnerungVerfasser | async" [placeholder]="command.Inputs.VERFASSER.placeholder" required=true ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
  </div>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
<!--- << custom    -->
    <div formGroupName="erinnerung">
      <input class="cdk-visually-hidden" formControlName="id" id="id"/>
      <input class="cdk-visually-hidden" formControlName="projekt" id="projekt"/>
      <input class="cdk-visually-hidden" formControlName="gutachten" id="gutachten"/>
      <input class="cdk-visually-hidden" formControlName="kunde" id="kunde"/>
      <input class="cdk-visually-hidden" formControlName="verfasser" id="verfasser"/>
      <nz-comment>
        <nz-avatar nz-comment-avatar nzIcon="user" [nzSrc]="auth.$avatar | async"></nz-avatar>
        <nz-comment-content>
          <nz-form-item>
            <textarea formControlName="kommentar" id="kommentar" nz-input rows="4"></textarea>
          </nz-form-item>
        </nz-comment-content>
        <nz-comment-action>
          <nz-date-picker formControlName="am" id="am"  nzFormat="dd.MM.yyyy"></nz-date-picker>
        </nz-comment-action>
        <nz-comment-action>
          <codeboard-user-selection formControlName="mitarbeiter" id="mitarbeiter" [placeholder]="command.Inputs.MITARBEITER.placeholder" placeholderGroups="Rollen"></codeboard-user-selection>
        </nz-comment-action>
        <nz-comment-action *ngIf="canAbmelden">
          <button nz-button nzType="default" type="button" nzDanger
                  (click)="abmelden()"
                  [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)"
                  nz-tooltip [nzTooltipTitle]="AbmeldenTooltip">
            <i nz-icon nzType="user-delete" nzTheme="outline"></i>
            Abmelden
            <ng-template #AbmeldenTooltip>
              Mich aus der Erinnerung entfernen
            </ng-template>
          </button>
        </nz-comment-action>
        <nz-comment-action>
            <button nz-button nzType="primary"
                    type="submit"
                    [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)"
                    nz-tooltip [nzTooltipTitle]="SubmitTooltip">
              <i nz-icon nzType="clock-circle" nzTheme="outline"></i>
              Erinnern
              <ng-template #SubmitTooltip>
                Erinnerung speichern
              </ng-template>
            </button>
        </nz-comment-action>
      </nz-comment>
    </div>
<!---    custom >> -->
<!---
<button nz-button nzType="primary"
  type="submit"
  [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)"
  nz-tooltip [nzTooltipTitle]="SubmitTooltip">
    {{ command.translation }}
  <ng-template #SubmitTooltip>
  </ng-template>
</button>
 -->
  </form>
</nz-spin>
