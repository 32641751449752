import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { ZeitServiceModule } from '../';
import { ZeitDetailComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ZeitServiceModule
  ],
  declarations: [ZeitDetailComponent],
  exports: [ZeitDetailComponent],

})
export class ZeitDetailComponentModule { }
