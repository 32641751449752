<nz-button-group [ngSwitch]="!!($filter | async)">
  <button nz-button [nzType]="!!($filter | async) ? 'default' : 'dashed'" (click)="open()" [ngSwitch]="!!($filter | async)">
    <ng-container *ngSwitchCase="true">
      <i nz-icon nzType="edit" nzTheme="outline"></i> "{{ ($filter | async)?.name }}"
    </ng-container>
    <ng-container *ngSwitchDefault>
      <i nz-icon [nzType]="filtersVisible ? 'plus-circle' : 'funnel-plot'" nzTheme="outline"></i> Filter anlegen
    </ng-container>
  </button>
  <button nz-button nzType="dashed" *ngSwitchCase="true" (click)="close()">
    <i nz-icon nzType="close-circle" nzTheme="outline"></i>
  </button>
  <button nz-button nzType="default" *ngSwitchDefault nz-dropdown [nzDropdownMenu]="filters" nzPlacement="bottomRight" [(nzVisible)]="filtersVisible">
    <i nz-icon nzType="down"></i>
  </button>
  <nz-dropdown-menu #filters="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item *ngFor="let preset of $presets | async" (click)="set(preset.id)" style="min-width: 170px">
        <a>
          <i nz-icon nzType="filter" style="margin-right: 8px" nzTheme="outline"></i>
          {{ preset.name}}
        </a>
      </li>
    </ul>
  </nz-dropdown-menu>
</nz-button-group>

<nz-modal [nzTitle]="ModalTitle" [nzContent]="ModalContent" [nzFooter]="ModalFooter" [nzVisible]="$edit | async" [nzClosable]="true" (nzOnCancel)="close()">
  <ng-template #ModalTitle>
    <nz-page-header [nzTitle]="ModalHeaderTitle">
      <ng-template #ModalHeaderTitle>
        Filter "{{ ($filter | async)?.name }}"
      </ng-template>
      <nz-page-header-extra>
        <nz-space>
          <button *nzSpaceItem nz-button nzType="danger" [disabled]="!($filter | async)?.id"
                  nz-popconfirm nzPopconfirmTitle="Wirklich löschen?" [nzIcon]="DeleteIcon" (nzOnConfirm)="remove()">
            <i nz-icon nzType="delete" nzTheme="outline" style="color: red;"></i>
            Löschen
            <ng-template #DeleteIcon>
              <i nz-icon nzType="delete" nzTheme="outline" style="color: red;"></i>
            </ng-template>
          </button>
          <button *nzSpaceItem type="button" nz-button nzType="default" (click)="close()">
            <i nz-icon nzType="close-circle" nzTheme="outline"></i>
            Schließen
          </button>
        </nz-space>
      </nz-page-header-extra>
    </nz-page-header>
  </ng-template>
  <ng-template #ModalContent>
    <nz-row>
      <nz-col nzSpan='24'>
        <form nz-form [formGroup]="form" (ngSubmit)="persist()">
        <nz-form-item style="width: 100%;">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">Name</nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" nzErrorTip="Bitte einen Namen angeben">
            <input nz-input formControlName="name" id="name" placeholder="Namen des Filters" cdkFocusInitial/>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item style="width: 100%;">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="groups">Gruppen</nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24">
            <codeboard-select-value formControlName="groups" id="groups" mode="multiple" [shapes]="$groups | async" placeholder="Gruppe(n) auswählen" ></codeboard-select-value>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item style="width: 100%;">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="users">Benutzer:innen</nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24">
            <codeboard-select-value formControlName="users" id="users" mode="multiple" [shapes]="$users | async" placeholder="Benutzer:in(nen) auswählen" ></codeboard-select-value>
          </nz-form-control>
        </nz-form-item>

          <nz-form-item>
            <nz-form-control [nzOffset]="16">
              <button type="button" nz-button nzType="primary" [disabled]="!form.valid" (click)="persist()">
                <i nz-icon nzType="save" nzTheme="outline"></i>
                Speichern
              </button>
            </nz-form-control>
          </nz-form-item>

      </form>
      </nz-col>
    </nz-row>
  </ng-template>
  <ng-template #ModalFooter>
  </ng-template>
</nz-modal>
