import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { DependenciesService } from "../../services";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { BehaviorSubject, combineLatest, Subscription } from "rxjs";
import { ISelection } from "@lib/persistence/types";
import { toInitials } from "@lib/common/frontend";

@Component({
  selector: 'codeboard-user-selection',
  templateUrl: './user-selection.component.html',
  styleUrls: ['./user-selection.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: UserSelectionComponent
    }
  ]
})
export class UserSelectionComponent implements OnInit, OnDestroy, ControlValueAccessor {
  private readonly subscriptions: Subscription[] = [];

  onChange = (value: string[]) => {};
  onTouched = () => {};
  touched = false;
  @Input() disabled = false;

  private $state = new BehaviorSubject<ISelection[]>([]);
  private $users = new BehaviorSubject<string[]>([]);
  $view = new BehaviorSubject<ISelection[]>([]);
  $selection = new BehaviorSubject<ISelection[]>([]);
  $groups = new BehaviorSubject<ISelection[]>([]);

  group: string|undefined;
  user: string|undefined;

  @Input() set users(users: string[]) {
    if (!users) { users = []; }
    this.$users.next(users)
  }
  @Output() usersChange = new EventEmitter<string[]>();

  @Input() placeholder = 'Auswahl'
  @Input() placeholderGroups = 'Auswahl'
  @Input() mode: 'avatar' = 'avatar';
  @Input() groups = true;

  constructor(
    public dependencies: DependenciesService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(...[
      combineLatest([
        this.$state,
        this.$users
      ]).subscribe(([state, users]) => {this.usersChange.emit(users);
        this.onChange(users);
        this.$view.next(state.filter(s => users.includes(s.value)));
        this.$selection.next(state.filter(s => !users.includes(s.value)));
      }),
      this.dependencies.users.subscribe((users) => this.$state.next(users.map(user => ({ value: user.id, label: user.anzeigename, avatar: user.bild as string, filter: user.rollen })))),
      this.dependencies.groups.subscribe((groups) => this.$groups.next(groups.map(g => ({ value: g.id, label: g.name })))),
    ]);
  }

  ngOnDestroy() {
    this.subscriptions.forEach($ => $.unsubscribe());
  }

  getMitarbeiterText(label: string, asInitialen = false) {
    if (!asInitialen || !label) return label;
    return toInitials(label.split('(')[0]);
  }

  addUser(id: string) {
    if (this.disabled) { return; }
    const users = this.$users.getValue();
    users.push(id);
    this.$users.next(users);
    this.user = undefined;
  }

  removeUser(id: string) {
    if (this.disabled) { return; }
    let users = this.$users.getValue();
    users = users.filter(u => u !== id);
    this.$users.next(users);
    this.user = undefined;
  }

  addGroup(groupID: string) {
    if (this.disabled) { return; }
    const selection = this.$selection.getValue();
    const members = selection.filter(s => (s.filter as string[])?.includes(groupID));
    let users = this.$users.getValue();
    users.push(...members.filter(m => !users.includes(m.value)).map(m => m.value));
    console.log(selection, members, users);
    this.$users.next(users);
    this.group = undefined;
  }

  writeValue(value: string[]) {
    this.users = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

}
