import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { ArchivServiceModule } from '../';
import { ArchivSorterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    ArchivServiceModule
  ],
  declarations: [ArchivSorterComponent],
  exports: [ArchivSorterComponent],

})
export class ArchivSorterComponentModule { }
