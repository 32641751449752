import { Inject, Injectable, Injector } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged } from "rxjs/operators";
import isEqual from "lodash/isEqual";
import { addDays } from 'date-fns'

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventType, InteractionRequiredAuthError, RedirectRequest } from "@azure/msal-browser";

import { ActionService, APP_CONFIG, IAppConfig, SecretService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { DependenciesService } from "../";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseURL = 'https://graph.microsoft.com/v1.0/';

  private readonly TOKEN_KEY = "base";
  readonly AUTHORIZED_ROUTES_KEY = "authorizedRoutes"

  private readonly scope = [
    'Sites.ReadWrite.All',
    'Sites.Read.All',
    'Files.Read',
    'Files.Read.All',
    'Files.ReadWrite',
    'Files.ReadWrite.All',
    'ServiceHealth.Read.All',
    'Calendars.Read',
    'ExternalItem.Read.All',
    'Mail.Read',
    'User.Read',
    'Directory.AccessAsUser.All',
    'Directory.Read.All',
    'Directory.ReadWrite.All',
    'User.Read.All',
    'User.ReadBasic.All',
    'User.ReadWrite',
    'User.ReadWrite.All',
    'Contacts.Read',
    'Contacts.Read.Shared',
    'Contacts.ReadWrite',
    'Contacts.ReadWrite.Shared',
    'Directory.AccessAsUser.All',
    'Directory.ReadWrite.All',
    'Group.ReadWrite.All',
    'Group.Read.All',
    'Group.ReadWrite.All',
    'GroupMember.Read.All',
    'DeviceManagementApps.Read.All',
    'DeviceManagementApps.ReadWrite.All',
    'DeviceManagementConfiguration.Read.All',
    'DeviceManagementConfiguration.ReadWrite.All',
    'DeviceManagementManagedDevices.Read.All',
    'DeviceManagementManagedDevices.ReadWrite.All',
    'DeviceManagementServiceConfig.Read.All',
    'DeviceManagementServiceConfig.ReadWrite.All',
    'Directory.AccessAsUser.All',
    'Directory.Read.All',
    'Directory.ReadWrite.All',
    'User.Read.All',
    'User.ReadBasic.All',
    'User.ReadWrite.All',
    'Application.Read.All',
    'Application.ReadWrite.All',
    'Directory.AccessAsUser.All',
    'Directory.Read.All',
    'Directory.ReadWrite.All'
  ];
  private readonly authRequest = {
    scopes: this.scope
  };

  $loading = new BehaviorSubject<boolean>(false);
  $accounts: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  $token: BehaviorSubject<any> = new BehaviorSubject(null);

  $id: BehaviorSubject<string|null> = new BehaviorSubject<string|null>(null);
  $displayName: BehaviorSubject<string|null> = new BehaviorSubject<string|null>(null);
  $email: BehaviorSubject<string|null> = new BehaviorSubject<string|null>(null);
  $avatar: BehaviorSubject<string|null> = new BehaviorSubject<string|null>(null);
  $isAdmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $isFinanzen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $groups: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>([]);
  $routes = new BehaviorSubject<Array<string>>([]);

  $ADgroups: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  $ADmemberships: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);


  get secrets(): SecretService {
    return this.injector.get(SecretService);
  }
  get actions(): ActionService {
    return this.injector.get(ActionService);
  }
  get dependencies(): DependenciesService {
    return this.injector.get(DependenciesService);
  }


  constructor(
    private injector: Injector,
    private sanitizer: DomSanitizer,
    @Inject(APP_CONFIG) public environment: IAppConfig,
    private messages: NzMessageService,
    private notifications: NzNotificationService,
    private msal: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private http: HttpService
    ) {
    this.msal.instance.handleRedirectPromise().then(async (result) => {
      if (result) {
        await this.refreshToken(result)
      }
    });
    this.msal.instance.addEventCallback(async (event: any) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        await this.setActiveAccount(event.payload.account);
      }
    });
    this.$id.pipe(distinctUntilChanged()).subscribe(async (id) => {
      if (id) {
        this.actions.unsubscribe('Anmelden')
        this.actions.subscribe({ key: 'Abmelden', action: () => this.logout() })
      } else {
        this.actions.unsubscribe('Abmelden')
        this.actions.subscribe({ key: 'Anmelden', action: () => this.login() })
      }
    })
    combineLatest([
      this.$ADgroups.pipe(distinctUntilChanged((a, b) => a && b && isEqual(a, b))),
      this.$ADmemberships,
    ]).subscribe(([groups, memberships]) => {
      if (!groups || !memberships || groups.some(group => group.isMember)) { return; }
      const ADgroups = groups.map(group => ({ ...group, isMember: memberships.map(m => m.id).indexOf(group.id) > -1}));
      this.$ADgroups.next(ADgroups)
    });
    try {
      const storage = localStorage.getItem(this.AUTHORIZED_ROUTES_KEY);
      const { authorizedRoutes, expires } = (storage ? JSON.parse(storage) : { authorizedRoutes: [], expires: addDays(new Date(), -1) });
      if (new Date() > expires) {
        this.$routes = new BehaviorSubject(authorizedRoutes ? JSON.parse(authorizedRoutes) : []);
      }
      this.$routes.subscribe(authorizedRoutes => localStorage.setItem(this.AUTHORIZED_ROUTES_KEY, JSON.stringify({ authorizedRoutes, expires: addDays(new Date(), 14) })))
    } catch (e) {}
    try {
      const token = localStorage.getItem(this.TOKEN_KEY);
      if (token) { this.refreshToken(JSON.parse(token)).then(); }
    } catch (e) {}
    this.$isAdmin.next(this.environment.admin);
    this.$accounts.next(this.msal.instance.getAllAccounts());
  }

  async login() {
    try {
      const accounts = this.$accounts.getValue();
      if (accounts.length > 0) {
        await this.setActiveAccount(accounts[0]);
      } else {
        await this.msal.loginRedirect({...this.authRequest} as RedirectRequest).toPromise();

      }
    } catch (error) {
      console.error(error);
    }
  }

  private async setActiveAccount(account: any) {
    this.msal.instance.setActiveAccount(account);
    await this.refreshToken();
  }

  private async refreshToken(token?: AuthenticationResult) {
    try {
      if (!token || !token.expiresOn || token.expiresOn > new Date()) {
        token = await this.msal.acquireTokenSilent(this.authRequest).toPromise();
      }
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        await this.msal.acquireTokenRedirect({ ...this.authRequest }).toPromise();
        return;
      }
    }
    if (!token) { return; }
    await this.setAuthentication(token);
    return token;
  }

  private async setAuthentication(token: AuthenticationResult) {
    if (!token) { return; }
    console.debug(token);
    this.$loading.next(true);
    this.$token.next(token.accessToken);
    this.http.$Authorization.next(token.idToken);
    localStorage.setItem(this.TOKEN_KEY, JSON.stringify(token));
    try {
      await Promise.all([
        this.dependencies.get(token.uniqueId),
        this.refreshGroups(token.uniqueId),
        this.secrets.get(),
      ]);
    } catch (e: any) {
      if (e.message) {
        this.messages.warning(e.message);
      }
    }
    this.$loading.next(false);
  }

  logout() {
    const account = this.msal.instance.getActiveAccount();
    localStorage.setItem(this.AUTHORIZED_ROUTES_KEY, JSON.stringify([]));
    localStorage.setItem(this.TOKEN_KEY, JSON.stringify(""));
    this.msal.logoutRedirect({  account });
  }

  access(target: string, routes?: string[]): boolean {
    routes = routes ? routes : this.$routes.getValue();
    target = target.replace('//', '/');
    if (target.indexOf('?') >= 0) {
      target = target.substring(0, target.indexOf('?'));
    }
    return this.environment.admin || routes.some(route => route.includes(target));
  }

  get headers() {
    return { Authorization: 'Bearer ' + this.$token.getValue()};
  }

  async updatePassword(id: string) {
    try {
      await this.http.patch(this.baseURL + `users/${id}`, {
        passwordProfile: { forceChangePasswordNextSignIn: true }
      });
      this.messages.success('Bei der nächsten Anmeldung wird ein neues Passwort verlangt');
      if (id === this.$id.getValue()) {
        this.logout();
      }
    } catch (error: any) {
      console.error(error)
      this.notifications.error('❓️', `something has gone wrong here: ${error.error.error.message}`);
    }
  }

  async refreshGroups(id: string) {
    let [groups, memberships] = await Promise.all([
      this.http.get<any[]>(this.baseURL + 'groups'),
      this.refreshMemberships(id)
    ]);
    groups = (groups ? groups : []);
    memberships = (memberships ? memberships : []);
    groups = groups.filter((group: any) => group).map(group => ({ ...group, emailOrSecurity: !group.groupTypes || group.groupTypes.length === 0}))
    const ADgroups = groups.map((group: any) => ({ ...group, isMember: memberships.map((m: any) => m.id).indexOf(group.id) > -1}));
    this.$ADgroups.next(ADgroups);
  }

  async refreshMemberships(id: string) {
    if (!id) {this.$ADmemberships.next([]);  }
    const memberships: any = await this.http.get(this.baseURL + `users/${id}/memberOf`);
    this.$ADmemberships.next(memberships);
    return memberships;
  }

  async addMemberToGroup(group: string, id: string) {
    try {
      await this.http.post(this.baseURL + `groups/${group}/members/$ref`, { '@odata.id': this.baseURL + `directoryObjects/${id}` });
      await this.refreshMemberships(id);
      this.messages.success('Mitgliedschaft hinzugefügt.');
    } catch (error: any) {
      await this.refreshGroups(id);
      console.error(error);
      this.notifications.error('️❓️', `something has gone wrong here: ${error.error.error.message}`);
    }
  }

  async removeMemberFromGroup(group: string, id: string) {
    try {
      await this.http.delete(this.baseURL + `groups/${group}/members/${id}/$ref`);
      await this.refreshMemberships(id);
      this.messages.success('Mitgliedschaft entfernt.');
    } catch (error: any) {
      await this.refreshGroups(id);
      console.error(error)
      this.notifications.error('️❓️', `something has gone wrong here: ${error.error.error.message}`);
    }
  }
}
