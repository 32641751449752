
<label>
  <nz-avatar nzIcon="user"
             [nzSize]="128"
             [nzSrc]="Src"
             [nzText]="text"
             [style.backgroundColor]="color"
             [nzShape]="'square'"
             style="cursor:pointer; margin-left: 16px; margin-top: 16px"></nz-avatar>
  <input #FileInput
         (change)='uploadedImage($event)'
         [style.visibility]="'hidden'"
         [style.width]="'10px'"
         accept='image/*'
         type='file'>
</label>
<a style="cursor:pointer; margin-left: 16px;" (click)="reset()">löschen</a>


<ng-template #ImageCropper>
  <nz-row style="margin-top: 15px;">
      <image-cropper
        [imageChangedEvent]="event"
        [maintainAspectRatio]="true"
        [aspectRatio]="4 / 4"
        [resizeToWidth]="256"
        format="jpeg"
        (imageCropped)="imageCropped($event)"></image-cropper>
  </nz-row>
</ng-template>
