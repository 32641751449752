import { NgModule } from '@angular/core';
import {
  ObjekteFilterComponentModule,
  ObjekteListComponentModule,
  ObjekteSorterComponentModule,
  SavedObjekteFiltersComponentModule
} from './';

@NgModule({
  imports: [
    ObjekteFilterComponentModule,
    ObjekteListComponentModule,
    ObjekteSorterComponentModule,
    SavedObjekteFiltersComponentModule
  ],
  exports: [
    ObjekteFilterComponentModule,
    ObjekteListComponentModule,
    ObjekteSorterComponentModule,
    SavedObjekteFiltersComponentModule
  ],
})
export class ObjekteQueryModule { }
