import { Inject, Injectable, Injector } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { Router } from "@angular/router";

import { NzMessageService } from "ng-zorro-antd/message";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { IEventMeta } from "@lib/common/types";
import { HTTPS_METHOD } from "@lib/https/types";
import { HttpService, OfflineCacheService } from "../../services";
import { APP_CONFIG, BroadcastService, IAppConfig } from "@lib/common/frontend";


@Injectable()
export class HttpInterceptor implements HttpInterceptor {

  get messages(): NzMessageService {
    return this.injector.get(NzMessageService);
  }
  get http(): HttpService {
    return this.injector.get(HttpService);
  }
  get broadcast(): BroadcastService {
    return this.injector.get(BroadcastService);
  }
  get cache(): OfflineCacheService {
    return this.injector.get(OfflineCacheService);
  }

  constructor(
    @Inject(APP_CONFIG) private config: IAppConfig,
    private injector: Injector,
    private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let url = request.url;
    let headers = request.headers;
    if (!url.startsWith('http')) {
      url = this.config.serverURL + url;
    }
    const Authorization = this.http.$Authorization.getValue();
    if (url.includes(this.config.serverURL) && Authorization && !headers.has('Authorization')) {
      headers = headers.set('Authorization', Authorization);
    }
    const req = request.clone({
      url,
      headers
    });
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          let body = event.body;
          const _events: IEventMeta[] = body && body._events ? body._events as IEventMeta[] : [];
          _events.filter(event => event && event.message).forEach(event => this.messages.info(event.message as string));
          _events.filter(event => event && event.event).map(event => event.event).forEach(event => this.broadcast.next(event));
          if (body) {
            if (body['@odata.context'] && body.value) {
              body = body.value
            }
            delete body['@odata.context'];
            delete body['@odata.id'];
            event = event.clone({ body });
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse|any) => {
        if (!['GET', 'OPTIONS'].includes(req.method) && this.http.isOfflineOrBadConnectionError(error)) {
          this.cache.addSyncableTask({
            method: HTTPS_METHOD[req.method as 'POST'|'DELETE'|'GET'|'PUT'|'PATCH'],
            url,
            body: req.body
          });
          return throwError({ code: 200, message: 'Offline' });
        } else {
          if (error.status === 0) {
            error = { ...error, message: 'Der Server ist nicht erreichbar. Bitte versuchen Sie es später noch einmal oder informieren Sie Ihr Team "IT" 💻️.' };
            this.router.navigate(['willkommen']).then();
          } else if (error.error) {
            error = error.error;
          }
        }
        return throwError(error);
      })
    );
  }
}
