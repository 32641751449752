import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { OffeneGutachtenServiceModule } from '../';
import { OffeneGutachtenSorterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    OffeneGutachtenServiceModule
  ],
  declarations: [OffeneGutachtenSorterComponent],
  exports: [OffeneGutachtenSorterComponent],

})
export class OffeneGutachtenSorterComponentModule { }
