import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { first, map, debounceTime, distinctUntilChanged } from "rxjs/operators";

import { isEqual } from "lodash";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { IPersistedFilter, IPersistedFilterList } from "@lib/filter/types";
import { ISelection } from "@lib/persistence/types";

import {
  IKundeRequest,
  IKundeResponse,
  IKundeResponseGroup,
  IKundeQueryFilterShapes
} from '@fa-kt-evaluation/kunden/types';

@Injectable({
  providedIn: 'root'
})
export class KundeService {

  protected route = 'kunden/kunde';
  protected dependencies = [
    'kunden/written-kunden-art',
    'kunden/removed-kunden-art',
    'kunden/written-kunden-gruppe',
    'kunden/removed-kunden-gruppe',

    'finanzen/extra-vereinbarung-entfernt',
    'finanzen/extra-vereinbarung-gespeichert',
    'finanzen/honorar-vereinbarung-entfernt',
    'finanzen/honorar-vereinbarung-gespeichert',
    'finanzen/rechnung-erstellt',
    'finanzen/zahlungseingang-entfernt',
    'finanzen/zahlungseingang-gespeichert',
    'formulare/formular-entfernt',
    'formulare/formular-gespeichert',
    'formulare/formular-kopiert',
    'kommunikation/erinnerung-abgemeldet',
    'kommunikation/erinnerung-entfernt',
    'kommunikation/erinnerung-gemerkt',
    'kommunikation/vermerk-entfernt',
    'kommunikation/vermerkt',
    'kunden/abteilung-entfernt',
    'kunden/abteilung-gespeichert',
    'kunden/fond-entfernt',
    'kunden/fond-gespeichert',
    'kunden/kunde-entfernt',
    'kunden/kunde-gespeichert',
    'kunden/kunden-finanzen-gespeichert',
    'projekte/projekt-archiviert',
    'projekte/projekt-entfernt',
    'projekte/projekt-gespeichert',
    'projekte/projekt-kopiert',
    'projekte/projekt-reaktiviert',
    'texte/vorlage-entfernt',
    'texte/vorlage-gespeichert',
    'zeiten/zeit-entfernt',
    'zeiten/zeit-gespeichert'
  ];

  readonly $result = new BehaviorSubject<IKundeResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IKundeRequest|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IKundeQueryFilterShapes|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private device: DeviceConfigService,
  ) {
/* << changes    */
    this.dependencies.push('kunden/fond-gespeichert');
    this.dependencies.push('kunden/abteilung-gespeichert');
/*    changes >> */
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IKundeRequest): IKundeRequest {
    if (payload) {
      this.$payload.next(payload as IKundeRequest);
    } else {
      payload = this.$payload.getValue() as IKundeRequest;
    }
    return payload;
  }

  public async request(payload: IKundeRequest, force = false): Promise<IKundeResponse> {
    const state = this.$result.getValue();
    if (!force && !this.device.alwaysRefresh && state && isEqual(payload, this.payload())) { return state; }
    payload = this.payload(payload);
    // if (!payload) { return this.$result.getValue() as IKundeResponse; }
    const query = this.http.serialize(payload);
    const { response, shapes } = await this.http.get<IKundeResponseGroup>(this.route + query);
    this.$result.next(response);
    this.$shapes.next(shapes);
    return response;
  }


  getKundeKundenArt(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['kunde.kundenArt']?.find(v => v.value === value)
  }

  get kundeKundenArt(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['kunde.kundenArt'] as ISelection[] : []));
  }

  getKundeKundenGruppe(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['kunde.kundenGruppe']?.find(v => v.value === value)
  }

  get kundeKundenGruppe(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['kunde.kundenGruppe'] as ISelection[] : []));
  }

  getVermerkeVerfasser(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['vermerke.verfasser']?.find(v => v.value === value)
  }

  get vermerkeVerfasser(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['vermerke.verfasser'] as ISelection[] : []));
  }

  getErinnerungenMitarbeiter(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['erinnerungen.mitarbeiter']?.find(v => v.value === value)
  }

  get erinnerungenMitarbeiter(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['erinnerungen.mitarbeiter'] as ISelection[] : []));
  }

  getErinnerungenVerfasser(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['erinnerungen.verfasser']?.find(v => v.value === value)
  }

  get erinnerungenVerfasser(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['erinnerungen.verfasser'] as ISelection[] : []));
  }


}
