import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostKundenFinanzenCommandService } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [PostKundenFinanzenCommandService],
  exports: []
})
export class PostKundenFinanzenCommandServiceModule { }
