import { NgModule } from '@angular/core';

import {
  PostFondCommandServiceModule,
  PostFondCommandComponentModule
} from './';

@NgModule({
  imports: [
    PostFondCommandServiceModule,
    PostFondCommandComponentModule
  ],
  exports: [
    PostFondCommandServiceModule,
    PostFondCommandComponentModule
  ]
})
export class PostFondCommandModule { }
