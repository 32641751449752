import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { FeldServiceModule } from '../';
import { SavedFeldFiltersComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    FeldServiceModule
  ],
  declarations: [SavedFeldFiltersComponent],
  exports: [SavedFeldFiltersComponent],

})
export class SavedFeldFiltersComponentModule { }
