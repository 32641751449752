import { NgModule } from '@angular/core';
import {
  FormularFilterComponentModule,
  FormularDetailComponentModule,
  FormularSorterComponentModule,
  SavedFormularFiltersComponentModule
} from './';

@NgModule({
  imports: [
    FormularFilterComponentModule,
    FormularDetailComponentModule,
    FormularSorterComponentModule,
    SavedFormularFiltersComponentModule
  ],
  exports: [
    FormularFilterComponentModule,
    FormularDetailComponentModule,
    FormularSorterComponentModule,
    SavedFormularFiltersComponentModule
  ],
})
export class FormularQueryModule { }
