import { NgModule } from '@angular/core';
import { 
  ObjektQueryModule,
  ObjekteQueryModule
} from './';

@NgModule({
  declarations: [],
  imports: [
    ObjektQueryModule,
    ObjekteQueryModule
  ],
  exports: [
    ObjektQueryModule,
    ObjekteQueryModule
  ]
})
export class ObjekteQuerysModule { }
