import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostAbteilungCommandServiceModule } from '../';
import { PostAbteilungCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostAbteilungCommandServiceModule
  ],
  declarations: [PostAbteilungCommandComponent],
  exports: [PostAbteilungCommandComponent],

})
export class PostAbteilungCommandComponentModule { }
