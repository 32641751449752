import { NgModule } from '@angular/core';
import { 
  BerichtsVorlagenQueryModule,
  VorlageQueryModule,
  VorlagenQueryModule
} from './';

@NgModule({
  declarations: [],
  imports: [
    BerichtsVorlagenQueryModule,
    VorlageQueryModule,
    VorlagenQueryModule
  ],
  exports: [
    BerichtsVorlagenQueryModule,
    VorlageQueryModule,
    VorlagenQueryModule
  ]
})
export class TexteQuerysModule { }
