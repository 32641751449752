<!---
  <div formGroupName="rechnung">
    <input class="cdk-visually-hidden" formControlName="id" id="id"/>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="projekt">{{ command.Inputs.PROJEKT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ProjektErrorTip">
        <ng-template #ProjektErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.PROJEKT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="projekt" id="projekt" [shapes]="postRechnung.rechnungProjekt | async" [placeholder]="command.Inputs.PROJEKT.placeholder" cdkFocusInitial required=true ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="kunde">{{ command.Inputs.KUNDE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="KundeErrorTip">
        <ng-template #KundeErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.KUNDE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="kunde" id="kunde" [shapes]="postRechnung.rechnungKunde | async" [placeholder]="command.Inputs.KUNDE.placeholder" required=true ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="bezeichnung">{{ command.Inputs.BEZEICHNUNG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BezeichnungErrorTip">
        <ng-template #BezeichnungErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BEZEICHNUNG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="bezeichnung" id="bezeichnung" [placeholder]="command.Inputs.BEZEICHNUNG.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="rechnungZaehler">{{ command.Inputs.RECHNUNG_ZAEHLER.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="RechnungZaehlerErrorTip">
        <ng-template #RechnungZaehlerErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.RECHNUNG_ZAEHLER.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="rechnungZaehler" id="rechnungZaehler" [placeholder]="command.Inputs.RECHNUNG_ZAEHLER.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="datum">{{ command.Inputs.DATUM.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DatumErrorTip">
        <ng-template #DatumErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.DATUM.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <nz-date-picker formControlName="datum" id="datum"  nzFormat="dd.MM.yyyy"></nz-date-picker>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="honorarVereinbarung">{{ command.Inputs.HONORAR_VEREINBARUNG.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="HonorarVereinbarungErrorTip">
        <ng-template #HonorarVereinbarungErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.HONORAR_VEREINBARUNG.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="honorarVereinbarung" id="honorarVereinbarung" [shapes]="postRechnung.rechnungHonorarVereinbarung | async" [placeholder]="command.Inputs.HONORAR_VEREINBARUNG.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="extraVereinbarungs">{{ command.Inputs.EXTRA_VEREINBARUNGS.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ExtraVereinbarungsErrorTip">
        <ng-template #ExtraVereinbarungsErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.EXTRA_VEREINBARUNGS.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value mode="multiple" formControlName="extraVereinbarungs" id="extraVereinbarungs" [shapes]="postRechnung.rechnungExtraVereinbarungs | async" [placeholder]="command.Inputs.EXTRA_VEREINBARUNGS.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="stunden">{{ command.Inputs.STUNDEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="StundenErrorTip">
        <ng-template #StundenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STUNDEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="stunden" id="stunden" [placeholder]="command.Inputs.STUNDEN.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="anschlaege">{{ command.Inputs.ANSCHLAEGE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AnschlaegeErrorTip">
        <ng-template #AnschlaegeErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ANSCHLAEGE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="anschlaege" id="anschlaege" [placeholder]="command.Inputs.ANSCHLAEGE.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="seiten">{{ command.Inputs.SEITEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="SeitenErrorTip">
        <ng-template #SeitenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.SEITEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="seiten" id="seiten" [placeholder]="command.Inputs.SEITEN.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fotos">{{ command.Inputs.FOTOS.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FotosErrorTip">
        <ng-template #FotosErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FOTOS.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="fotos" id="fotos" [placeholder]="command.Inputs.FOTOS.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="strecke">{{ command.Inputs.STRECKE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="StreckeErrorTip">
        <ng-template #StreckeErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STRECKE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="strecke" id="strecke" [placeholder]="command.Inputs.STRECKE.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fahrtzeit">{{ command.Inputs.FAHRTZEIT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FahrtzeitErrorTip">
        <ng-template #FahrtzeitErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FAHRTZEIT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="fahrtzeit" id="fahrtzeit" [placeholder]="command.Inputs.FAHRTZEIT.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="besichtigungen">{{ command.Inputs.BESICHTIGUNGEN.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BesichtigungenErrorTip">
        <ng-template #BesichtigungenErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BESICHTIGUNGEN.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value mode="multiple" formControlName="besichtigungen" id="besichtigungen" [shapes]="postRechnung.rechnungBesichtigungen | async" [placeholder]="command.Inputs.BESICHTIGUNGEN.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="mwstSatz">{{ command.Inputs.MWST_SATZ.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MwstSatzErrorTip">
        <ng-template #MwstSatzErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MWST_SATZ.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="mwstSatz" id="mwstSatz" [placeholder]="command.Inputs.MWST_SATZ.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="nachlass">{{ command.Inputs.NACHLASS.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NachlassErrorTip">
        <ng-template #NachlassErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NACHLASS.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="nachlass" id="nachlass" [placeholder]="command.Inputs.NACHLASS.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="summe">{{ command.Inputs.SUMME.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="SummeErrorTip">
        <ng-template #SummeErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.SUMME.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input type="number" style="width: 100px" formControlName="summe" id="summe" [placeholder]="command.Inputs.SUMME.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="datei">{{ command.Inputs.DATEI.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DateiErrorTip">
        <ng-template #DateiErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.DATEI.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="datei" id="datei" [shapes]="postRechnung.rechnungDatei | async" [placeholder]="command.Inputs.DATEI.placeholder" ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
  </div>
 -->
<nz-spin [nzSpinning]="($loading | async) || ($calculating | async)">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
<!--- << custom    -->
      <div formGroupName="rechnung">
      <ng-container *ngIf="kunde && kunde.rechnungNotizen">
        <nz-alert nzShowIcon nzType="info" [nzMessage]="'Kunde: ' + kunde.initialen" [nzDescription]="kunde.rechnungNotizen"></nz-alert>
        <br>
      </ng-container>
      <ng-container *ngIf="projekt && projekt.notizenFuerRechnung">
        <nz-alert nzShowIcon nzType="info" [nzMessage]="'Projekt: ' + projekt.nummer"  [nzDescription]="projekt.notizenFuerRechnung"></nz-alert>
        <br>
      </ng-container>
      <br>
      <input class="cdk-visually-hidden" formControlName="id" id="id"/>
      <input class="cdk-visually-hidden" formControlName="datei" id="datei"/>
      <input class="cdk-visually-hidden" formControlName="projekt" id="projekt"/>
      <input class="cdk-visually-hidden" formControlName="kunde" id="kunde"/>
      <input class="cdk-visually-hidden" formControlName="abteilung" id="abteilung"/>

      <nz-divider id="allgemein" nzText="Allgemein" nzOrientation="left"></nz-divider>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="bezeichnung">{{ command.Inputs.BEZEICHNUNG.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BezeichnungErrorTip">
          <ng-template #BezeichnungErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BEZEICHNUNG.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input formControlName="bezeichnung" id="bezeichnung" [placeholder]="command.Inputs.BEZEICHNUNG.placeholder"/>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="datum">{{ command.Inputs.DATUM.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="DatumErrorTip">
          <ng-template #DatumErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.DATUM.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <nz-date-picker formControlName="datum" id="datum"  nzFormat="dd.MM.yyyy"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>

        <nz-form-item style="width: 100%;">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="ansprechpartner">Ansprechpartner</nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ansprechpartnerErrorTip">
            <ng-template #ansprechpartnerErrorTip let-control>
              <ng-container *ngIf="control.hasError('required')">Bitte Ansprechpartner angeben</ng-container>
              <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
            </ng-template>
            <input nz-input formControlName="ansprechpartner" id="ansprechpartner" [placeholder]="'Ansprechpartner'"/>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item style="width: 100%;">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="plz">PLZ</nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="plzErrorTip">
            <ng-template #plzErrorTip let-control>
              <ng-container *ngIf="control.hasError('required')">Bitte PLZ angeben</ng-container>
              <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
            </ng-template>
            <input nz-input formControlName="plz" id="plz" [placeholder]="'PLZ'"/>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item style="width: 100%;">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="ort">Ort</nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ortErrorTip">
            <ng-template #ortErrorTip let-control>
              <ng-container *ngIf="control.hasError('required')">Bitte Ort angeben</ng-container>
              <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
            </ng-template>
            <input nz-input formControlName="ort" id="ort" [placeholder]="'Ort'"/>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item style="width: 100%;">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="ort">Strasse</nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="addresseErrorTip">
            <ng-template #addresseErrorTip let-control>
              <ng-container *ngIf="control.hasError('required')">Bitte Strasse angeben</ng-container>
              <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
            </ng-template>
            <input nz-input formControlName="addresse" id="addresse" [placeholder]="'Strasse'"/>
          </nz-form-control>
        </nz-form-item>


      <nz-divider id="honorar" nzText="Honorar" nzOrientation="left"></nz-divider>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="honorarVereinbarung">Nach Marktwert</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="HonorarVereinbarungErrorTip">
          <ng-template #HonorarVereinbarungErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Bitte Honorar-Vereinbarung auswählen</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <codeboard-select-value [filter]="kunde.id" formControlName="honorarVereinbarung" id="honorarVereinbarung" [shapes]="postRechnung.rechnungHonorarVereinbarung | async" [placeholder]="command.Inputs.HONORAR_VEREINBARUNG.placeholder" ></codeboard-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="honorarVereinbarungSumme"><b>Summe</b></nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="HonorarVereinbarungErrorTip">
          <ng-template #HonorarVereinbarungErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Bitte Summe angeben</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="honorarVereinbarungSumme" id="honorarVereinbarungSumme" [placeholder]="'Summe Honorar-Vereinbarung'"/> €
        </nz-form-control>
      </nz-form-item>


      <nz-divider id="extra-vereinbarungen" nzText="Extra-Vereinbarungen" nzOrientation="left"></nz-divider>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="extraVereinbarungs">Auswahl</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="ExtraVereinbarungsErrorTip">
          <ng-template #ExtraVereinbarungsErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.EXTRA_VEREINBARUNGS.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <codeboard-select-value mode="multiple" [filter]="kunde.id" formControlName="extraVereinbarungs" id="extraVereinbarungs" [shapes]="postRechnung.rechnungExtraVereinbarungs | async" [placeholder]="command.Inputs.EXTRA_VEREINBARUNGS.placeholder" ></codeboard-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="gutachten">Gutachten</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="GutachtenErrorTip">
          <ng-template #GutachtenErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Bitte Anzahl der Gutachten angeben</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="gutachten" id="gutachten" [placeholder]="'Gutachten'" min="0"/> Stk.
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="stunden">{{ command.Inputs.STUNDEN.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="StundenErrorTip">
          <ng-template #StundenErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STUNDEN.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="stunden" id="stunden" [placeholder]="command.Inputs.STUNDEN.placeholder" min="0"/> S
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="anschlaege">{{ command.Inputs.ANSCHLAEGE.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AnschlaegeErrorTip">
          <ng-template #AnschlaegeErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.ANSCHLAEGE.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="anschlaege" id="anschlaege" [placeholder]="command.Inputs.ANSCHLAEGE.placeholder" min="0"/> Stk.
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="seiten">{{ command.Inputs.SEITEN.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="SeitenErrorTip">
          <ng-template #SeitenErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.SEITEN.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="seiten" id="seiten" [placeholder]="command.Inputs.SEITEN.placeholder" min="0"/> Stk.
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fotos">{{ command.Inputs.FOTOS.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FotosErrorTip">
          <ng-template #FotosErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FOTOS.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="fotos" id="fotos" [placeholder]="command.Inputs.FOTOS.placeholder" min="0"/> Stk.
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="extraVereinbarungsSumme"><b>Summe</b></nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FahrtzeitErrorTip">
          <ng-template #FahrtzeitErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Bitte Summe Extra-Vereinbarungen angeben</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="extraVereinbarungsSumme" id="extraVereinbarungsSumme" [placeholder]="'Summe Extra-Vereinbarungen'"/> €
        </nz-form-control>
      </nz-form-item>


      <nz-divider id="fahrtkosten" nzText="Fahrtkosten" nzOrientation="left"></nz-divider>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="besichtigungen">{{ command.Inputs.BESICHTIGUNGEN.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="BesichtigungenErrorTip">
          <ng-template #BesichtigungenErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.BESICHTIGUNGEN.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <codeboard-select-value mode="multiple" formControlName="besichtigungen" id="besichtigungen" [shapes]="$besichtigungen | async" [placeholder]="command.Inputs.BESICHTIGUNGEN.placeholder" ></codeboard-select-value>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="strecke">{{ command.Inputs.STRECKE?.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="StreckeErrorTip">
          <ng-template #StreckeErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.STRECKE?.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="strecke" id="strecke" [placeholder]="command.Inputs.STRECKE?.placeholder" min="0"/> km
        </nz-form-control>
      </nz-form-item>
        <nz-form-item style="width: 100%;">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fahrtzeit">{{ command.Inputs.FAHRTZEIT?.title}}</nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FahrtzeitErrorTip">
            <ng-template #FahrtzeitErrorTip let-control>
              <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.FAHRTZEIT?.hint }}</ng-container>
              <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
            </ng-template>
            <input nz-input type="number" style="width: 100px" formControlName="fahrtzeit" id="fahrtzeit" [placeholder]="command.Inputs.FAHRTZEIT?.placeholder"/> h
          </nz-form-control>
        </nz-form-item>
        <nz-form-item style="width: 100%;">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="fahrtenSumme"><b>Summe</b></nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FahrtzeitErrorTip">
            <ng-template #FahrtzeitErrorTip let-control>
              <ng-container *ngIf="control.hasError('required')">Bitte Summe Fahrten angeben</ng-container>
              <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
            </ng-template>
            <input nz-input type="number" style="width: 100px" formControlName="fahrtenSumme" id="fahrtenSumme" [placeholder]="'Summe Fahrten'"/> €            <ng-container *ngIf="kunde && kunde.fahrtkostenAbrechnung && kunde.fahrtkostenAbrechnung !== 'keine'"> ({{ kunde.fahrtkostenAbrechnung === 'proKm' ? kunde.fahrtkostenProKm : kunde.fahrtkostenProStunde }} € {{ kunde.fahrtkostenAbrechnung }} {{ kunde.fahrtkostenAbrechnung === 'proKm' && kunde.fahrtkostenAbRadius ? 'ab ' + kunde.fahrtkostenAbRadius  + ' km Radius': '' }})</ng-container>
          </nz-form-control>
        </nz-form-item>


      <nz-divider id="netto" nzText="Netto" nzOrientation="left"></nz-divider>
        <nz-form-item style="width: 100%;">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="nachlass">{{ command.Inputs.NACHLASS.title}}</nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NachlassErrorTip">
            <ng-template #NachlassErrorTip let-control>
              <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NACHLASS.hint }}</ng-container>
              <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
            </ng-template>
            <input nz-input type="number" style="width: 100px" formControlName="nachlass" id="nachlass" [placeholder]="command.Inputs.NACHLASS.placeholder"/> %
          </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="auslagenSumme">Auslagen</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AuslagenErrorTip">
          <ng-template #AuslagenErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Bitte Auslagen angeben</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="auslagenSumme" id="auslagenSumme" [placeholder]="'Summe'"/> €
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="sonstigeKosten">Sonstige Kosten</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="AuslagenErrorTip">
          <ng-template #AuslagenErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Bitte sonstige Kosten angeben</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="sonstigeKosten" id="sonstigeKosten" [placeholder]="'Summe'"/> €
        </nz-form-control>
      </nz-form-item>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="nettoSumme"><b>Summe (netto)</b></nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="SummeErrorTip">
          <ng-template #SummeErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Bitte Summe (netto) angeben</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="nettoSumme" id="nettoSumme" [placeholder]="'Summe'"/> €
        </nz-form-control>
      </nz-form-item>


      <nz-divider id="brutto" nzText="Brutto" nzOrientation="left"></nz-divider>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="mwstSumme">Mehrwertsteuer</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MwstSatzErrorTip">
          <ng-template #MwstSatzErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">Bitte Summe angeben</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="mwstSumme" id="mwstSumme" [placeholder]="command.Inputs.MWST_SATZ.placeholder"/> €
        </nz-form-control>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="MwstSatzErrorTip">
          <ng-template #MwstSatzErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.MWST_SATZ.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input type="number" style="width: 100px" formControlName="mwstSatz" id="mwstSatz" [placeholder]="command.Inputs.MWST_SATZ.placeholder"/> %
        </nz-form-control>
      </nz-form-item>
        <nz-form-item style="width: 100%;">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="bruttoSumme"><b>Summe (brutto)</b></nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="SummeErrorTip">
            <ng-template #SummeErrorTip let-control>
              <ng-container *ngIf="control.hasError('required')">Bitte Summe (brutto) angeben</ng-container>
              <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
            </ng-template>
            <input nz-input type="number" style="width: 100px" formControlName="bruttoSumme" id="bruttoSumme" [placeholder]="'Summe'"/> €
          </nz-form-control>
        </nz-form-item>


        <nz-form-item style="width: 100%;">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="auslagenOhneMwstSumme">Auslagen o. MwSt</nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="auslagenOhneMwstSummeErrorTip">
            <ng-template #auslagenOhneMwstSummeErrorTip let-control>
              <ng-container *ngIf="control.hasError('required')">Bitte sonstige Auslagen o. MwSt angeben</ng-container>
              <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
            </ng-template>
            <input nz-input type="number" style="width: 100px" formControlName="auslagenOhneMwstSumme" id="auslagenOhneMwstSumme" [placeholder]="'Auslagen o. MwSt'"/> €
          </nz-form-control>
        </nz-form-item>
        <nz-form-item style="width: 100%;">
          <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzFor="finaleSumme"><b>Summe</b></nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="FinaleSummeErrorTip">
            <ng-template #FinaleSummeErrorTip let-control>
              <ng-container *ngIf="control.hasError('required')">Bitte finale Summe</ng-container>
              <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
            </ng-template>
            <input nz-input type="number" style="width: 100px" formControlName="finaleSumme" id="finaleSumme" [placeholder]="'Finale Summe'"/> €
          </nz-form-control>
        </nz-form-item>
    </div>

    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="default" class="save-button" type="button" (click)="download()"
                [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)">
          <i nz-icon nzType="download" nzTheme="outline"></i>
          Herunterladen
        </button>
      </nz-form-control>
    </nz-form-item>
<!---    custom >> -->
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button"
          type="submit"
          [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)"
          nz-tooltip [nzTooltipTitle]="SubmitTooltip">
<!--- << confirm    -->
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Speichern
<!---    confirm >> -->
          <ng-template #SubmitTooltip>
<!--- << hint    -->
            Rechnung speichern
<!---    hint >> -->
          </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
