import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { SwUpdate, UpdateAvailableEvent, VersionEvent } from "@angular/service-worker";
import { Router } from "@angular/router";

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from "ng-zorro-antd/message";
import { BehaviorSubject, interval, Subscription } from "rxjs";
import { filter, map } from "rxjs/operators";

import {
  ActionService,
  SecretService,
  BroadcastService,
  ResolverLoadingService,
  CodeboardErrorHandler,
  getEventFromSitemap, ISitemap, SITEMAP,
  IAppConfig, APP_CONFIG, APP_VERSION, APP_BUILD
} from "@lib/common/frontend";

import { OfflineCacheService } from "@lib/https/frontend";
import { AuthService, DependenciesService } from "@lib/auth/frontend";
/* << imports    */
import { combineLatest } from "rxjs";
import { formatDistance } from 'date-fns'
import { de } from 'date-fns/locale'
/*    imports >> */
@Component({
  selector: 'fa-kt-evaluation-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];

/* << fields    */
  isCollapsed = true;
  $menu = new BehaviorSubject<{ url: string[], emoji: string, title: string, children: { url: string[], emoji: string, title: string }[] }[]>([]);

  nzPopoverVisible = false;
  $slot: BehaviorSubject<{
    startedAt: Date;
    paused: boolean;
  }|null> = new BehaviorSubject(null);
  readonly $seconds = new BehaviorSubject<number|undefined>(undefined);
  readonly $work = new BehaviorSubject<number>(0);
  readonly $pause = new BehaviorSubject<number>(0);
  readonly $working = new BehaviorSubject<string>('');
  readonly $pausing = new BehaviorSubject<string>('');

/*    fields >> */

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    @Inject(APP_CONFIG) public environment: IAppConfig,
    @Inject(APP_VERSION) public version: String,
    @Inject(APP_BUILD) public build: String,
    private router: Router,
    private swUpdate: SwUpdate,
    private modal: NzModalService,
    private messages: NzMessageService,
    public resolverLoader: ResolverLoadingService,
    public offlineCache: OfflineCacheService,
    public auth: AuthService,
    public secrets: SecretService,
    public actions: ActionService,
    public broadcast: BroadcastService,
    public dependencies: DependenciesService,
  ) {
    this.auth.$id.subscribe(async (id) => {
      if (id) { await this.offlineCache.sync(); }
    })
    this.dependencies.me.subscribe(me => {
      this.auth.$email.next(me ? me.email : null);
      this.auth.$displayName.next(me ? me.displayName : null);
      this.auth.$avatar.next(me ? me.photo : null);
      this.auth.$groups.next(me ? me.groups : []);
      this.auth.$isAdmin.next(this.environment.admin || me && me.isAdmin ? true : false);
      this.auth.$routes.next(me ? me.authorizedRoutes : []);
      this.auth.$id.next(me ? me.id : null);
    });
    const angularPlugin = new AngularPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.environment.appInsights.instrumentationKey,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: {
            router: this.router,
            errorServices: [new CodeboardErrorHandler()]
          }
        },
        loggingLevelConsole: 1,
        autoTrackPageVisitTime: true,
        enableAutoRouteTracking: true,
        disableFetchTracking: false,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true
      }
    });
    appInsights.loadAppInsights();
    const telemetryInitializer = (envelope) => {
      envelope.tags['ai.cloud.role'] = this.environment.stage + '-app';
      envelope.tags["ai.cloud.roleInstance"] = `${this.environment.mode} - ${version} - (${build})`;
    };
    appInsights.addTelemetryInitializer(telemetryInitializer);
  }

  async ngOnInit() {
    if (this.environment.production) {
      this.swUpdate.versionUpdates.subscribe(async evt => {
        console.log('UpdateService: versionUpdates', evt);
        switch (evt.type) {
          case 'VERSION_DETECTED':
            console.log(`Downloading new app version: ${evt.version.hash}`);
            break;
          case 'VERSION_READY':
            console.log(`Current app version: ${evt.currentVersion.hash}`);
            console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
            await this.swUpdate.activateUpdate();
            location.reload();
            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
            break;
        }
      });
      await this.swUpdate.checkForUpdate();
    }

    this.broadcast.$events.pipe(
      map(eventName => getEventFromSitemap(this.sitemap, eventName)),
      filter((event) => event && event.translation && event.translation.length > 0)
    ).subscribe(event => this.messages.success(event.translation));

    this.subscriptions.push(...[
/* << subscriptions    */
      this.dependencies.me.subscribe(me => {
        this.auth.$isFinanzen.next(me ? me.finanzen : null);
      }),
      this.broadcast.$events.subscribe(eventName => console.debug('broadcast', eventName)),
      this.$menu.subscribe(menu => {
        menu.slice().reverse().forEach(section => {
          if (section.url && section.url.length > 0) {
            this.actions.subscribe({
              key: 'Zu ' + section.title,
              action: () => this.router.navigate(section.url).catch()
            });
          }
          section.children.slice().reverse().forEach(page => {
            this.actions.subscribe({
              key: 'Zu ' + section.title + ' → ' + page.title,
              action: () => this.router.navigate(page.url).catch()
            });
          });
        });
      }),
      this.auth.$routes.subscribe(routes => {
        this.$menu.getValue().forEach(section => {
          if (section.url && section.url.length > 0) {
            this.actions.unsubscribe('Zu ' + section.title);
          }
          section.children.forEach(page => {
            this.actions.unsubscribe('Zu ' + section.title + ' → ' + page.title);
          });
        });

        const admin = this.auth.$isAdmin.getValue();
        const menu = [];
        const dashboard = { ...this.sitemap.PROJEKTE.Pages.DASHBOARD, children: [] };
        if (dashboard && (admin || this.auth.access(dashboard.url.join('/')), routes)) {
          menu.push(dashboard);
        }
        const projekte = { ...this.sitemap.PROJEKTE.Pages.PROJEKTE, children: [] };
        if (projekte && (admin || this.auth.access(projekte.url.join('/')), routes)) {
          menu.push(projekte);
        }
        const touren = { ...this.sitemap.BESICHTIGUNGEN.Pages.TOUREN, children: [] };
        if (touren && (admin || this.auth.access(touren.url.join('/')), routes)) {
          menu.push(touren);
        }
        const finanzen = { ...this.sitemap.FINANZEN.Pages.FINANZEN, children: [] };
        if (finanzen && (admin || this.auth.access(finanzen.url.join('/')), routes)) {
          menu.push(finanzen);
        }
        const kunden = { ...this.sitemap.KUNDEN.Pages.KUNDEN, children: [] };
        if (kunden && (admin || this.auth.access(kunden.url.join('/')), routes)) {
          menu.push(kunden);
        }
        const markt = { ...this.sitemap.MARKT.Pages.MARKT_BERICHTE, children: [] };
        if (markt && (admin || this.auth.access(markt.url.join('/')), routes)) {
          menu.push(markt);
        }
        const formulare = { ...this.sitemap.FORMULARE.Pages.FORMULARE, children: [] };
        if (formulare && (admin || this.auth.access(formulare.url.join('/')), routes)) {
          menu.push(formulare);
        }
        const texte = { ...this.sitemap.TEXTE.Pages.VORLAGEN, children: [] };
        if (texte && (admin || this.auth.access(texte.url.join('/')), routes)) {
          menu.push(texte);
        }
        const zeiten = { ...this.sitemap.ZEITEN.Pages.ZEITEN, children: [] };
        if (zeiten && (admin || this.auth.access(zeiten.url.join('/')), routes)) {
          menu.push(zeiten);
        }
        const auswertungen = { ...this.sitemap.AUSWERTUNGEN.Pages.AUSWERTUNGEN, children: [] };
        if (auswertungen && (admin || this.auth.access(auswertungen.url.join('/')), routes)) {
          menu.push(auswertungen);
        }
        this.$menu.next(menu);

      }),
      combineLatest([
        this.$slot,
        interval(1000),
      ]).subscribe(([slot]) => {
        if (slot && !slot.paused) {
          const work = this.$work.getValue() + 1;
          this.$work.next(work);
          this.$working.next(formatDistance(0, work * 1000, { includeSeconds: false, locale: de }))
        } else if (slot) {
          const pause = this.$pause.getValue() + 1;
          this.$pause.next(pause);
          this.$pausing.next(formatDistance(0, pause * 1000, { includeSeconds: false, locale: de }))
        }
      }),
/*    subscriptions >> */
      ]);
/* << init    */
/*    init >> */
  }

  ngOnDestroy() {
    this.subscriptions.forEach($ => $.unsubscribe());
/* << destroy    */
/*    destroy >> */
  }

/* << methods    */
  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  startClock() {
    if (!this.$slot.getValue()) {
      this.$slot.next({
        startedAt: new Date,
        paused: false
      })
      this.$work.next(0);
      this.$pause.next(0);
      this.$working.next('');
      this.$pausing.next('');
      this.nzPopoverVisible = false;
    }
  }

  toggleClock() {
    const slot = this.$slot.getValue();
    if (slot && slot.paused) {
      this.toggleBreak();
    } else if (slot) {
      this.addTimeSlot(this.$work.getValue());
    }
  }

  toggleBreak() {
    const slot = this.$slot.getValue();
    this.$slot.next({ ...slot, paused: !slot.paused });
  }

  addTimeSlot(seconds: number) {
    this.$slot.next(null);
    this.$seconds.next(seconds);
  }
/*    methods >> */

}
