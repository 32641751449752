import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { KundenCommandsModule } from './commands'
import {
  KundenQuerysModule,
  KundeService,
  KundenService
} from './querys'
import {
  KundenArtsServiceModule, KundenArtsService,
  KundenGruppesServiceModule, KundenGruppesService
} from './settings';

const routes: Routes = [
  {
    path: 'kunden',
    loadChildren: () => import('./pages').then(m => m.KundenPagesModule)
  },
  {
    path: 'kunden',
    children: [
      {
        path: 'settings',
        loadChildren: () => import('./settings').then(m => m.KundenSettingsModule),
      }
    ]
  },
];

@NgModule({
  imports: [
    KundenCommandsModule,
    KundenQuerysModule,
    KundenArtsServiceModule,
    KundenGruppesServiceModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    KundenCommandsModule,
    KundenQuerysModule,
    KundenArtsServiceModule,
    KundenGruppesServiceModule
  ]
})
export class KundenModule {
  static forRoot(): ModuleWithProviders< KundenModule> {
    return {
      ngModule: KundenModule,
      providers: [
        KundeService,
        KundenService,
        KundenArtsService,
        KundenGruppesService
      ]
    };
  }
}
