import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostDokumentHinterlegenCommandServiceModule } from '../';
import { PostDokumentHinterlegenCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostDokumentHinterlegenCommandServiceModule
  ],
  declarations: [PostDokumentHinterlegenCommandComponent],
  exports: [PostDokumentHinterlegenCommandComponent],

})
export class PostDokumentHinterlegenCommandComponentModule { }
