import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostRechnungenHochzaehlenCommandServiceModule } from '../';
import { PostRechnungenHochzaehlenCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostRechnungenHochzaehlenCommandServiceModule
  ],
  declarations: [PostRechnungenHochzaehlenCommandComponent],
  exports: [PostRechnungenHochzaehlenCommandComponent],

})
export class PostRechnungenHochzaehlenCommandComponentModule { }
