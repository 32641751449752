import { InjectionToken } from "@angular/core";

export const APP_CONFIG = new InjectionToken("app.config");
export const APP_TITLE = new InjectionToken("app.title");
export const APP_VERSION = new InjectionToken("app.version");
export const APP_BUILD = new InjectionToken("app.build");
export interface IAppConfig {
  production: boolean;
  mode: string;
  stage: string|'production'|'staging'|'development';
  admin: boolean,
  no401?: boolean,
  serverURL: string;
  appInsights: {
    instrumentationKey: string;
  };
  auth: {
    principal: string;
    clientId: string;
    authority: string;
  };
};


