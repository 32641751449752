import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { first, map, debounceTime, distinctUntilChanged } from "rxjs/operators";

import { isEqual } from "lodash";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { FilterPipe, SearchPipe, SorterPipe } from "@lib/filter/frontend";
import { IPersistedFilter, IPersistedFilterList } from "@lib/filter/types";
import { ISelection } from "@lib/persistence/types";

import {
  IKundenRequest,
  IKundenResponse,
  IKundenResponseGroup,
  IKundenQuerySorter,
  IKundenQueryFilter,
  IKundenQueryFilterShapes
} from '@fa-kt-evaluation/kunden/types';

@Injectable({
  providedIn: 'root'
})
export class KundenService {

  protected route = 'kunden/kunden';
  protected dependencies = [
    'kunden/written-kunden-art',
    'kunden/removed-kunden-art',
    'kunden/written-kunden-gruppe',
    'kunden/removed-kunden-gruppe',

    'finanzen/extra-vereinbarung-entfernt',
    'finanzen/extra-vereinbarung-gespeichert',
    'finanzen/honorar-vereinbarung-entfernt',
    'finanzen/honorar-vereinbarung-gespeichert',
    'finanzen/rechnung-erstellt',
    'finanzen/zahlungseingang-entfernt',
    'finanzen/zahlungseingang-gespeichert',
    'formulare/formular-entfernt',
    'formulare/formular-gespeichert',
    'formulare/formular-kopiert',
    'kommunikation/erinnerung-abgemeldet',
    'kommunikation/erinnerung-entfernt',
    'kommunikation/erinnerung-gemerkt',
    'kommunikation/vermerk-entfernt',
    'kommunikation/vermerkt',
    'kunden/abteilung-entfernt',
    'kunden/abteilung-gespeichert',
    'kunden/fond-entfernt',
    'kunden/fond-gespeichert',
    'kunden/kunde-entfernt',
    'kunden/kunde-gespeichert',
    'kunden/kunden-finanzen-gespeichert',
    'projekte/projekt-archiviert',
    'projekte/projekt-entfernt',
    'projekte/projekt-gespeichert',
    'projekte/projekt-kopiert',
    'projekte/projekt-reaktiviert',
    'texte/vorlage-entfernt',
    'texte/vorlage-gespeichert',
    'zeiten/zeit-entfernt',
    'zeiten/zeit-gespeichert'
  ];

  readonly $result = new BehaviorSubject<IKundenResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IKundenRequest|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IKundenQueryFilterShapes|undefined>(undefined);
  readonly $response = new BehaviorSubject<IKundenResponse|undefined>(undefined);
  readonly $filter = new BehaviorSubject<IKundenQueryFilter>({});
  readonly $sorter = new BehaviorSubject<IKundenQuerySorter>({});
  readonly $search = new BehaviorSubject<string>('');
  readonly $presets = new BehaviorSubject<IPersistedFilter<IKundenResponse>[]>([]);
  readonly $preset = new BehaviorSubject<IPersistedFilter<IKundenResponse>|undefined>(undefined);
  private readonly $filtered = new BehaviorSubject<IKundenResponse|undefined>(undefined);
  private readonly $searched = new BehaviorSubject<IKundenResponse|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private device: DeviceConfigService,
    private search: SearchPipe,
    private filter: FilterPipe,
    private sorter: SorterPipe,
  ) {
/* << changes    */
/*    changes >> */
    combineLatest([
      this.$response,
      this.$filter,
    ]).subscribe(([response, filter]) => {
      if (response && response.kunden) {
        const kunden = this.filter.transform(response.kunden, filter);
        this.$filtered.next( { ...response, kunden });
      } else {
        this.$filtered.next(response);
      }
    });
    combineLatest([
      this.$filtered,
      this.$search.pipe(debounceTime(device.debounceTime), distinctUntilChanged()),
      this.$shapes.pipe(distinctUntilChanged((a, b) => isEqual(a, b)))
    ]).subscribe(([filtered, search, shapes]) => {
      if (filtered && filtered.kunden) {
        const kunden = this.search.transform(filtered.kunden, search, '_search', shapes);
        this.$searched.next( { ...filtered, kunden });
      } else {
        this.$searched.next(filtered);
      }
    });
    combineLatest([
      this.$searched,
      this.$sorter.pipe(distinctUntilChanged((a, b) => isEqual(a, b))),
      this.$shapes.pipe(distinctUntilChanged((a, b) => isEqual(a, b)))
    ]).pipe(debounceTime(10)).subscribe(([sorted, sorter, shapes]) => {
      if (sorted && sorted.kunden) {
        const kunden = this.sorter.transform(sorted.kunden, sorter, shapes);
        this.$result.next( { ...sorted, kunden });
      } else {
        this.$result.next(sorted);
      }
    });
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IKundenRequest): IKundenRequest {
    if (payload) {
      this.$payload.next(payload as IKundenRequest);
    } else {
      payload = this.$payload.getValue() as IKundenRequest;
    }
    return payload;
  }

  public async request(payload: IKundenRequest, force = false): Promise<IKundenResponse> {
    const state = this.$response.getValue();
    if (!force && !this.device.alwaysRefresh && state && isEqual(payload, this.payload())) { return state; }
    payload = this.payload(payload);
    if (!payload) { return this.$response.getValue() as IKundenResponse; }
    const query = this.http.serialize(payload);
    const { response, shapes, filter } = await this.http.get<IKundenResponseGroup>(this.route + query);
    this.$response.next(response);
    this.$shapes.next(shapes);
    this.$presets.next(filter && filter.filters ? filter.filters : []);
    return response;
  }

  public async writeFilter(filter: IPersistedFilter<IKundenResponse>): Promise<IPersistedFilter<IKundenResponse>|undefined> {
    filter = { ...filter, term: this.$search.getValue(), sorter: this.$sorter.getValue(), filter: this.$filter.getValue() };
    const result = await this.http.post(this.route + '/filters', filter) as IPersistedFilterList<IKundenResponse>;
    filter = result.filters.find(f => f.name === filter.name) as IPersistedFilter<IKundenResponse>;
    this.$presets.next(result.filters ? result.filters : []);
    this.setFilter(filter);
    return filter;
  }

  public async deleteFilter(id: string): Promise<void> {
    const result = await this.http.delete(this.route + '/filters?id=' + id) as IPersistedFilterList<IKundenResponse>;
    this.$presets.next(result.filters ? result.filters : []);
    this.setFilter();
  }

  public setFilter(filter?: IPersistedFilter<IKundenResponse>): void {
    this.$search.next(filter && filter.term ? filter.term : '');
    this.$filter.next(filter && filter.filter ? filter.filter : {});
    this.$sorter.next(filter && filter.sorter ? filter.sorter : {});
    this.$preset.next(filter ? filter : undefined);
  }


  getKundeKundenArt(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['kunde.kundenArt']?.find(v => v.value === value)
  }

  get kundeKundenArt(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['kunde.kundenArt'] as ISelection[] : []));
  }

  getKundeKundenGruppe(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['kunde.kundenGruppe']?.find(v => v.value === value)
  }

  get kundeKundenGruppe(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['kunde.kundenGruppe'] as ISelection[] : []));
  }


}
