<!---
  <div formGroupName="abschnitt">
    <input class="cdk-visually-hidden" formControlName="id" id="id"/>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="vorlage">{{ command.Inputs.VORLAGE.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="VorlageErrorTip">
        <ng-template #VorlageErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.VORLAGE.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <codeboard-select-value formControlName="vorlage" id="vorlage" [shapes]="postAbschnitt.abschnittVorlage | async" [placeholder]="command.Inputs.VORLAGE.placeholder" cdkFocusInitial required=true ></codeboard-select-value>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="platzhalter">{{ command.Inputs.PLATZHALTER.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="PlatzhalterErrorTip">
        <ng-template #PlatzhalterErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.PLATZHALTER.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="platzhalter" id="platzhalter" [placeholder]="command.Inputs.PLATZHALTER.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">{{ command.Inputs.NAME.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NameErrorTip">
        <ng-template #NameErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NAME.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <input nz-input formControlName="name" id="name" [placeholder]="command.Inputs.NAME.placeholder"/>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item style="width: 100%;">
      <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="text">{{ command.Inputs.TEXT.title}}</nz-form-label>
      <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="TextErrorTip">
        <ng-template #TextErrorTip let-control>
          <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.TEXT.hint }}</ng-container>
          <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
        </ng-template>
      <textarea nz-input formControlName="text" id="text" [placeholder]="command.Inputs.TEXT.placeholder"></textarea>
      </nz-form-control>
    </nz-form-item>
  </div>
 -->
<nz-spin [nzSpinning]="$loading | async">
  <form nz-form [formGroup]="form" (ngSubmit)="submit()">
<!--- << custom    -->

    <div formGroupName="abschnitt">
      <input class="cdk-visually-hidden" formControlName="id" id="id"/>
      <input class="cdk-visually-hidden" formControlName="vorlage" id="vorlage"/>
      <input class="cdk-visually-hidden" formControlName="order" id="order"/>

      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">{{ command.Inputs.NAME.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="NameErrorTip">
          <ng-template #NameErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.NAME.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input formControlName="name" id="name" [placeholder]="command.Inputs.NAME.placeholder"/>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="6" [nzXs]="24" nzRequired nzFor="platzhalter">{{ command.Inputs.PLATZHALTER?.title}}</nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="PlatzhalterErrorTip">
          <ng-template #PlatzhalterErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.PLATZHALTER?.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>
          <input nz-input formControlName="platzhalter" id="platzhalter" [placeholder]="command.Inputs.PLATZHALTER?.placeholder"/>
        </nz-form-control>
      </nz-form-item>

      <nz-divider id="inhalt" nzText="Inhalt" nzOrientation="left"></nz-divider>
      <nz-form-item style="width: 100%;">
        <nz-form-label [nzOffset]="2" [nzSm]="3" [nzXs]="24" nzFor="text">{{ command.Inputs.TEXT?.title}}</nz-form-label>
        <nz-form-control [nzSm]="15" [nzXs]="24" [nzErrorTip]="TextErrorTip">
          <ng-template #TextErrorTip let-control>
            <ng-container *ngIf="control.hasError('required')">{{ command.Inputs.TEXT?.hint }}</ng-container>
            <ng-container *ngIf="control.hasError('server')">{{ control?.getError('server') }}</ng-container>
          </ng-template>

          <codeboard-text-editor [felder]="felder" [object]="object" formControlName="text" id="text" [placeholder]="command.Inputs.TEXT.placeholder"></codeboard-text-editor>
        </nz-form-control>
      </nz-form-item>

    </div>
<!---    custom >> -->
    <nz-form-item>
      <nz-form-control>
        <button nz-button nzType="primary" class="save-button"
          type="submit"
          [nzLoading]="$loading | async" [disabled]="!form.valid || ($loading | async)"
          nz-tooltip [nzTooltipTitle]="SubmitTooltip">
<!--- << confirm    -->
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Speichern
<!---    confirm >> -->
          <ng-template #SubmitTooltip>
<!--- << hint    -->
            Abschnitt speichern
<!---    hint >> -->
          </ng-template>
        </button>
      </nz-form-control>
    </nz-form-item>
  </form>
</nz-spin>
