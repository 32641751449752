import { Component } from '@angular/core';
import { FormulareService } from "@fa-kt-evaluation/formulare/frontend";

/* << imports    */
 
/*    imports >> */
@Component({
  selector: 'fa-kt-evaluation-saved-formulare-filters',
  templateUrl: './saved-formulare-filters.component.html',
  styleUrls: ['./saved-formulare-filters.component.css']
})
export class SavedFormulareFiltersComponent {

  constructor(
    public formulare: FormulareService
/* << injection    */
/*    injection >> */
  ) {
  }
}