import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { DeleteKundeCommandServiceModule } from '../';
import { DeleteKundeCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteKundeCommandServiceModule
  ],
  declarations: [DeleteKundeCommandComponent],
  exports: [DeleteKundeCommandComponent],

})
export class DeleteKundeCommandComponentModule { }
