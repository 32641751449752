import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { TourServiceModule } from '../';
import { SavedTourFiltersComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    TourServiceModule
  ],
  declarations: [SavedTourFiltersComponent],
  exports: [SavedTourFiltersComponent],

})
export class SavedTourFiltersComponentModule { }
