import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { PersonenCommandsModule } from './commands'
import {
  PersonenQuerysModule,
  IchService,
  MitarbeiterService,
  ProfilService
} from './querys'
import {
  RollesServiceModule, RollesService,
  StandortsServiceModule, StandortsService
} from './settings';

const routes: Routes = [
  {
    path: 'personen',
    loadChildren: () => import('./pages').then(m => m.PersonenPagesModule)
  },
  {
    path: 'personen',
    children: [
      {
        path: 'settings',
        loadChildren: () => import('./settings').then(m => m.PersonenSettingsModule),
      }
    ]
  },
];

@NgModule({
  imports: [
    PersonenCommandsModule,
    PersonenQuerysModule,
    RollesServiceModule,
    StandortsServiceModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    PersonenCommandsModule,
    PersonenQuerysModule,
    RollesServiceModule,
    StandortsServiceModule
  ]
})
export class PersonenModule {
  static forRoot(): ModuleWithProviders< PersonenModule> {
    return {
      ngModule: PersonenModule,
      providers: [
        IchService,
        MitarbeiterService,
        ProfilService,
        RollesService,
        StandortsService
      ]
    };
  }
}
