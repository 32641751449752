<nz-spin [nzSpinning]="!!($waitingForSecrets | async)" nzTip="Initiieren">
  <ng-container *ngIf="mode === 'draw'">
    <input nz-input nzBorderless [ngModel]="$search | async" (ngModelChange)="$search.next($event)" [ngModelOptions]="{ standalone: true }"
           placeholder="Suche nach Adressen" name="xyz123" id="xyz123" autocomplete="xyz123" [nzAutocomplete]="AddressSuggestions" cdkFocusInitial/>
    <nz-autocomplete #AddressSuggestions nzBackfill nzDefaultActiveFirstOption="true" nzWidth="364px">
      <nz-auto-option *ngFor="let suggestion of $suggestions | async" [nzValue]="suggestion.address.freeformAddress" [nzLabel]="suggestion.address.freeformAddress">
        <span nz-typography nzType="primary" (click)="setSuggestion(suggestion)">{{ suggestion.address.freeformAddress }}</span>
      </nz-auto-option>
    </nz-autocomplete>
  </ng-container>
  <div #AddressMap [style.minWidth]="width" [style.width]="'100%'" [style.minHeight]="height" [style.height]="'100%'"></div>
  <ng-container *ngIf="mode === 'draw'">
    <button type="button" nz-button [nzBlock]="true" [nzType]="drawing ? 'primary' : 'default'" (click)="toggleDrawing()">
      <i nz-icon [nzType]="drawing ? 'check-circle' : 'edit'" nzTheme="outline"></i>
    </button>
  </ng-container>

</nz-spin>
