<nz-content style="padding: 64px">
  <nz-row>
    <nz-col>
      <nz-breadcrumb>
        <nz-breadcrumb-item> <span nz-typography nzType="warning">404</span></nz-breadcrumb-item>
      </nz-breadcrumb>
    </nz-col>
  </nz-row>
  <nz-row>
    <nz-col>
      <h1 nz-title>Seite nicht gefunden</h1>
      <img src="assets/404.png" class="logo" alt="404 Bild - nicht gefunden" style="cursor: pointer"/>
    </nz-col>
  </nz-row>
</nz-content>
