import { Component } from '@angular/core';
import { GutachtenService } from "@fa-kt-evaluation/gutachten/frontend";

/* << imports    */
 
/*    imports >> */
@Component({
  selector: 'fa-kt-evaluation-saved-gutachten-filters',
  templateUrl: './saved-gutachten-filters.component.html',
  styleUrls: ['./saved-gutachten-filters.component.css']
})
export class SavedGutachtenFiltersComponent {

  constructor(
    public gutachten: GutachtenService
/* << injection    */
/*    injection >> */
  ) {
  }
}