import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { OffeneGutachtenServiceModule } from '../';
import { OffeneGutachtenFilterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    OffeneGutachtenServiceModule
  ],
  declarations: [OffeneGutachtenFilterComponent],
  exports: [OffeneGutachtenFilterComponent],

})
export class OffeneGutachtenFilterComponentModule { }
