import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from "@angular/core";
import {
  ControlValueAccessor, FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators
} from "@angular/forms";

import { IAzureMapsSuggestion, IAddress, IAzureMapsPosition } from "@lib/geo/types";
import { AtlasService } from "@lib/geo/frontend";

import { BehaviorSubject, combineLatest, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";


@Component({
  selector: 'codeboard-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressInputComponent),
      multi: true
    }
  ]
})
export class AddressInputComponent implements OnInit, ControlValueAccessor {
  subscriptions: Subscription[] = [];

  form: FormGroup;
  $search: BehaviorSubject<string> = new BehaviorSubject<string>('');
  $suggestions: BehaviorSubject<IAzureMapsSuggestion[]> = new BehaviorSubject<IAzureMapsSuggestion[]>([]);
  $coordinates: BehaviorSubject<'clear'|IAzureMapsPosition|null> = new BehaviorSubject<'clear'|IAzureMapsPosition|null>(null)
  onChange: any = () => {};
  onTouched: any = () => {};

  @Input() set value(value: IAddress|undefined) {
    this.onChange(value);
    this.onTouched();
    if (!value) { return; }
    this.form.patchValue(value);
    if (value && (value as any) !== {}) {
      this.form.markAllAsTouched();
    }
  }
  get value(): IAddress {
    return this.form.value;
  }

  @Input() placeholder = '';
  @Input() set required(required: boolean) {
    const validators = Boolean(required) ? [Validators.required] : [];
    this.form.controls.land.setValidators(validators);
    this.form.controls.plz.setValidators([...validators, Validators.pattern(this.atlas.plzPattern)]);
    this.form.controls.strasse.setValidators(validators);
    this.form.controls.latitude.setValidators(validators);
    this.form.controls.longitude.setValidators(validators);
    this.form.setErrors(null);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.nzRequired = required;
  };
  public nzRequired = false;

  constructor(private formBuilder: FormBuilder, public atlas: AtlasService) {
    this.form = this.formBuilder.group({
      land: [''],
      bundesland: ['', []],
      kreis: ['', []],
      gemeinde_stadt: ['', []],
      ort_stadt_teil: ['', []],
      plz: ['', [Validators.pattern(this.atlas.plzPattern)]],
      strasse: [''],
      extra: [''],
      regierungsbezirk: ['', []],
      verband: ['', []],
      regionalSchluessel: ['', []],
      bundeslandKennzahl: ['', [Validators.maxLength(this.atlas.bundeslandKennzahlLength), Validators.pattern(this.atlas.keyPattern)]],
      regierungsbezirkKennzahl: ['', [Validators.maxLength(this.atlas.regierungsbezirkKennzahlLength), Validators.pattern(this.atlas.keyPattern)]],
      kreisKennzahl: ['', [Validators.maxLength(this.atlas.kreisKennzahlLength), Validators.pattern(this.atlas.keyPattern)]],
      verbandKennzahl: ['', [Validators.maxLength(this.atlas.verbandKennzahlLength), Validators.pattern(this.atlas.keyPattern)]],
      gemeindeKennzahl: ['', [Validators.maxLength(this.atlas.gemeindeKennzahlLength), Validators.pattern(this.atlas.keyPattern)]],
      latitude: [null],
      longitude: [null]
    });
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.form.valueChanges.subscribe((address: IAddress) => {
        this.onChange(address);
        this.onTouched();
        this.form.markAsDirty();
        this.form.markAllAsTouched();
        this.$coordinates.next(address.latitude && address.longitude ? { lat: address.latitude, lon: address.longitude, draggable: true } : null);
      }),
      combineLatest([
        this.form.controls.strasse.valueChanges.pipe(distinctUntilChanged()),
        this.form.controls.plz.valueChanges.pipe(distinctUntilChanged()),
        this.form.controls.land.valueChanges.pipe(distinctUntilChanged()),
      ]).subscribe(([strasse, plz, land]) => {
        this.form.patchValue({ latitude: '', longitude: '' });
        this.$search.next(`${strasse ? strasse + ', ' : ''}${ plz ? plz + ' ' : ''}${ land ? land : ''}`)
      }),
      combineLatest([
        this.form.controls.bundeslandKennzahl.valueChanges.pipe(distinctUntilChanged()),
        this.form.controls.regierungsbezirkKennzahl.valueChanges.pipe(distinctUntilChanged()),
        this.form.controls.kreisKennzahl.valueChanges.pipe(distinctUntilChanged()),
        this.form.controls.verbandKennzahl.valueChanges.pipe(distinctUntilChanged()),
        this.form.controls.gemeindeKennzahl.valueChanges.pipe(distinctUntilChanged()),
      ]).subscribe((state) => {
        this.form.patchValue({ regionalSchluessel: state.filter(v => v).join(' ') })
      }),
      this.$search.pipe(
        debounceTime(200),
        distinctUntilChanged()
      ).subscribe(async (term: string) => {
        const suggestions = await this.atlas.suggestByTerm(term);
        if (suggestions && suggestions.length > 0) {
          // await this.setSuggestion(suggestions[0]);
        }
        this.$suggestions.next(suggestions)
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


  async moveCoordinates($event: IAzureMapsPosition) {
    const suggestions = await this.atlas.suggestByCoordinates($event.lon, $event.lat);
    const current = this.$coordinates.getValue();
    if (suggestions.length === 0 && current) {
      this.$coordinates.next('clear');
      setTimeout(() => this.$coordinates.next(current), 100)
    } else if (suggestions[0] && suggestions[0].position.lat && suggestions[0].position.lon) {
      await this.setSuggestion(suggestions[0]);
    }
  }

  async setSuggestion(suggestion: IAzureMapsSuggestion) {
    if (suggestion) {
      this.form.patchValue({
      ...this.value,
      land: suggestion.address.country ? suggestion.address.country : null,
      bundesland: suggestion.address.countrySubdivision ? suggestion.address.countrySubdivision : null,
      kreis: suggestion.address.countrySecondarySubdivision ? suggestion.address.countrySecondarySubdivision : null,
      gemeinde_stadt: suggestion.address.municipality ? suggestion.address.municipality : null,
      ort_stadt_teil: suggestion.address.municipalitySubdivision ? suggestion.address.municipalitySubdivision : null,
      plz: suggestion.address.postalCode ? suggestion.address.postalCode : null,
      strasse: suggestion.address.streetAddress ? suggestion.address.streetAddress : null,
      latitude: suggestion.position ? suggestion.position.lat : null,
      longitude: suggestion.position ? suggestion.position.lon : null
    });
    }
    this.atlas.suggestRegionalSchluessel(suggestion.address.freeformAddress).then((schluessel: any) => {
      if (schluessel) {
        this.form.patchValue({
          ...schluessel,
        });
      }
    });
  }

  get regionalSchluessel(): FormControl {
    return this.form.get("regionalSchluessel") as FormControl
  }


  next($event: KeyboardEvent, current: HTMLInputElement, expected: number, next: HTMLInputElement) {
    if ($event.which !== 16 && $event.which !== 9 && current && current.value && current.value.length >= expected) {
      next.focus()
    }
  }

  fill(field: string, value: string, length: number) {
    while (value.length < length) {
      value = '0' + value;
    }
    // this.form.patchValue({ [field]: value})
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: IAddress | null) {
    if (value) {
      this.value = value;
    }
    if (value === null) {
      this.form.reset();
    }
  }

  validate(fc: FormControl) {
    return this.form.valid ? null : { address: { valid: false } };
  }

  setDisabledState(isDisabled: boolean): void {
  }
}
