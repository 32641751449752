import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IPostMitarbeiterRequest, IPostMitarbeiterResponse, IPostMitarbeiterCommandShapes } from '@fa-kt-evaluation/personen/types';

@Injectable({
  providedIn: 'root'
})
export class PostMitarbeiterCommandService {

  readonly route = 'personen/mitarbeiter';
  protected dependencies = [
      'personen/written-rolle',
      'personen/removed-rolle',
      'personen/written-standort',
      'personen/removed-standort',
      'personen/mitarbeiter-aktualisiert'
  ];

  readonly $shapes = new BehaviorSubject<IPostMitarbeiterCommandShapes|undefined>(undefined);
  
  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IPostMitarbeiterCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IPostMitarbeiterCommandShapes>(this.route + '/post/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IPostMitarbeiterRequest): Promise<IPostMitarbeiterResponse> {
    return await this.http.post<IPostMitarbeiterResponse>(this.route, payload) as unknown as IPostMitarbeiterResponse;
  }
  
  getMitarbeiterRollen(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['mitarbeiter.rollen']?.find(v => v.value === value)
  }

  get mitarbeiterRollen(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['mitarbeiter.rollen'] as ISelection[] : []));
  }

  getMitarbeiterStandort(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['mitarbeiter.standort']?.find(v => v.value === value)
  }

  get mitarbeiterStandort(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['mitarbeiter.standort'] as ISelection[] : []));
  }

}
