import { Component, OnInit, OnDestroy, Inject, Input, Output, EventEmitter, Injector, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { NzMessageService } from "ng-zorro-antd/message";

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { AuthService } from "@lib/auth/frontend";
import {
  ActionService,
  cleanObject, toInitials,
  CustomValidators, CustomFormatter, 
  ISitemap, ISitemapCommand, SITEMAP,
} from "@lib/common/frontend";

import { IPostKundeRequest, IPostKundeResponse } from "@fa-kt-evaluation/kunden/types";
import { PostKundeCommandService } from '@fa-kt-evaluation/kunden/frontend'

/* << importing    */
/*    importing >> */

@Component({
  selector: 'fa-kt-evaluation-post-kunde',
  templateUrl: './post-kunde.component.html',
  styleUrls: ['./post-kunde.component.css']
})
export class PostKundeCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) { this.$loading.next(this.$loading.getValue() || loading); }
  @Input() set value(value: IPostKundeRequest) { this.patch(value); }
  @Output() valueChanged: EventEmitter<IPostKundeRequest> = new EventEmitter<IPostKundeRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostKundeResponse> = new EventEmitter<IPostKundeResponse>();

/* << fields    */
/*    fields >> */

  get modalRef() {
    return this.injector.get(NzModalRef)
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public postKunde: PostKundeCommandService,
/* << more    */
/*    more >> */
  ) {
    this.command = sitemap.KUNDEN.Commands.POST_KUNDE;
    this.form = this.fb.group({
      kunde: this.fb.group({
        id: [null, []],
        name: [null, [Validators.required]],
        initialen: [null, [Validators.required]],
        kundenGruppe: [null, [Validators.required]],
        kundenArt: [null, []],
        addresse: [],
        website: [null, []],
        outlookKontakte: [[], []],
        kontaktNotizen: [null, []],
        anforderungenNotizen: [null, []],
        rechnungNotizen: [null, []],
        fahrtkostenAbrechnung: [null, []],
        steuerId: [null, []],
        fahrtkostenAbRadius: [null, []],
        fahrtkostenProKm: [null, []],
        fahrtkostenProStunde: [null, []],
        skonto: [null, []],
        zahlungsziel: [null, []],
        tageOhneSkonto: [null, []],
        aktiv: [null, []],
        dateipfad: [null, []],
        loraName: [null, []],
        unterlagenEmail: [null, []],
      })
    });
/* << constructor    */
/*    constructor >> */
    this.patch();
  }

  ngOnInit() {
    this.postKunde.prepare().catch(e => console.error(e));
    this.subscriptions.push(
      this.finished.subscribe((result) => { if (this.modalRef) { this.modalRef.destroy(result); }}),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() })
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostKundeRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),
      this.kundeId?.valueChanges.subscribe(async (id) => {
/* << on-kunde-id-change    */
/*    on-kunde-id-change >> */
      }) as Subscription,
      this.kundeName?.valueChanges.subscribe(async (name) => {
/* << on-kunde-name-change    */
/*    on-kunde-name-change >> */
      }) as Subscription,
      this.kundeInitialen?.valueChanges.subscribe(async (initialen) => {
/* << on-kunde-initialen-change    */
/*    on-kunde-initialen-change >> */
      }) as Subscription,
      this.kundeKundenGruppe?.valueChanges.subscribe(async (kundenGruppe) => {
/* << on-kunde-kunden-gruppe-change    */
/*    on-kunde-kunden-gruppe-change >> */
      }) as Subscription,
      this.kundeKundenArt?.valueChanges.subscribe(async (kundenArt) => {
/* << on-kunde-kunden-art-change    */
/*    on-kunde-kunden-art-change >> */
      }) as Subscription,
      this.kundeAddresse?.valueChanges.subscribe(async (addresse) => {
/* << on-kunde-addresse-change    */
/*    on-kunde-addresse-change >> */
      }) as Subscription,
      this.kundeWebsite?.valueChanges.subscribe(async (website) => {
/* << on-kunde-website-change    */
/*    on-kunde-website-change >> */
      }) as Subscription,
      this.kundeOutlookKontakte?.valueChanges.subscribe(async (outlookKontakte) => {
/* << on-kunde-outlook-kontakte-change    */
/*    on-kunde-outlook-kontakte-change >> */
      }) as Subscription,
      this.kundeKontaktNotizen?.valueChanges.subscribe(async (kontaktNotizen) => {
/* << on-kunde-kontakt-notizen-change    */
/*    on-kunde-kontakt-notizen-change >> */
      }) as Subscription,
      this.kundeAnforderungenNotizen?.valueChanges.subscribe(async (anforderungenNotizen) => {
/* << on-kunde-anforderungen-notizen-change    */
/*    on-kunde-anforderungen-notizen-change >> */
      }) as Subscription,
      this.kundeRechnungNotizen?.valueChanges.subscribe(async (rechnungNotizen) => {
/* << on-kunde-rechnung-notizen-change    */
/*    on-kunde-rechnung-notizen-change >> */
      }) as Subscription,
      this.kundeFahrtkostenAbrechnung?.valueChanges.subscribe(async (fahrtkostenAbrechnung) => {
/* << on-kunde-fahrtkosten-abrechnung-change    */
/*    on-kunde-fahrtkosten-abrechnung-change >> */
      }) as Subscription,
      this.kundeSteuerId?.valueChanges.subscribe(async (steuerId) => {
/* << on-kunde-steuer-id-change    */
/*    on-kunde-steuer-id-change >> */
      }) as Subscription,
      this.kundeFahrtkostenAbRadius?.valueChanges.subscribe(async (fahrtkostenAbRadius) => {
/* << on-kunde-fahrtkosten-ab-radius-change    */
/*    on-kunde-fahrtkosten-ab-radius-change >> */
      }) as Subscription,
      this.kundeFahrtkostenProKm?.valueChanges.subscribe(async (fahrtkostenProKm) => {
/* << on-kunde-fahrtkosten-pro-km-change    */
/*    on-kunde-fahrtkosten-pro-km-change >> */
      }) as Subscription,
      this.kundeFahrtkostenProStunde?.valueChanges.subscribe(async (fahrtkostenProStunde) => {
/* << on-kunde-fahrtkosten-pro-stunde-change    */
/*    on-kunde-fahrtkosten-pro-stunde-change >> */
      }) as Subscription,
      this.kundeSkonto?.valueChanges.subscribe(async (skonto) => {
/* << on-kunde-skonto-change    */
/*    on-kunde-skonto-change >> */
      }) as Subscription,
      this.kundeZahlungsziel?.valueChanges.subscribe(async (zahlungsziel) => {
/* << on-kunde-zahlungsziel-change    */
/*    on-kunde-zahlungsziel-change >> */
      }) as Subscription,
      this.kundeTageOhneSkonto?.valueChanges.subscribe(async (tageOhneSkonto) => {
/* << on-kunde-tage-ohne-skonto-change    */
/*    on-kunde-tage-ohne-skonto-change >> */
      }) as Subscription,
      this.kundeAktiv?.valueChanges.subscribe(async (aktiv) => {
/* << on-kunde-aktiv-change    */
/*    on-kunde-aktiv-change >> */
      }) as Subscription,
      this.kundeDateipfad?.valueChanges.subscribe(async (dateipfad) => {
/* << on-kunde-dateipfad-change    */
/*    on-kunde-dateipfad-change >> */
      }) as Subscription,
      this.kundeLoraName?.valueChanges.subscribe(async (loraName) => {
/* << on-kunde-lora-name-change    */
/*    on-kunde-lora-name-change >> */
      }) as Subscription,
      this.kundeUnterlagenEmail?.valueChanges.subscribe(async (unterlagenEmail) => {
/* << on-kunde-unterlagen-email-change    */
/*    on-kunde-unterlagen-email-change >> */
      }) as Subscription,
/* << subscibe    */
      this.name?.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged()
      ).subscribe((name: string) => {
        if (this.initialen && (!this.initialen.touched || !this.initialen.value)) {
          this.initialen?.patchValue(toInitials(name));
        }
      }) as Subscription,
/*    subscibe >> */
    );
/* << init    */
/*    init >> */
  }

  ngOnDestroy() {
/* << end    */
/*    end >> */
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IPostKundeRequest) {
    if (value) {
      value = cleanObject(value);
      if (value.kunde) {
        value.kunde = cleanObject(value.kunde);
      }
      this.form.patchValue(value);
/* << custom    */
/*    custom >> */
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload.kunde) {
        payload.kunde = cleanObject(payload.kunde);
      }
      const response: IPostKundeResponse = await this.postKunde.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of (error.errors ? error.errors : [])) {
        for (const children of (validation.children ? validation.children : [])) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

/* << methods    */

  get letzteId() {
    return this.form.controls.kunde.get('letzteId');
  }
  get initialen() {
    return this.form.controls.kunde.get('initialen');
  }
  get website() {
    return this.form.controls.kunde.get('website');
  }
  get name() {
    return this.form.controls.kunde.get('name');
  }
  get kundenGruppe() {
    return this.form.controls.kunde.get('kundenGruppe');
  }
  get kundenGruppeValue() {
    return this.kundenGruppe?.value ?? '*'
  }
  get kundenArt() {
    return this.form.controls.kunde.get('kundenArt');
  }
  get fahrtkostenAbrechnung() {
    return this.form.controls.kunde.get('fahrtkostenAbrechnung')
  }
/*    methods >> */

  get kundeId() {
    return this.form.controls.kunde.get('id');
  };
  get kundeName() {
    return this.form.controls.kunde.get('name');
  };
  get kundeInitialen() {
    return this.form.controls.kunde.get('initialen');
  };
  get kundeKundenGruppe() {
    return this.form.controls.kunde.get('kundenGruppe');
  };
  get kundeKundenArt() {
    return this.form.controls.kunde.get('kundenArt');
  };
  get kundeAddresse() {
    return this.form.controls.kunde.get('addresse');
  };
  get kundeWebsite() {
    return this.form.controls.kunde.get('website');
  };
  get kundeOutlookKontakte() {
    return this.form.controls.kunde.get('outlookKontakte');
  };
  get kundeKontaktNotizen() {
    return this.form.controls.kunde.get('kontaktNotizen');
  };
  get kundeAnforderungenNotizen() {
    return this.form.controls.kunde.get('anforderungenNotizen');
  };
  get kundeRechnungNotizen() {
    return this.form.controls.kunde.get('rechnungNotizen');
  };
  get kundeFahrtkostenAbrechnung() {
    return this.form.controls.kunde.get('fahrtkostenAbrechnung');
  };
  get kundeSteuerId() {
    return this.form.controls.kunde.get('steuerId');
  };
  get kundeFahrtkostenAbRadius() {
    return this.form.controls.kunde.get('fahrtkostenAbRadius');
  };
  get kundeFahrtkostenProKm() {
    return this.form.controls.kunde.get('fahrtkostenProKm');
  };
  get kundeFahrtkostenProStunde() {
    return this.form.controls.kunde.get('fahrtkostenProStunde');
  };
  get kundeSkonto() {
    return this.form.controls.kunde.get('skonto');
  };
  get kundeZahlungsziel() {
    return this.form.controls.kunde.get('zahlungsziel');
  };
  get kundeTageOhneSkonto() {
    return this.form.controls.kunde.get('tageOhneSkonto');
  };
  get kundeAktiv() {
    return this.form.controls.kunde.get('aktiv');
  };
  get kundeDateipfad() {
    return this.form.controls.kunde.get('dateipfad');
  };
  get kundeLoraName() {
    return this.form.controls.kunde.get('loraName');
  };
  get kundeUnterlagenEmail() {
    return this.form.controls.kunde.get('unterlagenEmail');
  };


}
