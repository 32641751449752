import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { PostRechnungCommandServiceModule } from '../';
import { PostRechnungCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    PostRechnungCommandServiceModule
  ],
  declarations: [PostRechnungCommandComponent],
  exports: [PostRechnungCommandComponent],

})
export class PostRechnungCommandComponentModule { }
