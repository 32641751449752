import { NgModule } from '@angular/core';

import {
  PostFormularKopierenCommandServiceModule,
  PostFormularKopierenCommandComponentModule
} from './';

@NgModule({
  imports: [
    PostFormularKopierenCommandServiceModule,
    PostFormularKopierenCommandComponentModule
  ],
  exports: [
    PostFormularKopierenCommandServiceModule,
    PostFormularKopierenCommandComponentModule
  ]
})
export class PostFormularKopierenCommandModule { }
