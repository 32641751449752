import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { first, map, debounceTime, distinctUntilChanged } from "rxjs/operators";

import { isEqual } from "lodash";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { IPersistedFilter, IPersistedFilterList } from "@lib/filter/types";
import { ISelection } from "@lib/persistence/types";

import {
  IVorlageRequest,
  IVorlageResponse,
  IVorlageResponseGroup,
  IVorlageQueryFilterShapes
} from '@fa-kt-evaluation/texte/types';

@Injectable({
  providedIn: 'root'
})
export class VorlageService {

  protected route = 'texte/vorlage';
  protected dependencies = [
    'gutachten/written-bewertungs-anlass',
    'gutachten/removed-bewertungs-anlass',
    'kunden/written-kunden-art',
    'kunden/removed-kunden-art',
    'kunden/written-kunden-gruppe',
    'kunden/removed-kunden-gruppe',
    'objekte/written-objekt-art',
    'objekte/removed-objekt-art',

    'texte/abschnitt-entfernt',
    'texte/abschnitt-gespeichert',
    'texte/abschnitte-sortiert',
    'texte/vorlage-entfernt',
    'texte/vorlage-gespeichert'
  ];

  readonly $result = new BehaviorSubject<IVorlageResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IVorlageRequest|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IVorlageQueryFilterShapes|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private device: DeviceConfigService,
  ) {
/* << changes    */
/*    changes >> */
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IVorlageRequest): IVorlageRequest {
    if (payload) {
      this.$payload.next(payload as IVorlageRequest);
    } else {
      payload = this.$payload.getValue() as IVorlageRequest;
    }
    return payload;
  }

  public async request(payload: IVorlageRequest, force = false): Promise<IVorlageResponse> {
    const state = this.$result.getValue();
    if (!force && !this.device.alwaysRefresh && state && isEqual(payload, this.payload())) { return state; }
    payload = this.payload(payload);
    // if (!payload) { return this.$result.getValue() as IVorlageResponse; }
    const query = this.http.serialize(payload);
    const { response, shapes } = await this.http.get<IVorlageResponseGroup>(this.route + query);
    this.$result.next(response);
    this.$shapes.next(shapes);
    return response;
  }


  getVorlageFuerBewertungsAnlaesse(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['vorlage.fuerBewertungsAnlaesse']?.find(v => v.value === value)
  }

  get vorlageFuerBewertungsAnlaesse(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['vorlage.fuerBewertungsAnlaesse'] as ISelection[] : []));
  }

  getVorlageFuerKunden(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['vorlage.fuerKunden']?.find(v => v.value === value)
  }

  get vorlageFuerKunden(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['vorlage.fuerKunden'] as ISelection[] : []));
  }

  getVorlageFuerKundenArten(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['vorlage.fuerKundenArten']?.find(v => v.value === value)
  }

  get vorlageFuerKundenArten(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['vorlage.fuerKundenArten'] as ISelection[] : []));
  }

  getVorlageFuerKundenGruppen(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['vorlage.fuerKundenGruppen']?.find(v => v.value === value)
  }

  get vorlageFuerKundenGruppen(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['vorlage.fuerKundenGruppen'] as ISelection[] : []));
  }

  getVorlageFuerObjektArten(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['vorlage.fuerObjektArten']?.find(v => v.value === value)
  }

  get vorlageFuerObjektArten(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['vorlage.fuerObjektArten'] as ISelection[] : []));
  }

  getAbschnitteVorlage(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['abschnitte.vorlage']?.find(v => v.value === value)
  }

  get abschnitteVorlage(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['abschnitte.vorlage'] as ISelection[] : []));
  }


}
