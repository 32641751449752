import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { VorlageServiceModule } from '../';
import { VorlageFilterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    VorlageServiceModule
  ],
  declarations: [VorlageFilterComponent],
  exports: [VorlageFilterComponent],

})
export class VorlageFilterComponentModule { }
