import { Component, OnInit, OnDestroy, Inject, Input, Output, EventEmitter, Injector, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { NzMessageService } from "ng-zorro-antd/message";

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { AuthService } from "@lib/auth/frontend";
import {
  ActionService,
  cleanObject, toInitials,
  CustomValidators, CustomFormatter, 
  ISitemap, ISitemapCommand, SITEMAP,
} from "@lib/common/frontend";

import { IPostAbteilungRequest, IPostAbteilungResponse } from "@fa-kt-evaluation/kunden/types";
import { PostAbteilungCommandService } from '@fa-kt-evaluation/kunden/frontend'

/* << importing    */
/*    importing >> */

@Component({
  selector: 'fa-kt-evaluation-post-abteilung',
  templateUrl: './post-abteilung.component.html',
  styleUrls: ['./post-abteilung.component.css']
})
export class PostAbteilungCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) { this.$loading.next(this.$loading.getValue() || loading); }
  @Input() set value(value: IPostAbteilungRequest) { this.patch(value); }
  @Output() valueChanged: EventEmitter<IPostAbteilungRequest> = new EventEmitter<IPostAbteilungRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostAbteilungResponse> = new EventEmitter<IPostAbteilungResponse>();

/* << fields    */
  @Input() businessHomePage = '';
  @Input() companyName = '';
/*    fields >> */

  get modalRef() {
    return this.injector.get(NzModalRef)
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public postAbteilung: PostAbteilungCommandService,
/* << more    */
/*    more >> */
  ) {
    this.command = sitemap.KUNDEN.Commands.POST_ABTEILUNG;
    this.form = this.fb.group({
      abteilung: this.fb.group({
        id: [null, []],
        kunde: [null, [Validators.required]],
        name: [null, [Validators.required]],
        ansprache: [null, []],
        outlookKontakte: [[], []],
        website: [null, []],
        adresse: [],
        aktiv: [null, []],
      })
    });
/* << constructor    */
/*    constructor >> */
    this.patch();
  }

  ngOnInit() {
    this.postAbteilung.prepare().catch(e => console.error(e));
    this.subscriptions.push(
      this.finished.subscribe((result) => { if (this.modalRef) { this.modalRef.destroy(result); }}),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() })
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostAbteilungRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),
      this.abteilungId?.valueChanges.subscribe(async (id) => {
/* << on-abteilung-id-change    */
/*    on-abteilung-id-change >> */
      }) as Subscription,
      this.abteilungKunde?.valueChanges.subscribe(async (kunde) => {
/* << on-abteilung-kunde-change    */
/*    on-abteilung-kunde-change >> */
      }) as Subscription,
      this.abteilungName?.valueChanges.subscribe(async (name) => {
/* << on-abteilung-name-change    */
/*    on-abteilung-name-change >> */
      }) as Subscription,
      this.abteilungAnsprache?.valueChanges.subscribe(async (ansprache) => {
/* << on-abteilung-ansprache-change    */
/*    on-abteilung-ansprache-change >> */
      }) as Subscription,
      this.abteilungOutlookKontakte?.valueChanges.subscribe(async (outlookKontakte) => {
/* << on-abteilung-outlook-kontakte-change    */
/*    on-abteilung-outlook-kontakte-change >> */
      }) as Subscription,
      this.abteilungWebsite?.valueChanges.subscribe(async (website) => {
/* << on-abteilung-website-change    */
/*    on-abteilung-website-change >> */
      }) as Subscription,
      this.abteilungAdresse?.valueChanges.subscribe(async (adresse) => {
/* << on-abteilung-adresse-change    */
/*    on-abteilung-adresse-change >> */
      }) as Subscription,
      this.abteilungAktiv?.valueChanges.subscribe(async (aktiv) => {
/* << on-abteilung-aktiv-change    */
/*    on-abteilung-aktiv-change >> */
      }) as Subscription,
/* << subscibe    */
/*    subscibe >> */
    );
/* << init    */
/*    init >> */
  }

  ngOnDestroy() {
/* << end    */
/*    end >> */
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IPostAbteilungRequest) {
    if (value) {
      value = cleanObject(value);
      if (value.abteilung) {
        value.abteilung = cleanObject(value.abteilung);
      }
      this.form.patchValue(value);
/* << custom    */
/*    custom >> */
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload.abteilung) {
        payload.abteilung = cleanObject(payload.abteilung);
      }
      const response: IPostAbteilungResponse = await this.postAbteilung.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of (error.errors ? error.errors : [])) {
        for (const children of (validation.children ? validation.children : [])) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

/* << methods    */
  get name() {
    return this.form.controls.abteilung.get('name');
  }
  get website() {
    return this.form.controls.abteilung.get('website');
  }
/*    methods >> */

  get abteilungId() {
    return this.form.controls.abteilung.get('id');
  };
  get abteilungKunde() {
    return this.form.controls.abteilung.get('kunde');
  };
  get abteilungName() {
    return this.form.controls.abteilung.get('name');
  };
  get abteilungAnsprache() {
    return this.form.controls.abteilung.get('ansprache');
  };
  get abteilungOutlookKontakte() {
    return this.form.controls.abteilung.get('outlookKontakte');
  };
  get abteilungWebsite() {
    return this.form.controls.abteilung.get('website');
  };
  get abteilungAdresse() {
    return this.form.controls.abteilung.get('adresse');
  };
  get abteilungAktiv() {
    return this.form.controls.abteilung.get('aktiv');
  };


}
