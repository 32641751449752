import { Injectable } from '@angular/core';
import { BlobServiceClient } from "@azure/storage-blob";

@Injectable({
  providedIn: 'root'
})
export class FileService {

  client: BlobServiceClient;

  constructor() {
    this.client = new BlobServiceClient('https://storageaccountfaktabe8d.blob.core.windows.net/?sv=2020-08-04&ss=bft&srt=co&sp=rwdlacuix&se=2100-03-08T17:40:27Z&st=2022-03-08T09:40:27Z&spr=https&sig=%2F8OaNYMBde6JoyTaeACcmdTl8aZ8xW6DkfKHPtG9Sj0%3D');
  }

  getFileUrl(path: string) {
    if (!path) { return; }
    const paths = path.split('/');
    const fileName = paths.pop();
    path = paths.join('/');
    const containerClient = this.client.getContainerClient(path);
    const excelClient = containerClient.getBlobClient(fileName as string);
    return excelClient.url;
  }

  async get(path: string): Promise<undefined|Blob> {
    if (!path) { return; }
    const url = this.getFileUrl(path) as string;
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
  }

  download(path: string) {
    if (!path) { return; }
    return window.open(this.getFileUrl(path), '_blank');
  }

  downloadBlob(file: Blob, fileName: string) {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(file);
    link.download = fileName;
    document.body.append(link);
    link.click();
    link.remove();
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  };


  readFileAsync(file: File) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    })
  }

  async upload(containerName: string, files: File[]|File) {
    const containerClient = this.client.getContainerClient(containerName);
    const uploads: any[] = [];
    for (const file of (Array.isArray(files) ? files : [files])) {
      const blockBlobClient = containerClient.getBlockBlobClient(file.name);
      uploads.push(blockBlobClient.uploadBrowserData(file));
    }
    await Promise.all(uploads);
  }

  async delete(paths: string|string[]) {
    for (let path of (Array.isArray(paths) ? paths : [paths]).filter(p => p)) {
      const paths = path.split('/');
      const fileName = paths.pop();
      path = paths.join('/');
      const containerClient = this.client.getContainerClient(path);
      if (!await containerClient.exists()) {
        await containerClient.create();
      }
      const blob = containerClient.getBlockBlobClient(fileName as string);
      await blob.delete();
    }
  }
}
