import { NgModule } from '@angular/core';

import {
  DeleteZahlungseingangCommandServiceModule,
  DeleteZahlungseingangCommandComponentModule
} from './';

@NgModule({
  imports: [
    DeleteZahlungseingangCommandServiceModule,
    DeleteZahlungseingangCommandComponentModule
  ],
  exports: [
    DeleteZahlungseingangCommandServiceModule,
    DeleteZahlungseingangCommandComponentModule
  ]
})
export class DeleteZahlungseingangCommandModule { }
