import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { DurchsuchenServiceModule } from '../';
import { SavedDurchsuchenFiltersComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    DurchsuchenServiceModule
  ],
  declarations: [SavedDurchsuchenFiltersComponent],
  exports: [SavedDurchsuchenFiltersComponent],

})
export class SavedDurchsuchenFiltersComponentModule { }
