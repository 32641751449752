import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IDeleteFeldRequest, IDeleteFeldResponse, IDeleteFeldCommandShapes } from '@fa-kt-evaluation/felder/types';

@Injectable({
  providedIn: 'root'
})
export class DeleteFeldCommandService {

  readonly route = 'felder/feld';
  protected dependencies = [
      'felder/written-feld-kategorie',
      'felder/removed-feld-kategorie',
      'felder/written-feld-unterkategorie',
      'felder/removed-feld-unterkategorie',
      'felder/feld-entfernt'
  ];

  readonly $shapes = new BehaviorSubject<IDeleteFeldCommandShapes|undefined>(undefined);
  
  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IDeleteFeldCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IDeleteFeldCommandShapes>(this.route + '/delete/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IDeleteFeldRequest): Promise<IDeleteFeldResponse> {
    return await this.http.delete<IDeleteFeldResponse>(this.route + this.http.serialize(payload)) as unknown as IDeleteFeldResponse;
  }
  
}
