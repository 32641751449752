import { ModuleWithProviders, NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { HttpInterceptor, HttpService, OfflineCacheComponent, OfflineCacheService } from "./";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzStatisticModule } from "ng-zorro-antd/statistic";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { LIB_FilesModule } from "@lib/files/frontend";

@NgModule({
  imports: [
    CommonModule,
    LIB_FilesModule,
    HttpClientModule,
    NzSpinModule,
    NzGridModule,
    NzStatisticModule,
    NzButtonModule,
    NzCollapseModule
  ],
  declarations: [
    OfflineCacheComponent
  ],
  exports: [
    OfflineCacheComponent
  ]
})
export class LIB_HttpsModule {
  static forRoot(): ModuleWithProviders<LIB_HttpsModule> {
    return {
      ngModule: LIB_HttpsModule,
      providers: [
        HttpService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpInterceptor,
          multi: true
        },
        OfflineCacheService
      ],
    };
  }
}
