import { NgModule } from '@angular/core';

import { LIB_CommonModule } from "@lib/common/frontend";

import { BerichtService } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
  ],
  providers: [
    BerichtService,
  ],
  exports: []
})
export class BerichtServiceModule { }
