import { Component, OnInit, OnDestroy, Inject, Input, Output, EventEmitter, Injector, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { NzModalRef, NzModalService } from "ng-zorro-antd/modal";
import { NzMessageService } from "ng-zorro-antd/message";

import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

import { AuthService } from "@lib/auth/frontend";
import {
  ActionService,
  cleanObject, toInitials,
  CustomValidators, CustomFormatter,
  ISitemap, ISitemapCommand, SITEMAP,
} from "@lib/common/frontend";

import { IPostZeitRequest, IPostZeitResponse } from "@fa-kt-evaluation/zeiten/types";
import { PostZeitCommandService } from '@fa-kt-evaluation/zeiten/frontend'
import { ISelection } from "@lib/persistence/types";
import { ProjekteService } from "@fa-kt-evaluation/projekte/frontend";

/* << importing    */
/*    importing >> */

@Component({
  selector: 'fa-kt-evaluation-post-zeit',
  templateUrl: './post-zeit.component.html',
  styleUrls: ['./post-zeit.component.css']
})
export class PostZeitCommandComponent implements OnInit, OnDestroy {
  command!: ISitemapCommand;

  subscriptions: Subscription[] = [];
  form!: FormGroup;
  $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $valid: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  CustomFormatter = CustomFormatter;

  @Input() set loading(loading: boolean) { this.$loading.next(this.$loading.getValue() || loading); }
  @Input() set value(value: IPostZeitRequest) { this.patch(value); }
  @Output() valueChanged: EventEmitter<IPostZeitRequest> = new EventEmitter<IPostZeitRequest>();
  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() finished: EventEmitter<IPostZeitResponse> = new EventEmitter<IPostZeitResponse>();

/* << fields    */
  $projekte = new BehaviorSubject<ISelection[]>([]);
/*    fields >> */

  get modalRef() {
    return this.injector.get(NzModalRef)
  }

  constructor(
    @Inject(SITEMAP) private sitemap: ISitemap,
    private fb: FormBuilder,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private modal: NzModalService,
    private message: NzMessageService,
    private actions: ActionService,
    public auth: AuthService,
    public postZeit: PostZeitCommandService,
    public projekte: ProjekteService,
/* << more    */
/*    more >> */
  ) {
    this.command = sitemap.ZEITEN.Commands.POST_ZEIT;
    this.form = this.fb.group({
      zeit: this.fb.group({
        id: [null, []],
        mitarbeiter: [null, [Validators.required]],
        projekt: [null, []],
        gutachten: [[], []],
        kunde: [null, []],
        zeitArt: [null, [Validators.required]],
        zeitUnterart: [null, []],
        datum: [null, [Validators.required]],
        wiederholenBis: [null, []],
        stunden: [null, []],
        minuten: [null, []],
        kommentar: [null, []],
        kosten: [null, []],
      })
    });
/* << constructor    */
/*    constructor >> */
    this.patch();
  }

  ngOnInit() {
    this.postZeit.prepare().catch(e => console.error(e));
    this.projekte.request({}).catch(e => console.error(e));
    this.subscriptions.push(
      this.projekte.$result.subscribe(result => {
        if (!result) { return; }
        this.$projekte.next(result.projekte.map(r => r.projekt).map(projekt => ({
          label: projekt.nummer + ' | ' + projekt.bezeichnung,
          value: projekt.id,
          filter: projekt.kunde
        })));
      }),
      this.finished.subscribe((result) => { if (this.modalRef) { this.modalRef.destroy(result); }}),
      this.valid.subscribe((valid) => {
        if (valid) {
          this.actions.subscribe({ key: this.command.translation, action: async () => await this.submit() })
        } else {
          this.actions.unsubscribe(this.command.translation);
        }
      }),
      this.form.valueChanges.subscribe((value: IPostZeitRequest) => {
        this.valueChanged.emit(value);
        this.valid.emit(this.form.valid);
      }),
      this.zeitId?.valueChanges.subscribe(async (id) => {
/* << on-zeit-id-change    */
/*    on-zeit-id-change >> */
      }) as Subscription,
      this.zeitMitarbeiter?.valueChanges.subscribe(async (mitarbeiter) => {
/* << on-zeit-mitarbeiter-change    */
/*    on-zeit-mitarbeiter-change >> */
      }) as Subscription,
      this.zeitProjekt?.valueChanges.subscribe(async (projekt) => {
/* << on-zeit-projekt-change    */
        const entry = this.$projekte.getValue()?.find((p) => p.value === projekt);
        this.zeitKunde?.patchValue(entry?.filter);
/*    on-zeit-projekt-change >> */
      }) as Subscription,
      this.zeitGutachten?.valueChanges.subscribe(async (gutachten) => {
/* << on-zeit-gutachten-change    */
/*    on-zeit-gutachten-change >> */
      }) as Subscription,
      this.zeitKunde?.valueChanges.subscribe(async (kunde) => {
/* << on-zeit-kunde-change    */
/*    on-zeit-kunde-change >> */
      }) as Subscription,
      this.zeitZeitArt?.valueChanges.subscribe(async (zeitArt) => {
/* << on-zeit-zeit-art-change    */
/*    on-zeit-zeit-art-change >> */
      }) as Subscription,
      this.zeitZeitUnterart?.valueChanges.subscribe(async (zeitUnterart) => {
/* << on-zeit-zeit-unterart-change    */
/*    on-zeit-zeit-unterart-change >> */
      }) as Subscription,
      this.zeitDatum?.valueChanges.subscribe(async (datum) => {
/* << on-zeit-datum-change    */
/*    on-zeit-datum-change >> */
      }) as Subscription,
      this.zeitStunden?.valueChanges.subscribe(async (stunden) => {
/* << on-zeit-stunden-change    */
/*    on-zeit-stunden-change >> */
      }) as Subscription,
      this.zeitMinuten?.valueChanges.subscribe(async (minuten) => {
/* << on-zeit-minuten-change    */
/*    on-zeit-minuten-change >> */
      }) as Subscription,
      this.zeitKommentar?.valueChanges.subscribe(async (kommentar) => {
/* << on-zeit-kommentar-change    */
/*    on-zeit-kommentar-change >> */
      }) as Subscription,
      this.zeitKosten?.valueChanges.subscribe(async (kosten) => {
/* << on-zeit-kosten-change    */
/*    on-zeit-kosten-change >> */
      }) as Subscription,
/* << subscibe    */
/*    subscibe >> */
    );
/* << init    */
/*    init >> */
  }

  ngOnDestroy() {
/* << end    */
/*    end >> */
    this.actions.unsubscribe(this.command.translation);
    this.subscriptions.filter(($: Subscription) => $ && !$.closed).forEach(($: Subscription) => $.unsubscribe());
  }

  private patch(value?: IPostZeitRequest) {
    if (value) {
      value = cleanObject(value);
      if (value.zeit) {
        value.zeit = cleanObject(value.zeit);
      }
      if (value.zeit.id) {
        this.form.controls.zeit?.get('wiederholenBis')?.disable();
      }
      this.form.patchValue(value);
/* << custom    */
/*    custom >> */
    }
    this.update();
  }

  update() {
    this.$loading.next(true);
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    this.$valid.next(this.form.valid);
    this.$loading.next(false);
  }

  async submit() {
    this.update();
    if (!this.form.valid) {
      return;
    }
    this.$loading.next(true);
    try {
      const payload = cleanObject(this.form.getRawValue());
      if (payload.zeit) {
        payload.zeit = cleanObject(payload.zeit);
      }
      const response: IPostZeitResponse = await this.postZeit.request(payload);
      this.finished.next(response);
    } catch (error: any) {
      this.message.error(error.message);
      for (const validation of (error.errors ? error.errors : [])) {
        for (const children of (validation.children ? validation.children : [])) {
          const message = Object.values(children.constraints).reduce((acc, val) => acc + ' ' + val, '');
          this.form.controls[validation.property].get(children.property)?.setErrors({ server: message });
        }
      }
    }
    this.$loading.next(false);
  }

/* << methods    */
/*    methods >> */

  get zeitId() {
    return this.form.controls.zeit.get('id');
  };
  get zeitMitarbeiter() {
    return this.form.controls.zeit.get('mitarbeiter');
  };
  get zeitProjekt() {
    return this.form.controls.zeit.get('projekt');
  };
  get zeitGutachten() {
    return this.form.controls.zeit.get('gutachten');
  };
  get zeitKunde() {
    return this.form.controls.zeit.get('kunde');
  };
  get zeitZeitArt() {
    return this.form.controls.zeit.get('zeitArt');
  };
  get zeitZeitUnterart() {
    return this.form.controls.zeit.get('zeitUnterart');
  };
  get zeitDatum() {
    return this.form.controls.zeit.get('datum');
  };
  get zeitStunden() {
    return this.form.controls.zeit.get('stunden');
  };
  get zeitMinuten() {
    return this.form.controls.zeit.get('minuten');
  };
  get zeitKommentar() {
    return this.form.controls.zeit.get('kommentar');
  };
  get zeitKosten() {
    return this.form.controls.zeit.get('kosten');
  };


}
