import { Injectable } from '@angular/core';
import { AbstractControl } from "@angular/forms";

import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, first, map } from "rxjs/operators";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { FilterPipe, SearchPipe, SorterPipe } from "@lib/filter/frontend";
import { ISelection } from "@lib/persistence/types";

import {
  IZeitUnterartsRequest,
  IZeitUnterartsResponse,
  IZeitUnterartsQuerySorter,
  IZeitUnterartsQueryFilter,
  IZeitUnterartsQueryFilterShapes,
  IZeitUnterartsResponseGroup,
} from '@fa-kt-evaluation/zeiten/types';

@Injectable({
  providedIn: 'root'
})
export class ZeitUnterartsService {

  protected route = 'zeiten/settings/zeit-unterarts';
  protected dependencies = [
    'zeiten/written-zeit-unterart',
    'zeiten/removed-zeit-unterart',
    'zeiten/written-zeit-art',
    'zeiten/removed-zeit-art',
  ];

  readonly $result = new BehaviorSubject<IZeitUnterartsResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IZeitUnterartsRequest|undefined>(undefined);
  readonly $response = new BehaviorSubject<IZeitUnterartsResponse|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IZeitUnterartsQueryFilterShapes|undefined>(undefined);
  readonly $filter = new BehaviorSubject<IZeitUnterartsQueryFilter>({});
  readonly $sorter = new BehaviorSubject<IZeitUnterartsQuerySorter>({});
  readonly $search = new BehaviorSubject<string>('');
  private readonly $filtered = new BehaviorSubject<IZeitUnterartsResponse|undefined>(undefined);
  private readonly $searched = new BehaviorSubject<IZeitUnterartsResponse|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private search: SearchPipe,
    private filter: FilterPipe,
    private sorter: SorterPipe,
    private device: DeviceConfigService
  ) {
    combineLatest([
      this.$response,
      this.$filter,
    ]).subscribe(([response, filter]) => {
      if (response && response.zeitUnterarts) {
        const zeitUnterarts = this.filter.transform(response.zeitUnterarts, filter, true);
        this.$filtered.next( { ...response, zeitUnterarts });
      } else {
        this.$filtered.next(response);
      }
    });
    combineLatest([
      this.$filtered,
      this.$search.pipe(debounceTime(device.debounceTime), distinctUntilChanged()),
      this.$shapes
    ]).subscribe(([filtered, search, shapes]) => {
      if (filtered && filtered.zeitUnterarts) {
        const zeitUnterarts = this.search.transform(filtered.zeitUnterarts, search, '_search', shapes);
        this.$searched.next( { ...filtered, zeitUnterarts });
      } else {
        this.$searched.next(filtered);
      }
    });
    combineLatest([
      this.$searched,
      this.$sorter,
      this.$shapes
    ]).pipe(debounceTime(10)).subscribe(([searched, sorter, shapes]) => {
      if (searched && searched.zeitUnterarts) {
        const zeitUnterarts = this.sorter.transform(searched.zeitUnterarts, sorter, shapes);
        this.$result.next( { ...searched, zeitUnterarts });
      } else {
        this.$result.next(searched);
      }
    });
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IZeitUnterartsRequest): IZeitUnterartsRequest {
    if (payload) {
      this.$payload.next(payload as IZeitUnterartsRequest);
    } else {
      payload = this.$payload.getValue() as IZeitUnterartsRequest;
    }
    return payload;
  }

  public async request(payload?: IZeitUnterartsRequest, force = false): Promise<IZeitUnterartsResponse> {
    payload = this.payload(payload);
    const state = this.$response.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const { response, shapes } = await this.http.get<IZeitUnterartsResponseGroup>(this.route + this.http.serialize(payload));
    this.$shapes.next(shapes);
    this.$response.next(response);
    return response;
  }
  

  async getZeitUnterartsZeitArt(value: string): Promise<ISelection|undefined> {
    return new Promise(resolve => {
      this.zeitUnterartsZeitArt.pipe(first()).subscribe(zeitUnterartsZeitArt => resolve(zeitUnterartsZeitArt.find(a => a.value === value)));
    });
  }

  get zeitUnterartsZeitArt(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['zeitUnterarts.zeitArt'] as ISelection[] : []));
  }


}
