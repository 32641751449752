import { Component, OnInit } from '@angular/core';

import { NzMarks } from "ng-zorro-antd/slider";

import { DevicePerformance } from '@lib/common/frontend';
import { DeviceConfigService } from "../../services";

@Component({
  selector: 'codeboard-device-config',
  templateUrl: './device-config.component.html',
  styleUrls: ['./device-config.component.css']
})
export class DeviceConfigComponent implements OnInit {

  DevicePerformance = DevicePerformance;
  nzDevicePerformanceTipFormatter = (value: DevicePerformance) => DevicePerformance[value];
  marks: NzMarks = {
    2: 'Optimale Suche',
    1: 'Verzögerte Suche',
    0: 'Einfache Suche'
  };

  min = 0;
  max = 2;
  mid = parseFloat(((this.max - this.min) / 2).toFixed(5));
  preHighLight = false;
  nextHighLight = false;


  constructor(public deviceConfigService: DeviceConfigService) { }

  ngOnInit(): void {
  }

  setDeviceConfigPerformance(devicePerformance: DevicePerformance) {
    this.deviceConfigService.writeDeviceConfigPerformance(devicePerformance)
    this.highlightIcon(devicePerformance);
  }

  highlightIcon(devicePerformance: DevicePerformance): void {
    this.preHighLight = devicePerformance <= this.mid;
    this.nextHighLight = devicePerformance >= this.mid;
  }

  setDeviceConfigAlwaysRefresh(alwaysRefresh: boolean) {
    this.deviceConfigService.writeDeviceConfigAlwaysRefresh(alwaysRefresh)
  }
}
