import { NgModule } from '@angular/core';
import { 
  DeleteBesichtigungCommandModule,
  PostBesichtigungCommandModule,
  PostOrdneBesichtigungenCommandModule,
  PostStartTourCommandModule,
  DeleteTourCommandModule,
  PostTourCommandModule
} from './';

@NgModule({
  declarations: [],
  imports: [
    DeleteBesichtigungCommandModule,
    PostBesichtigungCommandModule,
    PostOrdneBesichtigungenCommandModule,
    PostStartTourCommandModule,
    DeleteTourCommandModule,
    PostTourCommandModule
  ],
  exports: [
    DeleteBesichtigungCommandModule,
    PostBesichtigungCommandModule,
    PostOrdneBesichtigungenCommandModule,
    PostStartTourCommandModule,
    DeleteTourCommandModule,
    PostTourCommandModule
  ]
})
export class BesichtigungenCommandsModule { }
