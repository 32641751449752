import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { GutachtenServiceModule } from '../';
import { GutachtenFilterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    GutachtenServiceModule
  ],
  declarations: [GutachtenFilterComponent],
  exports: [GutachtenFilterComponent],

})
export class GutachtenFilterComponentModule { }
