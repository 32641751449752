import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { first, map, debounceTime, distinctUntilChanged } from "rxjs/operators";

import { isEqual } from "lodash";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { IPersistedFilter, IPersistedFilterList } from "@lib/filter/types";
import { ISelection } from "@lib/persistence/types";

import {
  IProjektRequest,
  IProjektResponse,
  IProjektResponseGroup,
  IProjektQueryFilterShapes
} from '@fa-kt-evaluation/projekte/types';

@Injectable({
  providedIn: 'root'
})
export class ProjektService {

  protected route = 'projekte/projekt';
  protected dependencies = [
    'kunden/written-kunden-art',
    'kunden/removed-kunden-art',
    'kunden/written-kunden-gruppe',
    'kunden/removed-kunden-gruppe',
    'personen/written-standort',
    'personen/removed-standort',
    'projekte/written-projekt-art',
    'projekte/removed-projekt-art',
    'projekte/written-projekt-status',
    'projekte/removed-projekt-status',

    'besichtigungen/besichtigung-entfernt',
    'besichtigungen/besichtigung-geordnet',
    'besichtigungen/besichtigung-gespeichert',
    'dokumente/datei-gespeichert',
    'finanzen/extra-vereinbarung-entfernt',
    'finanzen/extra-vereinbarung-gespeichert',
    'finanzen/honorar-vereinbarung-entfernt',
    'finanzen/honorar-vereinbarung-gespeichert',
    'finanzen/rechnung-erstellt',
    'finanzen/zahlungseingang-entfernt',
    'finanzen/zahlungseingang-gespeichert',
    'formulare/durchgang-gestartet',
    'formulare/formular-entfernt',
    'formulare/formular-gespeichert',
    'formulare/formular-kopiert',
    'gutachten/gutachten-entfernt',
    'gutachten/gutachten-gespeichert',
    'kommunikation/erinnerung-abgemeldet',
    'kommunikation/erinnerung-entfernt',
    'kommunikation/erinnerung-gemerkt',
    'kommunikation/vermerk-entfernt',
    'kommunikation/vermerkt',
    'kunden/abteilung-entfernt',
    'kunden/abteilung-gespeichert',
    'kunden/fond-entfernt',
    'kunden/fond-gespeichert',
    'kunden/kunde-entfernt',
    'kunden/kunde-gespeichert',
    'kunden/kunden-finanzen-gespeichert',
    'personen/mitarbeiter-aktualisiert',
    'projekte/projekt-archiviert',
    'projekte/projekt-entfernt',
    'projekte/projekt-gespeichert',
    'projekte/projekt-kopiert',
    'projekte/projekt-reaktiviert',
    'texte/vorlage-entfernt',
    'texte/vorlage-gespeichert',
    'zeiten/zeit-entfernt',
    'zeiten/zeit-gespeichert'
  ];

  readonly $result = new BehaviorSubject<IProjektResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IProjektRequest|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IProjektQueryFilterShapes|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private device: DeviceConfigService,
  ) {
/* << changes    */
/*    changes >> */
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IProjektRequest): IProjektRequest {
    if (payload) {
      this.$payload.next(payload as IProjektRequest);
    } else {
      payload = this.$payload.getValue() as IProjektRequest;
    }
    return payload;
  }

  public async request(payload: IProjektRequest, force = false): Promise<IProjektResponse> {
    const state = this.$result.getValue();
    if (!force && !this.device.alwaysRefresh && state && isEqual(payload, this.payload())) { return state; }
    payload = this.payload(payload);
    // if (!payload) { return this.$result.getValue() as IProjektResponse; }
    const query = this.http.serialize(payload);
    const { response, shapes } = await this.http.get<IProjektResponseGroup>(this.route + query);
    this.$result.next(response);
    this.$shapes.next(shapes);
    return response;
  }


  getProjektAbteilung(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.abteilung']?.find(v => v.value === value)
  }

  get projektAbteilung(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.abteilung'] as ISelection[] : []));
  }

  getRechnungenBesichtigungen(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['rechnungen.besichtigungen']?.find(v => v.value === value)
  }

  get rechnungenBesichtigungen(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['rechnungen.besichtigungen'] as ISelection[] : []));
  }

  getRechnungenExtraVereinbarungs(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['rechnungen.extraVereinbarungs']?.find(v => v.value === value)
  }

  get rechnungenExtraVereinbarungs(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['rechnungen.extraVereinbarungs'] as ISelection[] : []));
  }

  getProjektFond(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.fond']?.find(v => v.value === value)
  }

  get projektFond(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.fond'] as ISelection[] : []));
  }

  getRechnungenHonorarVereinbarung(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['rechnungen.honorarVereinbarung']?.find(v => v.value === value)
  }

  get rechnungenHonorarVereinbarung(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['rechnungen.honorarVereinbarung'] as ISelection[] : []));
  }

  getProjektKunde(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.kunde']?.find(v => v.value === value)
  }

  get projektKunde(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.kunde'] as ISelection[] : []));
  }

  getProjektBank(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.bank']?.find(v => v.value === value)
  }

  get projektBank(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.bank'] as ISelection[] : []));
  }

  getKundeKundenArt(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['kunde.kundenArt']?.find(v => v.value === value)
  }

  get kundeKundenArt(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['kunde.kundenArt'] as ISelection[] : []));
  }

  getKundeKundenGruppe(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['kunde.kundenGruppe']?.find(v => v.value === value)
  }

  get kundeKundenGruppe(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['kunde.kundenGruppe'] as ISelection[] : []));
  }

  getProjektBesichtigungen(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.besichtigungen']?.find(v => v.value === value)
  }

  get projektBesichtigungen(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.besichtigungen'] as ISelection[] : []));
  }

  getProjektGutachten(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.gutachten']?.find(v => v.value === value)
  }

  get projektGutachten(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.gutachten'] as ISelection[] : []));
  }

  getProjektPruefung(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.pruefung']?.find(v => v.value === value)
  }

  get projektPruefung(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.pruefung'] as ISelection[] : []));
  }

  getVermerkeVerfasser(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['vermerke.verfasser']?.find(v => v.value === value)
  }

  get vermerkeVerfasser(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['vermerke.verfasser'] as ISelection[] : []));
  }

  getErinnerungenMitarbeiter(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['erinnerungen.mitarbeiter']?.find(v => v.value === value)
  }

  get erinnerungenMitarbeiter(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['erinnerungen.mitarbeiter'] as ISelection[] : []));
  }

  getErinnerungenVerfasser(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['erinnerungen.verfasser']?.find(v => v.value === value)
  }

  get erinnerungenVerfasser(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['erinnerungen.verfasser'] as ISelection[] : []));
  }

  getProjektProjektArt(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.projektArt']?.find(v => v.value === value)
  }

  get projektProjektArt(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.projektArt'] as ISelection[] : []));
  }

  getProjektProjektStatus(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.projektStatus']?.find(v => v.value === value)
  }

  get projektProjektStatus(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.projektStatus'] as ISelection[] : []));
  }

  getZahlungseingaengeRechnung(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['zahlungseingaenge.rechnung']?.find(v => v.value === value)
  }

  get zahlungseingaengeRechnung(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['zahlungseingaenge.rechnung'] as ISelection[] : []));
  }

  getProjektStandort(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['projekt.standort']?.find(v => v.value === value)
  }

  get projektStandort(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['projekt.standort'] as ISelection[] : []));
  }


}
