import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { VorlageServiceModule } from '../';
import { VorlageSorterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    VorlageServiceModule
  ],
  declarations: [VorlageSorterComponent],
  exports: [VorlageSorterComponent],

})
export class VorlageSorterComponentModule { }
