import { NgModule } from '@angular/core';
import {
  DurchgangFilterComponentModule,
  DurchgangDetailComponentModule,
  DurchgangSorterComponentModule,
  SavedDurchgangFiltersComponentModule
} from './';

@NgModule({
  imports: [
    DurchgangFilterComponentModule,
    DurchgangDetailComponentModule,
    DurchgangSorterComponentModule,
    SavedDurchgangFiltersComponentModule
  ],
  exports: [
    DurchgangFilterComponentModule,
    DurchgangDetailComponentModule,
    DurchgangSorterComponentModule,
    SavedDurchgangFiltersComponentModule
  ],
})
export class DurchgangQueryModule { }
