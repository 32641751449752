import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { DeleteTourCommandServiceModule } from '../';
import { DeleteTourCommandComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    DeleteTourCommandServiceModule
  ],
  declarations: [DeleteTourCommandComponent],
  exports: [DeleteTourCommandComponent],

})
export class DeleteTourCommandComponentModule { }
