import { NgModule } from '@angular/core';

import {
  DeleteBerichtsVorlageCommandServiceModule,
  DeleteBerichtsVorlageCommandComponentModule
} from './';

@NgModule({
  imports: [
    DeleteBerichtsVorlageCommandServiceModule,
    DeleteBerichtsVorlageCommandComponentModule
  ],
  exports: [
    DeleteBerichtsVorlageCommandServiceModule,
    DeleteBerichtsVorlageCommandComponentModule
  ]
})
export class DeleteBerichtsVorlageCommandModule { }
