import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { DashboardServiceModule } from '../';
import { DashboardFilterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    DashboardServiceModule
  ],
  declarations: [DashboardFilterComponent],
  exports: [DashboardFilterComponent],

})
export class DashboardFilterComponentModule { }
