import { NgModule } from '@angular/core';
import {
  OffeneGutachtenFilterComponentModule,
  OffeneGutachtenListComponentModule,
  OffeneGutachtenSorterComponentModule,
  SavedOffeneGutachtenFiltersComponentModule
} from './';

@NgModule({
  imports: [
    OffeneGutachtenFilterComponentModule,
    OffeneGutachtenListComponentModule,
    OffeneGutachtenSorterComponentModule,
    SavedOffeneGutachtenFiltersComponentModule
  ],
  exports: [
    OffeneGutachtenFilterComponentModule,
    OffeneGutachtenListComponentModule,
    OffeneGutachtenSorterComponentModule,
    SavedOffeneGutachtenFiltersComponentModule
  ],
})
export class OffeneGutachtenQueryModule { }
