import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IDeleteZeitRequest, IDeleteZeitResponse, IDeleteZeitCommandShapes } from '@fa-kt-evaluation/zeiten/types';

@Injectable({
  providedIn: 'root'
})
export class DeleteZeitCommandService {

  readonly route = 'zeiten/zeit';
  protected dependencies = [
      'zeiten/written-zeit-art',
      'zeiten/removed-zeit-art',
      'zeiten/written-zeit-unterart',
      'zeiten/removed-zeit-unterart',
      'zeiten/zeit-entfernt'
  ];

  readonly $shapes = new BehaviorSubject<IDeleteZeitCommandShapes|undefined>(undefined);
  
  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IDeleteZeitCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IDeleteZeitCommandShapes>(this.route + '/delete/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IDeleteZeitRequest): Promise<IDeleteZeitResponse> {
    return await this.http.delete<IDeleteZeitResponse>(this.route + this.http.serialize(payload)) as unknown as IDeleteZeitResponse;
  }
  
}
