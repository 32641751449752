import { NgModule } from '@angular/core';

import {
  DeleteFeldCommandServiceModule,
  DeleteFeldCommandComponentModule
} from './';

@NgModule({
  imports: [
    DeleteFeldCommandServiceModule,
    DeleteFeldCommandComponentModule
  ],
  exports: [
    DeleteFeldCommandServiceModule,
    DeleteFeldCommandComponentModule
  ]
})
export class DeleteFeldCommandModule { }
