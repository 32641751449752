import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { OffeneGutachtenServiceModule } from '../';
import { SavedOffeneGutachtenFiltersComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    OffeneGutachtenServiceModule
  ],
  declarations: [SavedOffeneGutachtenFiltersComponent],
  exports: [SavedOffeneGutachtenFiltersComponent],

})
export class SavedOffeneGutachtenFiltersComponentModule { }
