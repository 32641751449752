import { NgModule } from '@angular/core';
import { LIB_CommonModule } from '@lib/common/frontend';
import { RechnungsVorlagenServiceModule } from '../';
import { RechnungsVorlagenSorterComponent } from './';

@NgModule({
  imports: [
    LIB_CommonModule,
    RechnungsVorlagenServiceModule
  ],
  declarations: [RechnungsVorlagenSorterComponent],
  exports: [RechnungsVorlagenSorterComponent],

})
export class RechnungsVorlagenSorterComponentModule { }
