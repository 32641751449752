import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from "rxjs";
import { map, first } from "rxjs/operators";

import { ISelection } from "@lib/persistence/types";
import { BroadcastService, DeviceConfigService } from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";

import { IPostVorlageRequest, IPostVorlageResponse, IPostVorlageCommandShapes } from '@fa-kt-evaluation/texte/types';

@Injectable({
  providedIn: 'root'
})
export class PostVorlageCommandService {

  readonly route = 'texte/vorlage';
  protected dependencies = [
      'gutachten/written-bewertungs-anlass',
      'gutachten/removed-bewertungs-anlass',
      'kunden/written-kunden-art',
      'kunden/removed-kunden-art',
      'kunden/written-kunden-gruppe',
      'kunden/removed-kunden-gruppe',
      'objekte/written-objekt-art',
      'objekte/removed-objekt-art',
      'texte/vorlage-gespeichert'
  ];

  readonly $shapes = new BehaviorSubject<IPostVorlageCommandShapes|undefined>(undefined);
  
  constructor(private http: HttpService, private broadcast: BroadcastService, private device: DeviceConfigService) {
    this.broadcast.on(this.dependencies)(() => this.prepare(true));
  }

  public async prepare(force = false): Promise<IPostVorlageCommandShapes> {
    const state = this.$shapes.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const shapes = await this.http.get<IPostVorlageCommandShapes>(this.route + '/post/shapes');
    this.$shapes.next(shapes);
    return shapes;
  }

  public async request(payload: IPostVorlageRequest): Promise<IPostVorlageResponse> {
    return await this.http.post<IPostVorlageResponse>(this.route, payload) as unknown as IPostVorlageResponse;
  }
  
  getVorlageFuerBewertungsAnlaesse(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['vorlage.fuerBewertungsAnlaesse']?.find(v => v.value === value)
  }

  get vorlageFuerBewertungsAnlaesse(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['vorlage.fuerBewertungsAnlaesse'] as ISelection[] : []));
  }

  getVorlageFuerKunden(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['vorlage.fuerKunden']?.find(v => v.value === value)
  }

  get vorlageFuerKunden(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['vorlage.fuerKunden'] as ISelection[] : []));
  }

  getVorlageFuerKundenArten(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['vorlage.fuerKundenArten']?.find(v => v.value === value)
  }

  get vorlageFuerKundenArten(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['vorlage.fuerKundenArten'] as ISelection[] : []));
  }

  getVorlageFuerKundenGruppen(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['vorlage.fuerKundenGruppen']?.find(v => v.value === value)
  }

  get vorlageFuerKundenGruppen(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['vorlage.fuerKundenGruppen'] as ISelection[] : []));
  }

  getVorlageFuerObjektArten(value: string): ISelection|undefined {
    return this.$shapes.getValue()?.['vorlage.fuerObjektArten']?.find(v => v.value === value)
  }

  get vorlageFuerObjektArten(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['vorlage.fuerObjektArten'] as ISelection[] : []));
  }

}
