import { NgModule } from '@angular/core';

import {
  PostTourCommandServiceModule,
  PostTourCommandComponentModule
} from './';

@NgModule({
  imports: [
    PostTourCommandServiceModule,
    PostTourCommandComponentModule
  ],
  exports: [
    PostTourCommandServiceModule,
    PostTourCommandComponentModule
  ]
})
export class PostTourCommandModule { }
