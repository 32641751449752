import { Injectable } from '@angular/core';
import { AbstractControl } from "@angular/forms";

import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, first, map } from "rxjs/operators";

import {
  BroadcastService,
  DeviceConfigService
} from "@lib/common/frontend";
import { HttpService } from "@lib/https/frontend";
import { FilterPipe, SearchPipe, SorterPipe } from "@lib/filter/frontend";
import { ISelection } from "@lib/persistence/types";

import {
  IErforderlichesDokumentsRequest,
  IErforderlichesDokumentsResponse,
  IErforderlichesDokumentsQuerySorter,
  IErforderlichesDokumentsQueryFilter,
  IErforderlichesDokumentsQueryFilterShapes,
  IErforderlichesDokumentsResponseGroup,
} from '@fa-kt-evaluation/dokumente/types';

@Injectable({
  providedIn: 'root'
})
export class ErforderlichesDokumentsService {

  protected route = 'dokumente/settings/erforderliches-dokuments';
  protected dependencies = [
    'dokumente/written-erforderliches-dokument',
    'dokumente/removed-erforderliches-dokument',
    'gutachten/written-bewertungs-anlass',
    'gutachten/removed-bewertungs-anlass',
    'gutachten/written-gutachten-status',
    'gutachten/removed-gutachten-status',
    'objekte/written-objekt-art',
    'objekte/removed-objekt-art',
    'objekte/written-objekt-nutzung',
    'objekte/removed-objekt-nutzung',
  ];

  readonly $result = new BehaviorSubject<IErforderlichesDokumentsResponse|undefined>(undefined);
  readonly $payload = new BehaviorSubject<IErforderlichesDokumentsRequest|undefined>(undefined);
  readonly $response = new BehaviorSubject<IErforderlichesDokumentsResponse|undefined>(undefined);
  readonly $shapes = new BehaviorSubject<IErforderlichesDokumentsQueryFilterShapes|undefined>(undefined);
  readonly $filter = new BehaviorSubject<IErforderlichesDokumentsQueryFilter>({});
  readonly $sorter = new BehaviorSubject<IErforderlichesDokumentsQuerySorter>({});
  readonly $search = new BehaviorSubject<string>('');
  private readonly $filtered = new BehaviorSubject<IErforderlichesDokumentsResponse|undefined>(undefined);
  private readonly $searched = new BehaviorSubject<IErforderlichesDokumentsResponse|undefined>(undefined);

  constructor(
    private http: HttpService,
    private broadcast: BroadcastService,
    private search: SearchPipe,
    private filter: FilterPipe,
    private sorter: SorterPipe,
    private device: DeviceConfigService
  ) {
    combineLatest([
      this.$response,
      this.$filter,
    ]).subscribe(([response, filter]) => {
      if (response && response.erforderlichesDokuments) {
        const erforderlichesDokuments = this.filter.transform(response.erforderlichesDokuments, filter, true);
        this.$filtered.next( { ...response, erforderlichesDokuments });
      } else {
        this.$filtered.next(response);
      }
    });
    combineLatest([
      this.$filtered,
      this.$search.pipe(debounceTime(device.debounceTime), distinctUntilChanged()),
      this.$shapes
    ]).subscribe(([filtered, search, shapes]) => {
      if (filtered && filtered.erforderlichesDokuments) {
        const erforderlichesDokuments = this.search.transform(filtered.erforderlichesDokuments, search, '_search', shapes);
        this.$searched.next( { ...filtered, erforderlichesDokuments });
      } else {
        this.$searched.next(filtered);
      }
    });
    combineLatest([
      this.$searched,
      this.$sorter,
      this.$shapes
    ]).pipe(debounceTime(10)).subscribe(([searched, sorter, shapes]) => {
      if (searched && searched.erforderlichesDokuments) {
        const erforderlichesDokuments = this.sorter.transform(searched.erforderlichesDokuments, sorter, shapes);
        this.$result.next( { ...searched, erforderlichesDokuments });
      } else {
        this.$result.next(searched);
      }
    });
    this.broadcast.on(this.dependencies)(() => this.request(this.payload(), true));
  }

  private payload(payload?: IErforderlichesDokumentsRequest): IErforderlichesDokumentsRequest {
    if (payload) {
      this.$payload.next(payload as IErforderlichesDokumentsRequest);
    } else {
      payload = this.$payload.getValue() as IErforderlichesDokumentsRequest;
    }
    return payload;
  }

  public async request(payload?: IErforderlichesDokumentsRequest, force = false): Promise<IErforderlichesDokumentsResponse> {
    payload = this.payload(payload);
    const state = this.$response.getValue();
    if (!force && !this.device.alwaysRefresh && state) { return state; }
    const { response, shapes } = await this.http.get<IErforderlichesDokumentsResponseGroup>(this.route + this.http.serialize(payload));
    this.$shapes.next(shapes);
    this.$response.next(response);
    return response;
  }
  

  async getErforderlichesDokumentsGutachten(value: string): Promise<ISelection|undefined> {
    return new Promise(resolve => {
      this.erforderlichesDokumentsGutachten.pipe(first()).subscribe(erforderlichesDokumentsGutachten => resolve(erforderlichesDokumentsGutachten.find(a => a.value === value)));
    });
  }

  get erforderlichesDokumentsGutachten(): Observable<ISelection[]> {
    return this.$shapes.pipe(map(shapes => shapes ? shapes['erforderlichesDokuments.gutachten'] as ISelection[] : []));
  }


}
